<template>
	<div class="container">
		<div
			class="row justify-content-center align-content-center"
			style="height: 100vh"
			>
			<div class="col-md-6 col-sm-8 col-xs-12 col-lg-4">
				<div class="card">
					<div class="card-body">
						<div class="title mb-4">
							<img src="@/assets/logo.png" />
						</div>
						<template v-if="!isSuccessful">
							<form class="mt-4">
								<div class="mt-3 text-center">
									<h2 class="mb-1">Create a new password</h2>
									<small>Try using something you can easily remember.</small>
								</div>
								<div class="form-group" v-if="errorMessage">
									<div class="alert alert-danger">
										<span>{{ errorMessage }}</span>
									</div>
								</div>

								<div class="form-group mt-4">
									<label for="password" class="sr-only">Password</label>

									<div class="input-group">
										<input
											v-model.trim="$v.form.password.$model"
											:class="{
												'is-invalid':
													$v.form.password.$dirty && $v.form.password.$error,
											}"
											id="password"
											name="password"
											:type="showPassword ? 'text' : 'password'"
											placeholder="Password"
											class="form-control"
											/>
										<button
											type="button"
											@click.prevent="toggleShowPassword"
											class="btn btn-light input-group-btn"
											>
											<span
												class="fe"
												:class="{
													'fe-eye': showPassword,
													'fe-eye-off': !showPassword,
												}"
												></span>
										</button>
									</div>

									<div
										class="invalid-feedback"
										v-if="
											$v.form.password.$dirty && !$v.form.password.$required
										"
										>
										Password is required.
									</div>
								</div>

								<div class="form-group mt-4">
									<label for="password" class="sr-only">Confirm Password</label>

									<div class="input-group">
										<input
											v-model.trim="$v.form.confirm_password.$model"
											:class="{
												'is-invalid':
													$v.form.confirm_password.$dirty &&
													$v.form.confirm_password.$error,
											}"
											id="confirm-password"
											name="confirm-password"
											:type="showPassword ? 'text' : 'password'"
											placeholder="Confirm password"
											class="form-control"
											/>
										<button
											type="button"
											@click.prevent="toggleShowPassword"
											class="btn btn-light input-group-btn"
											>
											<span
												class="fe"
												:class="{
													'fe-eye': showPassword,
													'fe-eye-off': !showPassword,
												}"
												></span>
										</button>
									</div>

									<div
										class="invalid-feedback"
										v-if="!$v.form.confirm_password.sameAsPassword"
										>
										Passwords must be identical.
									</div>
								</div>

								<div class="form-group">
									<button
										id="login-btn"
										:disabled="processing"
										class="btn btn-primary btn-block"
										type="submit"
										@click="resetPassword"
										>
										<span v-if="!processing">Set Password</span>
										<span v-else>
											<i class="fa fa-spin fa-spinner"></i>
										</span>
									</button>
								</div>
							</form>
						</template>

						<template v-else>
							<div class="text-center">
								<h2 class="mt-2">Password changed</h2>
								<p class="mt-1">
									You have successfully changed your password
								</p>
								<router-link to="/" class=""
									><button class="btn btn-primary btn-block">
										Back to login
									</button></router-link
									>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
  props: ['token'],
  data () {
    return {
      isSuccessful: false,
      showPassword: false,
      processing: false,
      errorMessage: '',
      form: {
        password: '',
        confirm_password: '',
        type: this.$route.query.type,
        token: this.token
      }
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      confirm_password: {
        sameAsPassword: sameAs('password')
      }
    }
  },
  methods: {
    toggleShowPassword () {
      this.showPassword = !this.showPassword
    },
    resetPassword () {
      this.$v.form.$touch()

      if (this.processing || this.$v.form.$error) {
        return
      }

      this.processing = true
      this.errorMessage = null

      this.axios
        .post('/v1/password/change', { ...this.form })
        .then(async () => {
          this.isSuccessful = true
        })
        .catch((err) => {
          this.isSuccessful = false

          if (err.response && err.response.data.message) {
            this.errorMessage = err.response.data.message
          } else {
            this.errorMessage = 'Oops! An error occurred. Please try again.'
          }
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
