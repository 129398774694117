<template>
	<div>
		<b-modal
			:id="modalId"
			centered
			no-close-on-backdrop
			no-close-on-esc
			hide-header
			footer-class="border-top-0"
			>
			<section class="d-flex flex-column align-items-center">
				<div class="circle-ripple">
					<i class="fe fe-alert-circle text-warn" style="font-size: 24px"></i>
				</div>
				<p class="mt-4 mb-0 title-text"> {{ modalType === 'attendees' ? 'Notify attendees': 'Notify attendee' }}</p>
				<p class="mt-2 mb-0 description-text">
          {{ modalType === 'attendees' ? 
          'Do you want to notify all attendees that routes are available?':
          'Do you want to notify this attendee that routes are available?' }}
				</p>
			</section>
			<template #modal-footer>
				<div class="btns">
					<button
						class="btn cancel"
            type="button"
						@click="$bvModal.hide('notify-attendees-modal')"
						>
						Cancel
					</button>
					<button
						:disabled="loading"
            type="button"
						class="btn proceed btn-primary"
						@click="$emit('onNotifyAttendees')"
						>
						{{ loading ? 'Processing...' : 'Proceed' }}
					</button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
export default {
  name: 'NotifyAttendeeModal',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    modalType: {
      type: 'attendees' | 'attendee',
      required: true
    }
  },
  data() {
    return {
      modalId: 'notify-attendees-modal',
    }
  },
  emits: ['onNotifyAttendees']
}
</script>

<style lang="scss" scoped>
$caution-icon-bg: #fef0c7;

.hidden {
  display: none !important;
}
.title-text {
  color: #101828;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.description-text {
  color: #667085;
  font-size: 14px;
  line-height: 20px;
}

.text-warn {
  display: block;
  text-align: center;
  color: #dc6803;
}

.btns {
  width: 100%;
  display: flex;
  gap: 20px;

  .btn {
    height: 44px;

    &.cancel {
      flex: 1;
      border: 1px solid#D0D5DD;
    }
    &.proceed {
      flex: 1;
    }
  }
}

.circle-ripple {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $caution-icon-bg;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  animation: ripple 0.7s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($caution-icon-bg, 0.3),
      0 0 0 1em rgba($caution-icon-bg, 0);
  }
  100% {
    box-shadow: 0 0 0 1em rgba($caution-icon-bg, 0.3),
      0 0 0 3em rgba($caution-icon-bg, 0);
  }
}
</style>
