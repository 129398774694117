import { ref, computed, watch } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import store from '@/store'
import { getAdminType } from './admin'

export const globalUserPermissions = ref([])
export const emailx = ref('')

const environmental_url = {
  test: 'https://v3.test.admin.shuttlers.africa',
  staging: 'https://v3.qa.admin.shuttlers.africa',
  prod: 'https://v3.admin.shuttlers.africa'
}

export const currentUser = computed(() => store.getters.currentUser)
export const getUserPermission = async () => {
  try {
    const res = await axiosInstance.get(`/v1/super-admin/staff-module-permissions/staff/${currentUser.value.id}`)
    globalUserPermissions.value = res.data.permissions
    currentUser.value.permissions = res?.data?.permissions
   
  } catch (err) {

  }
}


export const userHasPermission = (permission) =>
{
  if (process.env.VUE_APP_USER_BY_PASS_ACL === 'true' || getAdminType() === 'super_admin' || getAdminType() === 'admin') {
    return true
  }

  const codeArray = currentUser?.value?.permissions?.map((item) => item.code)


  return codeArray?.includes(permission)
}

export const DoNotShowOnProd = () => {
  return !window.location.href.includes('https://admin.shuttlers.africa')

}

export const openAsExternalUrl = (url) => { 
  const b64 = btoa(JSON.stringify(currentUser.value.token.token))
  switch (process.env.VUE_APP_ENV_ALIAS) { 
    case 'test':
    case 'TEST':
      window.location.href = `${environmental_url.test}/redirect/?path=${url}&token=${b64}`
      break
    case 'staging':
    case 'QA':
      window.location.href = `${environmental_url.staging}/redirect/?path=${url}&token=${b64}`
      break
    case 'prod':
    case 'PROD':
      window.location.href = `${environmental_url.prod}/redirect/?path=${url}&token=${b64}`
      break
    default:
      window.location.href = `${environmental_url.prod}/redirect/?path=${url}&token=${b64}`
      break
  }
}