<template>
	<div class="table-responsive mb-0">
		<table class="table table-sm table-nowrap card-table">
			<thead>
				<tr>
					<th v-for="field in fields" :key="field.key">
						<slot :name="`field_${field.key}`">
							<span class="text-muted">{{ field.label }}</span>
						</slot>
					</th>
				</tr>
			</thead>
			<tbody class="list">
				<template v-if="loading">
					<tr class="overflow-x-hidden">
						<td :colspan="fields.length" class="py-20 text-center">
							<div class="d-flex justify-content-center align-items-center">
								<div class="spinner-border" role="status"></div>
							</div>
						</td>
					</tr>
				</template>
				<template v-else>
					<template v-if="errorLoading">
						<tr>
							<td :colspan="fields.length + 1" class="text-center">
								An error occured, click
								<a
									href="#"
									class="text-decoration-underline"
									@click="$emit('retry')"
									>
									here
								</a>
								to retry.
							</td>
						</tr>
					</template>
					<template v-else>
						<template v-if="tableItems && tableItems.length">
							<tr
								v-for="(item, index) in tableItems"
								:key="index"
								@click="$emit('row-clicked', { item, index })"
								:class="{
									'hover:bg-gray-dark-mode cursor-pointer': clickable,
								}"
								>
								<td
									v-for="field in fields"
									:key="field.key"
									:colspan="field.span || 1"
									class="border-b border-gray-border text-wrap"
									:class="field.columnClasses || 'py-4 px-4'"
									>
									<slot
										:name="field.key"
										v-bind:item="item"
										v-bind:index="index"
										>
										<div class="text-xs">
											{{
												customRenderer
													? customRenderer({ item, key: field.key })
													: getPropPath(item, field.key)
											}}
										</div>
									</slot>
								</td>
							</tr>
						</template>
						<template v-else>
							<slot name="no-items">
								<tr>
									<td
										:colspan="
											fields.length +
												fields.reduce((sum, field) => sum + field.span || 0, 0)
										"
										class="p-2 text-center"
										>
										No Data Available
									</td>
								</tr>
							</slot>
						</template>
					</template>
				</template>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array
    },
    fields: {
      type: Array,
      required: true
    },
    loading: Boolean,
    errorLoading: {
      type: Boolean,
      default: false
    },
    usePagination: {
      type: Boolean,
      default: true
    },
    layout: {
      type: String,
      default: 'auto'
    },
    paginationPosition: {
      type: String,
      default: 'end'
    },
    clickable: {
      type: Boolean,
      default: false
    },
    fetchData: {
      type: Function
    },
    customRenderer: {
      type: Function
    }
  },
  data () {
    return {
      pagination: {
        itemsPerPage: 15,
        currentPage: 1
      }
    }
  },
  computed: {
    paginationStartIndex () {
      return (
        this.paginationItemsPerPage * this.pagination.currentPage -
        this.paginationItemsPerPage +
        1
      )
    },
    paginationItemsPerPage () {
      return parseInt(this.pagination.itemsPerPage)
    },
    tableItems () {
      if (this.usePagination) {
        return this.items.slice(
          this.paginationStartIndex - 1,
          this.paginationStartIndex + this.paginationItemsPerPage - 1
        )
      }

      return this.items
    },
    paginationTotalPages () {
      return Math.ceil(this.items.length / this.paginationItemsPerPage)
    },
    paginationIsInLastPage () {
      return this.pagination.currentPage == this.paginationTotalPages
    },
    paginationIsInFirstPage () {
      return this.pagination.currentPage === 1
    }
  },
  methods: {
    getPropPath (obj, path) {
      if (!path) {
        return
      }
      return this.getProp(obj, path.split('.'))
    },
    getProp (obj, props) {
      const prop = props.shift()
      if (!obj[prop] || !props.length) {
        return obj[prop]
      }
      return this.getProp(obj[prop], props)
    },
    prevPage () {
      let page = this.pagination.currentPage - 1

      if (page < 1) {
        page = 1
      }

      this.pagination.currentPage = page
    },
    nextPage () {
      let page = this.pagination.currentPage + 1

      if (page > this.paginationTotalPages) {
        page = this.paginationTotalPages
      }

      this.pagination.currentPage = page
    }
  }
}
</script>
