<template>
	<div class="main-content">
		<page-header
			title="Additional Charges"
			pre-title="Overview"
			:loading="false">
			<template slot="actions">
				<button class="btn" @click="showAddTypeModal = true">
					Add Charge Type
				</button>
			</template>
			<template slot="tabs">
				<div class="container flex flex-col">
					<div class="row pt-3 pb-1">
						<div class="col justify-self-center align-self-center flex-column">
							<ul class="nav nav-pills">
								<li class="nav-item">
									<router-link
										:to="{name: 'PricingControls'}"
										class="nav-link"
										active-class="active"
										>Pricing control</router-link
										>
								</li>
								<li class="nav-item">
									<router-link
										:to="{name: 'PaymentOptions'}"
										class="nav-link"
										active-class="active"
										>Payment option display</router-link
										>
								</li>
								<li class="nav-item">
									<router-link :to="{name: 'ConfigureCharges'}" class="nav-link" active-class="active" >
										Additional Charges
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="flex items-center gap-2 border-top p-2">
						<router-link v-for="n,i in chargesTab" :key="i" :to="{name: n.route}" 
							class="text-xs text-[#ACAFAE] font-medium py-2 px-3 rounded-lg"
							active-class="charge_active"
						>
							{{ n.name }}
						</router-link>
					</div>
				</div>
			</template>
		</page-header>

		<page-route-view :loading="false" :showBackBtn="false">
		</page-route-view>

		<BS_modal :show='showAddTypeModal' :title="isEditChargeType ? 'Edit charge type' : 'Add new charge type'" 
			@close='closeAddTypeModal' modalId='AddChargeTypeModal'
		>
			<form class="flex flex-col gap-[16px] w-full" @submit.prevent="isEditChargeType ? updateCharge() : createCharge()">
				<div class="flex flex-col gap-2">
					<label class="text-xs text-[#6E717C] font-medium">Charge name</label>
					<input required v-model.trim="name" placeholder="e.g Value Added Tax" class="text-sm border p-[16px] rounded outline-none" />
				</div>
				<div class="flex flex-col gap-2">
					<label class="text-xs text-[#6E717C] font-medium">Short name</label>
					<input required v-model.trim="short_name" placeholder="e.g VAT" class="text-sm border p-[16px] rounded outline-none" />
				</div>
				<div class="flex flex-col gap-2">
					<label class="text-xs text-[#6E717C] font-medium">Description</label>
					<textarea required v-model.trim="desc" placeholder="Write a short description about this charge" 
						class="text-sm border p-[16px] rounded outline-none max-h-[100px]" cols="30" rows="10"
					>
					</textarea>
				</div>
				<button type="submit" :disabled="loading"
					class="text-sm bg-black p-[16px] text-white text-center w-full mt-2 border-none outline-none rounded disabled:cursor-not-allowed disabled:bg-[#E0E6ED]"
				>
					{{loading ? 'processing...' : `${isEditChargeType ? 'Edit charge type' :'Add charge type'}`}}
				</button>
			</form>
		</BS_modal>

		<BS_modal :show='showConfigureChargeModal' :title=" isEditConfigureCharge ? 'Update configuration' : 'Configure new charge'"
			@close='closeConfigureChargeModal' modalId='ConfigureNewCharge'
		>
			<form class="flex flex-col gap-[16px] w-full" @submit.prevent="isEditConfigureCharge ? updateConfigureCharge() : configureCharge()">
				<div class="flex flex-col gap-2">
					<div class="flex items-center gap-[16px] justify-between">
						<label class="text-xs text-[#6E717C] font-medium">Select charge type</label>
						<button class="shrink-0 border-none outline-none flex items-center gap-2 font-medium justify-center bg-transparent text-[#0DAC5C]"
							@click="showAddTypeModal = true" type="button"
						>
							<img src="@/assets/img/icons/plus_black.svg" alt="">
							Add new charge
						</button>
					</div>
					<select required class="text-sm border p-[16px] rounded outline-none" v-model="chargeTypeId">
						<option v-for="n in allChargeTypes" :key="n.id" :value="n.id">{{ n.short_name }}</option>
					</select>
				</div>
				<div class="flex flex-col gap-2">
					<label class="text-xs text-[#6E717C] font-medium">Description</label>
					<textarea required v-model.trim="config_desc" placeholder="Write a short description about this configuration" class="text-sm border p-[16px] rounded outline-none max-h-[100px]" cols="30" rows="10"></textarea>
				</div>
				<div class="flex flex-col gap-2">
					<label class="text-xs text-[#6E717C] font-medium">Charge fee</label>
					<div class="flex rounded border items-center overflow-hidden">
						<input type="number" step="0.01" required v-model.trim="chargeValue" placeholder="" class="text-sm w-[60%] p-[16px] border-none outline-none" />
						<select v-model="chargeType" class="w-[40%] p-[16px] bg-[#E0E6ED] outline-none text-sm border-none border-left">
							<option class="p-2" value="percentage">Percent(%)</option>
							<option class="p-2" value="flat">Flat amount(₦)</option>
						</select>
					</div>
				</div>

				<div class="flex flex-col gap-2">
					<label class="text-xs text-[#6E717C] font-medium">Select country</label>
					<select v-model="chargeEntityId" required class="text-sm border p-[16px] rounded outline-none">
						<option v-for="n in allCountries" :key="n.id" :value="n.id">{{n.name}}</option>
					</select>
				</div>

				<div class="flex flex-col gap-2">
					<label class="text-xs text-[#6E717C] font-medium">Select cities</label>
					<multiselect v-model="cityIds" :placeholder="allCity ? '' : 'Search...'" :searchable="allCity ? false : true"
						label="name" track-by="id" :options="allCityNames" :multiple="true" :taggable="true"
					>
					<template slot="beforeList" slot-scope="props">
						<button type="button" @click="selectAllCities" 
							class="bg-transparent w-full p-3 border-none border-bottom outline-none hover:!bg-[rgb(65,184,131)] hover:text-white text-left font-medium"
						>
							Select all cities
						</button>
					</template>
					<template v-if="allCity" slot="selection" slot-scope="props">
						<p class="!bg-[rgb(65,184,131)] w-fit text-white text-sm font-medium px-2 py-1 rounded">All city selected</p>
					</template>
					</multiselect>
				</div>

				<div class="flex items-center gap-[16px] justify-between">
					<p class="text-sm text-[#575A65]">Users must pay this fee when making a booking</p>
					<VueToggles width="50" checkedBg="#15CD70" :value="isCompulsory" @click="isCompulsory = !isCompulsory" />
				</div>
				<button type="submit" :disabled="creating_configuration || !enableButton" class="text-sm bg-black p-[16px] text-white text-center w-full mt-2 rounded disabled:cursor-not-allowed disabled:bg-[#E0E6ED]">
					{{creating_configuration ? 'processing...' : `${isEditConfigureCharge ? 'Update configuration' : 'Configure new charge'}`}}
				</button>
			</form>
		</BS_modal>

		<BS_modal :show='showActivateChargeModal' title='Are you absolutely sure?' @close='closeActivateChargeModal' modalId='ActivateCharge'>
			<form class="flex flex-col gap-4" @submit.prevent="activateConfiguredCharge()">
				<p>Are you sure you want to <strong>activate</strong> this charge?</p>
				<div class="p-3 rounded bg-[#EBEBFF] flex items-center gap-3">
					<img src="@/assets/img/caution.svg" alt="">
					<span class="text-sm font-medium text-[#2323F0]">It’s important you read the message below!</span>
				</div>
				<p class="text-xs m-0">The charge will immediately apply to all routes bookings in the selected country/cities.</p>
				<div class="" autocomplete="off">
					<div class="form-group mb-0 relative" autocomplete="off">
						<label class="!mb-2">Please type <strong>YOUR PASSWORD</strong> to confirm this action.</label>
						<input required :type="showPassword ?'text':'password'" v-model="password" class="form-control" autocomplete='off' />
						<span @click="showPassword = !showPassword" class="fe absolute right-4 top-[34px] cursor-pointer" :class="[showPassword ? 'fe-eye' : 'fe-eye-off']"></span>
					</div>
				</div>

				<div class="mt-[30px] flex flex-col gap-2">
					<button type="submit" class='btn bg-dark text-white w-full outline-none disabled:cursor-not-allowed disabled:bg-[#E0E6ED]' 
						:disabled='activating || !password.length'
					>
						{{activating ? 'loading...' : 'Proceed' }}
					</button>
				</div>
			</form>
		</BS_modal>
	</div>
</template>

<script setup>
import PageHeader from '@/components/layout/PageHeader.vue'
import PageRouteView from '@/components/layout/PageRouteView.vue'
import BS_modal from '@/components/core/modals/BS_modal.vue'
import {ref} from 'vue'
import { useCreateChargeType, useFetchChargeTypes } from '@/views/PricingControl/composables/charges/chargeTypes/index'
import { useActivateConfiguration, useCreateConfigureCharge } from '@/views/PricingControl/composables/charges/configure/index'
import { useCityAndCountry } from '@/views/PricingControl/composables/utils'
import Multiselect from 'vue-multiselect'
import VueToggles from 'vue-toggles'

const chargesTab = [
	{name: 'Configure Charges', route: 'ConfigureCharges'},
	{name: 'Charge Types', route: 'ChargesTypes'},
	// {name: 'Charge Report', route: 'ChargesReport'},
]

const { allCityNames, allCountries, fetchAllCityNames, fetchAllCountries } = useCityAndCountry()
const { fetchAllChargeTypesWithoutLimit, allChargeTypes } = useFetchChargeTypes()
const { loading, desc, short_name, name, createCharge, updateCharge, showAddTypeModal, closeAddTypeModal, isEditChargeType } = useCreateChargeType()
const { activateConfiguredCharge, loading: activating, password, showActivateChargeModal,
		closeActivateChargeModal, showPassword } = useActivateConfiguration()
const { loading:creating_configuration, showConfigureChargeModal, isEditConfigureCharge, 
	chargeEntityId, chargeType, chargeTypeId, chargeValue, desc:config_desc, cityIds, allCity, 
	configureCharge, enableButton, closeConfigureChargeModal, isCompulsory, updateConfigureCharge } = useCreateConfigureCharge()


const selectAllCities = () => {
	cityIds.value = []
	allCity.value = true
}

fetchAllCityNames()
fetchAllCountries()
fetchAllChargeTypesWithoutLimit()
</script>

<style lang="scss" scoped>
@import "src/assets/scss/nav-tabs";

h1,h2,h3,h4,h5,h6,span, label, p{
	margin: 0 !important;
}

.charge_active{
	color: #0DAC5C;
	background: #F4F5F4;
}
.btn{
	background: black;
	color: white;
	font-size: 14px;
	width: fit-content;
	padding: 8px 12px;
	border-radius: 4px;
}

</style>
