import { loadRouteComponent } from '@/utils/helpers'
import Events from '@/layouts/modules/Events/Index.vue'
import EventDetails from '@/layouts/modules/Events/EventDetails.vue'
import EventList from '@/layouts/modules/Events/EventList.vue'
import AttendeeList from '@/layouts/modules/Events/AttendeeList.vue'
import AttendeeDetails from '@/layouts/modules/Events/AttendeeDetails.vue'

const EventRoutes = [
  {
    path: 'events',
    component: Events,
    children: [
      {
        path: '',
        component: EventList,
        children: [
          {
            path: '',
            redirect: 'list'
          },
          {
            path: 'list',
            component: loadRouteComponent('Events/List/EventList'),
            name: 'EventList',
            props: true
          },
          {
            path: 'upcoming',
            component: loadRouteComponent('Events/List/UpcomingEvents'),
            name: 'UpcomingEvents',
            props: true
          }
        ]
      },
      // {
      //   path: 'request/:eventId',
      //   component: loadRouteComponent('Events/EventRequest'),
      //   name: 'EventRequest',
      //   props: true
      // },
      {
        path: ':eventId',
        component: EventDetails,
        props: true,
        children: [
          {
            path: 'event-info',
            name: 'ShowEventInfo',
            component: loadRouteComponent('Events/Show/EventInfo'),
            props: true
          },
          // {
          //   path: 'attendee-list',
          //   name: 'ShowAttendeeList',
          //   component: loadRouteComponent('Events/Show/AttendeeList'),
          //   props: true
          // },
          // {
          //   path: 'accepted-event',
          //   name: 'ShowAcceptedEvent',
          //   component: loadRouteComponent('Events/Show/AcceptedUpcomingEvent'),
          //   props: true
          // },
          // {
          //   path: ':attendeeId',
          //   name: 'ShowAcceptedAttendee',
          //   component: loadRouteComponent('Events/Show/AcceptedAttendee'),
          //   props: true
          // }
        ]
      },
      {
        path: 'attendees',
        component: AttendeeList,
        props: true,
        children: [
          {
            path: ':eventId',
            name: 'ShowAttendeeList',
            component: loadRouteComponent('Events/Show/AttendeeList'),
            props: true
          }
        ]
      },
      {
        path: 'attendee',
        component: AttendeeDetails,
        props: true,
        children: [
          {
            path: ':eventId/:attendeeId',
            name: 'ShowAcceptedAttendee',
            component: loadRouteComponent('Events/Show/AcceptedAttendee'),
            props: true
          }
        ]
      }
    ]
  }
]

export default EventRoutes
