import {ref} from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import Swal from 'sweetalert2'
import {useFetchChargeTypes} from './fetch'

const { fetchAllChargeTypes } = useFetchChargeTypes()
const loading = ref(false)
export const useDeleteCharge = () => {
	const deleteCharge = (id) => {
		Swal.fire({
			icon: 'warning', title: 'Are you sure?', text: 'You won\'t be able to revert this!',
			showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				loading.value = true
				try {
					const res = await axios.delete(`/v1/additional-charges-types/${id}`)
					if(res.data?.message == 'Deleted successfully') {
						Swal.fire({ icon: 'success', title: 'Success', text: res.data?.message || 'Charge deleted successfully'})
						fetchAllChargeTypes()
					} else {
						Swal.fire({ icon: 'error', title: 'Error', text: res.data?.message ?? 'Could not delete charge'})
					}
				} catch (err) {
					Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
				} finally {
					loading.value = false
				}
			}
		})
		
	}

	return { loading, deleteCharge }
}