<template>
	<div>
    <b-dropdown :size="showLabel ? '' : 'sm'" :variant="showLabel ? 'outline-primary' : 'link'"
      :toggle-class="showLabel ? '' : 'text-decoration-none'" :no-caret="true" v-if="route">
      <template #button-content>
        <span v-if="showLabel">Options</span>
        <i class="fe fe-more-vertical"></i>
      </template>
      <b-dropdown-item :to="{ name: 'EditRoute', params: { routeId: route.id } }" v-if="userHasPermission('routes003')">Edit</b-dropdown-item>
      <b-dropdown-item href="#" @click.prevent="suspendRoute(route.id)" v-if="userHasPermission('routes013')">{{ this.isRouteActive ? 'Suspend' : 'Unsuspend' }}
      </b-dropdown-item>
      <b-dropdown-item @click.prevent="openDuplicateRouteModal(route.id)">Duplicate</b-dropdown-item>
      <b-dropdown-item href="#" @click.prevent="deleteRoute(route.id)" link-class="text-danger" v-if="userHasPermission('routes014')">Delete</b-dropdown-item>
      <b-dropdown-item href="#"  v-if="!userHasPermission('routes003') && !userHasPermission('routes014') && !userHasPermission('routes013')">UnAuthorised</b-dropdown-item>
    </b-dropdown>
  </div>
</template> 

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
import { useRouteDuplicate } from '@/views/Routes/composables/route'



const { openDuplicateRouteModal } = useRouteDuplicate()
</script>

<script>
import routeResource from '@/api/route'
import BS_modal from '@/components/core/modals/BS_modal.vue'
export default {
  props: {
    route: {
      required: false,
      type: Object
    },
    showLabel: {
      required: false,
      type: Boolean,
      default: () => false
    }
  },
  components: { BS_modal },
  data () {
    return {
    }
  },
  computed: {
    isRouteActive () {
      return this.route.status === 1
    }
  },
  methods: {
    suspendRoute (routeId) {
      return this.$swal({
        title: `Sure to ${this.isRouteActive ? 'suspend' : 'unsuspend'} route?`,
        text: `Customers will ${this.isRouteActive ? 'no longer' : 'now'} discover this route when searching on the mobile`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, ${this.isRouteActive ? 'suspend' : 'unsuspend'} it!`
      }).then((result) => {
        if (result.isConfirmed) {
          return this.handleSuspendRoute(routeId).catch((err) => {
            this.$swal(`Could not suspend ${this.isRouteActive ? 'suspend' : 'unsuspend'}`, err.toString(), 'info')
            return Promise.reject(false)
          })
        }
        return Promise.reject(false)
      })
    },
    async handleSuspendRoute (id) {
      try {
        this.savingRoute = true
        this.$Progress.start()
        const updatedRoute = await routeResource.saveRoute(id, { status: this.isRouteActive ? 0 : 1 })
        this.$eventbus.$emit('route:updated', updatedRoute.data)
        this.$Progress.finish()
      } catch (e) {
        
        this.$Progress.fail()
      }
    },
    deleteRoute (routeId) {
      return this.$swal({
        title: 'Sure to delete route?',
        text: 'Customers will no longer discover this route when searching on the mobile',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          return this.handleDeleteRoute(routeId).catch(() => {
            return Promise.reject(false)
          })
        }
        return Promise.reject(false)
      })
    },
    async handleDeleteRoute (id) {
      try {
        this.savingRoute = true
        this.$Progress.start()
        await routeResource.deleteRoute(id)
        this.$eventbus.$emit('route:deleted', id)
        this.$Progress.finish()
      } catch (e) {
        const errorMessage = e.response?.data?.message || e.toString()
        this.$swal('Could not delete route', errorMessage, 'info')
        this.$Progress.fail()
      }
    }
    
    
  }
}
</script>

  <style lang="scss" scoped>
  .route-list-item::before {
    display: block;
    height: 30px;
    width: 3px;
    background: linear-gradient(#003d36, #20e682);
    content: '';
    float: left;
    margin-right: 10px;
    margin-top: 8px;
    margin-bottom: auto;
  }

  .route-pickup-title::before {
    content: '';
    display: block;
    float: left;
    width: 10px;
    height: 10px;
    background: #003d36;
    z-index: 1;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    margin-left: -16px;
  }

  .route-destination-title::before {
    content: '';
    display: block;
    float: left;
    width: 10px;
    height: 10px;
    background: #20e682;
    z-index: 1;
    border-radius: 10px;
    position: absolute;
    top: 40px;
    margin-left: -16px;
  }
  </style>
