<template>
	<div>
		<div class="p-2 flex flex-col gap-2">
			<input type="text" v-model="busStopsFilter" placeholder="Filter Bus Stops in route" class="form-control" />
        <button class='btn btn-primary' @click='isDraggable = !isDraggable'>
      {{ isDraggable ? 'Disable Reorder' : 'Enable Reorder' }}
    </button>
		</div>
  
		<div style="overflow-y: scroll; height: calc(100vh - 132px)">
			<div v-if="activeTab == 'itineraries'">
				<div>
					<div class="p-5 text-center">
						<span>Work in progress</span>
					</div> 
				</div>
			</div>

			<div v-if="activeTab == 'details'">
				<div>
					<div class="p-5 text-center">
						<span>Work in progress</span>
					</div>
				</div>
			</div>

			<div>
				<draggable class="list-group rounded-0" v-if="activeTab == 'bus_stops'" v-model="stops" group="busstops" :disabled="!isDraggable || !userHasPermission('routes003')" @change="busStopDragChanged">
					<a href="#" :class="activeRouteBusStop != null && activeRouteBusStop.id == busStop.id
						? 'active'
						: 'bg-white'
					" @click.prevent="selectBusStop(busStop)" :key="busStop.id" class="list-group-item" v-for="busStop in filteredBusStops">
						<div class="flex-row d-flex">
							<div class="flex-grow-1">
								<span>{{ busStop.name }}</span>
							</div>

							<div class="">
								<b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" :no-caret="true" v-if="userHasPermission('routes003')">
									<template #button-content>
										<i class="fe fe-more-vertical"></i>
									</template>
									<b-dropdown-item href="#" @click.prevent="editBusStop(busStop)">Edit</b-dropdown-item>
									<b-dropdown-item href="#" @click.prevent="deleteBusStop(busStop)" class="text-danger">Delete</b-dropdown-item>
								</b-dropdown>
							</div>
						</div>
					</a> 
				</draggable>
			</div>
		</div>
	</div>
</template>


<script setup>
import { userHasPermission } from '@/composables/core/permissions'
import { ref, watch } from 'vue'

const isDraggable = ref(false)

</script>

<script>
import draggable from 'vuedraggable'
import RouteBusStopMixin from '@/mixins/route_bus_stop'
import { mapGetters } from 'vuex'
import routeBusStopService from '@/services/route-busstop-service'

export default {
  components: {
    draggable
  },
  mixins: [RouteBusStopMixin],
  props: {
    activeRouteId: {
      required: true,
      type: [Number, String]
    },
    activeRoute: {
      required: false,
      type: Object
    },
    addBusStop: [Function]
  },
  data () {
    return {
      activeTab: 'bus_stops',
      activeRouteBusStop: null,
      stops: [],
      loadingBusStops: false,
      busStopsFilter: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.fetchBusStops()
    })
  },
  computed: {
    filteredBusStops () {
      if (!this.busStopsFilter || !this.busStopsFilter.length) {
        return this.stops
      }

      return this.stops.filter((v) => {
        return new RegExp(this.busStopsFilter).test(v.name)
      })
    },
    ...mapGetters('routeStore', ['busStops'])
  },
  watch: {
    activeRouteId () {
      this.fetchBusStops()
    },
    busStops: {
      handler (value) {
        this.stops = value
      }
    }
  },
  methods: {
    busStopDragChanged (e) {
      if (e.moved) {
        const oldIndex = e.moved.oldIndex
        const newIndex = e.moved.newIndex
        const limit = (newIndex > oldIndex ? newIndex : oldIndex) + 1
        const originIndex = newIndex > oldIndex ? oldIndex : newIndex
        const affectedBusStops = this.stops.slice(originIndex, limit)
        /**
         * Updates rendered ui bus stops state to sync with  ui change
         * */
        const updatePayload = affectedBusStops
          .map((item, index) => {
            return {
              id: item.id,
              currentPosition: item.position,
              position: originIndex + index + 1
            }
          })
          .filter((i) => i.currentPosition != i.position)

        if (updatePayload.length) {
          this.saveBusStopOrder(this.activeRouteId, updatePayload)
            .then(() => {
              const updatedStops =
                routeBusStopService.updateAllChangedBusStopsData(
                  this.stops,
                  updatePayload
                )
              this.$store.dispatch('routeStore/setBusStops', updatedStops)
            })
            .catch((er) => {

            })
        }
      }
    },
    editBusStop (b) {
      this.activeRouteBusStop = b
      this.$set(b, 'edit', true)
      this.$emit('selected', b)
    },
    deleteBusStop (b) {
      this.attemptDeleteRouteBusStop(b).then(() => {
        this.$emit('deleted', b)
      })
    },
    selectBusStop (b) {
      this.activeRouteBusStop = b
      this.$emit('selected', b)
    },
    async fetchBusStops () {
      if (!this.activeRouteId) return
      this.$Progress.start()
      this.loadingBusStops = true
      try {
        const result = await this.axios.get(
          `/v1/routes/${this.activeRouteId}/busstops`
        )
        await this.$store.dispatch('routeStore/setBusStops', result.data.data)
        this.loadingBusStops = false
        this.$Progress.finish()
      } catch (e) {
        await this.$store.dispatch('routeStore/setBusStops', [])
        this.loadingBusStops = false
        this.$Progress.fail()
        this.$emit('loaded', [])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-group-item.active .btn {
  color: #fff;
}
</style>
