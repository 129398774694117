import { loadRouteComponent } from '@/utils/helpers'
import Drivers from '@/layouts/modules/Drivers/Index.vue'
import DriverDetails from '@/layouts/modules/Drivers/DriverDetails.vue'
import DriverRewards from '@/layouts/modules/Drivers/DriverRewards.vue'
import DriverRewardHistory from '@/layouts/modules/Drivers/DriverRewardHistory.vue'
const DriversRoutes = [
  {
    path: 'drivers',
    component: Drivers,
    children: [
      {
        path: '',
        name: 'Drivers',
        redirect: {
          name: 'DriversList'
        }
      },
      {
        path: 'list',
        component: loadRouteComponent('Drivers/pages/List'),
        name: 'DriversList',
        props: true,
        meta: {
          requiredPermissions: ['drivers002']
        }
      },
      {
        path: 'add/:driverId?',
        component: loadRouteComponent('Drivers/pages/Manage'),
        name: 'ManageDriver',
        props: true,
        meta: {
          requiredPermissions: ['drivers001']
        }
      },
      {
        path: 'notify',
        component: loadRouteComponent('Drivers/pages/Notify'),
        name: 'NotifyDrivers',
        props: true,
        meta: {
          requiredPermissions: ['drivers008']
        }
      },
      {
        path: ':driverId',
        component: DriverDetails,
        props: true,
        children: [
          {
            path: 'driver-info',
            name: 'ShowDriver',
            component: loadRouteComponent('Drivers/pages/DriverInfo'),
            props: true,
            meta: {
              requiredPermissions: ['drivers003']
            }
          },
          {
            path: 'customer-ratings',
            name: 'ShowDriverCustomerRatings',
            component: loadRouteComponent('Drivers/pages/CustomerRatings'),
            props: true,
            meta: {
              requiredPermissions: ['drivers003']
            }
          }
        ]
      }
    ]
  },
  {
    path: 'reward/drivers',
    component: DriverRewards,
    children: [
      {
        path: '',
        name: 'DriverRewards',
        redirect: {
          name: 'Points'
        }
      },
      {
        path: 'points',
        component: loadRouteComponent('Drivers/pages/reward/Point'),
        name: 'Points',
        props: true,
        meta: {
          requiredPermissions: ['drivers002']
        }
      },
      {
        path: 'drivers-and-rewards',
        component: loadRouteComponent('Drivers/pages/reward/DriversAndRewards'),
        name: 'DriversAndRewards',
        props: true,
        meta: {
          requiredPermissions: ['drivers002']
        }
      }
    ]
  },
  {
    path: 'reward/drivers/history',
    component: DriverRewardHistory,
    children: [
      {
        path: ':driverId',
        component: loadRouteComponent('Drivers/pages/reward/DriverRewardHistory'),
        name: 'DriversRewardHistory',
        props: true,
        meta: {
          requiredPermissions: ['drivers002']
        }
      }
    ]
  }
]

export default DriversRoutes
