import rewardPointServiceInstance from '@/api/reward_points'

export default {
  namespaced: true,
  state: {
    currentRewardPointRate: 0,
    fetchingRewardPoint: false,

    allComplianceRewardEventList: null,
    fetchingComplianceRewardEventList: true,

    singleDriverComplianceRewardHistory: null,
    fetchingSingleDriverComplianceRewardHistory: true,

    allComplianceReward: null,
    fetchingComplianceReward: true,

    allDriversComplianceAccumulatedPoint: null,
    fetchingDriversComplianceAccumulatedPoint: true,

    highestLowestDriversComplianceReward: null,
    fetchingHighestLowestDriversComplianceReward: true,

    fetchingComplianceRewardUpdate: false,

    fetchingComplianceRewardEventUpdate: false,
  },
  getters: {
    currentRewardPointRate: (state) => state.currentRewardPointRate,
    fetchingRewardPoint: (state) => state.fetchingRewardPoint,

    allComplianceRewardEventList: (state) => state.allComplianceRewardEventList,
    fetchingComplianceRewardEventList: (state) =>
      state.fetchingComplianceRewardEventList,

    singleDriverComplianceRewardHistory: (state) => state.singleDriverComplianceRewardHistory,
    fetchingSingleDriverComplianceRewardHistory: (state) =>
      state.fetchingSingleDriverComplianceRewardHistory,

    allComplianceReward: (state) => state.allComplianceReward,
    fetchingComplianceReward: (state) => state.fetchingComplianceReward,

    fetchingComplianceRewardUpdate: (state) =>
      state.fetchingComplianceRewardUpdate,

    allDriversComplianceAccumulatedPoint: (state) => state.allDriversComplianceAccumulatedPoint,
    fetchingDriversComplianceAccumulatedPoint: (state) => state.fetchingDriversComplianceAccumulatedPoint,

    highestLowestDriversComplianceReward: (state) => state.highestLowestDriversComplianceReward,
    fetchingHighestLowestDriversComplianceReward: (state) => state.fetchingHighestLowestDriversComplianceReward,

    fetchingComplianceRewardEventUpdate: (state) =>
      state.fetchingComplianceRewardEventUpdate,
  },
  mutations: {
  
    SET_CURRENT_REWARD_POINT_RATE(state, data) {
      state.currentRewardPointRate = data
    },

    SET_FETCHING_REWARD_POINT(state, data) {
      state.fetchingRewardPoint = data
    },

    SET_ALL_COMPLIANCE_REWARD_EVENT_LIST(state, data) {
      state.allComplianceRewardEventList = [...data]
    },

    SET_FETCHING_COMPLIANCE_REWARD_EVENT_LIST(state, data) {
      state.fetchingComplianceRewardEventList = data
    },

    SET_SINGLE_DRIVER_COMPLIANCE_REWARD_HISTORY(state, data) {
      state.singleDriverComplianceRewardHistory = [...data]
    },

    SET_FETCHING_SINGLE_DRIVER_COMPLIANCE_REWARD_HISTORY(state, data) {
      state.fetchingSingleDriverComplianceRewardHistory = data
    },

    SET_ALL_COMPLIANCE_REWARDS(state, data) {
      state.allComplianceReward = [...data]
    },

    SET_FETCHING_COMPLIANCE_REWARDS(state, data) {
      state.fetchingComplianceReward = data
    },

    SET_ALL_DRIVERS_COMPLIANCE_ACCUMULATED_POINTS(state, data) {
      state.allDriversComplianceAccumulatedPoint = [...data]
    },

    SET_FETCHING_DRIVERS_COMPLIANCE_ACCUMULATED_POINTS(state, data) {
      state.fetchingDriversComplianceAccumulatedPoint = data
    },

    SET_HIGHEST_LOWEST_DRIVERS_COMPLIANCE_ACCUMULATED_POINTS(state, data) {
      state.highestLowestDriversComplianceReward = data
    },

    SET_FETCHING_HIGHEST_LOWEST_DRIVERS_COMPLIANCE_ACCUMULATED_POINTS(state, data) {
      state.fetchingHighestLowestDriversComplianceReward = data
    },

    SET_FETCHING_COMPLIANCE_REWARD_UPDATE(state, data) {
      state.fetchingComplianceRewardUpdate = data
    },

    SET_FETCHING_COMPLIANCE_REWARD_EVENT_UPDATE(state, data) {
      state.fetchingComplianceRewardEventUpdate = data
    },
  },
  actions: {
    // setFetchingRewardPoint({ commit }, data) {
    //   commit('SET_FETCHING_REWARD_POINT', data)
    // },

    // setFetchingComplianceRewardEventList({ commit }, data) {
    //   commit('SET_FETCHING_COMPLIANCE_REWARD_EVENT_LIST', data)
    // },

    // setFetchingComplianceRewardHistory({ commit }, data) {
    //   commit('SET_FETCHING_SINGLE_DRIVER_COMPLIANCE_REWARD_HISTORY', data)
    // },

    async getCurrentRewardPointRate({ commit }) {
      commit('SET_FETCHING_REWARD_POINT', true)
      const res = await rewardPointServiceInstance.getRewardPointRate()

      if ([200, 201].includes(res.status)) {
        commit('SET_CURRENT_REWARD_POINT_RATE', res.data.value)
        commit('SET_FETCHING_REWARD_POINT', false)
      } else {
        commit('SET_FETCHING_REWARD_POINT', false)
      }

      return res
    },

    async setCurrentRewardPointRate({ commit }, data) {
      commit('SET_FETCHING_REWARD_POINT', true)
      const res = await rewardPointServiceInstance.configureRewardPointRate(
        data
      )

      if ([200, 201].includes(res.status)) {
        commit('SET_CURRENT_REWARD_POINT_RATE', res.data.value)
        commit('SET_FETCHING_REWARD_POINT', false)
      } else {
        commit('SET_FETCHING_REWARD_POINT', false)
      }

      return res
    },

    async getAllComplianceRewardEventList({ commit }) {
      commit('SET_FETCHING_COMPLIANCE_REWARD_EVENT_LIST', true)
      const res =
        await rewardPointServiceInstance.getAllComplianceRewardEventList()

      if ([200, 201].includes(res.status)) {
        commit('SET_ALL_COMPLIANCE_REWARD_EVENT_LIST', res.data)
        commit('SET_FETCHING_COMPLIANCE_REWARD_EVENT_LIST', false)
      } else {
        commit('SET_FETCHING_COMPLIANCE_REWARD_EVENT_LIST', false)
      }

      return res
    },

    async updateSingleComplianceRewardEvent({ commit }, data) {
      commit('SET_FETCHING_COMPLIANCE_REWARD_EVENT_UPDATE', true)
      const res =
        await rewardPointServiceInstance.updateSingleComplianceRewardEvent(
          data.id,
          {
            points: data.points,
          }
        )

      if ([200, 201].includes(res.status)) {
        commit('SET_FETCHING_COMPLIANCE_REWARD_EVENT_UPDATE', false)
      } else {
        commit('SET_FETCHING_COMPLIANCE_REWARD_EVENT_UPDATE', false)
      }

      return res
    },

    async getAllComplianceRewards({ commit }) {
      commit('SET_FETCHING_COMPLIANCE_REWARDS', true)
      const res =
        await rewardPointServiceInstance.getAllComplianceRewards()

      if ([200, 201].includes(res.status)) {
        commit('SET_ALL_COMPLIANCE_REWARDS', res.data)
        commit('SET_FETCHING_COMPLIANCE_REWARDS', false)
      } else {
        commit('SET_FETCHING_COMPLIANCE_REWARDS', false)
      }

      return res
    },

    async getAllDriversComplianceAccumulatedPoints({ commit }) {
      commit('SET_FETCHING_DRIVERS_COMPLIANCE_ACCUMULATED_POINTS', true)
      const res =
        await rewardPointServiceInstance.getAllDriversComplianceAccumulatedPoints()

      if ([200, 201].includes(res.status)) {
        commit('SET_ALL_DRIVERS_COMPLIANCE_ACCUMULATED_POINTS', res.data.data)
        commit('SET_FETCHING_DRIVERS_COMPLIANCE_ACCUMULATED_POINTS', false)
      } else {
        commit('SET_FETCHING_DRIVERS_COMPLIANCE_ACCUMULATED_POINTS', false)
      }

      return res
    },

    async getHighestLowestDriversComplianceAccumulatedPoints({ commit }) {
      commit('SET_FETCHING_HIGHEST_LOWEST_DRIVERS_COMPLIANCE_ACCUMULATED_POINTS', true)
      const res =
        await rewardPointServiceInstance.getHighestLowestDriversComplianceReward()

      if ([200, 201].includes(res.status)) {
        commit('SET_HIGHEST_LOWEST_DRIVERS_COMPLIANCE_ACCUMULATED_POINTS', res.data)
        commit('SET_FETCHING_HIGHEST_LOWEST_DRIVERS_COMPLIANCE_ACCUMULATED_POINTS', false)
      } else {
        commit('SET_FETCHING_HIGHEST_LOWEST_DRIVERS_COMPLIANCE_ACCUMULATED_POINTS', false)
      }

      return res
    },

    async updateSingleComplianceReward({ commit }, data) {
      commit('SET_FETCHING_COMPLIANCE_REWARD_UPDATE', true)
      const res =
        await rewardPointServiceInstance.updateSingleComplianceReward(data)

      if ([200, 201].includes(res.status)) {
        commit('SET_FETCHING_COMPLIANCE_REWARD_UPDATE', false)
      } else {
        commit('SET_FETCHING_COMPLIANCE_REWARD_UPDATE', false)
      }

      return res
    },

    async getSingleDriverComplianceRewardHistory({ commit }, data) {
      commit('SET_FETCHING_SINGLE_DRIVER_COMPLIANCE_REWARD_HISTORY', true)
      const res =
        await rewardPointServiceInstance.getSingleDriverComplianceRewardHistory(data.userId, data.userType)

      if ([200, 201].includes(res.status)) {
        commit('SET_SINGLE_DRIVER_COMPLIANCE_REWARD_HISTORY', res.data.data)
        commit('SET_FETCHING_SINGLE_DRIVER_COMPLIANCE_REWARD_HISTORY', false)
      } else {
        commit('SET_FETCHING_SINGLE_DRIVER_COMPLIANCE_REWARD_HISTORY', false)
      }

      return res
    }
  },
}
