import { loadRouteComponent } from '@/utils/helpers'
import Transactions from '@/layouts/modules/Transactions/Index.vue'
const TransactionsRoutes = [
  {
    path: 'transactions',
    component: Transactions,
    props: true,
    children: [
      {
        path: '',
        name: 'transactions.index',
        component: loadRouteComponent('Transactions/pages/List'),
        props: true
      },
      {
        path: ':transactionId',
        name: 'transactions.view',
        component: loadRouteComponent('Transactions/pages/Id'),
        props: true
      }
    ]
  }
]

export default TransactionsRoutes
