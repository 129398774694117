<template>
	<div class="p-4 w-full ">
		<div class="flex flex-col lg:flex-row gap-4" v-if="!loadingRouteItinerary">
			<div class="w-full md:max-w-[150px] lg:max-w-[200px] xl:max-w-[250px] px-0 shrink-0">
				<router-link v-if="userHasPermission('routes003')" :to="{ name: 'NewRouteItinerary', params: { routeId: routeId } }"
					class="btn mb-3 bg-shuttlers text-white">
					Add Availability Time
				</router-link>

				<div class="list-group">
					<router-link v-if="userHasPermission('routes007')" :to="{
						name: 'ShowRouteItinerary',
						params: {
							routeId: routeId,
							itineraryId: item.id,
						},
					}" v-for="(item, index) in itineraries" :key="item.id" class="list-group-item" exact-active-class="active">
						{{ item.trip_time }}

						<button @click.prevent="deleteRouteItinerary(item, index)" class="btn btn-sm font-medium float-right" v-if="userHasPermission('routes009')"
              :class="$route.params.itineraryId === item.id ? 'text-white' : 'text-black'"
            >
							Delete
						</button>
					</router-link>
				</div>
			</div>
			<div class="px-0 w-[100%] lg:w-[calc(100%-200px)]">
				<router-view></router-view>
			</div>
		</div>
		<template v-else>
			<div class="d-flex flex-column justify-content-center align-items-center">
				<div class="spinner-border" role="status"></div>
				<p class="text-center mt-4"><strong>Loading Itineraries...</strong></p>
			</div>
		</template>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
export default {
  props: {
    routeId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      activeRoute: null,
      itineraries: [],
      loadingRouteItinerary: false
    }
  },
  provide() {
    return {
      hasItineraries: () => !!this.itineraries.length,
      routeData: () => this.activeRoute
    }
  },
  created() {
    this.loadActiveRoute()
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        if (this.activeRoute && this.$route.routeId != this.activeRoute.id) {
          this.loadActiveRoute()
        }
      }
    }
  },
  methods: {
    deleteRouteItinerary(item, index) {
      return this.$swal({
        title: 'Sure to delete route itinerary?',
        text: 'Customers will no longer discover this route itinerary when searching on the app',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          return this.handleDeleteRoute(item, index).catch((err) => {
            this.$swal('Could not delete route', err.toString(), 'info')
            return Promise.reject(false)
          })
        }
        return Promise.reject(false)
      })
    },
    async handleDeleteRoute(item, index) {
      try {
        this.$Progress.start()
        await this.axios.delete(`/v1/route-itineraries/${item.id}`)
        this.$eventbus.$emit('route-itineraries:deleted', item.id)
        this.itineraries.splice(index, 1)
        this.$Progress.finish()
        if (this.$route && this.$route.params && this.$route.params.itineraryId) {
          this.$router.push({ name: 'ShowRouteItineraries' })
        }
      } catch (e) {
        let msg = e.toString()
        if (e.response && e.response.data && e.response.data.message) {
          msg = e.response.data.message
        }
        this.$swal('Opps', `Could not delete route itinerary: ${msg}`, 'danger')
        this.$Progress.fail()
      }
    },
    async listRouteItineraries() {
      if (!this.routeId) {
        this.loadingRouteItinerary = false
        return
      }
      this.$Progress.start()
      this.loadingRouteItinerary = true
      try {
        const result = await this.axios.get(
          `/v1/routes/${this.routeId}/itineraries?itinerary_only=1`
        )
        this.itineraries = result.data.data
        this.loadingRouteItinerary = false

        this.$Progress.finish()
      } catch (e) {
        this.itineraries = []
        this.loadingRouteItinerary = false
        this.$Progress.fail()
      }
    },
    async loadActiveRoute() {
      this.loadingRouteItinerary = true
      try {
        this.$Progress.start()
        const result = await this.axios.get(`/v1/routes/${this.routeId}`)
        const route = result.data
        route.showInfo = false
        this.showRoute(route)
        this.$Progress.finish()
      } catch (e) {
        this.$Progress.fail()
      } finally {
        await this.listRouteItineraries()
      }
    },
    showRoute(route) {
      this.activeRoute = route
    },
    addItineraryModal() { }
  }
}
</script>
