<template>
	<div>
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div class="bg-white border-bottom" v-else>
			<div class="header mb-0">
				<div class="container-fluid">
					<div class="header-body py-0 mt-4 border-0">
						<div class="row align-items-end">
							<div class="col">
								<h6 class="header-pretitle">Vehicle Overview</h6>
								<h1 class="header-title">
									{{
										currentVehicle
											? `${currentVehicle.brand} ${currentVehicle.name}`
											: ''
									}}
								</h1>
							</div>
							<div class="col-auto">
								<!-- <a href="#!" class="btn btn-primary">Create Report</a> -->
							</div>
						</div>
						<!-- / .row -->
					</div>
					<!-- / .header-body -->
				</div>
			</div>
			<div class="container-fluid">
				<div class="row pt-3 pb-1">
					<div class="col justify-self-center align-self-center flex-column">
						<ul class="nav nav-pills" v-if="partner_id">
							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShowVehicle',
										props: {
											vehicleId: $route.params.vehicleId,
											partnerId: partner_id,
										},
									}"
									class="nav-link"
									active-class="active"
									>Vehicle overview</router-link
									>
							</li>
							<li class="nav-item" v-if="partner_id">
								<router-link
									:to="{
										name: 'ShowVehicleDocument',
										props: {
											vehicleId: $route.params.vehicleId,
											partnerId: partner_id,
										},
									}"
									class="nav-link"
									active-class="active"
									>Vehicle document</router-link
									>
							</li>
							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShowVehicleTripHistory',
										props: {
											vehicleId: $route.params.vehicleId,
											partnerId: partner_id,
										},
									}"
									class="nav-link"
									active-class="active"
									>Trip History</router-link
									>
							</li>
							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShowVehicleCustomerRatings',
										props: {
											vehicleId: $route.params.vehicleId,
											partnerId: partner_id,
										},
									}"
									class="nav-link"
									active-class="active"
									>Customer Ratings</router-link
									>
							</li>
						</ul>

						<ul class="nav nav-pills" v-if="!partner_id">
							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShowVehicle',
										props: {
											vehicleId: $route.params.vehicleId,
										},
									}"
									class="nav-link"
									active-class="active"
									>Vehicle overview</router-link
									>
							</li>
							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShowVehicleTripHistory',
										props: {
											vehicleId: $route.params.vehicleId,
										},
									}"
									class="nav-link"
									active-class="active"
									>Trip History</router-link
									>
							</li>
							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShowVehicleCustomerRatings',
										props: {
											vehicleId: $route.params.vehicleId,
										},
									}"
									class="nav-link"
									active-class="active"
									>Customer Ratings</router-link
									>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div v-if="!loading" class="container-fluid my-4 p-1">
			<p>
				<!-- <router-link to="/" class="text-secondary font-weight-light"
          >Vehicle Partners<img src="@/assets/img/caret-right.svg" alt=""
        /></router-link> -->
				<router-link to="/fleet/list/vehicle" class="text-secondary font-weight-light"
					>Vehicles<img
						src="@/assets/img/caret-right.svg"
						alt="" /></router-link
					><router-link :to="url" class="text-secondary font-weight-light">
					{{
						currentVehicle
							? `${currentVehicle.brand} ${currentVehicle.name}`
							: ''
					}}</router-link
					><img src="@/assets/img/caret-right.svg" alt="" /><router-link
					:to="url"
					class="text-dark font-weight-light"
					>
					{{
						currentVehicle ? currentVehicle.registration_number : ''
					}}</router-link
					>
			</p>
		</div>

		<div class="">
			<div class="container-fluid">
				<router-link
					to="/fleet/list/vehicle"
					class="btn btn-light mb-4 btn-sm"
					v-if="!loading"
					>Go back</router-link
					>
				<router-view> </router-view>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    vehicleId: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    return {
      loading: false,
      currentVehicle: null,
      partner_id: ''
    }
  },
  created () {
    this.fetchVehicle(this.vehicleId)
  },
  computed: {
    url () {
      return `/fleet/${this.vehicleId}/vehicle-info`
    }
  },
  mounted () {
    this.fetchVehicle(this.vehicleId)
  },
  methods: {
    async fetchVehicle (vehicleId) {
      if (this.loading) return

      try {
        this.loading = true
        const result = await this.axios.get(`/v1/vehicles/${vehicleId}`)
        this.partner_id = result?.data?.partner_id

        this.currentVehicle = result?.data
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}

.VueTables__search-field > label {
  display: none;
}

.VueTables__search-field {
  margin-top: 20px;
}
</style>
