export default {
  watch: {
    show: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.$nextTick(() => {
            this.$refs.modalToggle.click()
          })
        }
      }
    }
  },
  methods: {
    close (e) {
      const target = (e && e.target) || this.$refs.closeModalButton

      target.click()

      this.$emit('close')
    }
  }
}
