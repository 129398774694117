<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Dashboard</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-lg-6 col-xl">
					<div class="card">
						<div class="card-body">
							<div class="row align-items-center gx-0">
								<div class="col">
									<div v-if="loadingStats" class="d-flex justify-content-center align-items-center">
										<div class="spinner-border" role="status"></div>
									</div>
									<div v-else>
										<h6 class="text-uppercase text-muted mb-2">Total Rides</h6>
										<span class="h2 mb-0">{{
											formatNumberToMoney(stats.totalRides)
										}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-xl">
					<div class="card">
						<div class="card-body">
							<div class="row align-items-center gx-0">
								<div class="col">
									<div v-if="loadingStats" class="d-flex justify-content-center align-items-center">
										<div class="spinner-border" role="status"></div>
									</div>
									<div v-else>
										<h6 class="text-uppercase text-muted mb-2">
											Total Transactions
										</h6>
										<span class="h2 mb-0">{{
											formatNumberToMoney(stats.totalTransactions)
										}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-xl">
					<div class="card">
						<div class="card-body">
							<div class="row align-items-center gx-0">
								<div class="col">
									<div v-if="loadingStats" class="d-flex justify-content-center align-items-center">
										<div class="spinner-border" role="status"></div>
									</div>
									<div v-else>
										<h6 class="text-uppercase text-muted mb-2">Total Users</h6>
										<span class="h2 mb-0">{{
											formatNumberToMoney(stats.totalUsers)
										}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-xl">
					<div class="card">
						<div class="card-body">
							<div class="row align-items-center gx-0">
								<div class="col">
									<div v-if="loadingStats" class="d-flex justify-content-center align-items-center">
										<div class="spinner-border" role="status"></div>
									</div>
									<div v-else>
										<h6 class="text-uppercase text-muted mb-2">
											Total Drivers
										</h6>
										<span class="h2 mb-0">{{
											formatNumberToMoney(stats.totalDrivers)
										}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-xl-12">
					<div class="card">
						<div class="card-header">
							<div>
								<h4 class="card-header-title">Trips</h4>
							</div>
						</div>
						<div class="card-body" v-if="isPermittedToLoadChat">
							<bar-chart v-if="ridesByUsersLast30Days" class="chart barchart" :chart-data="ridesByUsersLast30Days"
								:options="barChartOptions"></bar-chart>
							<div v-if="loadingUsersChartData" class="d-flex justify-content-center align-items-center">
								<div class="spinner-border" role="status"></div>
							</div>
						</div>

						<div class="card-body d-flex flex-column justify-content-center align-items-center"
							v-if="!isPermittedToLoadChat">
							<div>
								<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none"
									stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
									<circle cx="12" cy="12" r="10"></circle>
									<line x1="12" y1="8" x2="12" y2="12"></line>
									<line x1="12" y1="16" x2="12.01" y2="16"></line>
								</svg>
							</div>
							<p class="text-muted mt-3">Permission not allowed!</p>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<ratings-section> </ratings-section>
			</div>

			<div class="row">
				<div class="col-12 col-xl-6">
					<div class="card">
						<div class="card-header">
							<div class="row align-items-center">
								<div class="col">
									<h4 class="card-header-title">Last 5 Transactions</h4>
								</div>
							</div>
						</div>
						<app-table :fields="last5TransactionsFields" :items="last5Transactions.data"
							:loading="loadingLast5Transactions" :error-loading="errorLoadingLast5Transactions"
							@retry="fetchLast5Transactions">
							<template v-slot:created_at="{ item }">
								{{ item.created_at | date('hh:mm a MMM d, yyyy') }}
							</template>
							<template v-slot:title="{ item }">
								{{ item.title }}
							</template>
							<template v-slot:user="{ item }">
								{{ item.user.lname }}
							</template>
							<template v-slot:amount="{ item }">
								<span :class="item.type === 'debit' ? 'text-danger' : 'text-success'
								">{{ formatNumberAsCurrencyText(item.amount) }}</span>
							</template>
						</app-table>
					</div>
				</div>
				<div class="col-12 col-xl-6">
					<div class="card">
						<div class="card-header">
							<div class="row align-items-center">
								<div class="col">
									<h4 class="card-header-title">Last 5 User Signups</h4>
								</div>
							</div>
						</div>
						<div class="">
							<app-table :fields="last5UsersFields" :items="last5Users.data.slice(0, 5)" :loading="loadingLast5Users"
								:error-loading="errorLoadingLast5Users" @retry="fetchLast5Users">
								<template v-slot:user="{ item }">
									<div class="d-inline-flex align-items-center">
										<user-avatar :user="item"></user-avatar>
										<span class="ml-2 font-weight-bold">{{
											`${item.fname} ${item.lname}`
										}}</span>
									</div>
								</template>
								<template v-slot:status="{ item }">
									<span :class="item.active ? 'text-green' : 'text-red'">{{
										item.active ? 'Active' : 'Inactive'
									}}</span>
								</template>
							</app-table>
						</div>
						<div class="card-footer card-footer-boxed">
							<div class="row align-items-center justify-content-end">
								<router-link :to="{ name: 'UsersList' }" class="d-flex align-items-center justify-content-center">
									<small class="">
										All Users <i class="fe fe-arrow-right"></i>
									</small>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-xl-6">
					<div class="card card-fill">
						<div class="card-header">
							<h4 class="card-header-title">Our Routes</h4>
						</div>
						<div class="card-body" :class="{ 'p-0': !loadingRoutes }">
							<div v-if="loadingRoutes" class="d-flex justify-content-center align-items-center">
								<div class="spinner-border" role="status"></div>
							</div>
							<template v-else>
								<div class="single-route" v-for="route in routes.data.slice(0, 5)" :key="route.id">
									<router-link :to="{ name: 'ShowRoute', params: { routeId: route.id } }"
										class="d-flex align-items-center justify-content-between">
										<div>
											<div class="direction">
												<div>
													<span class="indicator from"></span>
													{{ route.pickup }}
												</div>
												<div>
													<span class="indicator to"></span>
													{{ route.destination }}
												</div>
											</div>
											<div class="d-flex align-items-center justify-content-between mt-4 ml-4 w-full">
												<small class="time flex-fill pre-icon sh-bus" title="Route Code">
													{{ route.route_code || 'N/A' }}
												</small>
												<small class="time flex-fill pre-icon sh-clock" title="Duration">
													{{ route.duration }}
												</small>
												<small class="pre-icon flex-fill sh-stops" title="Route Distance">
													{{ route.distance }}
												</small>
												<span class="badge text-white" :class="route.status ? 'bg-success' : 'bg-danger'">{{ route.status
													? 'Active' : 'Inactive' }}</span>
											</div>
										</div>
										<p class="h1"><i class="fa fa-angle-right"></i></p>
									</router-link>
								</div>
							</template>
						</div>
						<div class="card-body d-flex flex-column justify-content-center align-items-center"
							v-if="!isPermittedToLoadRoutes">
							<div>
								<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none"
									stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
									<circle cx="12" cy="12" r="10"></circle>
									<line x1="12" y1="8" x2="12" y2="12"></line>
									<line x1="12" y1="16" x2="12.01" y2="16"></line>
								</svg>
							</div>
							<p class="text-muted mt-3">Permission not allowed!</p>
						</div>
					</div>
				</div>
				<div class="col-12 col-xl-6">
					<div class="card">
						<div class="card-header">
							<div class="row align-items-center">
								<div class="col">
									<h4 class="card-header-title">Recent Charter Requests</h4>
								</div>
							</div>
						</div>
						<div v-if="isPermittedToLoadCharter">
							<div class="">
								<app-table :fields="charterRequestsFields" :items="charterRequests.data.slice(0, 5)"
									:loading="loadingCharterRequests" :error-loading="errorLoadingCharterRequests"
									@retry="fetchCharterRequests">
									<template v-slot:trip_type="{ item }">
										{{ item.return_address ? 'Round Trip' : 'One Way' }}
									</template>
								</app-table>
							</div>
							<div class="card-footer card-footer-boxed">
								<div class="row align-items-center justify-content-end">
									<router-link :to="{
										name: 'ChartersList',
									}">
										<small>
											All Charter Requests <i class="fe fe-arrow-right"></i>
										</small>
									</router-link>
								</div>
							</div>
						</div>

						<div class="card-body d-flex flex-column justify-content-center align-items-center"
							v-if="!isPermittedToLoadCharter">
							<div>
								<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none"
									stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
									<circle cx="12" cy="12" r="10"></circle>
									<line x1="12" y1="8" x2="12" y2="12"></line>
									<line x1="12" y1="16" x2="12.01" y2="16"></line>
								</svg>
							</div>
							<p class="text-muted mt-3">Permission not allowed!</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>



<script setup>

import { ref } from 'vue'
import BarChart from '@/components/charts/BarChart.vue'
import { getYearlyBarChartData } from '@/utils/chart'
import AppTable from '@/components/core/AppTable.vue'
import UserAvatar from '@/components/core/UserAvatar.vue'
import RatingsSection from '@/components/modules/trips/RatingsDashboardComponent.vue'
import { axiosInstance } from '@/plugins/axios'
import {
  formatNumberToMoney,
  formatNumberAsCurrencyText
} from '@/utils/helpers'
const stats = ref({
  totalRides: 0,
  totalTransactions: 0,
  totalUsers: 0,
  totalDrivers: 0
})

const loadingStats = ref(false)
const errorLoadingStats = ref(false)
const ridesByUsersLast30Days = ref(null)
const loadingUsersChartData = ref(true)
const errorLoadingUsersChartData = ref(false)
const loadingLast5Transactions = ref(true)
const errorLoadingLast5Transactions = ref(false)
const loadingLast5Users = ref(true)
const errorLoadingLast5Users = ref(false)
const loadingRoutes = ref(true)
const errorLoadingRoutes = ref(false)
const loadingCharterRequests = ref(true)
const errorLoadingCharterRequests = ref(false)
const isPermittedToLoadRoutes = ref(true)
const isPermittedToLoadChat = ref(true)
const isPermittedToLoadCharter = ref(true)
const last5Transactions = ref({
  data: []
})
const last5Users = ref({
  data: []
})
const routes = ref({
  data: []
})
const charterRequests = ref({
  data: []
})
const last5TransactionsFields = ref([
  {
    label: 'Date',
    key: 'created_at'
  },
  {
    label: 'Description',
    key: 'title'
  },
  {
    label: 'User',
    key: 'user'
  },
  {
    label: 'Amount',
    key: 'amount'
  }
])
const last5UsersFields = ref([
  {
    label: 'User',
    key: 'user'
  },
  {
    label: 'Email',
    key: 'email'
  },
  {
    label: 'Status',
    key: 'status'
  }
])
const charterRequestsFields = ref([
  {
    label: 'Pickup Date',
    key: 'pickup_date'
  },
  {
    label: 'From',
    key: 'pickup_address'
  },
  {
    label: 'To',
    key: 'dropoff_address'
  },
  {
    label: 'Trip Type',
    key: 'trip_type'
  }
])
const barChartOptions = ref({
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
        },
        offset: true
      }
    ],
    yAxes: [
      {
        gridLines: {
          borderDash: [8, 4],
          color: '#F6F6F7'
        },
        ticks: {
          stepSize: 500
        }
      }
    ]
  }
})

const fetchStats = () => {
  loadingStats.value = true
  errorLoadingStats.value = false

  Promise.all([
    axiosInstance.get('/v1/stats/rides-count'),
    axiosInstance.get('/v1/stats/users-count'),
    axiosInstance.get('/v1/stats/drivers-count'),
    axiosInstance.get('/v1/stats/transactions-count')
  ])
    .then((res) => {
      stats.value.totalRides = res[0].data?.count || 0
      stats.value.totalUsers = res[1].data?.count || 0
      stats.value.totalDrivers = res[2].data?.count || 0
      stats.value.totalTransactions = res[3].data?.count || 0
    })
    .catch(() => {
      errorLoadingStats.value = true
    })
    .finally(() => (loadingStats.value = false))
}

const fetchChartData = () => {
  loadingUsersChartData.value = true
  errorLoadingUsersChartData.value = false

  axiosInstance.get('/v1/trips/graph')
    .then((res) => {
      isPermittedToLoadChat.value = true
      ridesByUsersLast30Days.value = getYearlyBarChartData(res.data)
    })
    .catch((err) => {
      if (
        err?.response?.data?.message ===
        'Sorry, you do not have permission to perform this operation!'
      ) {
        isPermittedToLoadChat.value = false
      }
      errorLoadingUsersChartData.value = true
    })
    .finally(() => {
      loadingUsersChartData.value = false
    })
}

const fetchLast5Transactions = () => {
  loadingLast5Transactions.value = true
  errorLoadingLast5Transactions.value = false

  axiosInstance.get(
    '/v1/transactions?limit=5&page=1&sort[created_at]=desc&related=user'
  )
    .then((res) => {
      last5Transactions.value.data = res.data
    })
    .catch(() => {
      errorLoadingLast5Transactions.value = true
    })
    .finally(() => (loadingLast5Transactions.value = false))
}

const fetchRoutes = () => {
  loadingRoutes.value = true
  errorLoadingRoutes.value = false

  axiosInstance.get('/v1/routes?status=all&limit=5')
    .then((res) => {
      routes.value.data = res.data
      isPermittedToLoadRoutes.value = true
    })
    .catch((err) => {
      if (
        err?.response?.data?.message ===
        'Sorry, you do not have permission to perform this operation!'
      ) {
        isPermittedToLoadRoutes.value = false
      }
      errorLoadingRoutes.value = true
    })
    .finally(() => (loadingRoutes.value = false))
}

const fetchCharterRequests = () => {
  loadingCharterRequests.value = true
  errorLoadingCharterRequests.value = false

  axiosInstance.get('/v1/charters?limit=5')
    .then((res) => {
      charterRequests.value.data = res.data.data
      isPermittedToLoadCharter.value = true
    })
    .catch(() => {
      isPermittedToLoadCharter.value = false
      errorLoadingCharterRequests.value = true
    })
    .finally(() => (loadingCharterRequests.value = false))
}

const fetchLast5Users = () => {
  loadingLast5Users.value = true
  errorLoadingLast5Users.value = false

  axiosInstance
    .get('/v1/users?limit=5&page=1&sort[created_at]=desc')
    .then((res) => {
    last5Users.value.data = res.data
    })
    .catch(() => {
      errorLoadingLast5Users.value = true
    })
    .finally(() => (loadingLast5Users.value = false))
}

fetchStats()
fetchChartData()
fetchLast5Transactions()
fetchRoutes()
fetchCharterRequests()
fetchLast5Users()
</script>
