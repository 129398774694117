export default (store, isTokenExpired) => {
  return (to, from, next) => {
    // if (
    //   store.getters.isLoggedIn &&
    //   isTokenExpired(store.getters.currentUser?.token?.exp) &&
    //   to.path !== '/login'
    // ) {
    //   store
    //     .dispatch('logout')
    //     .then(async () => {
    //       await next({
    //         path: '/login'
    //       })
    //     })
    //     .catch(async () => {
    //       await next({
    //         path: '/login'
    //       })
    //     })
    //   return
    // }

    if (store.getters.isLoggedIn && to.path === '/login') {
      next({
        path: '/'
      })
      return
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.isLoggedIn) {
        next({
          path: '/login',
          query: { redirect: to.fullPath },
          replace: true
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }
}
