<template>
  <div>
    <b-modal
      :id="modalId"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @hidden="resetForm"
    >
      <template #modal-title> Create a reward </template>
      <div class="create-reward py-2">
        <div>
          <label for="range-1">Reward</label>
          <v-select
            v-model="form.rewardName"
            class="input"
            :options="allComplianceReward"
            label="pickup"
            :filterable="false"
            placeholder="Select reward"
            :get-option-label="(option) => option.name"
            @search="search"
          >
            <template v-slot:option="reward">
              <p class="route-options text-sm">
                <!-- <span v-if="route.route_code">{{
                  `${route.route_code} - `
                }}</span> -->
                {{ reward.name }}
              </p>
            </template>
            <template v-if="fetchingComplianceReward" v-slot:no-options>
              loading...
            </template>
            <!-- <template v-slot:selected-option="route">
                                <span v-if="route.route_code">{{
                                `${route.route_code} - `
                                }}</span>
                                {{ `${route.pickup} to ${route.destination}` }}
                            </template> -->
          </v-select>
        </div>
        <div>
          <label for="range-1">Reward value (points)</label>
          <b-form-input
            class="input"
            v-model="form.rewardValue"
            placeholder="Enter points"
          ></b-form-input>
        </div>
        <div class="d-flex justify-content-start align-items-center mt-2.5">
          <div class="toggle relative w-12 h-6">
            <input
              v-model="form.status"
              type="checkbox"
              class="w-12 h-full absolute z-10 opacity-0 cursor-pointer"
            />
            <label
              :class="{
                active: form.status,
              }"
            >
              <span :class="{ active: form.status }" />
            </label>
          </div>
          <p class="text-secondary" style="
          font-size: 12px;
          margin-left: 12px;
          ">Enable reward</p>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right mr-2 button"
            :disabled="isDisabled"
            @click="onCreate({
                  name: form.rewardName.name,
                  min_point: form.rewardValue,
                  status: form.status ? 'active' : 'inactive',
                })"
          >
            {{ fetchingComplianceRewardUpdate? 'Processing...' : 'Create' }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'

export default {
  name: 'CreateRewardModal',
  // props: {
  //   loading: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  data() {
    return {
      form: reactive({
        rewardName: null,
        rewardValue: null,
        status: ref(false),
      }),
      modalId: 'create-reward-modal',
      isValid: null,
      rewardOptions: [],
      isLoadingGetRoutes: ref(false),
    }
  },
  computed: {
    allComplianceReward() {
      return this.$store.state.rewardPoints.allComplianceReward
    },
    fetchingComplianceReward() {
      return this.$store.state.rewardPoints.fetchingComplianceReward
    },
    fetchingComplianceRewardUpdate() {
      return this.$store.state.rewardPoints.fetchingComplianceRewardUpdate
    },
    isDisabled() {
      if ((!this.form.rewardName || !this.form.rewardValue) || this.fetchingComplianceRewardUpdate){
        return true
      }
      return false
    },
  },
  emits: ['onCreate'],
  methods: {
    checkValidity() {
      if (!this.form.description) {
        this.isValid = false
        return
      }
      this.isValid = null
    },

    resetForm() {
      this.form.description = null
      this.isValid = null
    },

    queryEvent() {
      this.checkValidity()
      this.$emit('onQueryEvent', this.form)
    },

    onCreate(data) {
      this.$emit('onCreate', data)
    },

    search() {
      return
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  &:disabled {
    opacity: 40%;
    cursor: not-allowed;
  }
}

.create-reward {
  & div {
    & label {
      font-size: 12px;
      margin-bottom: 8px;
    }

    & .input {
      height: 50px;

      & :first-child {
        height: 100% !important;
        font-size: 0.9375rem !important;

        & :nth-child(2) {
          & :nth-child(1) { 
            margin-bottom: 0px !important;
          }
        }
      }
    }
  }

  & :first-child {
    margin-bottom: 16px;
  }

  & .toggle {
    position: relative;
    width: 48px;
    height: 24px;
    transition: all 200ms ease-in-out;

    & input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10px;
      opacity: 0;
      cursor: pointer;
    }

    & label {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #f9fbfa;
      // border-radius: 50%;
      border-radius: 9999px;
      // ro
      // box-shadow: inn;
      // inset: 0px;
      transition: all 200ms ease-in-out;
      border: 1px solid #e5e5ea;

      &.active {
        background: #15cd70;
      }

      & span {
        position: inherit;
        width: 24px;
        height: 100%;
        background: #ffffff;
        border-radius: 9999px;
        box-shadow: grey;
        inset: 0px;
        transition: all 200ms ease-in-out;
        border: 1px solid #e5e5ea;

        &.active {
          transform: translateX(100%);
        }
      }
    }
  }
}

.description {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  & .alert-circle {
    font-size: 16px;
    color: #0dac5c;
  }

  & .text {
    font-size: 12px;
    color: #737876;
  }
}
</style>
