export default {
  namespaced: true,
  state: {
    trips: []
  },
  mutations: {
    setTrips (state, data) {
      state.trips = data
    }
  },
  actions: {
    setTips ({ commit }, data) {
      commit('setTrips', data)
    }
  }
}
