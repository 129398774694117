<template>
	<div class="main-content">
		<page-header title="Partners Payout" pre-title="Overview" :loading="false">
			<template slot="actions">
				<!-- <router-link v-if="!intrestedPartner && userHasPermission('partners001')" :to="{ name: 'NewVehiclePartner' }" class="btn btn-primary"
				>
					Add Partner 
				</router-link> -->
			</template>
			<!-- <feature-flag-enabled feature-flag="VEHICLE_PARTNERS"> -->
			<template slot="tabs">
				<page-tabs :menu-tabs="menuTabs">
					<template v-slot="props">
						<router-link :to="{ name: props.menu.routeName }" class="nav-link" active-class="active">
							{{ props.menu.label }}
						</router-link>
					</template>
				</page-tabs>
			</template>
			<!-- </feature-flag-enabled> -->
		</page-header>

		<page-route-view :loading="false"> </page-route-view>
		<template>
			<payout-config-modal :show="showPayoutConfig" @close="showPayoutConfig = false" @showPayout="showConfig()">
			</payout-config-modal>
		</template>

		<BS_modal :show="isDeductModal" title="Deduct Payout" @close="isDeductModal = false" modalId="isDeductModal">
			<form @submit.prevent="deduct" class="flex flex-col w-full">
				<div class="form-group">
					<label for="amount" class="form-radio-label ml-2">Deducted Amount</label>
					<input v-model="deductAmount" class="form-control mb-3" type="number" required />
					<span class="text-red-500 -mt-5" v-if="deductAmount > isDeductAmountTable">Amount is larger than payslip
						total</span>
				</div>
				<div class="form-group">
					<label for="dec" class="form-radio-label ml-2">Deduction Description</label>
					<textarea v-model="deductDescription" class="form-control mb-3" required />
				</div>
				<div class="form-group">
					<label for="warning" class="form-radio-label ml-2 text-black font-bold">Type in YES to confirm this
						action</label>
					<input v-model="deductValidText" class="form-control mb-3" type="text" required />
				</div>

				<button :disabled="deductAmount > isDeductAmountTable ||
					deductAmount == 0 ||
					deductDescription == '' ||
					deductValidText !== 'YES' ||
					isDeductloading
					"
					class="btn-primary py-3 rounded-md disabled:bg-slate-300 disabled:text-black border-none disabled:cursor-not-allowed">
					{{ isDeductloading ? 'Loading...' : 'Deduct' }}
				</button>
			</form>
		</BS_modal>
	</div>
</template>

<script setup>
import { userHasPermission, DoNotShowOnProd } from '@/composables/core/permissions'
import PayoutConfigModal from '@/components/modals/PayoutConfigModal.vue'
import BS_modal from '@/components/core/modals/BS_modal.vue'
import Swal from 'sweetalert2'
import moment from 'moment'

</script>

<script>
// import FeatureFlagEnabled from "@/components/layout/FeatureFlagEnabled";
import { computed } from 'vue'
import PageHeader from '@/components/layout/PageHeader'
import PageTabs from '@/components/layout/PageTabs'
import PageRouteView from '@/components/layout/PageRouteView'
export default {
	// components: {PageRouteView, PageTabs, PageHeader, FeatureFlagEnabled},
	components: { PageRouteView, PageTabs, PageHeader },
	data() {
		return {
			menuTabs: [
				{
					routeName: 'PendingPayout',
					label: 'Pending'
				},
				{
					routeName: 'CompletedPayout',
					label: 'Completed'
				},
				{
					routeName: 'FailedPayout',
					label: 'Failed'
				}
			],
			isDeductId: '',
			isDeductAmountTable: 0,
			isDeductModal: false,
			isDeductloading: false,
			deductAmount: null,
			deductDescription: '',
			deductValidText: '',
			showPayoutConfig: false,
			to_refresh: ''
		}
	},
	computed: {
		intrestedPartner() {
			return this.$route.name === 'IntrestedPartnersList'
		}
	},
	methods: {
		triggerRefresh () {
			this.to_refresh = ''
		},
		openDeductionModal(data, from) {
			this.isDeductModal = true
			this.isDeductAmountTable = data.item.amount
			this.isDeductId = data.item.id
			this.to_refresh = from
		},
		async deduct() {
			this.isDeductloading = true
			const sent_data = {
				amount: Number(this.deductAmount),
				description: this.deductDescription,
			}
			try {
				const res = await this.axios.post(
					`/cost-revenue/v1/earnings/${this.isDeductId}/deductions`,
					sent_data
				)
				this.triggerRefresh()
				this.$toastr.s('Revenue Deducted', 'Success')
				this.isDeductloading = false
				this.isDeductModal = false
			} catch (e) {
				this.$toastr.e('Error deducting revenue', 'Error')
			}
		},
		async markRevenueAsPaid(data, from) {
			this.to_refresh = from
			const sent_data = {
				partnerId: data.item.partnerId,
				earningId: data.item.id,
			}
			try {
				await this.$swal({
					icon: 'question',
					title: 'Please Confirm',
					text: 'Are you sure you want to mark this slip as paid?',
					showConfirmButton: true,
					showCancelButton: true,
					preConfirm: () => {
						return this.axios
							.post('/cost-revenue/v1/earnings/paid', sent_data)
							.then((res) => {
								this.triggerRefresh()
								this.$toastr.s('Revenue marked as paid', 'Success')
							})
							.catch((error) => {
								const msg =
									error.response && error.response.data
										? error.response.data.message
										: 'An error occured, please try again.'

								this.$swal().showValidationMessage(msg)
							})
					},
					allowOutsideClick: () => !Swal.isLoading(),
				})
			} catch (e) {
				this.$toastr.e('Something went wrong', 'Error')
			}
		},
		showConfig() {
			this.showPayoutConfig = !this.showPayoutConfig
		}
	},
	provide() {
		return {
			openDeductionModal: this.openDeductionModal,
			markRevenueAsPaid: this.markRevenueAsPaid,
			to_refresh: computed(() => this.to_refresh)
		}
	},
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/nav-tabs';
</style>
