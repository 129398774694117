<template>
  <div class="main-content">
    <page-header title="Driver Reward" pre-title="Overview" :loading="false">
      <template slot="tabs">
        <page-tabs-vue :menu-tabs="menuTabs">
          <template v-slot="props">
            <router-link
              :to="{ name: props.menu.routeName }"
              class="nav-link"
              exact-active-class="active"
            >
              {{ props.menu.label }}
            </router-link>
          </template>
        </page-tabs-vue>
      </template>

      <template slot="actions">
        <div class="list-assigned__header">
          <section class="btns">
            <button class="add btn" @click="showModal('create-reward-modal')">
              Create reward
            </button>
            <b-button
              variant="primary"
              @click="showModal('configure-point-modal')"
              >Configure point</b-button
            >
          </section>
        </div>
      </template>

      <template slot="desc">
        <div class="rate-desc w-100 h-6">{{ currentRewardPointRate ? 'Current point rate: 1 point ~ ₦ ' + `${currentRewardPointRate}` : 'No point rate assigned yet'}}</div>
      </template>
    </page-header>
    <page-route-view
     :bus="bus"
     :loading="false"
     @update:rewardPointConfigurationLoading="updateRewardPointConfigurationLoader"
    > </page-route-view>
    <create-reward-modal @onCreate="createSingleComplianceReward"> </create-reward-modal>
    <delete-reward-modal> </delete-reward-modal>
    <create-reward-success-modal> </create-reward-success-modal>
    <delete-reward-success-modal> </delete-reward-success-modal>
    <configure-point-modal @onSetPointRate="setPointRate"></configure-point-modal>
    <configure-confirmation-modal
     @onConfigureRewardPoint="configureRewardPoint"
     > </configure-confirmation-modal>
    <configure-point-success-modal> </configure-point-success-modal>
    <clear-point-modal> </clear-point-modal>
    <clear-point-success-modal> </clear-point-success-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import PageHeader from '@/components/layout/PageHeader.vue'
import PageRouteView from '@/components/layout/PageRouteView.vue'
import PageTabsVue from '@/components/layout/PageTabs.vue'
import CreateRewardModal from '@/views/Drivers/components/modals/CreateReward.vue'
import DeleteRewardModal from '@/views/Drivers/components/modals/DeleteReward.vue'
import CreateRewardSuccessModal from '@/views/Drivers/components/modals/CreateRewardSuccess.vue'
import DeleteRewardSuccessModal from '@/views/Drivers/components/modals/DeleteRewardSuccess.vue'
import ConfigurePointModal from '@/views/Drivers/components/modals/ConfigurePoint.vue'
import ConfigureConfirmationModal from '@/views/Drivers/components/modals/ConfigureConfirmation.vue'
import ConfigurePointSuccessModal from '@/views/Drivers/components/modals/ConfigurePointSuccess.vue'
import ClearPointModal from '@/views/Drivers/components/modals/ClearPoint.vue'
import ClearPointSuccessModal from '@/views/Drivers/components/modals/ClearPointSuccess.vue'
// import { mapGetters } from 'vuex'

export default {
  name: 'DriverRewards',
  // props: {
  //   // driverId: {
  //   //   required: true,
  //   //   type: [Number, String]
  //   // }
  // },
  components: {
    PageTabsVue,
    PageRouteView,
    PageHeader,
    CreateRewardModal,
    CreateRewardSuccessModal,
    DeleteRewardModal,
    DeleteRewardSuccessModal,
    ConfigurePointModal,
    ConfigureConfirmationModal,
    ConfigurePointSuccessModal,
    ClearPointModal,
    ClearPointSuccessModal,
  },
  data() {
    return {
      pointRate: 0,
      bus: new Vue(),
      menuTabs: [
        {
          routeName: 'Points',
          label: 'Points',
        },
        {
          routeName: 'DriversAndRewards', // list of approved events
          label: 'Drivers & rewards',
        },
      ],
      isRewardPointConfigurationLoading: false
    }
  },
  computed: {
    // ...mapGetters('rewardPoints', ['currentRewardPointRate']),
    currentRewardPointRate () {
      return this.$store.state.rewardPoints.currentRewardPointRate
    },
  },
  methods: {
    showModal(id) {
      this.$bvModal.show(id)
    },
    hideModal(id) {
      this.$bvModal.hide(id)
    },
    setPointRate(rate) {
      this.pointRate = rate
    },
    configureRewardPoint() {
      this.bus.$emit('configureRewardPoint', this.pointRate)
    },
    updateRewardPointConfigurationLoader(value) {
      this.isRewardPointConfigurationLoading = value
    },
    createSingleComplianceReward(data) {
      this.bus.$emit('onCreateComplianceReward', data)
    }
  },
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/_nav-tabs.scss';

.list-assigned__header .edit {
  background: #4848ed;
  color: #fff;
}

.list-assigned__header .add {
  border: 1px solid#101211;
}

.list-assigned__header .btns {
  display: flex;
  gap: 12px;
}
.rate-desc {
  height: 100%;
  background: #000000;
  color: #ffffff;
  text-align: center;
  padding: 10px 0px;
}

@media screen and (max-width: 480px){
  .list-assigned__header .btn {
    font-size: 12px;
  }

}
</style>
