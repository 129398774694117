<template>
	<div class="main-content">
		<page-header
			title="Games & Campaigns"
			pre-title="Overview"
			:loading="false">
			<template slot="tabs">
				<page-tabs :menu-tabs="menuTabs">
					<template v-slot="props">
						<router-link
							:to="{ name: props.menu.routeName}"
							class="nav-link"
							active-class="active">
							{{ props.menu.label }}
						</router-link>
					</template>
				</page-tabs>
			</template>
		</page-header>

		<page-route-view
			:loading="false">
		</page-route-view>
	</div>
</template>

<script>
// import FeatureFlagEnabled from "@/components/layout/FeatureFlagEnabled";
import PageHeader from '@/components/layout/PageHeader'
import PageTabs from '@/components/layout/PageTabs'
import PageRouteView from '@/components/layout/PageRouteView'
export default {
  // components: {PageRouteView, PageTabs, PageHeader, FeatureFlagEnabled},
  components: { PageRouteView, PageTabs, PageHeader },
  data () {
    return {
      menuTabs: [
        {
          routeName: 'ValentineCampaignList',
          label: 'Valentine'
        }
        // {
        //   routeName:'ChristmasCampaignList',
        //   label: 'Christmas'
        // }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/nav-tabs";
</style>
