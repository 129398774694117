
<template>
	<div>
		<div class="bg-white border-bottom">
			<div class="mb-0 header">
				<div class="container-fluid">
					<div class="py-0 mt-4 border-0 header-body">
						<div class="row align-items-end">
							<div class="col">
								<h6 class="header-pretitle">User Overview</h6>
								<h1 class="header-title">
									{{ userName }}
								</h1>
							</div>
							<div class="col-auto">
								<!-- <a href="#!" class="btn btn-primary">Create Report</a> -->
							</div>
						</div>
						<!-- / .row -->
					</div>
					<!-- / .header-body -->
				</div>
			</div>
			<div class="container-fluid">
				<div class="pt-3 pb-1 row">
					<div class="col justify-self-center align-self-center flex-column">
						<ul class="nav nav-pills">
							<li class="nav-item" v-for="tab in menuTabs" :key="tab.routeName">
								<router-link
									:to="{
										name: tab.routeName,
										props: tab.props,
									}"
									class="nav-link"
									active-class="active"
									>{{ tab.label }}</router-link
									>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="pt-4">
			<div class="container-fluid">
				<router-link to="/users/list" class="mb-4 btn btn-light btn-sm" v-if="!loading"
					>Go back</router-link
					>
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import { DoNotShowOnProd } from '@/composables/core/permissions'

export default {
  props: {
    userId: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    return {
      loading: false,
      currentUser: null,
      menuTabs: [
        {
          label: 'User Info',
          routeName: 'ShowUser',
          props: { userId: this.$route.params.userId }
        },
        {
          label: 'Rides',
          routeName: 'UserRides',
          props: { userId: this.$route.params.userId }
        },
        {
          label: 'Transactions',
          routeName: 'UserTransactions',
          props: { userId: this.$route.params.userId }
        },
        {
          label: 'Active Bookings',
          routeName: 'UserActiveBookings',
          props: { userId: this.$route.params.userId }
        },
        {
          label: 'Past Bookings',
          routeName: 'UserPastBookings',
          props: { userId: this.$route.params.userId }
        },{
          label: 'Cancelled Trips',
          routeName: 'UserCancelledBookings',
          props: { userId: this.$route.params.userId }
        }]
    }
  },
  mounted () {
    this.fetchUser(this.userId)
  },
  methods: {
    // DoNotShowOnProd_M()
    // {
    //   return DoNotShowOnProd()
    // },

    async fetchUser (userId) {
      if (this.loading) return

      try {
        this.loading = true
        const result = await this.axios.get(`/v1/users/${userId}`)
        this.currentUser = result.data

        if (this.currentUser.corporate_id) {
          this.menuTabs.push({
            label: 'Creditline Usages',
            routeName: 'ShowACompanyStaffCreditLineUsages',
            props: {
              userId: this.$route.params.userId,
              companyId: this.currentUser.corporate_id
            }
          })
        }
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  },
  computed: {
    userName () {
      return this.currentUser
        ? `${this.currentUser.fname} ${this.currentUser.lname}`
        : ''
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}

.VueTables__search-field > label {
  display: none;
}

.VueTables__search-field {
  margin-top: 20px;
}
</style>
