import {ref} from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import Swal from 'sweetalert2'


const allCityNames = ref([])
const allCountries = ref([])

export const useCityAndCountry = () => {
	const fetchAllCityNames = async () => {
		try {
			const res = await axios.get('/v1/city/all?limit=1000&fields=id,name')
			allCityNames.value = res.data?.data?.length ? res.data.data : []
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'could not get cities'})
		}
	}
	const fetchAllCountries = async () => {
		try {
			const res = await axios.get('/v1/countries')
			allCountries.value = res.data?.length ? res.data : []
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'could not get countries'})
		}
	}

	return  {allCityNames, allCountries, fetchAllCityNames, fetchAllCountries }
}