export default {
  namespaced: true,
  state: {
    attendeeList: [],
    events: []
  },
  mutations: {
    // setCurrentUser (state, model) {
    //   state.currentUser = model;
    //   if(model) {
    //     FeatureFlagService.setCurrentUserData({
    //       email: model.email,
    //       userId: model.id
    //     });
    //   }
    // },
    setAttendeeList (state, data) {
      state.attendeeList = data
    },

    setEvents (state, data) {
      state.attendeeList = data
    }
  },
  actions: {
    setAttendeeList ({ commit }, data) {
      commit('setAttendeeList', data)
    },
    setEvents ({ commit }, data) {
      commit('setEvents', data)
    }
  }
}
