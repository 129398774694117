<template>
	<div class="row pt-1 pb-1">
		<div class="col justify-self-center align-self-center flex-column">
			<ul class="nav nav-pills">
				<li class="nav-item" v-for="(menu, index) in menuTabs" :key="index">
					<slot :menu="menu"></slot>
					<!--          TODO: handle default presentation if slot template isnt provided-->
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
  name: 'PageTabs',
  props: {
    menuTabs: { type: Array, required: true }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  gap: 16.5px
}
.nav-link {
  padding-right: 0px;
  padding-left: 0px;
}
</style>
