<!-- <template>
	<div>
		<page-header
			:loading="loading"
			:title="loading || errorLoading ? '' : tripDescription"
			:headerCss="['mb-0']"
			pre-title="Trip overview"
			>
			<template slot="tabs" v-if="!errorLoading">
				<page-tabs :menu-tabs="menuTabs">
					<template v-slot="props">
						<feature-flag-enabled
							v-if="props.menu.featureFlag"
							:feature-flag="props.menu.featureFlag"
							>
							<router-link
								:to="{
									name: props.menu.routeName,h
									params: props.menu.props ? props.menu.props : {},
								}"
								class="nav-link"
								active-class="active"
								>
								<span>{{ props.menu.label }}</span>
							</router-link>
						</feature-flag-enabled>
						<router-link
							v-else
							:to="{ name: props.menu.routeName }"
							class="nav-link"
							active-class="active"
							>
							<span>{{ props.menu.label }}</span>
						</router-link>
					</template>
				</page-tabs>
			</template>

			<template slot="actions">
				<b-dropdown
					variant="primary"
					:no-caret="true"
					right
					v-if="isUpcomingTrip && !loading && tripDetailsActive && userHasPermission('trips008')"
					>
					<template #button-content>
						<span>Options</span>
						<i class="fe fe-more-vertical"></i>
					</template>
          <b-dropdown-item>
						Start Trip
					</b-dropdown-item>
          <b-dropdown-item>
						<span style="color: rgb(45, 37, 37)">Cancel Trip</span>
					</b-dropdown-item>
					<b-dropdown-item @click.prevent="showChangeDriver = true">
						Update Driver
					</b-dropdown-item>
					<b-dropdown-item @click.prevent="showChangeVehicle = true">
						Update Vehicle
					</b-dropdown-item>
				</b-dropdown>

				<b-dropdown
					variant="primary"
					:no-caret="true"
					right
					v-if="isActiveTrip && userHasPermission('trips009')"
					>
					<template #button-content>
						<span>Options</span>
						<i class="fe fe-more-vertical"></i>
					</template>
          <b-dropdown-item @click.prevent="showChangeVehicle = true">
						<span style="color: red">End Trip</span>
					</b-dropdown-item>
					<b-dropdown-item @click.prevent="showChangeDriver = true">
						Update Driver
					</b-dropdown-item>
					<b-dropdown-item @click.prevent="showChangeVehicle = true">
						Update Vehicle
					</b-dropdown-item>
				</b-dropdown>
			</template>
		</page-header>

		<page-route-view
			:loading="loading"
			:containerBodyCss="['m-0', 'p-0']"
			:error-loading="errorLoading"
			:error-text="'Failed to load trip information'"
			>
		</page-route-view>

		<template v-if="(isUpcomingTrip && !loading) || isActiveTrip">
			<change-trip-modal
				:show="showChangeDriver"
				:model="trip"
				:drivers="drivers"
				:vehicles="vehicles?.data || []"
				update-type="driver"
				:selectedTrip="selectedTrip"
				modal-title="Update Driver"
				@close="showChangeDriver = false"
				@data-changed="processDataChange"
				></change-trip-modal>

			<change-trip-modal
				:show="showChangeVehicle"
				:model="trip"
				:drivers="drivers"
				:vehicles="vehicles?.data || []"
				update-type="vehicle"
				:selectedTrip="selectedTrip"
				modal-title="Update Vehicle"
				@close="showChangeVehicle = false"
				@data-changed="processDataChange"
				></change-trip-modal>
		</template>


    <delete-prompt-modal
    modal-title="Are you sure you want to cancel sss this trip ?"
    @close="showCancelTripModal = false"
    :verifying-delete="false"
    :verification-message="verificationMessage"
    :processing-action="cancellingTrip"
    :show="showCancelTripModal"
    prompt-text="To cancel this trip, type your password to proceed"
    prompt-key="your password"
    :is-password-type="true"
    :delete-function="(passKey) => {
      cancelTrip(data.item, passKey)
    }
    ">
  </delete-prompt-modal>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import moment from 'moment'
import _ from 'lodash'
import PageHeader from '@/components/layout/PageHeader'
import PageTabs from '@/components/layout/PageTabs'
import filters from '@/filters'
import ChangeTripModal from '@/components/modals/ChangeTripDriverModal'
import DeletePromptModal from '@/components/modals/DeletePromptModal'
import PageRouteView from '@/components/layout/PageRouteView'
import FeatureFlagEnabled from '@/components/layout/FeatureFlagEnabled'
import { FEATURE_FLAGS } from '@/utils/feature-flags'

export default {
  name: 'TripDetailIndex',
  components: {
    FeatureFlagEnabled,
    PageRouteView,
    ChangeTripModal,
    PageTabs,
    PageHeader,
    DeletePromptModal
  },
  props: {
    tripId: { type: [String, Number], required: true }
  },
  data () {
    return {
      menuTabs: [
        {
          label: 'Details',
          routeName: 'TripDetails',
          props: { tripId: this.$route.params.tripId }
        },
        {
          label: 'Passengers',
          routeName: 'TripPassengers',
          props: { tripId: this.$route.params.tripId }
        }
      ],
      errorLoading: false,
      cancellingTrip: false,
      loading: false,
      trip: null,
      showChangeDriver: false,
      showChangeVehicle: false,
      showCancelTripModal: false,
      verificationMessage: 'Are you sure you want to end this trip ?',
      drivers: [],
      vehicles: [],
      selectedTrip: '',
      currentRouteState: this.$route
    }
  },
  provide () {
    return {
      tripDetail: () => {
        return {
          ...this.trip,
          driverName: this.driverName,
          tripStartTime: this.tripStartTime,
          tripDate: this.tripDate,
          tripDescription: this.tripDescription,
          isUpcomingTrip: this.isUpcomingTrip
        }
      }
    }
  },
  watch: {
    $route (to) {
      this.currentRouteState = to
    }
  },
  computed: {
    tripDetailsActive () {
      return this.currentRouteState?.name === 'TripDetails'
    },
    driverName () {
      return this.trip?.driver
        ? `${this.trip?.driver?.fname || ''} ${this.trip?.driver?.lname || ''}`
        : 'N/A'
    },
    tripStartTime () {
      return this.isUpcomingTrip
        ? filters.utcDate(this.trip.trip_start_time, 'h:mm a')
        : filters.utcDate(this.trip.start_trip, 'h:mm a')
    },
    tripDate () {
      if (this.trip.trip_date) {
        filters.utcDate(this.trip.trip_date, 'd MMMM, yyyy')
      }

      return this.isUpcomingTrip
        ? filters.utcDate(this.trip.trip_start_time, 'd MMMM, yyyy')
        : filters.utcDate(this.trip.start_trip, 'd MMMM, yyyy')
    },
    tripDescription () {
      return this.trip
        ? `${this.trip.route.route_code}  •  ${this.tripStartTime}  •  ${this.driverName}  •  ${this.tripDate}`
        : 'Trip Information'
    },
    isUpcomingTrip () {
      return (
        this.tripId &&
        this.$route.params.tripType === 'upcoming'
      )
    },

    isActiveTrip () {
      return (
        this.tripId &&
        this.tripId.toString()?.startsWith('__') &&
        this.$route.params.tripType === 'active'
      )
    },

    isCompletedTrip () {
      return (
        this.tripId &&
        this.tripId.toString()?.startsWith('__') &&
        this.$route.params.tripType === 'completed'
      )
    }
  },
  created () {
    this.loadTripDetails()
    this.fetchDrivers()
    this.fetchVehicles()
    if (!this.isUpcomingTrip && !this.isActiveTrip) {
      this.menuTabs.push({
        label: 'Financials',
        routeName: 'TripFinancials',
        props: { tripId: this.$route.params.tripId },
      })
    }
    this.selectedTrip = this.$route.params.tripType
  },
  methods: {
    cancelTrip(trip, passKey)
    {
      this.cancellingTrip = true
      this.axios
        .delete(`/v1/upcoming-trips/${trip.id}`, {
          data: {
            password: passKey
          }
        })
        .then(() =>
        {
          this.fetchTripList()
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Trip cancelled successfully',
            showCloseButton: true
          })
          this.cancellingTrip = false
          this.showCancelTripModalIndex = -1
        })
        .catch((error) =>
        {
          this.cancellingTrip = false
          const msg = extractErrorMessage(
            error,
            'An error occurred, please try again.'
          )
          this.$swal().showValidationMessage(msg)
        })
    },
    fetchDrivers () {
      this.axios.get('/v1/drivers?status=active').then((res) => {
        this.drivers = res.data
      })
    },
    fetchVehicles () {
      this.axios.get('/v1/vehicles?status=active').then((res) => {
        this.vehicles = res.data.data
      })
    },
    loadTripDetails () {
      this.loading = true
      this.errorLoading = false
      return this.fetchTrip()
        .then(() => {
          if (!this.isUpcomingTrip) {
            this.menuTabs.splice(2, 0, {
              label: 'Ratings',
              routeName: 'TripRatings',
              props: { tripId: this.$route.params.tripId }
            })
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.$router.go('-1')
          } else {
            this.errorLoading = true
          }
        })
        .finally(() => (this.loading = false))
    },
    fetchTrip () {
      const tripData = this.$store.getters[('session', 'selectedTripData')]
      if (this.isActiveTrip) {
        return this.axios
          .get(`/v1/trips/${this.tripId.replace('__', '')}`)
          .then((res) => {
            this.trip = res.data
          })
      } else if (this.isCompletedTrip) {
        return this.axios
          .get(`/v1/trips/${this.tripId.replace('__', '')}`)
          .then((res) => {
            this.trip = res.data
          })
      } else if (!this.isUpcomingTrip && this.tripId) {
        return this.axios.get(`/v1/trips/${this.tripId}`).then((res) => {
          this.trip = res.data
        })
      } else if (this.isUpcomingTrip && tripData) {
        tripData.driver = tripData.route?.driver
        tripData.start_trip = tripData.trip_start_time
        this.trip = tripData
        return Promise.resolve(tripData)
      } else if (this.isUpcomingTrip) {
        return this.axios
          .get(`/v1/upcoming-trips/${this.tripId.replace('__', '')}`)
          .then((res) => {
            const data = res.data
            const tripData = {}
            tripData.driver = data.driver
            tripData.start_trip = data.itinerary.trip_time
            this.trip = res.data
            this.trip.trip_start_time = moment(
              `${data.trip_date} ${data.itinerary.trip_time}`,
              'YYYY-MM-DD h:mm a'
            ).toISOString()
          })
      } else {
        return Promise.reject({ response: { status: 404 } })
      }
    },
    processDataChange ({ vehicle, driver }) {
      if (vehicle) {
        this.trip.vehicle = vehicle
      }
      if (driver) {
        this.trip.driver = driver
      }
    }
  },
  beforeDestroy () {
    this.$store.dispatch('setSelectedTripData', null)
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}
</style> -->

<template>
	<div>
		<page-header
			:loading="loading"
			:title="loading || errorLoading ? '' : tripDescription"
			:headerCss="['mb-0']"
			pre-title="Trip overview"
			>
			<template slot="tabs" v-if="!errorLoading">
				<page-tabs :menu-tabs="menuTabs">
					<template v-slot="props">
						<feature-flag-enabled
							v-if="props.menu.featureFlag"
							:feature-flag="props.menu.featureFlag"
							>
							<router-link
								:to="{
									name: props.menu.routeName,
									params: props.menu.props ? props.menu.props : {},
								}"
								class="nav-link"
								active-class="active"
								>
								<span>{{ props.menu.label }}</span>
							</router-link>
						</feature-flag-enabled>
						<router-link
							v-else
							:to="{ name: props.menu.routeName }"
							class="nav-link"
							active-class="active"
							>
							<span>{{ props.menu.label }}</span>
						</router-link>
					</template>
				</page-tabs>
			</template>

			<template slot="actions">
				<b-dropdown
					variant="primary"
					:no-caret="true"
					right
					v-if="isUpcomingTrip && !loading && tripDetailsActive && userHasPermission('trips008')"
					>
					<template #button-content>
						<span>Options</span>
						<i class="fe fe-more-vertical"></i>
					</template>
          <!-- <b-dropdown-item @click.prevent="showChangeVehicle = true">
						Start Trip
					</b-dropdown-item>
          <b-dropdown-item @click.prevent="showChangeVehicle = true">
						<span style="color: red">End Trip</span>
					</b-dropdown-item> -->
					<b-dropdown-item @click.prevent="showChangeDriver = true">
						Update Driver
					</b-dropdown-item>
					<b-dropdown-item @click.prevent="showChangeVehicle = true">
						Update Vehicle
					</b-dropdown-item>
				</b-dropdown>

				<b-dropdown
					variant="primary"
					:no-caret="true"
					right
					v-if="isActiveTrip && userHasPermission('trips009')"
					>
					<template #button-content>
						<span>Options</span>
						<i class="fe fe-more-vertical"></i>
					</template>
          <!-- <b-dropdown-item @click.prevent="showChangeVehicle = true">
						Start Trip
					</b-dropdown-item>
          <b-dropdown-item @click.prevent="showChangeVehicle = true">
						<span style="color: red">End Trip</span>
					</b-dropdown-item> -->
					<b-dropdown-item @click.prevent="showChangeDriver = true">
						Update Driver
					</b-dropdown-item>
					<b-dropdown-item @click.prevent="showChangeVehicle = true">
						Update Vehicle
					</b-dropdown-item>
				</b-dropdown>
			</template>
		</page-header>

		<page-route-view
			:loading="loading"
			:containerBodyCss="['m-0', 'p-0']"
			:error-loading="errorLoading"
			:error-text="'Failed to load trip information'"
			>
		</page-route-view>

		<template v-if="(isUpcomingTrip && !loading) || isActiveTrip">
			<change-trip-modal
				:show="showChangeDriver"
				:model="trip"
				:drivers="drivers"
				:vehicles="vehicles?.data || []"
				update-type="driver"
				:selectedTrip="selectedTrip"
				modal-title="Update Driver"
				@close="showChangeDriver = false"
				@data-changed="processDataChange"
				></change-trip-modal>

			<change-trip-modal
				:show="showChangeVehicle"
				:model="trip"
				:drivers="drivers"
				:vehicles="vehicles?.data || []"
				update-type="vehicle"
				:selectedTrip="selectedTrip"
				modal-title="Update Vehicle"
				@close="showChangeVehicle = false"
				@data-changed="processDataChange"
				></change-trip-modal>
		</template>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import moment from 'moment'
import _ from 'lodash'
import PageHeader from '@/components/layout/PageHeader'
import PageTabs from '@/components/layout/PageTabs'
import filters from '@/filters'
import ChangeTripModal from '@/components/modals/ChangeTripDriverModal'
import PageRouteView from '@/components/layout/PageRouteView'
import FeatureFlagEnabled from '@/components/layout/FeatureFlagEnabled'
import { FEATURE_FLAGS } from '@/utils/feature-flags'

export default {
  name: 'TripDetailIndex',
  components: {
    FeatureFlagEnabled,
    PageRouteView,
    ChangeTripModal,
    PageTabs,
    PageHeader
  },
  props: {
    tripId: { type: [String, Number], required: true }
  },
  data () {
    return {
      menuTabs: [
        {
          label: 'Details',
          routeName: 'TripDetails',
          props: { tripId: this.$route.params.tripId }
        },
        {
          label: 'Passengers',
          routeName: 'TripPassengers',
          props: { tripId: this.$route.params.tripId }
        }
      ],
      errorLoading: false,
      loading: false,
      trip: null,
      showChangeDriver: false,
      showChangeVehicle: false,
      drivers: [],
      vehicles: [],
      selectedTrip: '',
      currentRouteState: this.$route
    }
  },
  provide () {
    return {
      tripDetail: () => {
        return {
          ...this.trip,
          driverName: this.driverName,
          tripStartTime: this.tripStartTime,
          tripDate: this.tripDate,
          tripDescription: this.tripDescription,
          isUpcomingTrip: this.isUpcomingTrip
        }
      }
    }
  },
  watch: {
    $route (to) {
      this.currentRouteState = to
    }
  },
  computed: {
    tripDetailsActive () {
      return this.currentRouteState?.name === 'TripDetails'
    },
    driverName () {
      return this.trip?.driver
        ? `${this.trip?.driver?.fname || ''} ${this.trip?.driver?.lname || ''}`
        : 'N/A'
    },
    tripStartTime () {
      return this.isUpcomingTrip
        ? filters.utcDate(this.trip.trip_start_time, 'h:mm a')
        : filters.utcDate(this.trip.start_trip, 'h:mm a')
    },
    tripDate () {
      if (this.trip.trip_date) {
        filters.utcDate(this.trip.trip_date, 'd MMMM, yyyy')
      }

      return this.isUpcomingTrip
        ? filters.utcDate(this.trip.trip_start_time, 'd MMMM, yyyy')
        : filters.utcDate(this.trip.start_trip, 'd MMMM, yyyy')
    },
    tripDescription () {
      return this.trip
        ? `${this.trip.route.route_code}  •  ${this.tripStartTime}  •  ${this.driverName}  •  ${this.tripDate}`
        : 'Trip Information'
    },
    isUpcomingTrip () {
      return (
        this.tripId &&
        this.$route.params.tripType === 'upcoming'
      )
    },

    isActiveTrip () {
      return (
        this.tripId &&
        this.tripId.toString()?.startsWith('__') &&
        this.$route.params.tripType === 'active'
      )
    },

    isCompletedTrip () {
      return (
        this.tripId &&
        this.tripId.toString()?.startsWith('__') &&
        this.$route.params.tripType === 'completed'
      )
    }
  },
  created () {
    this.loadTripDetails()
    this.fetchDrivers()
    this.fetchVehicles()
    if (!this.isUpcomingTrip && !this.isActiveTrip) {
      this.menuTabs.push({
        label: 'Financials',
        routeName: 'TripFinancials',
        props: { tripId: this.$route.params.tripId },
      })
    }
    this.selectedTrip = this.$route.params.tripType
  },
  methods: {
    fetchDrivers () {
      this.axios.get('/v1/drivers?status=active').then((res) => {
        this.drivers = res.data
      })
    },
    fetchVehicles () {
      this.axios.get('/v1/vehicles?status=active').then((res) => {
        this.vehicles = res.data.data
      })
    },
    loadTripDetails () {
      this.loading = true
      this.errorLoading = false
      return this.fetchTrip()
        .then(() => {
          if (!this.isUpcomingTrip) {
            this.menuTabs.splice(2, 0, {
              label: 'Ratings',
              routeName: 'TripRatings',
              props: { tripId: this.$route.params.tripId }
            })
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.$router.go('-1')
          } else {
            this.errorLoading = true
          }
        })
        .finally(() => (this.loading = false))
    },
    fetchTrip () {
      const tripData = this.$store.getters[('session', 'selectedTripData')]
      if (this.isActiveTrip) {
        return this.axios
          .get(`/v1/trips/${this.tripId.replace('__', '')}`)
          .then((res) => {
            this.trip = res.data
          })
      } else if (this.isCompletedTrip) {
        return this.axios
          .get(`/v1/trips/${this.tripId.replace('__', '')}`)
          .then((res) => {
            this.trip = res.data
          })
      } else if (!this.isUpcomingTrip && this.tripId) {
        return this.axios.get(`/v1/trips/${this.tripId}`).then((res) => {
          this.trip = res.data
        })
      } else if (this.isUpcomingTrip && tripData) {
        tripData.driver = tripData.route?.driver
        tripData.start_trip = tripData.trip_start_time
        this.trip = tripData
        return Promise.resolve(tripData)
      } else if (this.isUpcomingTrip) {
        return this.axios
          .get(`/v1/upcoming-trips/${this.tripId.replace('__', '')}`)
          .then((res) => {
            const data = res.data
            const tripData = {}
            tripData.driver = data.driver
            tripData.start_trip = data.itinerary.trip_time
            this.trip = res.data
            this.trip.trip_start_time = moment(
              `${data.trip_date} ${data.itinerary.trip_time}`,
              'YYYY-MM-DD h:mm a'
            ).toISOString()
          })
      } else {
        return Promise.reject({ response: { status: 404 } })
      }
    },
    processDataChange ({ vehicle, driver }) {
      if (vehicle) {
        this.trip.vehicle = vehicle
      }
      if (driver) {
        this.trip.driver = driver
      }
    }
  },
  beforeDestroy () {
    this.$store.dispatch('setSelectedTripData', null)
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}
</style>

