var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center align-content-center",staticStyle:{"height":"100vh"}},[_c('div',{staticClass:"col-md-6 col-sm-8 col-xs-12 col-lg-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),(!_vm.isSuccessful)?[_c('form',{staticClass:"mt-4"},[_vm._m(1),(_vm.errorMessage)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"alert alert-danger"},[_c('span',[_vm._v(_vm._s(_vm.errorMessage))])])]):_vm._e(),_c('div',{staticClass:"form-group mt-4"},[_c('label',{staticClass:"sr-only",attrs:{"for":"password"}},[_vm._v("Password")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.form.password.$model),expression:"$v.form.password.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
											'is-invalid':
												_vm.$v.form.password.$dirty && _vm.$v.form.password.$error,
										},attrs:{"id":"password","name":"password","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Password"},domProps:{"value":(_vm.$v.form.password.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('button',{staticClass:"btn btn-light input-group-btn",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleShowPassword.apply(null, arguments)}}},[_c('span',{staticClass:"fe",class:{
												'fe-eye': _vm.showPassword,
												'fe-eye-off': !_vm.showPassword,
											}})])]),(
										_vm.$v.form.password.$dirty && !_vm.$v.form.password.$required
									)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group mt-4"},[_c('label',{staticClass:"sr-only",attrs:{"for":"password"}},[_vm._v("Confirm Password")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.form.confirm_password.$model),expression:"$v.form.confirm_password.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
											'is-invalid':
												_vm.$v.form.confirm_password.$dirty &&
												_vm.$v.form.confirm_password.$error,
										},attrs:{"id":"confirm-password","name":"confirm-password","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Confirm password"},domProps:{"value":(_vm.$v.form.confirm_password.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.confirm_password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('button',{staticClass:"btn btn-light input-group-btn",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleShowPassword.apply(null, arguments)}}},[_c('span',{staticClass:"fe",class:{
												'fe-eye': _vm.showPassword,
												'fe-eye-off': !_vm.showPassword,
											}})])]),(!_vm.$v.form.confirm_password.sameAsPassword)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Passwords must be identical. ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"id":"login-btn","disabled":_vm.processing,"type":"submit"},on:{"click":_vm.resetPassword}},[(!_vm.processing)?_c('span',[_vm._v("Set Password")]):_c('span',[_c('i',{staticClass:"fa fa-spin fa-spinner"})])])])])]:[_c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"mt-2"},[_vm._v("Password changed")]),_c('p',{staticClass:"mt-1"},[_vm._v(" You have successfully changed your password ")]),_c('router-link',{attrs:{"to":"/"}},[_c('button',{staticClass:"btn btn-primary btn-block"},[_vm._v(" Back to login ")])])],1)]],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title mb-4"},[_c('img',{attrs:{"src":require("@/assets/logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 text-center"},[_c('h2',{staticClass:"mb-1"},[_vm._v("Create a new password")]),_c('small',[_vm._v("Try using something you can easily remember.")])])
}]

export { render, staticRenderFns }