<template>
	<div>
		<div class="bg-white border-bottom">
			<div class="header mb-0">
				<div class="container-fluid">
					<div class="header-body py-0 mt-4 border-0">
						<div class="row align-items-end">
							<div class="col">
								<h6 class="header-pretitle">Overview</h6>
								<h1 class="header-title">
									Promotional {{ isBanner ? 'Banner' : 'Carousel' }}
								</h1>
							</div>

							<router-link
								v-if="isBanner"
								:to="{ name: 'ManageBanner' }"
								class="btn btn-primary"
								>
								<span class="fe fe-plus"></span> Create new banner
							</router-link>
							<router-link
								v-if="!isBanner"
								:to="{ name: 'ManageCarousel' }"
								class="btn btn-primary"
								>
								<span class="fe fe-plus"></span> Create new carousel
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="container-fluid">
				<div class="row pt-3 pb-1">
					<div class="col justify-self-center align-self-center flex-column">
						<ul class="nav nav-pills">
							<li
								class="nav-item"
								v-for="(menu, index) in menuTabs"
								:key="index"
								>
								<router-link
									:to="{
										name: menu.routeName,
									}"
									class="nav-link"
									active-class="active"
									>
									<span>{{ menu.label }} </span>
								</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="pt-4">
			<div class="container-fluid">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {},
  data () {
    return {
      menuTabs: [
        { label: 'Banner', routeName: 'BannerConfig' },
        { label: 'Carousel', routeName: 'CarouselConfig' }
      ]
    }
  },
  computed: {
    isBanner () {
      return this.$route.name === 'BannerConfig'
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}
</style>
