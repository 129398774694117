<template>
	<div>
		<template v-if="loading">
			<div v-if="loading" class="text-center p-4">
				<div class="mx-auto spinner-border" role="status"></div>
				<p class="mx-auto text-center">Loading...</p>
			</div>
		</template>

		<div class="" v-else-if="errorLoading">
			<div class="container-fluid">
				<p class="mx-auto text-center">{{ errorText }}</p>
			</div>
		</div>
		<div class="" v-else>
			<div :class="containerBodyCss">
				<button v-if="!loading && showBackBtn" class="btn btn-light mb-4 btn-sm" @click="$router.go(-1)" >
					Go back</button>
				<router-view 
					:eventRequestHandler="bus" 
					@update:eventStatus="updateEventStatus" 
					@update:acceptanceLoading="updateAcceptanceLoading"
					@update:rejectionLoading="updateRejectionLoading"
					@update:queryLoading="updateQueryLoading"
					@update:attendeesNotificationLoading="updateAttendeesNotificationLoading"
					@update:routeAssignmentLoader="updateRouteAssignmentLoader"
					@update:allRoutes="updateAllRoutes"
					@update:isLoadingGetRoutes="updateGetRoutesLoader"
					@update:eventRoutes="updateEventRoutes"
          @update:routeAssigned="updateRouteAssigned"
          @update:rewardPointConfigurationLoading="updateRewardPointConfigurationLoader"
					></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'PageRouteView',
  props: {
    showBackBtn: { type: Boolean, default: true, required: false },
    containerBodyCss: {
      type: [Array, Object],
      default: () => ['container-fluid']
    },
    loading: { type: Boolean, default: false, required: true },
    errorText: {
      type: String,
      default: 'Failed to load data',
      required: false
    },
    backLink: { type: [String, null], default: null, required: false },
    errorLoading: { type: Boolean, default: false, required: false },
    bus: {
      type: Vue,
      required: false
    }
  },
  emits: [
    'update:eventStatus', 
    'update:routeAssigned',
    'update:acceptanceLoading', 
    'update:rejectionLoading', 
    'update:queryLoading', 
    'update:attendeesNotificationLoading', 
    'update:allRoutes',
    'update:routeAssignmentLoader',
    'update:isLoadingGetRoutes',
    'update:eventRoutes',
    'update:rewardPointConfigurationLoading'
  ],
  methods: {
    updateEventStatus (status) {
      this.$emit('update:eventStatus', status)
    },
    updateRouteAssigned (status) {
      this.$emit('update:routeAssigned', status)
    },
    updateAcceptanceLoading(value) {
      this.$emit('update:acceptanceLoading', value)
    },
    updateRejectionLoading (value) {
      this.$emit('update:rejectionLoading', value)
    },
    updateQueryLoading (value) {
      this.$emit('update:queryLoading', value)
    },
    updateAttendeesNotificationLoading(value) {
      this.$emit('update:attendeesNotificationLoading', value)
    },
    updateAllRoutes(value) {
      this.$emit('update:allRoutes', value)
    },
    updateRouteAssignmentLoader(value) {
      this.$emit('update:routeAssignmentLoader', value)
    },
    updateGetRoutesLoader(value) {
      this.$emit('update:isLoadingGetRoutes', value)
    },
    updateEventRoutes(value) {
      this.$emit('update:eventRoutes', value)
    },
    updateRewardPointConfigurationLoader(value) {
      this.$emit('update:rewardPointConfigurationLoading', value)
    }
  }
}
</script>

<style scoped>
</style>
