import { loadRouteComponent } from '@/utils/helpers'
import RouteSearch from '@/layouts/modules/RouteSearch/Index.vue'
const RouteSearchRoutes = [
  {
    path: 'route-searches',
    component: RouteSearch,
    props: true,
    children: [
      {
        path: '',
        name: 'route-searches.review',
        redirect: 'traffic-flow'
      },
      {
        path: ':filterType',
        name: 'route-searches.filtered-review',
        component: loadRouteComponent('RouteSearch/pages/Show'),
        props: true
      }
    ]
  }
]

export default RouteSearchRoutes
