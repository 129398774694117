<template>
	<div class="main-content">
		<page-header title="Route" pre-title="Overview" :loading="false">
			<template slot="actions">
				<div class="flex gap-3">
					<router-link :to="{
						name: 'CreateBatchBooking',
					}" class="btn btn-outline-primary" v-if="userHasPermission('users011')">Create Batch Booking</router-link>
					<button class="btn btn-outline-primary" @click="showSuspendModal = true" >Availability Config</button>

					<router-link :to="{
						name: 'NewRoute',
					}" class="btn btn-primary" v-if="userHasPermission('routes001')">Create Route</router-link>
				</div>
			</template>
			<template slot="tabs">
				<div class="container">
					<div class="row pt-3 pb-1">
						<div class="col justify-self-center align-self-center flex-column">
							<ul class="nav nav-pills">
								<li class="nav-item">
									<router-link :to="{ name: 'MainRoutes' }" class="nav-link" exact-active-class="active">Main Routes</router-link>
								</li>
								<li class="nav-item">
									<router-link :to="{ name: 'SuspendedRoutes' }" class="nav-link" exact-active-class="active">Route Availability</router-link>
								</li>
								<li class="nav-item" v-if="DoNotShowOnProd()">
									<router-link :to="{ name: 'AuctionRoutes' }" class="nav-link" exact-active-class="active">Route Auction</router-link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</template>
		</page-header>

		<page-route-view :showBackBtn="false" :loading="false">
		</page-route-view>
		<BS_modal :show='showSuspendModal' title='Route Availability Configuration' @close='closeModal' modalId='suspendModal'>
			<div v-if="!payloadData.confirm.value" class="form-group mb-3">
				<div class="flex flex-col gap-1">
					<p class="m-0 text-base ">Route visibility</p>
					<div class="grid grid-cols-2 ">

						<div class="flex items-center gap-2">
							<input type="radio" name="public" id="public" value="public" v-model="payloadData.route_visibility.value">
							<label for="public" class="m-0">Public</label>
						</div>
						<div class="flex items-center gap-2">
							<input type="radio" name="private" id="private" value="private" v-model="payloadData.route_visibility.value">
							<label for="private" class="m-0">Private</label>
						</div>
					</div>

					<div class="form-group mb-0" v-if="payloadData.route_visibility.value == 'private'">
						<label class="mb-1">select company</label>
						<Multiselect v-model="companyData.selectedCompany.value" id="ajax" :custom-label="corporateName" track-by="id" placeholder="Type to search" open-direction="bottom" :options="companyData.corporatesList.value" :multiple="false" :searchable="true"
							:loading="companyData.loading.value" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="300" :limit="10" :limit-text="limitText" :show-no-results="false" :hide-selected="true" @search-change="asyncFindCorporates">
							<template slot="clear">
								<div class="multiselect__clear" v-if="companyData.selectedCompany" @mousedown.prevent.stop="newRoute.corporateOwner = null"></div>
							</template><span slot="noResult">Oops! No corporates found. Consider changing the search
								query.</span>
						</Multiselect>
						<p v-if="!companyData.selectedCompany.value" class="text-xs text-red-500 font-medium">You need to select a company</p>
					</div>
				</div>
				<div class="border my-4"></div>

				<div class="flex flex-col gap-1">
					<p class="m-0 text-base ">Route type</p>
					<div class="grid grid-cols-2 ">
						<div class="flex items-center gap-2">
							<input id="shared" :disabled="payloadData.route_visibility.value === 'public'" type="checkbox" v-model="payloadData.shared.value"
								class="disabled:cursor-not-allowed"
							>
							<label for="shared" class="m-0">Shared</label>
						</div>
						<div class="flex items-center gap-2" v-if="payloadData.route_visibility.value !== 'public'">
							<input id="exclusive" :disabled="payloadData.route_visibility.value === 'public'" type="checkbox" v-model="payloadData.exclusive.value"
								class="disabled:cursor-not-allowed"
							>
							<label for="exclusive" class="m-0">Exclusive</label>
						</div>
					</div>
				</div>
				<div class="border my-4"></div>

				<div class="flex flex-col gap-1">
					<p class="m-0 text-base ">Route selection</p>
					<div class="grid grid-cols-2 ">
						<div class="flex items-center gap-2">
							<input type="radio" name="route_selection" id="all_route" value="all_route" v-model="payloadData.route_selection.value">
							<label class="m-0">All</label>
						</div>
						<div class="flex items-center gap-2">
							<input type="radio" name="route_selection" id="selected_route" value="selected_route" v-model="payloadData.route_selection.value">
							<label class="m-0">Selected</label>
						</div>
					</div>
					<div class="form-group mb-0" v-if="payloadData.route_selection.value == 'selected_route'">
						<label class="form-label">Select Route</label>
						<v-select v-model="payloadData.selectedRoutes.value" class="form-group mb-0" placeholder="Search" :reduce="route => route.id" :options="routeData.routes.value" label="route_code" required multiple>
							<template v-slot:option="route">
								<span>{{ `${route.route_code ? route.route_code + ' - ' : ''} ${route.visibility} - ${route.is_exclusive == '1' ? 'Exclusive' : 'Shared'}` }}</span>
							</template>
							<template v-slot:selected-option="route">
								<span>{{ `${route.route_code ? route.route_code + ' - ' : ''} ${route.visibility} - ${route.is_exclusive == '1' ? 'Exclusive' : 'Shared'}` }}</span>
							</template>
						</v-select>
						<p v-if="!payloadData.selectedRoutes.value.length" class="text-xs text-red-500 font-medium">You need to select one or more route(s)</p>
					</div>
				</div>
				<div class="border my-4"></div>

				<div class="">
					<div class="form-group">
						<label class="form-label">Unavailability period</label>
						<VuePersianDatetimePicker v-model="payloadData.dates.value" locale="en" multiple displayFormat="dddd, MMMM Do YYYY" format='YYYY-MM-DD' />
					</div>
				</div>
				<div class="">
					<div class="form-group">
						<label class="mb-1">Reason</label>
						<input required type="text" class="form-control" v-model='payloadData.reason.value' autocomplete='off' />
					</div>
				</div>

				<!-- <div>
					<div class="flex gap-4">
						<input required type="checkbox" v-model='payloadData.cancleUserBookings.value' autocomplete='off' />
						<label class="mb-1">Cancel user bookings </label>
					</div>
				</div> -->

				<div class="mt-[30px]">
					<button class='btn bg-dark text-white w-full disabled:cursor-not-allowed' @click='payloadData.confirm.value = true' :disabled="!isSuspendRouteEnabled">
						Proceed
					</button>
				</div>
			</div>
			<div v-else class="flex flex-col gap-4">
				<div class="p-3 rounded bg-[#EBEBFF] flex items-center gap-3">
					<img src="@/assets/img/caution.svg" alt="">
					<span class="text-sm font-medium text-[#2323F0]">It’s important you read the message below!</span>
				</div>
				<p class="text-xs m-0">Are you sure you want to make the selected routes unavailable? if this action was not agreed upon by the necessary stakeholders, it is advised that you abort as penalties may be attached to unauthorized actions.</p>
				<div class="" autocomplete="off">
					<div class="form-group mb-0 relative" autocomplete="off">
						<label class="mb-1">Password</label>
						<input required :type="showPassword ?'text':'password'" class="form-control" v-model='payloadData.staff_password.value' autocomplete='off' />
						<span @click="showPassword = !showPassword" class="fe absolute right-4 top-[34px] cursor-pointer" :class="[showPassword ? 'fe-eye' : 'fe-eye-off']"></span>
					</div>
				</div>

				<div class="mt-[30px] flex flex-col gap-2">
					<button class='btn bg-dark text-white w-full' @click='suspendRoute' :disabled='!payloadData.staff_password.value'>
						{{ payloadData.loading.value ? 'loading...' : 'Proceed' }}
					</button>
					<button class="text-center text-dark w-fit bg-transparent border-0 mx-auto" @click="payloadData.confirm.value = false">Go back</button>
				</div>
			</div>
		</BS_modal>
		<BS_modal :show='modalStatus' title='Duplicate Routes' @close='closeDuplicateRouteModal' modalId='duplicateRouteModalOne'>
			<div class="flex flex-col gap-4">
			<div class="flex flex-col">
				<label class="m-0">New route code</label>
				<input type="text" class="p-2 border rounded-md" v-model="new_route_code">
			</div>

			<div class="flex items-center gap-4">
				<input type="checkbox" id="iti" v-model="copy_itinary">
				<label class="m-0" for="iti">Copy Itineraries</label>
			</div>

			<button class='btn bg-dark text-white w-full mt-[30px]' @click='proceedToDuplicate' :disabled="new_route_code == ''">
				{{ dup_loading ? 'loading...' : 'Duplicate route' }}
			</button>
			</div>
		</BS_modal>
	</div>
</template>

<script setup>

import Swal from 'sweetalert2'
import PageHeader from '@/components/layout/PageHeader'
import PageRouteView from '@/components/layout/PageRouteView'
import { userHasPermission, DoNotShowOnProd } from '@/composables/core/permissions'
import BS_modal from '@/components/core/modals/BS_modal.vue'
import { ref, watch, computed } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import Multiselect from 'vue-multiselect'
import routeResource from '@/api/route'
import { useSuspendedRoutes } from './pages/composable'
import { useRouteDuplicate } from './composables/route'


const { modalStatus, new_route_code, copy_itinary, dup_loading, proceedToDuplicate, closeDuplicateRouteModal } = useRouteDuplicate()
const showPassword = ref(false)
const showSuspendModal = ref(false)
const companyData = {
	loading: ref(false),
	selectedCompany: ref(null),
	corporatesList: ref([]),
}
const routeData = {
	loading: ref(false),
	routes: ref([]),
	dates: ref([])
}

const payloadData = {
	loading: ref(false),
	dates: ref([]),
	selectedRoutes: ref([]),
	reason: ref(''),
	staff_password: ref(''),
	route_visibility: ref('public'),
	shared: ref(true),
	exclusive: ref(false),
	route_selection: ref('all_route'),
	cancleUserBookings: ref(false),
	confirm: ref(false)
}

const route_type = computed(() => {
	return (payloadData.shared.value && payloadData.exclusive.value) ? 'all'
	: payloadData.shared.value ? 'shared'
	: payloadData.exclusive.value ? 'exclusive'
	: 'all'
})

const suspendRoute = () => {
	payloadData.loading.value = true
	const sent_data = {
		dates: payloadData.dates.value,
		reason: payloadData.reason.value,
		staff_password: payloadData.staff_password.value,
		route_type: route_type.value,
		route_visibility: payloadData.route_visibility.value,
		cancel_user_bookings : false
	}
	if(payloadData.selectedRoutes.value.length) {
		sent_data['route_ids'] = payloadData.selectedRoutes.value
	}
	if(companyData.selectedCompany.value){
		sent_data['corporate_ids'] = [companyData.selectedCompany.value.id]
	}

	axiosInstance.post('/v1/routes/booking-suspensions', sent_data).then(() => {
		payloadData.loading.value = false; showSuspendModal.value = false
		resetValue()
		useSuspendedRoutes().getRoutes()
		Swal.fire({
			title: 'Success',
			text: 'Route Unavailability configured successfully',
			icon: 'success',
			confirmButtonText: 'Ok'
		})
	}).catch((err) =>
	{
		payloadData.loading.value = false
		Swal.fire({
			title: 'Error',
			text: err?.response?.data?.message || 'An error occured',
			icon: 'error',
			confirmButtonText: 'Ok'
		})
	})
}

const resetValue = () =>
{
	payloadData.dates.value = []
	payloadData.selectedRoutes.value = []
	payloadData.reason.value = ''
	payloadData.staff_password.value = ''
	routeData.dates.value = []
	routeData.routes.value = []
	payloadData.shared.value = true
	payloadData.exclusive.value = false
	payloadData.route_selection.value = 'all_route'
	payloadData.confirm.value = false
	companyData.selectedCompany.value = null
	payloadData.cancleUserBookings.value = false
	payloadData.route_visibility.value = 'public'

}

const isSuspendRouteEnabled = computed(() => {
	if(
		payloadData.dates.value.length && payloadData.reason.value &&
		(
			(payloadData.route_selection.value == 'selected_route' && payloadData.selectedRoutes.value.length) ||
			(payloadData.route_selection.value == 'all_route' && !payloadData.selectedRoutes.value.length)
		) &&
		(
			(payloadData.route_visibility.value == 'private' && companyData.selectedCompany.value != null) ||
			(payloadData.route_visibility.value == 'public')
		)
	){
		return true
	} else {
		return false
	}
})

const loadCorporates = async () => {
	companyData.loading.value = true
	const { data } = await axiosInstance.get('/v1/corporates')
	companyData.corporatesList.value = data.data
	companyData.loading.value = false
}

const fetchRoutes = () => {
	const obj = {
		status: 1,
		limit: 1000000,
		corporate_id: companyData.selectedCompany.value?.id,
		visibility: payloadData.route_visibility.value,
		is_exclusive: route_type.value == 'exclusive' ? 1 : route_type.value == 'shared' ? 0 : route_type.value
	}
	if(obj.visibility == 'all') delete obj.visibility
	if(obj.is_exclusive == 'all') delete obj.is_exclusive

	routeResource.listRoute({
		params: obj
	}).then((res) => { routeData.routes.value = res })
}

const asyncFindCorporates = (query) =>
{
	companyData.loading.value = true
	axiosInstance
		.post('/v1/corporates/search', { name: query })
		.then((response) =>
		{
			companyData.corporatesList.value = response.data
			companyData.loading.value = false
		})
		.catch((e) =>
		{
			companyData.loading.value = false
			companyData.corporatesList.value = []
		})
}

const corporateName = ({ corporate_name }) =>
{
	return corporate_name
}
const limitText = (count) =>
{
	return `and ${count} other corporates`
}

const closeModal = () => {
	showSuspendModal.value = false
	resetValue()
}


watch(payloadData.route_visibility, () => {
	companyData.selectedCompany.value = null
	if(payloadData.route_visibility.value === 'public') {
		payloadData.exclusive.value = false
		payloadData.shared.value = true
	}
})

watch([ payloadData.route_visibility, companyData.selectedCompany, payloadData.shared, payloadData.exclusive ], () => {
	payloadData.selectedRoutes.value = []
	fetchRoutes()
})

watch(payloadData.route_selection, () => {
	if(payloadData.route_selection.value == 'all_route') payloadData.selectedRoutes.value = []
})

watch(showSuspendModal, () => {
	if(showSuspendModal.value === true) fetchRoutes()
})

fetchRoutes()
loadCorporates()
</script>



<style lang="scss" scoped>
@import "src/assets/scss/nav-tabs";
.pointer {
	cursor: pointer;
}

.input-group {
	background: white;
	border-radius: 5px;
}
</style>

<style lang="scss">
.vpd-icon-btn {
	display: none !important;
}

.mx-datepicker-range {
	width: 100% !important;
}

.vpd-input-group input {
	flex-grow: 1;
	border-radius: 0.375rem !important;
	border: 1px solid #dadada !important;
	border-right: 1px solid #dadada !important;
	/* border-top-right-radius: 0; */
	/* border-bottom-right-radius: 0; */
	/* line-height: 30px; */
	/* padding: 0 10px; */
}
</style>

