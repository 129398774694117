<template>
	<div class="card" v-if="cloneRouteItinerary">
		<div class="card-body">
			<h3 class="card-title mb-4">
				Default Price Settings <span v-if="!userHasPermission('routes010')">(edit unauthorised)</span> <div class="float-right"><b-icon icon="arrow-repeat" animation="spin"
					v-if="processing"></b-icon></div>
			</h3>

			<table class="table table-striped" >
				<tbody> 
					<tr>
						<td>Pricing Type</td>
						<td>
							<div class="d-flex">
								<select
									:disabled="!userHasPermission('routes010')"
									@change="updateRouteItinerary(cloneRouteItinerary.id, 'pricing_type', $event.target.value)"
									type="select" class="custom-select d-inline-block w-auto pt-0 pb-0 h-25"
									v-model="cloneRouteItinerary.pricing_type">
									<option>Select Option</option>
									<option :value="item.value" :key="index"
										v-for="(item, index) in [{ value: 'route_price_table_lookup', label: 'Look Up From Pricing Table' }, { value: 'margin_value_generated', label: 'Auto Generated From Margin' }]">
										{{ item.label }}</option>
								</select>
							</div>
						</td>
					</tr>

					<tr>
						<td>Pricing Scheme</td>
						<td>
							<div class="d-flex">
								<select
								:disabled="!userHasPermission('routes010')"
									@change="updateRouteItinerary(cloneRouteItinerary.id, 'pricing_scheme', $event.target.value)"
									type="select" class="custom-select d-inline-block w-auto pt-0 pb-0 h-25"
									v-model="cloneRouteItinerary.pricing_scheme">
									<option>Select Option</option>
									<option :value="item.value" :key="index"
										v-for="(item, index) in [{ label: 'per_vehicle', value: 'per_vehicle' }, { label: 'per_seat', value: 'per_seat' }]">
										{{ item.label }}</option>
								</select>
							</div>
						</td>
					</tr>

					<tr v-if="cloneRouteItinerary.pricing_type !== 'route_price_table_lookup'">
						<td>Pricing Margin</td>
						<td>
							<div class="d-flex">
								<input
								:disabled="!userHasPermission('routes010')"
									@change="updateRouteItinerary(cloneRouteItinerary.id, 'pricing_margin', parseFloat($event.target.value))"
									type="number" class="form-control d-inline-block w-25 pt-0 pb-1 h-25 mr-2"
									v-model.number="cloneRouteItinerary.pricing_margin" />

								<select
								:disabled="!userHasPermission('routes010')"
									@change="updateRouteItinerary(cloneRouteItinerary.id, 'pricing_margin_unit', $event.target.value)"
									type="select" class="custom-select d-inline-block w-auto pt-0 pb-0 h-25"
									v-model="cloneRouteItinerary.pricing_margin_unit">
									<option>Select Option</option>
									<option :value="item.value" :key="index"
										v-for="(item, index) in [{ label: 'flat', value: 'flat' }, { label: 'percent', value: 'percent' }]">
										{{ item.label }}</option>
								</select>

							</div>
						</td>
					</tr>

				</tbody>
			</table>
		</div>
	</div>
</template>


<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
export default {
  props: {
    routeItinerary: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      cloneRouteItinerary: null,
      processing: false
    }
  },
  watch: {
    routeItinerary (newv, _) {
      this.cloneRouteItinerary = JSON.parse(JSON.stringify(newv))
    }
  },
  created () {
    this.cloneRouteItinerary = JSON.parse(JSON.stringify(this.routeItinerary))
  },
  mounted () {
    this.cloneRouteItinerary = JSON.parse(JSON.stringify(this.routeItinerary))
  },
  methods: {
    async updateRouteItinerary (routeItineraryId, key, value) {

      const existingItem = this.routeItinerary[key]

      const payload = {
        [key]: value
      }
      this.processing = true
      this.axios
        .patch(`/v1/route-itineraries/${routeItineraryId}`, payload)
        .then((res) => {
          this.$emit('updated', res.data)
        })
        .catch((err) => {
          this.$set(this.cloneRouteItinerary, key, existingItem[key])
          this.$toastr.e(`could not update ${key} to ${value}`, 'Error')
        }).finally(() => this.processing = false)
    }
  }
}
</script>
