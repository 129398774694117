<template>
	<div class="main-content">
		<div class="row mt-4">
			<div class="col-12">
				<div class="p-2">
					<div class="card">
						<div class="card-header align-items-center justify-content-between">
							<div class="col"></div>
							<div class="col-2">
								<select
									name="select-vehicle-type"
									v-model="selectedItinerary"
									class="custom-select"
									>
									<option value="">All Itineraries</option>
									<template v-if="itineraries">
										<option
											:value="item.id"
											v-for="item in itineraries"
											:key="item.id"
											>
											{{ item.trip_time }}
										</option>
									</template>
								</select>
							</div>

							<div class="col-2">
								<select
									name="select-route-type"
									v-model="selectedTripType"
									class="custom-select"
									>
									<option value="">All Trips</option>
									<option value="upcoming">Upcoming Trips</option>
									<option value="active">Active Trips</option>
									<option value="completed">Completed Trips</option>
								</select>
							</div>
							<div class="col-auto">
								<v-datepicker
									style="width: 100%"
									v-model="filter.startEndDatesFilterArray"
									placeholder="Filter by date"
									range
									></v-datepicker>
							</div>
						</div>
						<b-table
							striped
							hover
							selectable
							responsive
							show-empty
							:items="tableData"
							:fields="computedFields"
							:current-page="currentPage"
							:busy="loading"
							@row-clicked="viewTripDetail($event)"
							>
							<template #table-busy>
								<div
									class="d-flex flex-column justify-content-center align-items-center"
									>
									<div class="spinner-border" role="status"></div>
									<p class="text-center mt-2"><strong>Loading...</strong></p>
								</div>
							</template>

							<template #empty>
								<p class="text-center text-secondary">No records available</p>
							</template>

							<template #cell(date)="data">
								<div>
									<div>
										<span
											>Trip date &nbsp;&nbsp;&nbsp;<code>{{
												data.item.trip_date | date('d MMMM, yyyy')
											}}</code></span
											>
									</div>
									<div v-if="data.item.trip?.start_trip">
										<span
											>Started At
											<code>{{
												data.item.trip.start_trip | date('d MMMM, yyyy h:mm a')
											}}</code></span
											>
									</div>
									<div v-if="data.item.trip?.end_trip">
										<span
											>Ended At &nbsp;&nbsp;<code>{{
												data.item.trip.end_trip | date('d MMMM, yyyy h:mm a')
											}}</code></span
											>
									</div>
								</div>
							</template>

							<template #cell(route)="data">
								<route-description
									:max-width="250"
									:pickup="data.item.route.pickup"
									:destination="data.item.route.destination"
									>
								</route-description>
							</template>

							<template #cell(route_code)="data">
								<router-link
									class="sh-page-link"
									:to="{
										name: 'ShowRoute',
										params: { routeId: data.item.route.id },
									}"
									>{{ data.item.route.route_code }}
								</router-link>
							</template>

							<template #cell(itinerary)="data">
								<router-link
									class="sh-page-link"
									v-if="data.item.itinerary"
									:to="{
										name: 'ShowRouteItinerary',
										params: {
											itineraryId: data.item.itinerary.id,
											routeId: data.item.itinerary.route_id,
										},
									}"
									>{{ data.item.itinerary.trip_time }}
								</router-link>
								<span v-else> N/A </span>
							</template>
							<template #cell(partner)="data">
								<span class="sh-page-link" v-if="data.item?.vehicle?.partner_id">{{ data.item.vehicle.partner_id }}</span>
								<span v-else> N/A </span>
							</template>

							<template #cell(vehicle)="data">
								<div v-if="data.item.vehicle">
									<div>
										<span
											><router-link
												class="sh-page-link"
												:to="{
													name: 'ShowVehicle',
													params: { vehicleId: data.item.vehicle.id },
												}"
												>
												{{
													data.item.vehicle
														? `${data.item.vehicle.brand} ${data.item.vehicle.type}`
														: 'N/A'
												}}
											</router-link></span
											>
									</div>
									<div>
										<span
											><small>{{
												data.item.vehicle.registration_number
											}}</small></span
											>
									</div>
								</div>
								<div v-else>
									<span>N/A</span>
								</div>
							</template>

							<template #cell(driver)="data">
								<div v-if="data.item.driver">
									<div>
										<span
											><router-link
												class="sh-page-link"
												:to="{
													name: 'ShowDriver',
													params: { driverId: data.item.driver.id },
												}"
												>
												{{
													data.item.driver
														? `${data.item.driver.fname} ${data.item.driver.lname}`
														: data.item.route.driver
															? `${data.item.route.driver.fname} ${data.item.route.driver.lname}`
															: 'N/A'
												}}
											</router-link></span
											>
									</div>
									<div>
										<a
											v-if="data.item.driver && data.item.driver.phone"
											:href="'tel:' + data.item.driver.phone"
											>{{
												data.item.driver ? data.item.driver.phone : 'N/A'
											}}</a
											>
										<span v-else>N/A</span>
									</div>
								</div>
								<span v-else>N/A</span>
							</template>

							<template #cell(cost_of_supply)="data">
								<span class="sh-page-link" v-if="data.item.trip && data.item.trip.cost_of_supply"
									>₦{{ data.item.trip.cost_of_supply }}
								</span>
								<span v-else> N/A </span>
							</template>

							<template #cell(trip_status)="data">
								<div v-if="!data.item.trip">
									<span class="badge badge-warning">Upcoming Trip</span>
								</div>
								<div v-if="data.item.trip">
									<span
										class="badge badge-primary"
										v-if="!data.item.trip.end_trip"
										>Active Trip</span
										>
									<span
										class="badge badge-success"
										v-if="data.item.trip.end_trip"
										>Completed Trip</span
										>
								</div>
							</template>

							<template #cell(actions)="data">
								<router-link
									:to="{
										name: 'TripDetails',
										params: {
											tripId: data.item.trip?.id || `__${data.item.id}`,
										},
									}"
									class="btn btn-outline-secondary btn-sm"
									>View Trip</router-link
									>
							</template>

							<template
								v-if="tripType === 'completed'"
								#cell(completedTripFinancials)="data"
								>
								<div>
									<b-dropdown
										size="sm"
										variant="link"
										toggle-class="text-decoration-none"
										no-caret
										no-flip
										class="m-2"
										>
										<template #button-content>
											<p class="mt-3" style="font-weight: 900">&#x2026;</p>
										</template>
										<b-dropdown-item
											class="font-weight-bold"
											href="#"
											@click="viewTripFinancials(data.item.id)"
											>View Financials
										</b-dropdown-item>
									</b-dropdown>
								</div>
							</template>
						</b-table>
						<div class="card-footer" v-if="totalRecords">
							<div class="row align-items-center">
								<div class="col">
									<span class="text-dark font-weight-bold">{{
										tablePageText
									}}</span>
								</div>
								<div class="col-auto">
									<b-pagination
										v-model="currentPage"
										:total-rows="totalRecords"
										:per-page="pageSize"
										></b-pagination>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment'
import { extractErrorMessage } from '@/utils/helpers'
import RouteDescription from '@/components/modules/routes/RouteDescription'
import TableView from '@/mixins/tableview'

export default {
  components: { RouteDescription },
  mixins: [TableView],
  props: {
    routeId: [String, Number],
    showActions: {
      type: Boolean,
      default: true
    },
    showTripDate: {
      type: Boolean,
      default: false
    },
    isUpcoming: {
      type: Boolean,
      default: false
    },
    tripType: {
      type: String,
      default: null
    },
    waitlistCount: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      selectedItinerary: '',
      selectedTripType: '',
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      loading: false,
      tableData: [],
      itineraries: [],
      fields: [
        {
          key: 'Date',
          label: 'Date'
        },
        {
          key: 'route_code',
          label: 'Route Code'
        },
        {
          key: 'itinerary',
          label: 'Itinerary'
        },
        {
          key: 'trip_status',
          label: 'Status'
        },
        {
          key: 'vehicle',
          label: 'Vehicle'
        },
        {
          key: 'partner',
          label: 'Partner'
        },
        {
          key: 'driver',
          label: 'Driver'
        },
        {
          key: 'cost_of_supply',
          label: 'Cost Of Supply '
        }
      ],
      waitlistFields: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'phone',
          label: 'Phone'
        },
        {
          key: 'email',
          label: 'Email'
        }
      ],
      verificationMessage: 'Are you sure you want to end this trip ?',
      showCancelTripModalIndex: -1,
      cancellingTrip: false,
      search: null,
      filter: {
        startEndDatesFilterArray: []
      }
    }
  },
  watch: {
    currentPage (value) {
      this.fetchTripList()
    },
    selectedItinerary (value) {
       this.appendObjectToCurrentURL('iti', value)
      this.fetchTripList(true)
    },
    selectedTripType (value) {
        this.appendObjectToCurrentURL('type', value)
      this.fetchTripList(true)
    },
    dateFilterValid () {
      this.fetchTripList(true)
    },
    dateRange (newValue, oldValue) {
      
      if (newValue[0] !== newValue[1] || oldValue[0] !== oldValue[1]) {
        if (newValue[0] !== 'Invalid date' && newValue[1] !== 'Invalid date') {
             this.appendObjectToCurrentURL('from', newValue[0])
        this.appendObjectToCurrentURL('to', newValue[1])
        }else{
          this.appendObjectToCurrentURL('from', '')
          this.appendObjectToCurrentURL('to', '')
        }
     
        this.fetchTripList(true)
      }
    }
  },

  created () {
    this.fetchItineraries()
    if (this.showActions) {
      this.fields.push({ key: 'actions', value: 'Actions' })
    }
    if (this.tripType === 'completed') {
      this.fields.splice(-1, 0, { key: 'rating', label: 'Average Rating' })
      this.fields.splice(-1, 0, {
        key: 'completedTripFinancials',
        label: 'Action'
      })
    }
    this.convertURLParamsToObject()
    this.fetchTripList()
  },

  computed: {
    computedFields () {
      if (!this.isUpcoming) {
        return this.fields.filter((field) => !field.isUpcoming)
      } else {
        return this.fields
      }
    },
    tableColumns () {
      if (this.showTripDate) {
        return this.fields
      } else {
        return this.fields.filter((column) => column.label !== 'Trip Date')
      }
    },
    dateProp () {
      return this.tripType === 'upcoming' ? 'trip_date' : 'start_trip'
    },

    dateRange () {
      return this.filter.startEndDatesFilterArray.map((date) =>
        moment(date).format('YYYY-MM-DD')
      )
    },
    dateFilterValid () {
      return (
        this.filter.startEndDatesFilterArray.length &&
        this.filter.startEndDatesFilterArray.filter((date) => !!date).length > 0
      )
    }
  },

  mounted () {
    this.emitInterface()
  },
  methods: {
       convertURLParamsToObject(){
      const urlParams = this.$route.query
      this.filter.startEndDatesFilterArray = [urlParams.from, urlParams.to]
      this.selectedTripType = urlParams.type
       this.selectedItinerary = urlParams.iti
    },

      appendObjectToCurrentURL(key, value){
      let url = window.location.href
      let queryString = ''
      const hasQueryString = url.includes('?')
      if (hasQueryString) {
        const [baseUrl, existingQueryString] = url.split('?')
        const params = new URLSearchParams(existingQueryString)
        if (params.has(key)) {
          params.delete(key)
        }
        queryString = params.toString()
        if (queryString.length > 0) {
          queryString += '&'
        }
      }
      if (value) {
        queryString += encodeURIComponent(key) + '=' + encodeURIComponent(value)
      }
      url = hasQueryString ? url.split('?')[0] + '?' + queryString : url + '?' + queryString
      window.history.replaceState(null, null, url)
    },
    async fetchItineraries () {
      this.axios
        .get(`/v1/routes/${this.routeId}/itineraries?itinerary_only=1`)
        .then((res) => {
          this.itineraries = res.data.data
        })
    },
    viewTripFinancials (tripId) {
      this.$router.push({ name: 'TripFinancials', params: { tripId: tripId } })
    },
    viewTripDetail (trip, routeName = null) {
      if (this.tripType && this.tripType === 'upcoming') {
        this.$store.dispatch('setSelectedTripData', trip)
        this.$router.push({
          name: routeName || 'TripDetails',
          params: { tripId: '__' + trip.id }
        })
      } else {
        this.$router.push({
          name: routeName || 'TripDetails',
          params: { tripId: trip.id }
        })
      }
    },
    async fetchTripList () {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        const data = await this.requestFunction.call(this, {
          page: this.currentPage,
          limit: this.pageSize,
          search: this.search,
          from: this.dateRange[0] !== 'Invalid date' ? this.dateRange[0] : null,
          to: this.dateRange[1] !== 'Invalid date' ? this.dateRange[1] : null,
        })
        this.tableData = data.data
        this.totalRecords = data.count
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    refreshTable (searchEnabled = false) {
      if (!searchEnabled) {
        this.search = ''
      }
      if (this.currentPage === 1) {
        this.fetchTripList()
      } else {
        // change current page to trigger effect on property watch
        this.currentPage = 1
      }
    },
    async startTrip (trip) {
      const time = trip.route.itineraries[0]?.trip_time
      const tripTimeMinus10Minutes = moment(
        `${trip.trip_date} ${time}`
      ).subtract(10, 'minutes')

      // Check if current time is greater than (trip_time - 10 minutes)
      if (!moment().isAfter(tripTimeMinus10Minutes)) {
        this.$swal({
          icon: 'info',
          title: 'Trip can\'t be started',
          text: 'You can only start a trip 10 minutes before start time or after',
          showCloseButton: true
        })
        return
      }
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to start this trip?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .post('/v1/trips', {
              route_id: trip.route.id,
              pickup_coordinate: trip.route.pickup_coordinate,
              driver_id: trip.route.driver.id
            })
            .then(() => {
              this.refreshTable()
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occurred, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Trip started successfully',
            showCloseButton: true
          })
        }
      })
    },
    cancelTrip (trip, passKey) {
      this.cancellingTrip = true
      this.axios
        .delete(`/v1/upcoming-trips/${trip.id}`, {
          data: {
            password: passKey
          }
        })
        .then(() => {
          this.refreshTable()
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Trip cancelled successfully',
            showCloseButton: true
          })
          this.cancellingTrip = false
          this.showCancelTripModalIndex = -1
        })
        .catch((error) => {
          this.cancellingTrip = false
          const msg = extractErrorMessage(
            error,
            'An error occurred, please try again.'
          )
          this.$swal().showValidationMessage(msg)
        })
    },
    async endTrip (trip) {
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to end this trip?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/trips/${trip.id}`, {
              route_id: trip.route_id,
              destination_coordinate: trip.destination_coordinate
                ? trip.destination_coordinate
                : '6.5135466666661115, 3.3668908333333337'
            })
            .then(() => {
              this.refreshTable()
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occurred, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Trip ended successfully',
            showCloseButton: true
          })
        }
      })
    },
    viewPassengers (tripData) {
      this.viewTripDetail(tripData, 'TripPassengers')
    },
    emitInterface () {
      this.$emit('trip-history-interface', {
        refreshTable: () => this.refreshTable()
      })
    },
    openCancelTripModal (message) {
      this.verificationMessage = message
    },
    getActions (data) {
      return {
        endTrip: () => {
          this.endTrip(data.item)
        },
        startTrip: () => {
          this.startTrip(data.item)
        },
        openDelete: (message) => {
          this.showCancelTripModalIndex = data.index
          this.openCancelTripModal(message)
        },
        data: data
      }
    },
    applySearch (event) {
      if (event.key === 'Enter' || event.keyCode === 13) {
        this.refreshTable(true)
      } else if (!this.search) {
        this.refreshTable(false)
      }
    },
    async requestFunction (params) {
      this.params = params
      const limit = params ? params.limit : 10
      const page = params ? params.page : 1

      const baseUrl = `/v1/routes/${this.routeId}/trips`
      let url = `${baseUrl}?limit=${limit}&page=${page}`
      if (params?.from || params?.to) {
        if (params?.from) {
          url += `&startDate=${params.from}`
        }
        if (params?.to) {
          url += `&endDate=${params.to}`
        }
      }

      if (params?.search) {
        url += `&search=${params.search}`
      }

      if (this.selectedTripType) {
        url += `&tripStatus=${this.selectedTripType}`
      }

      if (this.selectedItinerary) {
        url += `&itineraryId=${this.selectedItinerary}`
      }
      const result = await this.axios.get(url)
      const data = result.data.data
      return {
        data: data,
        count: result.data?.total ?? result.data?.metadata?.total
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@use 'src/assets/scss/b-table';

.view-passenger {
  border: 1px solid;
  padding: 0.25rem 0.25rem;
  border-radius: 1rem;
  cursor: pointer;
  font-weight: 500;
  color: #109352;
  font-size: 0.8rem;
}
</style>