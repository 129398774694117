import { render, staticRenderFns } from "./Redirect.vue?vue&type=template&id=d9e47f98&scoped=true"
import script from "./Redirect.vue?vue&type=script&setup=true&lang=js"
export * from "./Redirect.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9e47f98",
  null
  
)

export default component.exports