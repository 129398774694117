import FeatureFlagService from '@/utils/feature-flags'

export default {
  namespaced: true,
  state: {
    currentUser: null,
    selectedTripData: null
  },
  mutations: {
    setCurrentUser(state, model){
      state.currentUser = model
      if (model) {
        FeatureFlagService.setCurrentUserData({
          email: model.email,
          userId: model.id
        })
      }
    },
    setSelectedTripData (state, data) {
      state.selectedTripData = data
    }
  }
}
