<template>
	<div class="card shadow-none border">
		<div class="card-body">
			<div class="row align-items-center gx-0">
				<div class="col">
					<p class="mb-2 text-muted">{{ title }}</p>
					<span class="h1 mb-0 mt-2">{{ showRating }}</span>
					<span>/{{ maxValue }}</span>
				</div>
				<div class="col-auto">
					<img :src="image" />
				</div>
			</div>
			<div class="mt-4">
				<span
					><svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						>
						<path
							d="M14.7378 2.5H12.1409C11.9745 2.50004 11.8119 2.54941 11.6735 2.64187C11.5352 2.73434 11.4273 2.86575 11.3637 3.01949C11.3 3.17323 11.2833 3.3424 11.3158 3.50561C11.3482 3.66882 11.4283 3.81874 11.546 3.93642L12.4598 4.85028L9.44227 7.86703C9.28193 8.02026 9.06869 8.10577 8.84691 8.10577C8.62513 8.10577 8.41189 8.02026 8.25156 7.86703L8.1085 7.72313C7.62769 7.26374 6.98828 7.00738 6.32328 7.00738C5.65828 7.00738 5.01888 7.26374 4.53806 7.72313L0.24646 12.0147C0.0883392 12.1733 -0.00031478 12.3882 8.39857e-07 12.6121C0.00031646 12.8361 0.0895761 13.0507 0.248143 13.2088C0.406711 13.3669 0.621597 13.4556 0.845529 13.4553C1.06946 13.455 1.2841 13.3657 1.44222 13.2071L5.73382 8.91552C5.89405 8.7621 6.10733 8.67646 6.32917 8.67646C6.55101 8.67646 6.76429 8.7621 6.92453 8.91552L7.06758 9.05942C7.54859 9.51843 8.18792 9.77453 8.8528 9.77453C9.51768 9.77453 10.157 9.51843 10.638 9.05942L13.6556 6.04183L14.5695 6.95569C14.6875 7.07144 14.8371 7.14983 14.9995 7.18108C15.1618 7.21233 15.3298 7.19506 15.4824 7.13141C15.635 7.06776 15.7655 6.96057 15.8576 6.82321C15.9496 6.68585 15.9992 6.52442 16 6.35908V3.76224C16 3.42747 15.867 3.10642 15.6303 2.8697C15.3936 2.63299 15.0725 2.5 14.7378 2.5Z"
							fill="#109352"
							/>
					</svg> </span
					><span class="rating-dashboard-green"> {{ ratingsCount }} ratings</span
					><span> in total</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    title: String,
    rating: String,
    ratingsCount: String,
    image: String,
    maxValue: [String, Number]
  },

  computed: {
    showRating () {
      return parseFloat(this.rating).toFixed(1)
    }
  }

}
</script>
