<template>
	<div>
		<div class="bg-white border-bottom">
			<div class="header mb-0">
				<div class="container-fluid">
					<div class="header-body py-0 mt-4 border-0">
						<div class="flex flex-col md:flex-row gap-y-3 gap-x-4 md:justify-between md:items-center">
							<div class="col">
								<h6 class="header-pretitle">VEHICLE PARTNERS OVERVIEW</h6>
								<h1 class="header-title">{{ partnerDets?.owner.fname }} {{ partnerDets?.owner.lname }}</h1>
							</div>
              
							<div v-if="earnings" class="flex items-center gap-3 flex-wrap">
                <button type="button" v-if="canApprove" id="deductPayoutButton"
                  aria-expanded="false" @click="openDeductionModal" class="btn btn-lg btn-secondary"
                >
                  Deduct from earnings
                </button>
                <button type="button" v-if="canApprove" id="markAsPaidPayoutButton"
                  aria-expanded="false" @click="markRevenueAsPaid()" class="btn btn-lg btn-secondary"
                >
                  Mark as paid
                </button>
								<button type="button" v-if="userHasPermission('partners014') && canApprove" id="approvePayoutButton" 
                  aria-expanded="false" @click="showPayout()" class="btn btn-lg btn-black text-white"
                >
                  Approve
                </button>
                <button type="button" v-if="canRetry" id="retryPayoutButton" 
                  aria-expanded="false" @click="showPayout()" class="btn btn-lg btn-black text-white"
                >
                  Retry
                </button>
							</div>
						</div>
						<!-- / .row -->
					</div>
					<!-- / .header-body -->
				</div>
			</div>
			<div class="container-fluid">
				<div class="row pt-3 pb-1">
					<div class="col justify-self-center align-self-center flex-column">
						<ul class="nav nav-pills">
							<li class="nav-item">
								<router-link
									:to="{
										name: 'PartnerInformation',
									}"
									class="nav-link"
									active-class="active"
									>Partner Information</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<template>
			<review-payout-modal
        :isRetry="canRetry"
				:show="showPayoutModal"
				:partner_sid="partnerDets?.account_sid"
				:earnings_id="earnings_id"
				@close="showPayoutModal = false"
				@showRefund="showPayout()"
				@updated="updatePage()"
				>
			</review-payout-modal>
		</template>
		<div>
			<div class="container-fluid">
				<router-view :key="componentKey"></router-view>
			</div>
		</div>

    <BS_modal :show="isDeductModal" title="Deduct Payout" @close="isDeductModal = false" modalId="isDeductModal">
		<form @submit.prevent="deduct" class="flex flex-col w-full">
			<div class="form-group">
				<label for="amount" class="form-radio-label ml-2">Deducted Amount</label>
				<input v-model="deductAmount" class="form-control mb-3" type="number" required />
				<span class="text-red-500 -mt-5" v-if="deductAmount > isDeductAmountTable">Amount is larger than payslip
					total</span>
			</div>
			<div class="form-group">
				<label for="dec" class="form-radio-label ml-2">Deduction Description</label>
				<textarea v-model="deductDescription" class="form-control mb-3" required />
			</div>
			<div class="form-group">
				<label for="warning" class="form-radio-label ml-2 text-black font-bold">Type in YES to confirm this
					action</label>
				<input v-model="deductValidText" class="form-control mb-3" type="text" required />
			</div>

			<button :disabled="deductAmount > isDeductAmountTable ||
				deductAmount == 0 ||
				deductDescription == '' ||
				deductValidText !== 'YES' ||
				isDeductloading
				"
				class="btn-primary py-3 rounded-md disabled:bg-slate-300 disabled:text-black border-none disabled:cursor-not-allowed">
				{{ isDeductloading ? 'Loading...' : 'Deduct' }}
			</button>
		</form>
	</BS_modal>

	<BS_modal :show="deductRevenue.showModal" title="Deduct From Revenue" @close="closeDeductRevenue" modalId="isDeductRevenueModal">
		<form @submit.prevent="proceedDeductRevenue()" class="flex flex-col w-full">
			<div class="form-group">
				<label for="amount" class="form-radio-label ml-2">Deducted Amount</label>
				<input v-model="deductRevenue.amount" class="form-control mb-3" type="number" required />
			</div>
			<div class="form-group">
				<label for="dec" class="form-radio-label ml-2">Deduction Description</label>
				<textarea v-model="deductRevenue.desc" class="form-control mb-3" required />
			</div>
			<div class="form-group">
				<label for="warning" class="form-radio-label ml-2 text-black font-bold">Type in YES to confirm this
					action</label>
				<input v-model="deductValidText" class="form-control mb-3" type="text" required />
			</div>

			<button :disabled="
				deductRevenue.amount < 1 ||
				deductRevenue.desc == '' ||
				deductValidText !== 'YES' ||
				deductRevenue.loading
				"
				class="btn-primary py-3 rounded-md disabled:bg-slate-300 disabled:text-black border-none disabled:cursor-not-allowed"
			>
				{{ deductRevenue.loading ? 'Loading...' : 'Deduct Revenue' }}
			</button>
		</form>
	</BS_modal>

	</div>
</template>


<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import { computed } from 'vue'
import ReviewPayoutModal from '@/components/modals/ReviewPayoutModal.vue'
import Swal from 'sweetalert2'
import BS_modal from '@/components/core/modals/BS_modal.vue'

export default {
  name: 'ManagePayout',
  components: {
    ReviewPayoutModal, BS_modal
  },
  data () {
    return {
		showPayoutModal: false,
		id: parseInt(this.$route.params.payoutId),
		earnings_id: this.$route.params.earningId,
		partnerDets: null,
		loading: false,
		earnings: null,
		componentKey: 0,
		retrying_payout: false,
		isDeductAmountTable: 0,
		isDeductModal: false,
		isDeductloading: false,
		deductAmount: null,
		deductDescription: '',
		deductValidText: '',
		deductRevenue: {
			showModal: false,
			amount: null,
			desc: '',
			loading: false,
			id: null
		},
		refresh_key: 1
    }
  },
  methods: {
    showPayout () {
      if (this.earnings.settlementAccount == null) {
        this.$swal({
          icon: 'error',
          title: 'You cannot approve a Partner without a Settlement Account',
          showConfirmButton: true,
          showCancelButton: false
        })
      } else {
        this.showPayoutModal = !this.showPayoutModal
      }
    },
    async getPartnerDetails () {
      this.loading = true
      try {
        const res = await this.axios.get(`/v1/partners/${this.id}`)
        if (res.status == 200) {
          const r = res.data
          r.owner.fname = r.owner.fname.charAt(0).toUpperCase() + r.owner.fname.slice(1)
          r.owner.lname = r.owner.lname.charAt(0).toUpperCase() + r.owner.lname.slice(1)
          this.partnerDets = r
        }
      } catch (err) {

      } finally {
        this.loading = false
        this.getPartnerEarningsDets()
      }
    },
    async getPartnerEarningsDets () {
      try {
        const res = await this.axios.get(`cost-revenue/v1/earnings/${this.earnings_id}`)
        if (res.status == 200) {
          this.earnings = res.data
        }
      } catch (err) {

      }
    },
    updatePage () {
      this.componentKey += 1
      this.getPartnerEarningsDets()
    },
    async retryPayout () {
      this.retrying_payout = true
      try {
        const res = await this.axios.post(`v1/partners/${this.partnerDets.account_sid}/earnings/${this.earnings_id}/retry`)
        if (res.status == 200) {
          Swal.fire({ icon: 'success', title: 'Success', text: res?.data?.message ?? 'Payout successful'})
        }
      } catch (err) {
        Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
      } finally {
        this.retrying_payout = false
        this.getPartnerEarningsDets()
      }
    },
    openDeductionModal() {
		this.isDeductModal = true
		this.isDeductAmountTable = this.earnings.netRevenue
		this.isDeductId = this.earnings_id
	},
    async deduct() {
		this.isDeductloading = true
		const sent_data = {
			amount: Number(this.deductAmount),
			description: this.deductDescription,
		}
		try {
			const res = await this.axios.post(
				`/cost-revenue/v1/earnings/${this.isDeductId}/deductions`,
				sent_data
			)
			if(res.data?.success) {
				this.$toastr.s(res.data?.message || 'Revenue Deducted', 'Success')
				this.isDeductloading = false
				this.isDeductModal = false
				this.updatePage()
			} else {
				this.$toastr.e(res.data?.message || 'Unable to deduct', 'Error')
			}
			
		} catch (e) {
			this.$toastr.e('Error deducting revenue', 'Error')
		}
	},
    async markRevenueAsPaid() {
      if(!this.earnings?.settlementAccount?.partnerId) {
        Swal.fire({ icon: 'error', title: 'Error', text: 'No partner Id'})
        return
      }
			const sent_data = {
				partnerId: this.earnings.settlementAccount.partnerId,
				earningId: this.earnings_id,
			}
			try {
				await this.$swal({
					icon: 'question',
					title: 'Please Confirm',
					text: 'Are you sure you want to mark this slip as paid?',
					showConfirmButton: true,
					showCancelButton: true,
					preConfirm: () => {
						return this.axios
							.post('/cost-revenue/v1/earnings/paid', sent_data)
							.then((res) => {
                if(res.data?.success) {
                  this.$toastr.s('Revenue marked as paid', 'Success')
                  this.updatePage()
                } else {
                  this.$toastr.e(res.data.message || 'Revenue marked as paid', 'Error')
                }
							})
							.catch((error) => {
								const msg =
									error.response && error.response.data
										? error.response.data.message
										: 'An error occured, please try again.'

								this.$swal().showValidationMessage(msg)
							})
					},
					allowOutsideClick: () => !Swal.isLoading(),
				})
			} catch (e) {
				this.$toastr.e('Something went wrong', 'Error')
			}
		},
	openDeductRevenue(data) {
		this.deductRevenue.id = data.id
		this.deductRevenue.showModal = true
	},
	closeDeductRevenue() {
		this.deductRevenue.id = null
		this.deductRevenue.amount = null
		this.deductRevenue.desc = ''
		this.deductRevenue.showModal = false
		this.deductValidText = ''
	},
	async proceedDeductRevenue() {
		this.deductRevenue.loading = true
		const sent_data = {
			amount: Number(this.deductRevenue.amount),
			description: this.deductRevenue.desc,
		}
		try {
			const res = await this.axios.post(
				`/cost-revenue/v1/revenues/${this.deductRevenue.id}/deductions`,
				sent_data
			)
			if(res.data?.success) {
				this.$toastr.s('Revenue Deducted', 'Success')
				this.deductRevenue.loading = false
				this.closeDeductRevenue()
				this.refresh_key++
			} else {
				this.$toastr.e(res.data?.messsage || 'Unable to deduct from revenue', 'Error')
			}
			
		} catch (e) {
			this.$toastr.e('Error deducting revenue', 'Error')
		}
	}
  },
  created () {
    this.getPartnerDetails()
    this.updatePage()
  },
  computed: {
    canApprove() {
      return (this.earnings?.status == 'pending-payout' || this.earnings?.status == 'pending-settlement') ? true : false
    },
    canRetry () {
      return (this.earnings?.status == 'failed') ? true : false
    }
  },
	provide() {
		return {
			openDeductRevenue: this.openDeductRevenue,
			refresh_key: computed(() => this.refresh_key)
		}
	},
}
</script>

<style lang="scss" scoped>

.btn-black{
   background-color:#000005;
}

.btn-secondary{
	border: 1px solid #000005;
	background-color: transparent;
	color: #000005;
}
</style>
