import { loadRouteComponent } from '@/utils/helpers'
import GamesAndCampaigns from '@/layouts/modules/GamesAndCampaigns/Index.vue'
import Promotions from '@/layouts/modules/GamesAndCampaigns/Promotions.vue'
import Valentine from '@/layouts/modules/GamesAndCampaigns/Valentine.vue'
const GamesAndCampaignsRoutes = [
  {
    path: 'games&campaigns',
    component: GamesAndCampaigns,
    children: [
      {
        path: '',
        component: Promotions,
        props: true,
        children: [
          {
            path: 'banner',
            component: loadRouteComponent(
              'Games&Campaigns/Promotion/pages/BannerConfig'
            ),
            name: 'BannerConfig',
            props: true
          },
          {
            path: 'carousel',
            component: loadRouteComponent(
              'Games&Campaigns/Promotion/pages/CarouselConfig'
            ),
            name: 'CarouselConfig',
            props: true
          }
        ]
      },
      {
        path: 'banner/:bannerId',
        component: loadRouteComponent(
          'Games&Campaigns/Promotion/pages/profile/ShowBanner'
        ),
        name: 'ShowBanner',
        props: true
      },
      {
        path: 'carousel/:carouselId',
        component: loadRouteComponent(
          'Games&Campaigns/Promotion/pages/profile/ShowCarousel'
        ),
        name: 'ShowCarousel',
        props: true
      },
      {
        path: 'manage-banner',
        name: 'ManageBanner',
        component: loadRouteComponent(
          'Games&Campaigns/Promotion/pages/ManageBanner'
        ),
        props: true
      },
      {
        path: 'manage-carousel',
        name: 'ManageCarousel',
        component: loadRouteComponent(
          'Games&Campaigns/Promotion/pages/ManageCarousel'
        ),
        props: true
      },

      {
        path: '',
        props: true,
        component: Valentine,
        children: [
          {
            path: 'valentine',
            component: loadRouteComponent(
              'Games&Campaigns/Valentine/pages/List/ValentineCampaignList'
            ),
            name: 'ValentineCampaignList',
            props: true
          }
        ]
      },
      {
        path: 'valentine/:campaignId',
        component: loadRouteComponent(
          'Games&Campaigns/Valentine/pages/Profile/CampaignDetails'
        ),
        name: 'CampaignDetails',
        props: true
      }
    ]
  }
]

export default GamesAndCampaignsRoutes
