<template>
	<main>
	  <div class="card-body p-0 bg-white border rounded">
		<div class="flex flex-col gap-y-3 gap-x-4 p-2 border-bottom lg:flex-row lg:items-center lg:justify-between w-full">
			<div class="w-full lg:max-w-fit flex items-center gap-3 md:flex-grow p-2">
				<img class="shrink-0" src="@/assets/img/icons/search.svg" alt="">
				<input type="text" placeholder="Search" class="flex-grow border-none outline-none text-sm">
			</div>
			<div class="flex flex-col gap-4 lg:flex-row">
				<div class="flex flex-col gap-4 sm:flex-row sm:items-center">
					<date-picker class="!w-full lg:shrink-0 lg:max-w-[150px]" v-model="date" valueType="format" placeholder="Filter by trip date"></date-picker>
					<date-picker class="!w-full lg:shrink-0 lg:max-w-[150px]" v-model="date" valueType="format" placeholder="Filter by trip date"></date-picker>
				</div>
				<button class="lg:shrink-0 bg-transparent whitespace-nowrap rounded px-3 py-2 text-sm text-black font-bold border-none w-fit flex items-center gap-2">
					<img src="@/assets/img/downloadIcon.svg" alt="">
					Download Report
				</button>
			</div>
		</div>
		<div class="flex flex-col lg:flex-row px-3 py-3 gap-4 lg:justify-between lg:items-center">
			<div class="flex items-center gap-4">
				<div class="flex items-center gap-2" @click="status.ongoing.value = !status.ongoing.value">
					<checkBox :value="status.ongoing.value" />
					<label class="text-[#364152] text-sm font-medium">Ongoing</label>
				</div>
				<div class="flex items-center gap-2" @click="status.pending.value = !status.pending.value">
					<checkBox :value="status.pending.value" />
					<label class="text-[#364152] text-sm font-medium">Pending</label>
				</div>
				<div class="flex items-center gap-2" @click="status.ended.value = !status.ended.value">
					<checkBox :value="status.ended.value" />
					<label class="text-[#364152] text-sm font-medium">Ended</label>
				</div>
			</div>
			<div class="flex items-center gap-4">
				<div class="flex items-center gap-2">
					<label class="m-0 text-sm text-[#6E717C]">Itinerary</label>
					<select v-model="itinerary" class="border-0 font-medium">
						<option value="all">All</option>
						<option value="active">5:00 am</option>
					</select>
				</div>

				<div class="flex items-center gap-2">
					<label class="m-0 text-sm text-[#6E717C]">Route type</label>
					<select v-model="type" class="border-0 font-medium">
						<option value="">All routes</option>
						<option value="exclusive">Exclusive</option>
						<option value="shared">Shared</option>
					</select>
				</div>
			</div>
		</div>
		<b-table ref="staffTable" :current-page="currentPage" :busy="false" responsive striped :items="sample_data" :fields="fields" @row-clicked="openAuction" class='cursor-pointer'>
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<strong>Loading...</strong>
				</div>
			</template>
			<template #empty>
				<h4>There are no auctions found</h4>
			</template>
			<template #cell(id)="data">
				{{ (currentPage - 1) * perPage + data.index + 1 }}
			</template>
			<template #cell(pickup)="data">
				<route-description :max-width="250" :pickup="data.item.pickup"
					:destination="data.item.destination">
				</route-description>
			</template>
			<template #cell(itinerary)="data">
				<div class="flex flex-col gap-1 text-sm">
					<p class="text-[#7493CB]">SGT253</p>
					<p class="text-[#000005]">5:00 AM</p>
				</div>
			</template>
			<template #cell(status)="data">
				<p class="text-xs text-[#313533] p-2 rounded w-fit"
					:class="data.item.status == 'ongoing' ? 'ongoing' : data.item.status == 'pending' ? 'pending' : 'ended'"
				>
					{{ data.item.status }}
				</p>
			</template>
		</b-table>
		<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
	  </div>

	</main>
  </template>

  <script setup >
  import Swal from 'sweetalert2'
  import { ref, watch } from 'vue'
  import { axiosInstance as axios } from '@/plugins/axios'
  import moment from 'moment'
  import DatePicker from 'vue2-datepicker'
  import checkBox from '@/components/core/checkBox.vue'
  import RouteDescription from '@/components/modules/routes/RouteDescription'
  import router from '@/router'

  const date = ref()
  const itinerary = ref('all')
  const type = ref('')
  const status = {
	ongoing: ref(true),
	pending: ref(false),
	ended: ref(true)
  }
  const currentPage = ref(1)
  const perPage = ref(10)
  const totalRecords = ref(1)

  const fields = [
	{ key: 'id', label: 'ID' },
	{ key: 'pickup', label: 'Route' },
	{ key: 'itinerary', label: 'Itinerary' },
	{ key: 'trip_start', label: 'Trip Start Date' },
	{ key: 'vehicle', label: 'Preferred Vehicle' },
	{ key: 'bid_start_date', label: 'Bid start date' },
	{ key: 'status', label: 'Bid status' },
	{ key: 'winning_bid', label: 'Winning bid' },
	// { key: 'updated_at', label: 'Action' },
  ]

  const sample_data = ref([
	{
		id: 123,
		pickup: 'Alaka busstop',
		destination: 'Iponri costain',
		itinerary: '5:30 am',
		trip_start: '2023-10-25',
		vehicle: 'Toyota Camry 2020',
		bid_start_date: '2023-10-23',
		status: 'ongoing',
		winning_bid: '----'
	}
  ])

  const openAuction = (val) => {
	router.push(`/routes/${val.id}/itineraries/auction-details`)
  }
  </script>

<style scoped>
span,p,h1,h2,h3,h4,h5,h6,label {
	margin: 0 !important;
}

.pending{
	background-color: #FFFCEB;
	border: 1px solid #FFDF18;
}

.ongoing{
	background-color: #F5FFFA;
	border: 1px solid #0DAC5C;
}

.ended{
	background-color: #FFF5F5;
	border: 1px solid #FF6966;
}
</style>
