<template>
	<div class="main-content cont">
		<!-- {{ eventStatus }} -->
		<page-header
			:loading="loading"
			:title="loading ? '' : eventDescription"
			pre-title="Overview"
			v-if="renderHeader"
			>
			<template slot="tabs" v-if="!loading && !eventStatus">
				<div></div>
			</template>
			<template slot="tabs" v-else-if="!loading && isAccepted">
				<page-tabs-vue :menu-tabs="menuTabs">
					<template v-slot="props">
						<router-link
							:to="{ name: props.menu.routeName }"
							class="nav-link"
							active-class="active"
							>
							{{ props.menu.label }}
						</router-link>
					</template>
				</page-tabs-vue>
			</template>
			<template slot="tabs" v-else-if="!loading && !isAccepted">
				<div>
					<b-breadcrumb>
						<b-breadcrumb-item href="#/events/list">Events</b-breadcrumb-item>
						<i class="px-2 inactive fe fe-chevron-right"></i>
						<b-breadcrumb-item active-class="active" active
							>Event information</b-breadcrumb-item
							>
					</b-breadcrumb>
				</div>
			</template>
      
			<template slot="actions">
				<div class="dropdown">
					<button
						class="btn btn-primary dropdown-toggle"
						type="button"
						id="dropdownMenuButton"
						data-toggle="dropdown"
						aria-expanded="false"
						:disabled="isActionButtonDisabled"
						>
						Actions
					</button>
					<div
						v-if="eventStatus === 'accepted'"
						class="dropdown-menu dropdown-menu-right mt-3"
						aria-labelledby="dropdownMenuButton"
						>
						<a
							class="dropdown-item" 
              :class="{
                'btn btn-primary' : routeAssigned,
                'btn disabled' : !routeAssigned
              }"
							@click="() => routeAssigned ? showModal('notify-attendees-modal') : null"
              
							>Notify Attendees</a
							>
						<a
							class="dropdown-item btn btn-primary"
							@click.prevent="showModal('event-route-modal')"
							>
							Assign Route
						</a>
					</div>
					<div
						class="dropdown-menu dropdown-menu-right mt-3"
						aria-labelledby="dropdownMenuButton"
						>
						<button
							class="dropdown-item btn btn-primary"
              type="button"
              :disabled="eventStatus === 'cancelled'"
							@click.prevent="showModal('accept-event-modal')"
							>Accept
              </button>
            <button
							class="dropdown-item btn btn-primary"
              type="button"
              :disabled="eventStatus === 'cancelled'"
							@click.prevent="showModal('query-event-modal')"
							>
                Query
            </button>
						<button
							class="dropdown-item btn btn-primary"
              type="button"
              :disabled="eventStatus === 'cancelled'"
							@click.prevent="showModal('reject-event-modal')"
							>
                Reject
            </button>
					</div>
				</div>
			</template>
		</page-header>
		<page-route-view
			:loading = false
			:bus="bus"
			@update:eventStatus="updateEventStatus"
			@update:acceptanceLoading="updateAcceptanceLoading"
			@update:rejectionLoading="updateRejectionLoading"
			@update:queryLoading="updateQueryLoading"
			@update:attendeesNotificationLoading="updateAttendeesNotificationLoading"
			@update:allRoutes="updateAllRoutes"
			@update:routeAssignmentLoader="updateRouteAssignmentLoader"
			@update:isLoadingGetRoutes="updateGetRoutesLoader"
			@update:eventRoutes="updateEventRoutes"
      @update:routeAssigned = 'updateRouteAssigned'
			>
		</page-route-view>
		<event-route-modal 
			:eventRoutes="eventRoutes"
			:allRoutes="allRoutes"
			:loading="routeAssignmentLoader"
			:isLoadingGetRoutes="isLoadingGetRoutes"
			@onAssignRoutes="assignRoutes"
			@searchRoutes="searchRoutes"
			> </event-route-modal>
		<accept-event-modal 
			:loading="acceptanceLoading"
			@onAcceptEvent="acceptEvent"></accept-event-modal>
		<query-event-modal
			:loading="queryLoading"
			@onQueryEvent="queryEvent"
			>
		</query-event-modal>
		<reject-event-modal
			:loading="rejectionLoading"
			@onRejectEvent="rejectEvent"
			>
		</reject-event-modal>
		<notify-attendee-modal 
			:loading="attendeesNotificationLoading"
      modal-type="attendees"
			@onNotifyAttendees="notifyAttendees"
			>
		</notify-attendee-modal>
		<custom-toast
			v-for="(toast, index) in toastData" :key="index"
			:toastId="toast.toastId"
			:variant="toast.variant"
			:icon="toast.icon"
			:iconBgClass="toast.iconBgClass"
			:message="toast.message"
			:toastBodyClass="toast.toastBodyClass"
			></custom-toast>
	</div>
</template>
<script>
import Vue from 'vue'
import PageHeader from '@/components/layout/PageHeader.vue'
import PageTabsVue from '@/components/layout/PageTabs.vue'
import PageRouteView from '@/components/layout/PageRouteView.vue'
import EventRouteModal from '@/views/Events/components/modals/EventRouteModal.vue'
import NotifyAttendeeModal from '@/views/Events/components/modals/NotifyAttendeeModal.vue'
import QueryEventModal from '@/views/Events/components/modals/QueryEventModal.vue'
import RejectEventModal from '@/views/Events/components/modals/RejectEventModal.vue'
import AcceptEventModal from '@/views/Events/components/modals/AcceptEventModal.vue'
import CustomToast from '@/components/core/CustomToast.vue'
export default {
  components: {
    PageRouteView,
    PageHeader,
    PageTabsVue,
    EventRouteModal,
    NotifyAttendeeModal,
    QueryEventModal,
    RejectEventModal,
    AcceptEventModal,
    CustomToast
  },
  data () {
    return {
      warning: false,
      event: {},
      loading: false,
      errorLoadingData: false, // error state if api request fails to fetch event information
      showEventRoute: false,
      rejectionLoading: false,
      acceptanceLoading: false,
      attendeesNotificationLoading: false,
      isLoadingGetRoutes: false,
      queryLoading: false,
      routeAssignmentLoader: false,
      eventStatus: '',
      routeAssigned: false,
      allRoutes: [],
      eventRoutes: [],
      bus: new Vue(),
      toastData: [
        {
          toastId: 'event-accepted-toast',
          variant: 'success',
          icon: 'fe fe-check check-icon',
          iconBgClass: 'icon-bg success',
          message: 'Event request has been accepted',
          toastBodyClass: 'body-success'
        },
        {
          toastId: 'event-rejected-toast',
          variant: 'success',
          icon: 'fe fe-check check-icon',
          iconBgClass: 'icon-bg success',
          message: 'Event request has been rejected',
          toastBodyClass: 'body-success'
        },
        {
          toastId: 'event-queried-toast',
          variant: 'success',
          icon: 'fe fe-check check-icon',
          iconBgClass: 'icon-bg success',
          message: 'Event request has been queried',
          toastBodyClass: 'body-success'
        },
        {
          toastId: 'event-route-toast',
          variant: 'success',
          icon: 'fe fe-check check-icon',
          iconBgClass: 'icon-bg success',
          message: 'Routes have been successfully assigned',
          toastBodyClass: 'body-success'
          
        },
        {
          toastId: 'request-success',
          variant: 'success',
          icon: 'fe fe-check check-icon',
          iconBgClass: 'icon-bg success',
          message: 'Request Successful.',
          toastBodyClass: 'body-success'
        },
        {
          toastId: 'attendee-route-notification',
          variant: 'success',
          icon: 'fe fe-check check-icon',
          iconBgClass: 'icon-bg success',
          message: 'Successfully sent to attendees',
          toastBodyClass: 'body-success'
          
        },
        {
          toastId: 'organizer-notification-success',
          variant: 'success',
          icon: 'fe fe-check check-icon',
          iconBgClass: 'icon-bg success',
          message: 'Organizer notified successfully',
          toastBodyClass: 'body-success'
        },
      ],
      menuTabs: [
        {
          routeName:'ShowEventInfo',
          label: 'Event information'
        },
        {
          routeName:'ShowAttendeeList',
          label: 'Attendees list'
        }
      ],
    }
  },
  computed: {
    eventDescription () {
      // this will return a dynamic value based one api model property and can be tweaked as preferred
      return 'Event Request'
    },
    renderHeader () {
      const notAllowedRoutes = ['ShowAcceptedEvent']

      return !notAllowedRoutes.includes(this.$route?.name)
    },

    isActionButtonDisabled() {
      return ['', 'rejected', ''].includes(this.eventStatus)
    },
    isAccepted() {
      if (['accepted'].includes(this.eventStatus)) {
        return true
      } 
      return false
    }
  },
  emits: ['acceptEvent', 'rejectEvent', 'queryEvent', 'notifyAttendees', 'assignRoutes', 'searchRoutes'],
  methods: {
    showModal (id) {
      this.$bvModal.show(id)
    },
    hideModal (id) {
      this.$bvModal.hide(id)
    },
    updateEventStatus(status) {
      this.eventStatus = status.toString()
    },
    updateRouteAssigned(status) {
      this.routeAssigned = status
    },
    updateRejectionLoading (value) {
      this.rejectionLoading = value
    },
    updateAcceptanceLoading (value) {
      this.acceptanceLoading = value
    },
    updateQueryLoading (value) {
      this.queryLoading = value
    },
    updateAttendeesNotificationLoading(value) {
      this.attendeesNotificationLoading = value
    },
    updateAllRoutes(value) {
      this.allRoutes = value
    },
    updateRouteAssignmentLoader(value) {
      this.routeAssignmentLoader = value
    },
    updateGetRoutesLoader(value) {
      this.isLoadingGetRoutes = value
    },
    updateEventRoutes(value) {
      this.eventRoutes = value
    },
    acceptEvent() {
      this.bus.$emit('acceptEvent')
    },
    rejectEvent (data) {
      this.bus.$emit('rejectEvent', data)
    },
    queryEvent (data) {
      this.bus.$emit('queryEvent', data)
    },
    notifyAttendees() {
      this.bus.$emit('notifyAttendees')
    },
    assignRoutes(data) {
      this.bus.$emit('assignRoutes', data)
    },
    searchRoutes(data) {
      this.isLoadingGetRoutes = true
      this.bus.$emit('searchRoutes', data)
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/_nav-tabs.scss';

.cont {
  position: relative;
}

.dropdown-item {
  color: #000
}

.cancel {
  flex: 1;

  outline: none;
  background: #fff;
  border: 1px solid #d0d5dd;
  color: #344054;
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
}

.proceed {
  flex: 1;

  outline: none;
  background: #101211;
  border: none;
  color: #fff;
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.content {
  font-size: 14px;
  color: #667085;
  text-align: center;
}

.inactive {
  color: #6e84a3;
}

.breadcrumb-item {
  & a {
    color: #6e84a3;

    &:hover {
      color: #09090f;
    }
  }

  &.active {
    font-weight: 500;
    color: #09090f;
  }
}
</style>
