<template>
  <div class="route-controller">
    <div class="">
      <div class="">
        <div class="">
          <div class="">
            <div class="card">
              <div class=" card-header row d-flex justify-content-between align-items-center">
                <div class="flex-row-reverse col-8 input-group input-group-flush">
                  <input v-model="routesFilter" class="form-control list-search" type="Search Routes"
                    @keyup.enter.prevent="loadRoutes(true)" placeholder="Search" />
                  <span class="border-0 input-group-text">
                    <i class="fe fe-search"></i>
                  </span>
                </div>
                <div class="flex items-center space-x-3" v-if="!hideCorporateSelector">
                  <div class="">
                    <v-select v-model="filter.corporateId" class="form-group" :options="companies" label="corporate_name"
                      :reduce="(company) => company.id" placeholder="Filter by corporates" style="width: 300px">
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col">
                  <label for="status_active" class="px-2 d-block-inline">
                    <input type="radio" v-model="statusFilter" name="status" :value="1" id="status_active" />
                    Active
                  </label>
                  <label for="status_inactive" class="px-2 d-block-inline">
                    <input type="radio" v-model="statusFilter" name="status" :value="0" id="status_inactive" />
                    Inactive
                  </label>
                </div>
                <div class="flex items-center col-auto" >
                  <div class="flex items-center gap-x-6">
                    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <div class="flex items-center gap-x-1">
                          <span class="inline text-xs font-bold">Type:</span>
                          <span class="inline text-xs font-bold">{{ route_type === 0 ? 'Shared' : route_type === 1 ? 'Exclusive' :'All' }}</span>
                          <span class="cursor-pointer"><svg
                              xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                              stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M6 9l6 6 6-6" />
                            </svg></span>
                        </div>
                      </template>

                      <div class="flex items-center justify-center">
                        <div class="flex flex-col justify-center gap-y-2">
                          <div class="flex items-center gap-2 gap-x-6">
                            <input @change="handleSelectedRouteVisibility($event, 'shared')" type="checkbox"
                              v-model="payloadData.shared">
                            <label class="m-0 text-sm">Shared</label>
                          </div>
                          <div class="flex items-center gap-2 gap-x-6">
                            <input @change="handleSelectedRouteVisibility($event, 'exclusive')" type="checkbox"
                              v-model="payloadData.exclusive">
                            <label class="m-0 text-sm">Exclusive</label>
                          </div>
                        </div>
                      </div>

                    </b-dropdown>

                    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <div class="flex items-center gap-x-1">
                          <span class="inline text-xs font-bold">Visibility:</span>
                          <span class="inline text-xs font-bold">{{ route_visibility ? route_visibility :
                            'All' }}</span>
                          <span class="cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                              viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round">
                              <path d="M6 9l6 6 6-6" />
                            </svg></span>
                        </div>
                      </template>

                      <div class="flex items-center justify-center">
                        <div class="flex flex-col justify-center gap-y-2">
                          <div class="flex items-center gap-2 gap-x-6">
                            <input @change="handleSelectedRouteVisibility($event, 'public')" type="checkbox"
                              v-model="payloadData.public">
                            <label class="m-0 text-sm">Public</label>
                          </div>
                          <div class="flex items-center gap-2 gap-x-6">
                            <input @change="handleSelectedRouteVisibility($event, 'private')" type="checkbox"
                              v-model="payloadData.private">
                            <label class="m-0 text-sm">Private</label>
                          </div>
                        </div>
                      </div>

                    </b-dropdown>
                  </div>
                  <div class="">
                    <download-button :downloading="downloadingReport" label="Download Report"
                      @download="downloadReport()">
                    </download-button>
                  </div>
                  <div>
                    <filter-button :options="cityFilters" :loading-text="'Loading cities'"
                      :loading="loadingRoutes && !cityFilters.length">
                      <template #label> City: {{ cityFilter.name }} </template>

                      <template #option="data">
                        <span @click="selectCity(data)">
                          {{ data.name }}
                        </span>
                      </template>
                    </filter-button>
                  </div>
                  <!-- </div> -->
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <b-table striped hover :items="routes" :fields="fields" :current-page="currentPage"
                      :busy="loadingRoutes" @row-clicked="openRoute" class='cursor-pointer'>
                      <template #table-busy>
                        <div class="my-2 text-center text-secondary">
                          <strong>Loading...</strong>
                        </div>
                      </template>

                      <template #cell(index)="data">
                        {{ data.index + 1 + (currentPage - 1) * perPage }}
                      </template>

                      <template #cell(status)="data">
                        <div>
                          <span class="badge badge-success" v-if="data.value == 1">
                            Active
                          </span>

                          <span class="badge badge-warning" v-if="data.value == 0">
                            InActive
                          </span>
                        </div>
                      </template>

                      <template #cell(route)="data">
                        <route-description :max-width="250" :pickup="data.item.pickup"
                          :destination="data.item.destination">
                        </route-description>
                      </template>

                      <template #cell(route_code)="data">
                        <div class="d-flex">
                          <div><span class="mr-2">{{ data.value }}</span></div>
                        </div>
                      </template>

                      <template #cell(type)="data">
                        <div class="d-flex">
                          <div>
                            <span class="mr-2 badge-sm badge exclusive-badge" v-if="data.item.is_exclusive == 1">
                              Exclusive
                            </span>
                            <span class="mr-2 badge-sm badge shared-badge" v-else>
                              Shared
                            </span> <br>
                            <span class="badge-sm badge" v-if="!!data.item.corporate_id">
                              private
                            </span>
                            <span class="badge-sm badge" v-else>
                              public
                            </span>
                          </div>

                        </div>
                      </template>

                      <template #cell(options)="data" v-if="!hideCorporateSelector">
                        <route-list-item-menu :route="data.item" />
                      </template>
                    </b-table>

                    <div class="card-footer">
                      <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"
                        aria-controls="my-table"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import RouteResource from '@/api/route'
import RouteListItemMenu from '@/components/modules/routes/RouteListItemMenu.vue'
import FilterButton from '@/components/core/FilterButton'
import DownloadButton from '@/components/core/DownloadButton'
import { ExportToCsv } from 'export-to-csv'
import { extractErrorMessage } from '@/utils/helpers'
import RouteDescription from '@/components/modules/routes/RouteDescription'

export default {
  components: { FilterButton, RouteListItemMenu, DownloadButton, RouteDescription, Multiselect },
  props: {
    corporateId: {
      required: false,
      type: [Number]
    },
    hideCorporateSelector: {
      required: false,
      type: [Boolean],
      default: () => false
    }
  },
  data() {
    return {
      payloadData: {
        public: false,
        private: false,
        shared: false,
        exclusive: false,
        allVisibility: false,
        allTypes: false
      },
      visibilityType: '',
      checkedRouteType: '',
      checkedRouteVisibility: '',
      showRouteVisibilityDropdown: false,
      showRouteTypeDropdown: false,
      selectedRouteType: '',
      selectedRouteVisibility: '',
      routeType: '',
      routeListTypes: [],
      perPage: 20,
      currentPage: 1,
      downloadingReport: false,
      fields: [
        {
          key: 'index',
          label: 'S/N'
        },
        {
          key: 'route',
          sortable: true,
          label: 'Route'
        },
        {
          key: 'route_code',
          label: 'Route Code',
          sortable: true
        },
        {
          key: 'type',
          label: 'Type'
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'options',
          label: ''
        }
      ],
      currentlyShowingFeature: null,
      routesFilter: '',
      centerPosition: null,
      activeRoute: null,
      zoomLevel: 15,
      showInfo: true,
      statusFilter: 1,
      infoWindowContext: {
        position: {
          lat: 44.2899,
          lng: 11.8774
        }
      },
      infoWindowsList: [],
      routes: [],
      addingBusStopMode: false,
      newBusStop: null,
      contextMenu: [
        {
          name: 'Add Bus Stop',
          id: 'addBusStop'
        }
      ],
      totalRecords: 0,
      loadingRoutes: false,
      cityFilter: {
        id: null,
        name: 'All Cities',
        city_id: null
      },
      cityFilters: [],
      companies: [],
      filter: {
        corporateId: ''
      }
    }
  },
  mounted() {
    this.filter.corporateId = this.corporateId
    this.$eventbus.$on('route:updated', this.onRouteUpdated)
    this.$eventbus.$on('route:deleted', this.onRouteDeleted)
    this.convertURLParamsToObject()
    this.loadRoutes()
    this.fetchCompanies()
  },
  watch: {
    currentPage() {
      this.loadRoutes()
    },
    statusFilter(value) {
      this.appendObjectToCurrentURL('status', value)
      this.loadRoutes(true)
    },
    routesFilter(value) {
      this.appendObjectToCurrentURL('q', value)
      if (!value) {
        this.loadRoutes(true)
      }
    },

    'payloadData.allTypes'() {
      this.loadRoutes(true)
    },

    'payloadData.allVisibility'() {
      this.loadRoutes(true)
    },

    'payloadData.public'(val) {
      this.loadRoutes(true)
    },

    'payloadData.private'(val) {
      this.loadRoutes(true)
    },

    'payloadData.shared'(val) {
      this.loadRoutes(true)
    },

    'payloadData.exclusive'(val) {
      this.loadRoutes(true)
    },

    'filter.corporateId'(value) {
      this.loadRoutes(true)
    }
  },
  computed: {
    filteredRoutes() {
      if (!this.routesFilter || this.routesFilter.trim() == '') {
        return this.routes.filter((r) => {
          return parseInt(r.status) === this.statusFilter
        })
      }

      if (this.corporateId) {
        return this.routes.filter((r) => {
          return r.corporate_id === this.corporateId
        })
      }

      return this.routes.filter((v) => {
        const r = new RegExp(this.routesFilter, 'i')
        return (
          (r.test(v.pickup) || r.test(v.destination) || r.test(v.route_code)) &&
          parseInt(v.status) === this.statusFilter
        )
      })
    },

    route_visibility() {
      return (!this.payloadData.private && !this.payloadData.public) ? '' : (this.payloadData.private && this.payloadData.public) ? '' : this.payloadData.private ? 'private' : this.payloadData.public ? 'public' : ''
    },

    route_type() {
      return (!this.payloadData.exclusive && !this.payloadData.shared) ? '' : (this.payloadData.exclusive && this.payloadData.shared) ? '' : this.payloadData.exclusive ? 1 : this.payloadData.shared ? 0 : ''

    },
  },
  methods: {
      handleSelectedRouteVisibility($event, item) {
        if (item === 'public' && $event.target.checked) {
          this.payloadData.public = true
        }

        if (item === 'public' && !$event.target.checked) {
          this.payloadData.public = false
        }

        if (item === 'shared' && !$event.target.checked) {
          this.payloadData.shared = false
        }

        if (item === 'shared' && $event.target.checked) {
          this.payloadData.shared = true
        }

        if (item === 'private' && $event.target.checked) {
          this.payloadData.private = true
        }

        if (item == 'private' && !$event.target.checked) {
          this.payloadData.private = false
        }

        if (item === 'exclusive' && $event.target.checked) {
          this.payloadData.exclusive = true
        }

        if (item === 'exclusive' && !$event.target.checked) {
          this.payloadData.exclusive = false
        }

      },

      async loadRoutes(reset = false) {
        try {
          if (reset) {
            this.currentPage = 1
          }
          const options = {
            params: {
              limit: this.perPage,
              page: this.currentPage,
              metadata: true,

              status: this.statusFilter,
              ...(this.routesFilter ? { search: this.routesFilter } : {}),
              ...(this.cityFilter?.city_id ? { city_id: this.cityFilter.city_id } : {}),
              ...((this.corporateId || this.filter.corporateId) ? { corporate_id: this.corporateId || this.filter.corporateId } : {}),
              ...(this.route_visibility ? { visibility: this.route_visibility } : {}),
              ...(this.route_type !== '' ? { is_exclusive: this.route_type } : {})
            }
          }
          this.$Progress.start()
          this.loadingRoutes = true
          const data = await RouteResource.listRoute(options)
          await this.getCities()
          const filteredArray = data?.data.filter((r) => {
            return r.corporate_id === this.corporateId
          })
          this.routes = !this.corporateId ? data?.data : filteredArray
          this.totalRecords = data?.metadata.total
          this.$Progress.finish()
          this.loadingRoutes = false

        } catch (e) {
          this.$Progress.fail()
          this.loadingRoutes = false
        }
      },
      convertURLParamsToObject() {

        const urlParams = this.$route.query
        this.statusFilter = urlParams?.status || 1
        this.routesFilter = urlParams?.q
        this.filter.corporateId = urlParams?.id
      },
      openRoute(item) {
        this.$router.push({ name: 'ShowRoute', params: { routeId: item.id } })
      },
      onRouteUpdated(v) {
        if (!v || !this.routes) return
        const indexOfV = this.routes.findIndex((o) => {
          return o.id === v.id
        })
        if (indexOfV > -1) {
          this.$set(this.routes, indexOfV, v)
        }
      },
      onRouteDeleted(v) {
        if (!v || !this.routes) return
        const indexOfV = this.routes.findIndex((o) => {
          return o.id === v.id
        })
        if (indexOfV > -1) {
          this.routes.splice(indexOfV, 1)
        }
      },
      async getCities() {
        if (this.cityFilters.length) {
          return
        }
        try {
          const cityData = await RouteResource.getCities()
          this.cityFilters = [
            { id: null, name: 'All Cities', city_id: null },
            ...cityData.data
          ]
        } catch (e) {

        }
      },

      appendObjectToCurrentURL(key, value) {
        let url = window.location.href
        let queryString = ''
        const hasQueryString = url.includes('?')
        if (hasQueryString) {
          const [baseUrl, existingQueryString] = url.split('?')
          const params = new URLSearchParams(existingQueryString)
          if (params.has(key)) {
            params.delete(key)
          }
          queryString = params.toString()
          if (queryString.length > 0) {
            queryString += '&'
          }
        }
        if (value) {
          queryString += encodeURIComponent(key) + '=' + encodeURIComponent(value)
        }
        url = hasQueryString ? url.split('?')[0] + '?' + queryString : url + '?' + queryString
        window.history.replaceState(null, null, url)
      },

      selectCity(data) {
        if (data.city_id === this.cityFilter.city_id) {
          return
        }
        this.cityFilter = data
        this.loadRoutes(true)
      },
      fetchCompanies() {
        this.axios.get('/v1/corporates?limit=1000').then((res) => {
          this.companies = res?.data?.models
        })
      },
      async downloadReport() {
        try {
          this.downloadingReport = true
          const options = {
            params: {
              limit: this.totalRecords,
              metadata: true,
              status: this.statusFilter,
              ...(this.routesFilter ? { search: this.routesFilter } : {}),
              ...(this.cityFilter?.city_id
                ? { city_id: this.cityFilter.city_id }
                : {}),
              corporate_id: this.filter.corporateId ? this.filter.corporateId : this.corporateId ? this.corporateId : ''
            }
          }

          const data = await RouteResource.listRoute(options)
          const csvData = data.data.map((route) => {
            return {
              pickup: route.pickup,
              destination: route.destination,
              routeCode: route.route_code,
              status: route.status === 1 ? 'Active' : 'Inactive'
            }
          })
          const docName = 'Routes Report'
          const csvParams = {
            filename: docName,
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: docName,
            useTextFile: false,
            useBom: true,
            headers: ['Pickup', 'Destination', 'Route Code', 'Status']
          }
          const csvExporter = new ExportToCsv(csvParams)
          csvExporter.generateCsv(csvData)
          this.$swal({
            icon: 'success',
            title: 'Report downloaded',
            text: 'Report has been downloaded successfully',
            showCloseButton: true
          })
        } catch (e) {
          this.$swal({
            icon: 'error',
            title: 'An error occurred!',
            text: extractErrorMessage(e, 'Failed to download report'),
            showCloseButton: true
          })
        } finally {
          this.downloadingReport = false
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.exclusive-badge {
  background: #FAFAFF;
  color: #4848ED;
}

.shared-badge {
  background: #FFFCEB;
  color: #B89E00;
}

.route-controller .adding-bus-stop {
  cursor: url('https://firebasestorage.googleapis.com/v0/b/android-driver-production.appspot.com/o/public%2Fbus-stop-end-sm.png?alt=media'),
    crosshair;
}

.pointer {
  cursor: pointer;
}

.input-group {
  background: white;
  border-radius: 5px;
}

.badge-xs.badge {
  padding: 0.175em 0.6em;
  font-size: 60%;
  font-weight: 300;
}
</style>
