import { loadRouteComponent } from '@/utils/helpers'
import Fleets from '@/layouts/modules/Fleets/Index.vue'
import FleetDetails from '@/layouts/modules/Fleets/FleetDetails.vue'
const FleetsRoutes = [
  {
    path: 'fleet',
    component: Fleets,
    children: [
      {
        path: '',
        name: 'Fleet',
        redirect: 'list'
      },
      {
        path: 'list',
        component: loadRouteComponent('Fleet/pages/List'),
        name: 'FleetList',
        props: true,
        meta: {
          requiredPermissions: ['vehicles002']
        },
        children: [
          {
            path: 'vehicle',
            component: loadRouteComponent('Fleet/pages/VehicleList'),
            name: 'FleetVehicleList',
            props: true,
            meta: {
              requiredPermissions: ['vehicles002']
            },
          },
            {
            path: 'inspection-days',
            name: 'InspectionDays',
            component: loadRouteComponent('Fleet/pages/InspectionDaysList'),
            props: true,
          },
          ]
      },
      {
        path: 'add',
        component: loadRouteComponent('Fleet/pages/Create'),
        name: 'CreateVehicle',
        props: true,
        meta: {
          requiredPermissions: ['vehicles001']
        }
      },
      {
        path: ':vehicleId',
        component: FleetDetails,
        props: true,
        children: [
          {
            path: 'vehicle-info',
            name: 'ShowVehicle',
            component: loadRouteComponent('Fleet/Show/VehicleInfo'),
            props: true,
            meta: {
              requiredPermissions: ['vehicles003']
            }
          },
          {
            path: 'vehicle-document-info',
            name: 'ShowVehicleDocument',
            component: loadRouteComponent('Fleet/Show/VehicleDocumentInfo'),
            props: true,
            meta: {
              requiredPermissions: ['vehicles003']
            }
          },
          {
            path: 'trips',
            name: 'ShowVehicleTripHistory',
            component: loadRouteComponent('Fleet/Show/RideHistory'),
            props: true,
            meta: {
             requiredPermissions: ['vehicles012']
            }
          },
          {
            path: 'customer-ratings',
            name: 'ShowVehicleCustomerRatings',
            component: loadRouteComponent('Fleet/Show/CustomerRatings'),
            props: true,
            meta: {
               requiredPermissions: ['vehicles003']
            }
          }
        ]
      }
    ]
  }
]

export default FleetsRoutes
