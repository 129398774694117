import {ref} from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import Swal from 'sweetalert2'

const loading = ref(false)
const chargeTypes = ref([])
const chargeTypeMetadata = ref(null)
const currentPage = ref(1)
const perPage = ref(10)
const search = ref('')

const allChargeTypes = ref([])
export const useFetchChargeTypes = () => {
	const fetchAllChargeTypes = async () => {
		loading.value = true
		try {
			const res = await axios.get(`/v1/additional-charges-types?limit=${perPage.value}&page=${currentPage.value}&search=${search.value}`)
			if(res.data?.data?.length){
				chargeTypes.value = res.data.data
				chargeTypeMetadata.value = res.data.metadata
			} else {
				chargeTypes.value = []
			}
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		} finally{
			loading.value = false
		}
	}

	const fetchAllChargeTypesWithoutLimit = async () => {
		loading.value = true
		try {
			const res = await axios.get(`/v1/additional-charges-types?limit=${perPage.value}&page=1`)
			if(res.data?.data?.length){
				const total = res.data.metadata.total
				const resp = await axios.get(`/v1/additional-charges-types?limit=${total}&page=1`)
				allChargeTypes.value = resp.data.data.length ? resp.data.data : []
			} else {
				allChargeTypes.value = []
			}
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		} finally{
			loading.value = false
		}
	}

	return {loading, chargeTypes, fetchAllChargeTypes, chargeTypeMetadata, currentPage, perPage, search, allChargeTypes, fetchAllChargeTypesWithoutLimit}
}