<template>
	<div :class="{ 'flex flex-col gap-4': showBadge }">
		<div class="avatar" :class="`avatar-${size}`">
			<template v-if="user?.avatar">
				<ImageZoom styleClass="avatar-sm" v-if="expandable">
					<template v-slot:image>
						<img :src="user?.avatar" alt="Avatar" class="avatar-img rounded-circle" />
					</template>
				</ImageZoom>
				<img v-else :src="user?.avatar" alt="Avatar" class="avatar-img rounded-circle" />
			</template>

			<span class="avatar-title rounded-circle" v-else>
				{{ `${user?.fname} ${user?.lname}` | initials }}
			</span>
		</div>
		<span class="badge new-user-badge" v-if="showBadge">
			{{ badgeText }}
		</span>
	</div>
</template>

<script>
import ImageZoom from '@/components/core/ImageZoom'
export default {
	components: { ImageZoom },
	props: {
		user: Object,
		showBadge: Boolean,
		badgeText: String,
		expandable: {
			type: Boolean,
			default: true
		},
		size: {
			type: String,
			validator: (value) => ['sm', 'md'].includes(value)
		}
	}
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/partials/sh-colors';

.new-user-badge {
	margin-top: -0.5rem;
	z-index: 1;
	margin-left: auto;
	margin-right: auto;
	color: #FFFFFF;
	background: sh-colors.$sh-green-700;
}
</style>
