import { loadRouteComponent } from '@/utils/helpers'
import Charter from '@/layouts/modules/Charter/Index.vue'
const CharterRoutes = [
  {
    path: 'charter',
    component: Charter,
    children: [
      {
        path: 'list',
        component: loadRouteComponent('Charter/List'),
        name: 'ChartersList',
        props: true,
        meta: {
          requiredPermissions: [
             'charter004'
          ]
        }
      },
      {
        path: 'create',
        component: loadRouteComponent('Charter/pages/addCharter'),
        name: 'CreateCharter',
        // props: true,
        meta: {
          requiredPermissions: [
            //  'charter004'
          ]
        }
      },
      {
        path: ':charterId',
        component: loadRouteComponent('Charter/pages/Show'),
        name: 'ShowCharter',
        props: true,
        meta: {
          requiredPermissions: [
            'charter001'
          ]
        }
      }
    ]
  }
]

export default CharterRoutes
