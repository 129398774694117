<template>
	<div
		id="app"
		class="d-flex align-items-center bg-auth"
		:class="isAuthUrl ? 'border-top border-top-2 border-primary' : ''"
		>
		<router-view />
		<vue-progress-bar></vue-progress-bar>
		<modal />
	</div>
</template>

<script>
import modal from '@/components/core/modal.vue'
export default {
  computed: {
    isAuthUrl () {
      return this.$route.path.indexOf('login') > 0
    }
  },
  components: { modal },
  mounted () {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish()
  },
  created () {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, _, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      this.$Progress.finish()
    })
  }
}
</script>

<style lang="scss" scoped>
#app {
  min-height: 100vh;
}

.nav.nav-pills a.nav-link {
  color: #333;
}

.nav-link.active {
  background-color: rgba(0, 0, 0, 0.05);
}

:root {
  --primary: #01e069;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
