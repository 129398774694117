<template>
    <aside class="sidebar">
        <div class="sidebar-header">
            <router-link to="/">
                <inline-svg class="img" :src="SidebarData.logoUrl"></inline-svg>
            </router-link>
            <button ref="menuToggle" type="button" class="hidden">
                <inline-svg class="img" :src="SidebarData.menuUrl"></inline-svg>
            </button>
        </div>
        <div class="sidebar-menus">
            <ul class="nav">
                <li v-for="main in SidebarData.menuList">
                    <label class="navbar-label" v-if="main.title">{{ main.title }}</label>
                    <template v-for="(menu, index) in main.menus.value">
                        <sidebar-menu :key="`${index}_system`" :menu="menu" :route='route'></sidebar-menu>
                    </template>
                </li>
            </ul>
        </div>
        <div class="sidebar-footer d-none d-md-block">
            <div class="user-box" id="sidebarUser" v-if="currentUser">
                <div class="avatar avatar-sm w-auto">
                    <router-link class="flex items-center" to="/profile" title="View your profile">
                        <div class="w-10 h-10 flex-1 ">
                            <img :src="currentUser.avatar" alt="Avatar" class="avatar-img rounded-circle" v-if='currentUser.avatar' />
                            <div class="avatar-title rounded-full" v-else>
                                {{ getInitials(currentUser.fname, currentUser.lname) }}
                            </div>
                        </div>

                        <span class="name ml-2">{{ currentUser.fname }}</span>
                    </router-link>
                </div>
                <span class="icon" title="Logout" @click="signOutFunction()">
                    <i class="fe fe-log-out"></i>
                </span>
            </div>
        </div>
    </aside>
</template>

<script setup>
import InlineSvg from 'vue-inline-svg'
import SidebarMenu from '@/components/layout/sidebar/SidebarMenuItem'
import { watch } from 'vue'

const getInitials = (string1, string2) =>{
    const initials = string1[0] + string2[0]
    return initials.toUpperCase()
}

const props = defineProps({
    SidebarData: {
        type: Object,
        required: true,
    },
    signOutFunction: {
        type: Function,
        required: true,
    },
    currentUser: {
        type: Object,
        required: true,
    },
    route: {
        type: Object,
        required: true
    }
})
watch(
    props.route,
    (value) =>
    {
        resetMenus()
    },
    { deep: true }
)

const pathContainsRoot = (rootPath) =>
{
    if (rootPath === '/users') {
        return props.route.path.startsWith(rootPath) || props.route.path.startsWith('/bookings')
    }
    if (rootPath === '/routes') {
        return (
            props.route.path.includes(rootPath) ||
            props.route.path.startsWith('/trips') ||
            props.route.path.startsWith('/waitlist')
        )
    }
    return props.route.path.includes(rootPath)
}
const isRouteActive = (menu) =>
{
    return props.route.matched.findIndex((opt) => opt.name === menu.routeName) > -1
}
const resetMenus = () =>
{
    props.SidebarData.menuList.forEach((main_menu) =>
    {
        main_menu.menus.value.forEach((menu) =>
        {
            if (menu.isOpen) {
                if (!isRouteActive(menu) && !pathContainsRoot(menu.rootPath)) {
                    menu.isOpen = false
                }
            }
        })
    })

}



</script>



<style lang="scss" scoped>
@use 'src/assets/scss/partials/sh-colors' as colors;
@use 'src/assets/scss/partials/layout';

.avatar-title {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #244166;
    color: #FFFFFF;
}

.sidebar {
    border-bottom: 1px solid colors.$sh-neutral-400;

    .sidebar-header {
        padding: 1rem 1.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //background-color: transparentize(colors.$sh-green-50, 0.6);
    }

    .sidebar-menus {
        padding: 1rem 1rem;
        margin: 0;

        &:before {
            display: none;
        }
    }

    .sidebar-footer {
        padding: 1rem;

        .user-box {
            background: transparentize(colors.$sh-green-900, 0.9);
            border-radius: 0.625rem;
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: colors.$sh-gray-3;
            }

            & .avatar-online::before {
                z-index: 1;
            }

            & .icon {
                cursor: pointer;
            }
        }
    }

    .nav {
        flex-direction: column;
        width: 100%;
    }

    .navbar-label {
        font-weight: 500;
        font-size: 0.6875rem;
        line-height: 1.25rem;
        letter-spacing: 0.04em;
        color: colors.$sh-neutral-400;
        padding-left: 0.5rem;
        text-transform: uppercase;
        margin-bottom: 0;
        margin-top: 0.5rem;
    }

    @media screen and (min-width: 768px) {
        width: layout.$sidebar-width;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        overflow: auto;
        border-right: 1px solid colors.$sh-gray-9;
        border-bottom: none;

        .sidebar-header {
            padding: 1.5rem 1.25rem;
        }

        .sidebar-menus {
            display: flex !important;
        }
    }
}
</style>
