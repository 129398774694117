<template>
	<div class="container-fluid">
		<b-breadcrumb :items="items"></b-breadcrumb>
	</div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  //  staff: {
  //     type: Object,
  //     required: true,
  //   },
  }
}
</script>

<style scoped>
</style>
