<template>
	<div class="container-fluid">
		<template v-if="currentRouteItinerary">
			<div class="row mt-5" >
				<div class="col-12">
					<div class="card">
						<div class="card-body">
							<h3 class="card-title">
								{{ currentRouteItinerary.trip_time }} Trip Time 
							</h3>

							<div class="mt-4">
								<table class="table table-striped">
									<tbody>
										<tr>
											<td>Starting Time</td>
											<td>
												<div>
													<div v-if="edits.$trip_time">
														<div class="d-flex flex-row">
															<div>
																<vue-timepicker
																	format="h:mm A"
																	hide-clear-button
																	v-model="tempTripTime"
																	input-class="form-control"
																	></vue-timepicker>
															</div>

															<div>
																<span
																	@click="onUpdateTripTime"
																	class="btn btn-sm btn-primary ml-2"
																	>Save</span
																	>
																<span
																	@click="edits.$trip_time = false"
																	class="btn btn-sm btn-link ml-2"
																	>Cancel</span
																	>
															</div>
														</div>
													</div>

													<div v-else  class="d-flex flex-row">
														<div>{{ currentRouteItinerary.trip_time }}</div>
														<div>
															<span
																@click="editRouteItineraryTripTime"
																class="text-info ml-2"
																>Edit</span
																>
														</div>
													</div>
												</div>
											</td>
										</tr>

										<tr>
											<td>Starting Bus Stop</td>
											<td>
												<div v-if="edits.$pickup_id">
													<div class="d-flex flex-row">
														<div>
															<select
																class="custom-select custom-select-sm"
																v-model="currentRouteItinerary.pickup_id"
																>
																<option
																	v-for="(b, i) in routeBusStops"
																	:value="b.id"
																	:key="i"
																	>
																	{{ b.name }}
																</option>
															</select>
														</div>

														<div>
															<span
																@click="onUpdatePickup"
																class="btn btn-sm btn-primary ml-2"
																>Save</span
																>
															<span
																@click="edits.$pickup_id = false"
																class="btn btn-sm btn-link ml-2"
																>Cancel</span
																>
														</div>
													</div>
												</div>

												<div class="d-flex flex-row" v-else>
													<div class="">
														{{
															currentRouteItinerary.starting_bus_stop
																? currentRouteItinerary.starting_bus_stop.name
																: ' - '
														}}&nbsp;
													</div>
													<div>
														<span
															@click="editRouteItineraryPickup"
															class="text-info ml-2"
															>Edit</span
															>
													</div>
												</div>
											</td>
										</tr>

										<tr>
											<td>Total Capacity</td>
											<td>
												{{currentRouteItinerary.total_seats || 'Not Available'}}
											</td>
										</tr>

									</tbody>
								</table>
							</div>
						</div>
					</div>

					<ItineraryPricingSettings :routeItinerary="currentRouteItinerary">

					</ItineraryPricingSettings>

					<itinerary-pricing
						:routeId="routeId"
						:itineraryId="itineraryId"
						:currentRouteItinerary="currentRouteItinerary"
						:routeBusStops="routeBusStops"
						></itinerary-pricing>

					<itinerary-driver-settings
						:itinerary="currentRouteItinerary">
					</itinerary-driver-settings>

				</div>
			</div>

		</template>
		<template v-else-if="loadingRouteItinerary">
			<div class="d-flex py-5 justify-content-center align-items-center w-100">
				<div class="spinner-border" role="status"></div>
			</div>
		</template>
	</div>
</template>

<script>
import _ from 'lodash'
import VueTimepicker from 'vue2-timepicker'
import ItineraryDriverSettings from '@/views/Routes/components/itinerary/ItineraryDriverSettings'
import ItineraryPricingSettings from '@/views/Routes/components/itinerary/ItineraryPricingSettings'
import drpService from './itenerary/drp.service.js'
import { validationMixin } from 'vuelidate'
import itineraryPricing from './itenerary/itinerary-pricing.vue'

export default {
  components: { ItineraryDriverSettings, ItineraryPricingSettings, VueTimepicker, itineraryPricing },
  mixins: [validationMixin],
  props: {
    routeId: {
      type: [String, Number],
      required: true
    },
    itineraryId: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      tempTripTime: '',
      newVehicles: {
        vehicles: []
      },
      vehicles: [],
      currentRouteItinerary: null,
      loadingBusStops: false,
      loadingRouteItinerary: false,
      routeBusStops: [],
      edits: {
        $pickup_id: false,
        $trip_time: false
      },
      newItinerary: {
        time: null,
        pricingTable: []
      },
      isErrored: false,
      triggerPricingUpdate: false
    }
  },
  provide () {
    return {
      currentItinerary: () => this.currentRouteItinerary,
      busStops: () => this.routeBusStops

    }
  },
  mounted () {
    this.initialize()
  },
  watch: {
    $route: function () {
      this.initialize()
    }
  },
  methods: {
    initialize () {
      this.loadCurrentRouteItinerary(true)
    },
    editRouteItineraryPickup () {
      this.edits.$pickup_id = !this.edits.$pickup_id
    },
    editRouteItineraryTripTime () {
      this.edits.$trip_time = !this.edits.$trip_time
    },
    onUpdatePickup () {
      this.edits.$pickup_id = false
      this.updateRouteItinerary()
    },
    onUpdateTripTime () {
      if (this.tempTripTime.trim() == '') return
      this.currentRouteItinerary.trip_time = this.tempTripTime
      const timeParts = this.tempTripTime.split(' ')
      this.currentRouteItinerary.departure_time = timeParts[0]
      this.currentRouteItinerary.time_of_day = timeParts[1]
      this.edits.$trip_time = false
      this.updateRouteItinerary()
    },
    onUpdate () {
      this.updateRouteItinerary()
    },
    async updateRouteItinerary () {
      if (!this.itineraryId) return
      this.$Progress.start()
      this.loadingRouteItinerary = true
      try {
        const result = await this.axios.patch(
            `/v1/route-itineraries/${this.itineraryId}`,
            this.currentRouteItinerary
        )
        await drpService.updateItinerary(
          this.itineraryId,
          {
            startTime: this.currentRouteItinerary.trip_time,
            startBusstopId: this.currentRouteItinerary.starting_bus_stop.id
          }
        )
        this.currentRouteItinerary = { ...this.currentRouteItinerary, ...result.data }
        this.setItineraryVehicleMap(this.currentRouteItinerary)

        this.loadingRouteItinerary = false
        this.$Progress.finish()
      } catch (e) {
        
        this.loadingRouteItinerary = false
        this.$Progress.fail()
      }
    },
    loadCurrentRouteItinerary (initialLoad = false) {
      if (!this.itineraryId) return
      this.loadingRouteItinerary = true
      this.$Progress.start()
      this.axios.get(`/v1/route-itineraries/${this.itineraryId}`).then((response) => {
        this.currentRouteItinerary = response.data
        this.tempTripTime = this.currentRouteItinerary.trip_time
        if (initialLoad) {
          this.fetchBusStops()
        }
        this.$Progress.finish()
      }).catch(() => {
        this.$Progress.fail()
      }).finally(() => {
        this.loadingRouteItinerary = false
      })
    },

    handleChoosePriceType (value) {
      this.shouldUpdatePricingType = false
      this.pricingType = value
    },

    async fetchBusStops () {
      if (!this.routeId) return
      this.$Progress.start()
      this.loadingBusStops = true
      try {
        const result = await this.axios.get(
            `/v1/routes/${this.routeId}/busstops`
        )

        let routeBusStops = _.sortBy(result.data.data, 'position')
        if (
          this.currentRouteItinerary &&
            this.currentRouteItinerary.starting_bus_stop
        ) {
          routeBusStops = routeBusStops.filter((v) => {
            return (
              v.position >=
                this.currentRouteItinerary.starting_bus_stop.position
            )
          })
        }
        this.routeBusStops = routeBusStops
        this.loadingBusStops = false
        this.$Progress.finish()
      } catch (e) {
        this.loadingBusStops = false
        this.$Progress.fail()
      }
    },

    extractErrorMessage (error) {
      return error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : error.toString()
    }
  }
}
</script>
