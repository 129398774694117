var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_vm._m(0)]):_c('div',{staticClass:"bg-white border-bottom"},[_c('div',{staticClass:"header mb-0"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"header-body py-0 mt-4 border-0"},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"header-pretitle"},[_vm._v("Driver Overview")]),_c('h1',{staticClass:"header-title"},[_vm._v(" "+_vm._s(_vm.currentDriver ? `${_vm.currentDriver.fname} ${_vm.currentDriver.lname}` : '')+" ")])]),_c('div',{staticClass:"col-auto"})])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row pt-3 pb-1"},[_c('div',{staticClass:"col justify-self-center align-self-center flex-column"},[_c('ul',{staticClass:"nav nav-pills"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowDriver',
										props: { driverId: _vm.$route.params.driverId },
									},"active-class":"active"}},[_vm._v("Driver Info")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowDriverCustomerRatings',
										props: { driverId: _vm.$route.params.driverId },
									},"active-class":"active"}},[_vm._v("Customer Ratings")])],1)])])])])]),_c('div',{staticClass:"pt-4"},[_c('div',{staticClass:"container-fluid"},[(!_vm.loading)?_c('router-link',{staticClass:"btn btn-light mb-4 btn-sm",attrs:{"to":"/drivers"}},[_vm._v("Go back")]):_vm._e(),_c('router-view')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center text-center p-4"},[_c('span',{staticClass:"fe fe-spinner"},[_vm._v("Loading..")])])
}]

export { render, staticRenderFns }