<template>
  <div>
    <b-modal
      :id="modalId"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @hidden="resetForm"
    >
      <template #modal-title> Configure point </template>
      <div class="configure-point py-2">
        <div>
          <label for="range-1">Naira equivalent</label>
          <b-form-input
            class="input"
            v-model="form.pointRate"
            placeholder="Enter points"
          ></b-form-input>
        </div>
        <p class="evaluation">
          <span>1 point</span>
          <span>~</span>
          <span>₦ {{ form.pointRate ? form.pointRate  : '--'  }}</span>
        </p>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            :disabled="!form.pointRate"
            class="float-right mr-2 button w-100"
            @click="openConfigurationConfirmationModal"
          >
            {{ loading ? 'Processing...' : 'Configure' }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { reactive } from 'vue'

export default {
  name: 'ConfigurePointModal',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: reactive({
        pointRate: null,
      }),
      modalId: 'configure-point-modal',
      isValid: null,
      rewardOptions: [],
    }
  },
  computed: {
    isDisabled() {
      if (!this.form.description || this.$props.loading) {
        return true
      }
      return false
    },
  },
  emits: ['onSetPointRate'],
  methods: {
    checkValidity() {
      if (!this.form.description) {
        this.isValid = false
        return
      }
      this.isValid = null
    },

    resetForm() {
      this.form.description = null
      this.isValid = null
    },

    openConfigurationConfirmationModal() {
      this.$emit('onSetPointRate', this.form.pointRate)
      this.$bvModal.hide('configure-point-modal')
      this.$bvModal.show('configure-confirmation-modal')
      //   this.$emit('onQueryEvent', this.form)
    },

    search() {
      return
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  &:disabled {
    opacity: 40%;
    cursor: not-allowed;
  }
}

.configure-point {
  & div {
    margin-bottom: 7px;

    & label {
      font-size: 12px;
      margin-bottom: 8px;
    }

    & .input {
      height: 50px;

      & :first-child {
        height: 100% !important;
        font-size: 0.9375rem !important;
      }
    }
  }

  & .evaluation {
    background: var(--neut-100, #f4f5f4);
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-radius: 6px;
    color: #6e717c;

    & :nth-child(2) {
      color: #000005;
    }
  }

  // & :first-child {
  //     margin-bottom: 16px
  // }
}

.description {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  & .alert-circle {
    font-size: 16px;
    color: #0dac5c;
  }

  & .text {
    font-size: 12px;
    color: #737876;
  }
}
</style>
