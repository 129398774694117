<template>
  <router-link :to="menu.routeName ? { name: menu.routeName } : { path: menu.rootPath }">
    <template v-slot="{ isActive, isExactActive, href }">
      <li class="nav-menu" :class="{ 'nav-menu--open': menu.isOpen, 'nav-menu--expandable': hasSubMenus(menu), 'nav-menu--active': isActive || pathContainsRoot(menu.rootPath) }">
        <template v-if="hasSubMenus(menu)">
          <div class="nav-title" @click="toggleMenuOpenedState(menu)">
            <span class="d-flex align-items-center">
              <inline-svg class="img" :src="menu.iconPath"></inline-svg>
              <span>{{ menu.title }}</span>
            </span>
            <i class="float-right fe fe-chevron-up" v-if="menu.isOpen"></i>
            <i class="float-right fe fe-chevron-down" v-else></i>
          </div>
          <ul class="nav-submenus">
            <span v-for="(submenu, submenuIndex) in menu.children" :key="submenuIndex">
              <router-link v-if='!submenu.isExternal' custom exact-active-class="nav-submenu--active" active-class="nav-submenu--active" :to="{ name: submenu.routeName }">
                <template v-slot="{ isActive, href }">
                  <li class="nav-submenu" :class="{ 'nav-submenu--active': isActive && excludedPathsIgnored(submenu) }">
                    <router-link :to="href">
                      {{ submenu.title }}
                    </router-link>
                  </li>
                </template>
              </router-link>
              <span v-else class="nav-submenu" @click='submenu.calling_function'>
                    {{ submenu.title }}
              </span>
            </span>

          </ul>
        </template>
        <template v-else>
          <router-link v-if="!menu.isExternal" :to="href">
            <div class="nav-title">
              <span class="d-flex align-items-center">
                <inline-svg v-if="menu.iconPath" class="img" :src="menu.iconPath">
                </inline-svg>
                <i class="img fe" :class="menu.feIcon" v-else></i>
                <span>{{ menu.title }} </span>
              </span>
            </div>
          </router-link>
          <div v-else class="nav-title" @click='menu.calling_function'>
            <span class="d-flex align-items-center">
              <inline-svg v-if="menu.iconPath" class="img" :src="menu.iconPath">
              </inline-svg>
              <i class="img fe" :class="menu.feIcon" v-else></i>
              <span>{{ menu.title }} </span>
            </span>
          </div>
        </template>
      </li>
    </template>
  </router-link>
</template>

<script setup>
import InlineSvg from 'vue-inline-svg'
import { defineProps, watch, ref } from 'vue'



const props = defineProps({
  menu: {
    type: Object,
    required: true
  },
  route: {
    type: Object,
    required: true
  }
})
const currentRoute = ref(props.route)

watch(props.route, (value) =>
{
  currentRoute.value = value
})
const toggleMenuOpenedState = (menu) =>
{
  menu.isOpen = !menu?.isOpen
}
const hasSubMenus = (menu) =>
{
  return menu?.children?.length
}
const pathContainsRoot = (rootPath) =>
{
  if (rootPath === '/users') {
    return currentRoute.value.path.startsWith(rootPath) || currentRoute.value.path.startsWith('/bookings')
  }
  if (rootPath === '/companies') {
    return currentRoute.value.path.startsWith(rootPath) || currentRoute.value.path.startsWith('/request')
  }
  if (rootPath === '/routes') {
    return currentRoute.value.path.includes(rootPath) || currentRoute.value.path.startsWith('/trips') ||
      currentRoute.value.path.startsWith('/waitlist') || currentRoute.value.path.startsWith('/charter')
  }
  return currentRoute.value.path.includes(rootPath)
}
const excludedPathsIgnored = (submenu) =>
{
  if (submenu.excludedPaths && submenu.excludedPaths?.length) {
    return !Array.from(submenu.excludedPaths).includes(currentRoute.value.path)
  }
  return true
}

</script>


<style lang="scss" scoped>
@use "src/assets/scss/partials/sh-colors" as colors;

.nav-menu {
  color: colors.$sh-neutral-600;
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
  position: relative;

  .nav-title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    color: colors.$sh-neutral-600;

    .img {
      $icon-dimension: 1.25rem;
      height: $icon-dimension;
      width: $icon-dimension;
      margin-right: .5rem;

      &.fe {
        font-size: $icon-dimension;

        &:before {
          margin-top: -0.25rem;
          display: block;
        }
      }
    }
  }

  .nav-submenus {
    transition: height .35s ease;
    padding-left: 2rem;
    max-height: 0;
    overflow: hidden;
  }

  .nav-submenu {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    margin: 0.625rem 0;
    cursor: pointer;
    list-style-type: none;
    display: flex;
    width: 100%;
    position: relative;

    &::marker {
      font-weight: 700;
      font-size: 1rem;
    }

    a {
      // Overrides the default color on a tags for the router link
      color: white;
      width: 100%;
    }

    &:hover,
    &--active {
      a {
        color: colors.$sh-green-500;
      }
    }

    &--active {
      &:after {
        top: 0.05rem;
        bottom: 0.05rem;
        right: 0;
        left: auto;
        position: absolute;
        border-radius: 10px;
        border-right: 4px solid colors.$sh-green-500;
        border-bottom: 0;
        content: "";
      }

    }
  }

  &:not(&--open):not(&--active):hover {
    & .nav-title {
      color: colors.$sh-neutral-900;
    }
  }

  &--open,
  &--active {
    .nav-title {
      color: white;
    }
  }

  &--active {

    &:not(.nav-menu--open) {
      color: colors.$sh-green-500;

      &+.nav-menu--open {
        margin-top: 0.5rem;
      }

      &:after {
        top: 0.75rem;
        bottom: 0.75rem;
        right: 0;
        left: auto;
        position: absolute;
        border-radius: 10px;
        border-right: 4px solid colors.$sh-green-500;
        border-bottom: 0;
        content: "";
      }
    }

    background-color: colors.$sh-neutral-900;
  }

  &--open {
    color: white;
    background-color: colors.$sh-neutral-900;

    &:not(:last-of-type) {
      margin-bottom: .5rem;
    }

    .nav-title {
      font-weight: bold;
    }

    .nav-submenus {
      display: block;
      max-height: 20rem;
    }
  }

  &--open.nav-menu--expandable {
    padding-right: 0;

    & .nav-title,
    .nav-submenu {
      padding-right: 1rem;
    }
  }
}
</style>
