import { loadRouteComponent } from '@/utils/helpers'
import Waitlist from '@/layouts/modules/Waitlist/Index.vue'
const WaitlistRoutes = [
  {
    path: 'waitlist',
    component: Waitlist,
    children: [
      {
        path: '',
        name: 'WaitList',
        component: loadRouteComponent('Waitlist/pages/List/WaitListSummary')
      },
      {
        path: 'users',
        name: 'WaitListDetail',
        component: loadRouteComponent('Waitlist/pages/List/WaitListDetail')
      }
    ]
  }
]

export default WaitlistRoutes
