import adminSession from '@/services/session'

export default {
  async login ({ commit }, payload) {
    const result = await adminSession.login(payload)
    commit('session/setCurrentUser', result, { root: true })
    return result
  },

  async otpLogin ({ commit }, data) {
    const result = await adminSession.otpLogin(data)
    commit('session/setCurrentUser', result, { root: true })
    return result
  }
}
