const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
]

export function months (config) {
  const cfg = config || {}
  const count = cfg.count || 12
  const section = cfg.section
  const values = []
  let i, value

  for (i = 0; i < count; ++i) {
    value = MONTHS[Math.ceil(i) % 12]
    values.push(value.substring(0, section))
  }

  return values
}

export function getYearlyBarChartData (data) {
  const labels = months({ count: 12 })

  const mappedData = []

  for (const i of Array(12).keys()) {
    const datum = data.find((datum) => datum.month === i + 1)
    const currentMonth = new Date().getMonth() + 1

    if (datum && datum.month <= currentMonth) {
      mappedData.push(datum.total)
    } else {
      mappedData.push(0)
    }
  }

  return {
    labels,
    datasets: [
      {
        backgroundColor: '#00e069',
        data: mappedData,
        barThickness: 22
      }
    ]
  }
}

export function getDailyBarChartData (data) {
  const daysCount = new Date((new Date().getFullYear()), (new Date().getMonth()), 0).getDate()

  const labels = []

  const mappedData = []
  for (const i of Array(daysCount).keys()) {
    labels.push((new Date((new Date().getFullYear()), (new Date().getMonth()), i + 1)).toDateString())
    const datum = data.find((datum) => new Date(datum.created_at).getDate() === i + 1)
    if (datum) {
      mappedData.push(datum.total)
    } else {
      mappedData.push(0)
    }
  }

  return {
    labels,
    datasets: [
      {
        backgroundColor: '#00e069',
        data: mappedData,
        barThickness: 22
      }
    ]
  }
}

export function getRatingsChartData (data) {
  const labels = data.map((datum) => datum.date)
  const dailyRatingsByPassengersData = data.map((datum) => datum.total_rating)
  const dailyNoRatingsByPassengersData = data.map((datum) => datum.total_passengers - datum.total_rating)

  return {
    labels: labels,
    datasets: [
      {
        barThickness: 22,
        label: 'No. of passengers that rated trip',
        data: dailyRatingsByPassengersData,
        backgroundColor: '#20E682',
        stack: 'Ratings Stack'
      },
      {
        label: 'Total passengers on trip',
        data: dailyNoRatingsByPassengersData,
        backgroundColor: '#E0E6ED',
        stack: 'Ratings Stack',
        barThickness: 22
      }
    ]
  }
}
