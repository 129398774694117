import FeatureFlagService from '@/utils/feature-flags'
export default {
  name: 'FeatureFlagEnabled',
  props: {
    featureFlag: {
      type: String,
      required: true
    }
  },
  computed: {
    mountContent () {
      return FeatureFlagService.isFlagEnabled(this.featureFlag)
    }
  },
  render () {
    return (
      this.mountContent ? (this.$slots.default) : null
    )
  }
}
