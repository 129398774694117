import { axiosInstance, setAuthorization } from '../plugins/axios'
import store from '../store'
const localforage = require('localforage')

class SessionService {
  constructor (localforage, axios) {
    this.localforage = localforage
    this.axios = axios
  }

  async isLoggedIn () {
    const session = await this.fetchCurrentSession()
    return session != null
  }

  async fetchCurrentSession () {
    const session = this.localforage.getItem('session')
    return session
  }

  async login (payload) {
    const result = await this.axios.post('/v1/login', {
      ...payload,
      type: 'staff'
    })
    setAuthorization(result.data.token.token)
    if (result.data.role !== 'super_admin' && result.data.role !== 'admin') {
      // if (process.env.VUE_APP_DISABLE_ACL === 'true') {
      const permissions = await this.getUserPermission(result.data.id)
      result.data.permissions = permissions
      // }
    } else {
      result.data.permissions = undefined
    }

    await this.localforage.setItem('session', result.data)
    store.dispatch('setCurrentUser', result.data)
    return result.data
  }

  async redirectLogin (payload) {
    setAuthorization(payload.token.token)
    if (payload.role !== 'super_admin' && payload.role !== 'admin') {
      // if (process.env.VUE_APP_DISABLE_ACL === 'true') {
      const permissions = await this.getUserPermission(payload.id)
      payload.permissions = permissions
      // }
    } else {
      payload.permissions = undefined
    }

    await this.localforage.setItem('session', payload)
    store.dispatch('setCurrentUser', payload)
    return payload
  }


  async otpLogin (payload) {
    payload = { ...payload, type: 'staff' }
    const result = await this.axios.patch(
      `/v1/otp-login/${payload.referenceId}`,
      { otp_code: payload.otpCode, type: payload.type }
    )
    await this.localforage.setItem('session', result.data)
    setAuthorization(result.data.token.token)
    store.dispatch('setCurrentUser', result.data)
    return result.data
  }

  async clearSession () {
    return this.localforage.removeItem('session').then(() => {
      this.localforage.setItem('session', null)
    })
  }

  async getUserPermission (staffId) {
    const res = await this.axios.get(
      `/v1/super-admin/staff-module-permissions/staff/${staffId}`
    )
    return res.data.permissions
  }
}

const sessionServiceInstance = new SessionService(localforage, axiosInstance)
export default sessionServiceInstance
