import {ref, shallowRef} from 'vue'

const modalState = {
	modalStatus: ref(false),
	modalComp: shallowRef(),
	mainModal: ref(false),
	propValue: ref(null)
}

export const useModal = () => {
	const openModal = (comp, prop=null) => {
		modalState.modalComp.value = comp
		modalState.propValue.value = prop
		modalState.modalStatus.value = true
	}

	const closeModal = () => {
		modalState.mainModal.value = false
		modalState.modalComp.value = undefined
		modalState.propValue.value = null
	}

	return {...modalState, openModal, closeModal}
}