import { ref } from 'vue'
import routeResource from '@/api/route'
import Swal from 'sweetalert2'
import router from '@/router'

const modalStatus = ref(false)
const new_route_code = ref('')
const copy_itinary = ref(false)
const dup_loading = ref(false)
const dup_id = ref()

const closeDuplicateRouteModal = () => {
	modalStatus.value = false
	new_route_code.value = ''
	copy_itinary.value = false
}

const openDuplicateRouteModal = (id) => {
	dup_id.value = id
	modalStatus.value = true
}

export const useRouteDuplicate = () => {
	const proceedToDuplicate = async () => {
		try {
		dup_loading.value = true
		const payload = {
			route_id: dup_id.value,
			new_route_code: new_route_code.value,
			copy_itineraries: copy_itinary.value
		}
		const res = await routeResource.duplicateRoute(payload)
		if(res?.data?.success) {
			Swal.fire({ icon: 'success', title: 'Successful', text: res?.data?.message ||'Route duplicated successfully'})
			closeDuplicateRouteModal()
			router.push(`/routes/${res?.data?.route?.id}/details`)
		} else{
			Swal.fire({ icon: 'error', title: 'Error', text: 'Could not duplicate route'})
		}
		dup_loading.value = false
		} catch (e) {
			const errorMessage = e.response?.data?.message || e.toString()
			dup_loading.value = false
			Swal.fire({ icon: 'error', title: 'Error', text: errorMessage || 'An error occured'})
		}
	}

	return { modalStatus, new_route_code, copy_itinary, dup_loading, proceedToDuplicate, closeDuplicateRouteModal, openDuplicateRouteModal }
}