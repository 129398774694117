<template>
	<b-modal
		:id="modalId"
		@close="close"
		size="md"
		no-close-on-backdrop
		no-close-on-esc
		>
		<template #modal-title>
			<!-- <p> -->
			{{
				currentStep === 1
					? `${isVehicle ? 'Link vehicle' : 'Link driver'}`
					: 'Are you absolutely sure?'
			}}
			<!-- </p> -->
		</template>

		<template v-if="currentStep === 1">
			<div class="d-flex justify-content-between align-items-center">
				<div>
					<p class="mb-0 pb-0" style="font-weight-bold">
						Batch link a {{ isVehicle ? 'vehicle' : 'driver' }}
					</p>
					<small class="text-muted"
						>Upload
						{{ isVehicle ? 'vehicles plate number' : 'drivers list' }} (CSV
						only)</small
						>
				</div>
				<download-button
					class="cursor-pointer"
					:downloading="downloadingReport"
					label="Download CSV template"
					@download="downloadTemplate()"
					style="color: #4848ed; cursor: pointer; text-decoration: underline"
					></download-button>
			</div>
			<label
				class="custom-file-upload border-0 d-flex justify-content-between align-items-center mt-4 rounded cursor-pointer"
				style="background-color: #f9fbfa"
				>
				<div class="d-flex align-items-center">
					<img src="@/assets/img/upload_image.svg" class="mr-2" alt="" />
					<div>
						<p class="font-weight-bold mb-0 pb-0">Upload document</p>
						<p class="text-muted pt-0 mt-0" style="font-size: 13px">
							File size should be a max of 500kb
						</p>
					</div>
				</div>
				<input
					type="file"
					accept=".csv"
					@change="handleFileUpload"
					ref="file"
					/>
			</label>
			<div v-if="file !== null">
				<div
					class="alert alert-primary d-flex justify-content-between align-items-center"
					role="alert"
					:show="file !== null"
					>
					<p class="py-0 my-0">{{ file.name }}</p>
					<p
						@click="handleFileCancellation"
						class="d-flex justify-content-end align-items-end font-weight-bold py-0 my-0"
						style="cursor: pointer; color: white"
						>
						X
					</p>
				</div>
			</div>
			<b-row cols="4" class="grid">
				<b-col
					style="
            background-color: #f4f5f4;
            font-size: 10px;
            font-weight: lighter;
            d-flex justify-content-center align-items-center
          "
					class="parentClass rounded border m-1 py-2 text-center text-sm d-flex flex-row"
					v-for="(eachContent, index) in extractedContents"
					:key="index"
					>
					{{ eachContent }}
				</b-col>
			</b-row>
		</template>

		<template v-if="currentStep === 2">
			<div>
				<div class="row">
					<div v-if="plateNumbersErrorsArray.length">
						<small class="text-muted mt-0 pt-0">
							The following plate numbers are invalid!. Please correct them and
							try again.
						</small>
						<div class="d-flex justify-items-center align-items-center f">
							<p
								style="
                  background-color: #ffcccb;
                  color: #f70d1a;
                  font-size: 10px;
                  font-weight: lighter;
                "
								class="parentClass rounded border m-1 my-2 py-2 px-2 text-center text-sm d-flex flex-row"
								v-for="(eachPlateNumber, index) in plateNumbersErrorsArray"
								:key="index"
								>
								{{ eachPlateNumber.vehicle_id }}
							</p>
						</div>
					</div>

					<div v-if="driversEmailErrorsArray.length">
						<small class="text-muted mt-0 pt-0">
							The following emails are invalid!. Please correct them and try
							again.
						</small>
						<div class="d-flex justify-items-center align-items-center f">
							<p
								style="
                  background-color: #ffcccb;
                  color: #f70d1a;
                  font-size: 10px;
                  font-weight: lighter;
                "
								class="parentClass rounded border m-1 my-2 py-2 px-2 text-center text-sm d-flex flex-row"
								v-for="(eachDriverEmail, index) in driversEmailErrorsArray"
								:key="index"
								>
								{{ eachDriverEmail.driver_id }}
							</p>
						</div>
					</div>

					<div class="warn col-12 d-flex rounded align-items-center">
						<img src="@/assets/img/payout-caution.svg" alt="" />
						<h3>It’s important you read the message below!</h3>
					</div>
					<div class="col-12 my-2">
						<h4 class="info-text">
							If this {{ isVehicle ? 'vehicle' : 'driver' }} has already been
							assigned to another
							{{ isVehicle ? 'vehicle' : 'driver' }} partner, assigning the
							vehicle/driver to this new partner would automatically unassign
							the {{ isVehicle ? 'vehicle' : 'driver' }} from the previous
							{{ isVehicle ? 'vehicle' : 'driver' }}
							partner.
						</h4>
					</div>
					<div class="col-12 d-flex flex-column py-2 position-relative">
						<label class="form-label"
							>Please type <span class="font-weight-bold">YES</span> to confirm
							this action.</label
							>
						<input
							class="input-review"
							type="text"
							@keyup="validatePassCode"
							v-model="passCode"
							/>
					</div>
				</div>
			</div>
		</template>

		<template #modal-footer>
			<div v-if="currentStep === 1" class="w-100">
				<b-button
					:disabled="file === null"
					class="float-right"
					@click="confirmAction()"
					:style="[
						file !== null
							? 'backgroundColor : black'
							: 'backgroundColor : #ACAFAE',
					]"
					>
					Proceed
				</b-button>
				<b-button variant="secondary" class="float-right mr-2" @click="close()">
					Close
				</b-button>
			</div>

			<div v-if="currentStep === 2" class="w-100">
				<b-button
					:disabled="!isPassValid || loading"
					:style="[
						isPassValid || loading
							? 'backgroundColor : black'
							: 'backgroundColor : #ACAFAE',
					]"
					variant="secondary"
					class="float-right"
					@click="handleBatchLinking()"
					>
					{{
						loading
							? 'processing..'
							: `Assign ${isVehicle ? 'vehicles' : 'drivers'}`
					}}
				</b-button>
				<b-button
					variant="secondary"
					:disabled="loading"
					class="float-right mr-2"
					@click="cancelConfirmation()"
					>
					Cancel
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'
import { extractErrorMessage } from '@/utils/helpers'
import DownloadButton from '@/components/core/DownloadButton.vue'
export default {
  name: 'BatchLinkingModal',
  props: {
    show: {
      type: Boolean
    },
    partnerId: {
      type: [Number, String]
    },
    selectedItem: {
      type: String
    }
  },
  components: {
    DownloadButton
  },
  data () {
    return {
      currentStep: 1,
      modalId: 'batchLinkingModal',
      passCode: '',
      isPassValid: false,
      file: null,
      extractedContents: [],
      loading: false,
      defaultCsvList: [''],
      downloadingReport: false,
      plateNumbersErrorsArray: [],
      driversEmailErrorsArray: []
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.currentStep = 1
        this.showModal()
      } else {
        this.hideModal()
      }
    },
    passCode () {
      this.validatePassCode()
    }
  },
  computed: {
    isVehicle () {
      return this.selectedItem == 'vehicle'
    }
  },
  methods: {
    handleFileCancellation () {
      this.file = null
      this.extractedContents = []
      this.$emit('close')
    },
    showModal () {
      this.$bvModal.show(this.modalId)
    },
    hideModal () {
      this.$bvModal.hide(this.modalId)
    },
    close () {
      this.$emit('close')
      this.file = null
      this.extractedContents = []
    },
    cancelConfirmation () {
      this.currentStep -= 1
    },

    async handleBatchLinking () {
      this.loading = true
      const vehiclesPayload =
        Array.isArray(this.extractedContents) &&
        this.extractedContents.map((eachVehicle) => ({
          partner_id: this.partnerId,
          vehicle_id: eachVehicle
        }))

      const driversPayload =
        Array.isArray(this.extractedContents) &&
        this.extractedContents.map((eachDriver) => ({
          partner_id: this.partnerId,
          driver_id: eachDriver
        }))

      let url

      const baseUrl = '/v1/partners/'

      if (this.isVehicle) {
        url = baseUrl + 'batch-assign-vehicles'
      }

      if (!this.isVehicle) {
        url = baseUrl + 'batch-assign-drivers'
      }

      await this.axios
        .post(url, this.isVehicle ? vehiclesPayload : driversPayload)
        .then((res) => {
          
          if (res.data.errors.length && this.isVehicle) {
            this.plateNumbersErrorsArray = res.data.errors
            this.$toastr.e(
              'OOPs! Some vehicles plate numbers were not found. Please verify and try again',
              'Error'
            )
            return
          }

          if (res.data.errors.length && !this.isVehicle) {
            this.driversEmailErrorsArray = res.data.errors
            this.$toastr.e(
              'OOPs! Some drivers emails were not found. Please verify and try again',
              'Error'
            )
            return
          }

          this.$toastr.s(
            `${
              this.isVehicle ? 'Vehicles' : 'Drivers'
            } has been successfully batch linked`,
            'Error'
          )
          if (this.isVehicle) {
            this.$emit('refreshVehiclesList')
          } else {
            this.$emit('refreshDriversList')
          }
          this.$emit('close')
          this.passCode = ''
        })
        .catch((err) => {

          this.loading = false
          this.$toastr.e(extractErrorMessage(err, `Something went wrong while linking ${this.isVehicle ? 'vehicles' : 'drivers'}` ||'Error'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    
    validatePassCode () {
      if (this.passCode.includes('YES') !== true) {
        this.isPassValid = false
      } else {
        this.isPassValid = true
      }
    },
    confirmAction () {
      this.currentStep += 1
    },
    async handleFileUpload () {
      this.file = this.$refs.file.files[0]

      this.$papa.parse(this.file, {
        download: true,
        skipEmptyLines: true,
        complete: (value) => {
          const modifiedArray = value.data
            .flat(2)
            .slice(1)
            .filter((eachPlateNumber) => {
              return eachPlateNumber !== ''
            })
          this.extractedContents = modifiedArray
        },
        error: () => {
          this.$toastr.e(
            'An error occured while while extracting files',
            'Error'
          )
        }
      })
    },

    downloadTemplate () {
      this.downloadingReport = true
      const csvData = this.defaultCsvList.map((data) => {
        return {
          plateNumber: data
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        filename: `${
          this.isVehicle ? 'Vehicles' : 'Drivers'
        } batch linking template`,
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: `${this.isVehicle ? 'Plate Numbers' : 'Drivers List'}`,
        useTextFile: false,
        useBom: true
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)

      this.$swal({
        icon: 'success',
        title: 'Template downloaded',
        text: 'Batch linking Template has been successfully downloaded',
        showCloseButton: true
      })
      this.$emit('close')
      this.downloadingReport = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/_sh-colors.scss';

.input-review {
  height: 50px;
  border: 1px solid $sh-gray-9;
  border-radius: 8px;
  outline: none;
  padding: 16px 12px;
}

.review-btn-cancel {
  background-color: $sh-gray-11;
  border: none;
}

.review-btn-proceed {
  background-color: $sh-black-1;
}

button:disabled {
  background: #acafae;
  border: none;
}

.warn {
  background: #ebebff;
  border-radius: 10px;
  padding: 14px 16px 14px 20px;

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #2323f0;
    margin: 0 0 0 8px;
  }
}

.info-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #444854;
}

#review-success-modal {
  width: 400px;
  text-align: center;
}

.review-btn-success {
  background-color: #000005;
  width: 100%;
}

.password-toggle {
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 50%;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.plateNumber {
  background: white !important;
  color: black;
  font-size: 10px;
  margin-bottom: 5px;
  margin-right: 5px;
  display: flex;
  flex-wrap: wrap;
}

.parentClass {
  display: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
