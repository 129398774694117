<template>
	<div
		class="modal fade"
		id="payoutConfigModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="payoutConfigModalLabel"
		aria-hidden="true"
		>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="removeConfig"
			>
			<div class="modal-content">
				<div class="modal-header" id="config-payout-header">
					<div>
						<h3 class="modal-title" id="configPayoutLabel">
							Payout configuration
						</h3>
					</div>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body" id="config-body">
					<form>
						<div class="row">
							<div class="col-12 d-flex flex-column py-2">
								<label class="form-label">Select Configuration</label>
								<select class="select-tab" v-model="form.config">
									<option value="null">Select Configuration</option>
									<option
										v-for="day in days"
										:key="day"
										:value="day.slice(0, -2)"
										>
										{{ day }} of the Month
									</option>
								</select>
							</div>
							<div class="col-12 d-flex justify-content-end py-2 mt-3">
								<button
									class="btn btn-dark review-btn-cancel col-2 mr-3"
									@click="close"
									aria-label="close"
									data-dismiss="modal"
									>
									Cancel
								</button>
								<button
									class="btn btn-dark review-btn-proceed col-2 p-1"
									type="submit"
									@click="showAprroval"
									:disabled="$v.form.$invalid"
									>
									Proceed
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="showConfirm"
			>
			<div class="modal-content" id="review-success-modal">
				<div
					class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            p-4
          "
					>
					<img src="@/assets/img/icons/payout-config.svg" alt="" />
					<h3 class="pt-3">Configure payout?</h3>
					<p class="refund-success-p">Are you sure you want to continue?</p>
					<div class="col-12 d-flex py-2 mt-3">
						<button
							class="btn btn-dark config-btn-cancel col-6 mr-3"
							@click="close"
							aria-label="close"
							data-dismiss="modal"
							>
							Cancel
						</button>
						<button
							class="btn btn-dark review-btn-proceed col-6 p-1"
							type="submit"
							@click="approveConfig"
							>
							{{ processing ? 'Loading...' : `Proceed` }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="showSucess"
			>
			<div class="modal-content" id="review-success-modal">
				<div
					class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            p-4
          "
					>
					<img src="@/assets/img/icons/refund-success.svg" alt="" />
					<h3 class="pt-3">Payout configured</h3>
					<p class="refund-success-p">
						You have successfully configured payout for this partner
					</p>
					<button
						class="btn btn-lg text-white review-btn-success"
						@click="resetModal($event)"
						aria-label="close"
						data-dismiss="modal"
						>
						Dismiss
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#payoutConfigModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { days } from '@/utils/days.js'
import Swal from 'sweetalert2'

export default {
  props: {
    show: Boolean
  },
  mixins: [ModalMixin, validationMixin],
  data () {
    return {
      removeConfig: true,
      showSucess: false,
      showConfirm: false,
      processing: false,
      days,
      form: {
        config: null
      },
      partnerId: this.$route.params.accountSid
    }
  },
  validations: {
    form: {
      config: {
        required
      }
    }
  },

  methods: {
    showAprroval () {
      this.removeConfig = false
      this.showConfirm = true
    },
    async approveConfig () {
      this.processing = true
      try {
        await this.axios.post('/cost-revenue/v1/earnings-remittance-period', {
          partnerId: this.partnerId,
          dayOfMonth: this.form.config
        })
        this.$emit('updatedPayout')
      } catch (err) {
        
      } finally {
        this.processing = false
        this.showConfirm = false
        this.showSucess = true
      }
    },
    resetModal (e) {
      this.close(e)
      setTimeout(() => {
        this.removeConfig = true
        this.showSucess = false
        this.form.config = null
      }, 400)
    }
  },
  emits: ['updatedPayout']
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/_sh-colors.scss';

.select-tab {
  height: 50px;
  border: 1px solid $sh-gray-9;
  border-radius: 8px;
  outline: none;
  padding: 10px 12px;
}

.review-btn-cancel {
  background-color: $sh-gray-11;
  border: none;
}

.review-btn-proceed {
  background-color: $sh-black-1;
}

button:disabled {
  background: #acafae;
  border: none;
}

.config-btn-cancel {
  color: #00000d;
  background-color: transparent;
  border: 1px solid $sh-gray-9;
}

#review-success-modal {
  width: 400px;
  text-align: center;
}

.review-btn-success {
  background-color: #000005;
  width: 100%;
}
</style>
