import { loadRouteComponent } from '@/utils/helpers'
import ReferralCodes from '@/layouts/modules/Referrals/Index.vue'
const ReferralCodeRoutes = [
  {
    path: 'referral-codes',
    component: ReferralCodes,
    props: true,
    children: [
      {
        path: '',
        name: 'ListReferralCodes',
        component: loadRouteComponent('ReferralCodes/pages/ListReferralCodes'),
        props: true,
        meta: {
          requiredPermissions: [
            'promotions002'
          ]
        }
      },
      {
        path: 'new',
        name: 'NewReferralCode',
        component: loadRouteComponent('ReferralCodes/pages/NewReferralCodes'),
        props: true,
        meta: {
          requiredPermissions: [
            'promotions001'
          ]
        }
      },
      {
        path: ':referralCodeId',
        name: 'ShowReferralCode',
        component: loadRouteComponent('ReferralCodes/pages/ShowReferralCode'),
        props: true,
        meta: {
          requiredPermissions: [
            'promotions003'
          ]
        }
      }
    ]
  }
]

export default ReferralCodeRoutes
