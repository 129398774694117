
import { ref, watch, computed } from 'vue'
import { axiosInstance } from '@/plugins/axios'

    const suspendedRoutes = ref([])
const routeLoading = ref(false)
    const currentPage = ref(1)
    const perPage = ref(10)
    const totalRecords = ref()
    const type = ref('')
    const status = ref('')


export const useSuspendedRoutes = () =>
{

    
    const getRoutes = async () => {
        routeLoading.value = true
        suspendedRoutes.value = []
  const { data } = await axiosInstance.get(`/v1/routes/booking-suspensions?page=${currentPage.value}&limit=${perPage.value}&sort[created_at]=desc${type.value ? `&filter[route_type]=${type.value}` : ''}${status.value ? `&filter[status]=${status.value}` : ''}`)
  suspendedRoutes.value = computed(() => {
    return data.data.map((item, index) => {
      return {
        ...item,
        serial_number: index + 1
      }
    })
  })
  totalRecords.value = data.metadata.total
  routeLoading.value = false
}
return { getRoutes, suspendedRoutes, routeLoading, currentPage, perPage, totalRecords, type, status }
}

