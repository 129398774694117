<template>
	<div>
		<template v-if="actions && actions.length">
			<b-dropdown size="sm" variant="link" class="position-relative text-center w-100"
				:toggle-class="'text-decoration-none'" :disabled="downloading" :no-caret="true">
				<template #button-content>
					<div class="sh-btn-download">
						<img class="sh-btn-download__icon" src="@/assets/img/icons/download_icon.svg">
						<template v-if="!downloading">
							<label class="sh-btn-download__label">{{ label }}</label>
							<i v-if="actions && actions.length" class="fe fe-chevron-down"></i>
						</template>
						<div class="d-flex align-items-center" v-else>
							<div class="spinner-border spinner" role="status"></div>
							<p class="text-center sh-btn-download__label"><span>Downloading</span></p>
						</div>
					</div>
				</template>
				<template v-if="actions">
					<b-dropdown-item v-for="(action, index) in actions" :key="index">
						<slot :name="`option(${action.key})`" v-bind="action"></slot>
					</b-dropdown-item>
				</template>
			</b-dropdown>
		</template>
		<template v-else>
			<div class="sh-btn-download" @click="$emit('download')">
				<template v-if="!downloading">
					<img class="sh-btn-download__icon" src="@/assets/img/icons/download_icon.svg">
					<label class="sh-btn-download__label">{{ label }}</label>
				</template>
				<div class="d-flex align-items-center" v-else>
					<div class="spinner-border spinner" role="status"></div>
					<p class="text-center sh-btn-download__label"><span>Downloading</span></p>
				</div>
			</div>
		</template>

	</div>
</template>

<script>
export default {
  name: 'DownloadButton',
  props: {
    downloading: {
      type: Boolean,
      required: false,
      default: false
    },
    actions: {
      type: Array,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: 'Download'
    }
  }
}
</script>

<style lang="scss" scoped>
.sh-btn-download {
  min-width: 160px;
  width: 160px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &__icon {
    height: 0.75rem;
    width: 0.875rem;
  }

  &__label {
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    margin: 0;
    padding: 0 .25rem;
    cursor: pointer;
  }
}

::v-deep .b-dropdown {
  outline: none;

  & .dropdown-toggle {
    box-shadow: none;
  }
}

.spinner {
  width: 1rem;
  margin-right: 5px;
  height: 1rem;
  border-width: 0.15rem;
}
</style>
