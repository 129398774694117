import { loadRouteComponent } from '@/utils/helpers'
import Partners from '@/layouts/modules/Partners/Index.vue'
import PartnerDetails from '@/layouts/modules/Partners/PartnerDetails.vue'
import PayoutDetails from '@/layouts/modules/Partners/PayoutDetails.vue'
import PartnersList from '@/layouts/modules/Partners/PartnersList.vue'
import PayoutList from '@/layouts/modules/Partners/PayoutList.vue'
const PartnerRoutes = [
  {
    path: 'partners',
    component: Partners,
    children: [
      {
        path: '',
        component: PartnersList,
        children: [
          {
            path: '',
            name: 'Partners',
            redirect: 'list'
          },
          {
            path: 'list',
            component: loadRouteComponent('Partners/pages/List/PartnersList'),
            name: 'PartnersList',
            props: true,
            meta: {
              requiredPermissions: [
                'partners003'
              ]
            }
          },
          {
            path: 'new-partners',
            component: loadRouteComponent('Partners/pages/List/NewPartnerRequest'),
            name: 'NewPartnerRequest',
            props: true,
            meta: {
              requiredPermissions: [
                'partners003'
              ]
            }
          },
          {
            path: 'intrested-partners',
            component: loadRouteComponent(
              'Partners/pages/List/IntrestedPartnersList'
            ),
            name: 'IntrestedPartnersList',
            props: true,
            meta: {
              requiredPermissions: [
                'partners003'
              ]
            }
          }
        ]
      },
      {
        path: 'payout',
        component: PayoutList,
        name: 'Payout',
        props: true,
        meta: {
          requiredPermissions: [
            'partners003'
          ]
        },
        children: [
          {
            path: '',
            name: 'Payouts',
            redirect: 'pending'
          },
          {
            path: 'pending',
            component: loadRouteComponent('Partners/pages/List/Payouts/pending'),
            name: 'PendingPayout',
            props: true
          },
          {
            path: 'completed',
            component: loadRouteComponent('Partners/pages/List/Payouts/completed'),
            name: 'CompletedPayout',
            props: true
          },
          {
            path: 'failed',
            component: loadRouteComponent('Partners/pages/List/Payouts/failed'),
            name: 'FailedPayout',
            props: true
          }
        ]
      },
      // {
      //   path: 'payout',
      //   component: loadRouteComponent('Partners/pages/List/Payout'),
      //   name: 'Payout',
      //   props: true,
      //   meta: {
      //     requiredPermissions: [
      //       'partners003'
      //     ]
      //   }
      // },
      {
        path: '/pastpayout/:id',
        component: loadRouteComponent('Partners/pages/List/PastPartnerPayout'),
        name: 'PastPartnerPayout',
        props: true,
        meta: {
          requiredPermissions: [
            'partners002'
          ]
        }
      },
      {
        path: ':id/payoutsummary/:earningId/:start/:end',
        component: loadRouteComponent('Partners/pages/List/PayoutSummary'),
        name: 'PayoutSummary',
        props: true,
        meta: {
          requiredPermissions: [
            'partners009', 'partners010'
          ]
        }
      },
      {
        path: 'payout/:payoutId/:earningId',
        component: PayoutDetails,
        name: 'ManagePayout',
        props: true,
        children: [
          {
            path: '',
            component: loadRouteComponent(
              'Partners/pages/Profile/PartnerInformation'
            ),
            name: 'PartnerInformation',
            props: true,
            meta: {
              requiredPermissions: [
                'partners002',
              ]
            }
          }
        ]
      },
      {
        path: 'new',
        component: loadRouteComponent('Partners/pages/ManagePartner'),
        name: 'NewVehiclePartner',
        props: true,
        meta: {
          requiredPermissions: [
            'partners001'
          ]
        }
      },
      {
        path: ':partnerId/:accountSid',
        component: PartnerDetails,
        props: true,
        children: [
          {
            path: 'info',
            component: loadRouteComponent('Partners/pages/Profile/PartnerDetails'),
            name: 'PartnerDetails',
            props: true,
            meta: {
              requiredPermissions: [
               'partners002',
              ]
            }
          },
          {
            path: 'company-info',
            component: loadRouteComponent(
              'Partners/pages/Profile/CompanyInformation'
            ),
            name: 'CompanyInformation',
            props: true,
            meta: {
              requiredPermissions: [
                'partners002',
              ]
            }
          },
          {
            path: 'documents',
            component: loadRouteComponent('Partners/pages/Profile/PartnerDocuments'),
            name: 'PartnerDocuments',
            props: true,
            meta: {
              requiredPermissions: [
                'partners002'
              ]
            }

          },
          {
            path: 'vehicles',
            component: loadRouteComponent('Partners/pages/Profile/PartnerVehicles'),
            name: 'PartnerVehicles',
            props: true,
            meta: {
              requiredPermissions: [
                'partners002'
              ]
            }
          },
          {
            path: 'drivers',
            component: loadRouteComponent('Partners/pages/Profile/PartnerDrivers'),
            name: 'PartnerDrivers',
            props: true,
            meta: {
              requiredPermissions: [
                'partners002'
              ]
            }
          },
          {
            path: 'cost-configuration',
            component: loadRouteComponent(
              'Partners/pages/Profile/PartnerCostConfiguration'
            ),
            name: 'PartnerCostConfiguration',
            props: true,
            meta: {
              requiredPermissions: [
                'partners002',
              ]
            }
          },
          {
            path: 'trip-earnings',
            component: loadRouteComponent(
              'Partners/pages/Profile/PartnerTripEarnings'
            ),
            name: 'PartnerTripEarnings',
            props: true,
            meta: {
              requiredPermissions: [
                'partners002',
              ]
            }
          },
          {
            path: 'accounts',
            component: loadRouteComponent(
              'Partners/pages/Profile/PartnerAccounts'
            ),
            name: 'PartnerAccounts',
            props: true,
            // meta: {
            //   requiredPermissions: [
            //     'partners002',
            //   ]
            // }
          }
        ]
      },
      {
        path: 'interested-parter/:partnerId',
        component: loadRouteComponent(
          'Partners/pages/Profile/IntrestedPartnerDetails'
        ),
        name: 'InterestedPartnerDetails',
        props: true
      },
      {
        path: 'edit/:partnerId',
        component: loadRouteComponent('Partners/pages/ManagePartner'),
        name: 'EditVehiclePartner',
        props: true,
        meta: {
          requiredPermissions: [
            'partners002', 'partners004', 'partners005', 'partners006', 'partners007', 'partners007', 'partners009', 'partners010', 'partners011', 'partners012', 'partners013'
          ]
        }
      }
    ]
  }
]

export default PartnerRoutes
