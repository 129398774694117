<template>
	<div class="w-full">
		<template v-if="newDrpEnabled">
			<show-route-itinerary
				:itinerary-id="$route.params.itineraryId"
				:route-id="$route.params.routeId">
			</show-route-itinerary>
		</template>
		<template v-else>
			<old-route-itinerary
				:itinerary-id="$route.params.itineraryId"
				:route-id="$route.params.routeId">
			</old-route-itinerary>
		</template>
	</div>
</template>

<script>
import FeatureFlagService, { FEATURE_FLAGS } from '@/utils/feature-flags'
import OldRouteItinerary from '@/views/Routes/OldRouteItinerary'
import ShowRouteItinerary from '@/views/Routes/ShowRouteItinerary'

export default {
  name: 'RouteItinerary',
  components: { ShowRouteItinerary, OldRouteItinerary },
  computed: {
    newDrpEnabled () {
      return FeatureFlagService.isFlagEnabled(FEATURE_FLAGS.DRP)
    }
  }
}
</script>

<style scoped>

</style>
