import { axiosInstance } from '@/plugins/axios'

export class RouteResource {
  constructor (axios) {
    this.axios = axios
  }

  generateRoutePreview (startPoint, endPoint, waypoints = [], options = {}) {
    const routeUri = '/v1/routes/direction'
    const params = {
      startPoint,
      endPoint
    }
    if (waypoints && waypoints.length) {
      params.waypoints = waypoints
    }
    return this.axios.post(routeUri, params, options)
  }

  saveRoute (id, payload, options = {}) {
    return this.axios.patch(`/v1/routes/${id}`, payload, options)
  }

  deleteRoute (id, options = {}) {
    return this.axios.delete(`/v1/routes/${id}`, options)
  }

  duplicateRoute ( options = {}) {
    return this.axios.post('/v1/routes/duplicates', options)
  }

  createRoute (payload, options = {}) {
    return this.axios.post('/v1/routes', payload, options)
  }

  async listRoute (options = {}) {
    const defaultParams = { 'fields[route]': 'id,pickup,destination,status,route_code,corporate_id,is_exclusive,visibility', 'sort[pickup]': 'asc', related: '' }
    options.params = { ...defaultParams, ...options?.params || {} }
    const result = await this.axios.get('/v1/routes', {
      ...options
    })
    return result.data
  }

  async getCities () {
    const result = await this.axios.get('/v1/city')
    return result.data
  }
}

const defaultResource = new RouteResource(axiosInstance)

export default defaultResource
