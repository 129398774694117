<template>
	<div class="main-content">
		<page-header
			title="Event request"
			pre-title="Overview"
			:loading="false">

			<template slot="tabs">
				<page-tabs-vue :menu-tabs="menuTabs">
					<template v-slot="props">
						<router-link
							:to="{ name: props.menu.routeName}"
							class="nav-link"
							active-class="active">
							{{ props.menu.label }}
						</router-link>
					</template>
				</page-tabs-vue>
			</template>

			<template slot="actions">
				<div class="dropdown">
					<button
						class="btn btn-primary dropdown-toggle"
						type="button"
						id="dropdownMenuButton"
						data-toggle="dropdown"
						aria-expanded="false"
						:disabled="isActionButtonDisabled"
						>
						Actions
					</button>
					<div
						class="dropdown-menu dropdown-menu-right mt-3"
						aria-labelledby="dropdownMenuButton"
						>
						<a
							class="dropdown-item"
              :class="{
                'btn btn-primary' : routeAssigned,
                'btn disabled' : !routeAssigned
              }"
							@click="() => (
                // if (routeAssigned) {
                //   showModal('notify-attendees-modal')
                // }
                routeAssigned ? showModal('notify-attendees-modal') : null
              )"
							>
							Notify Attendees
						</a>
					</div>
				</div>
			</template>
		</page-header>
		<page-route-view
			:loading="loading"
			:bus="bus"
			@update:attendeesNotificationLoading="updateAttendeesNotificationLoading"
			@update:eventStatus="updateEventStatus"
      @update:routeAssigned="updateRouteAssigned">
		</page-route-view>
		<notify-attendee-modal 
			:loading="attendeesNotificationLoading"
      modal-type="attendees"
			@onNotifyAttendees="notifyAttendees"
			>
		</notify-attendee-modal>
		<custom-toast
			v-for="(toast, index) in toastData" :key="index"
			:toastId="toast.toastId"
			:variant="toast.variant"
			:icon="toast.icon"
			:iconBgClass="toast.iconBgClass"
			:message="toast.message"
			:toastBodyClass="toast.toastBodyClass"
			></custom-toast>
	</div>
</template>

<script>
import Vue from 'vue'
import PageHeader from '@/components/layout/PageHeader.vue'
import PageRouteView from '@/components/layout/PageRouteView.vue'
import PageTabsVue from '@/components/layout/PageTabs.vue'
import NotifyAttendeeModal from '@/views/Events/components/modals/NotifyAttendeeModal.vue'
import CustomToast from '@/components/core/CustomToast.vue'
export default {
  name: 'AttendeeListIndex',
  components: { 
    PageTabsVue, 
    PageRouteView, 
    PageHeader,
    NotifyAttendeeModal,
    CustomToast
  },
  data () {
    return {
      menuTabs: [
        {
          routeName: 'ShowEventInfo',
          label: 'Event information'
        },
        {
          routeName: 'ShowAttendeeList', // list of attendees
          label: 'Attendee list'
        }
      ],
      toastData: [
        {
          toastId: 'attendee-route-notification',
          variant: 'success',
          icon: 'fe fe-check check-icon',
          iconBgClass: 'icon-bg success',
          message: 'Successfully sent to attendees',
          toastBodyClass: 'body-success'
          
        },
        {
          toastId: 'request-success',
          variant: 'success',
          icon: 'fe fe-check check-icon',
          iconBgClass: 'icon-bg success',
          message: 'Request Successful.',
          toastBodyClass: 'body-success'
        }
      ],
      loading: false,
      eventStatus: '',
      attendeesNotificationLoading: false,
      routeAssigned: false,
      bus: new Vue(),
    }
  },
  computed: {
    isActionButtonDisabled() {
      return ['', 'rejected', 'queried'].includes(this.eventStatus)
    }
  },
  emits: ['notifyAttendees'],
  methods: {
    showModal (id) {
      this.$bvModal.show(id)
    },
    hideModal (id) {
      this.$bvModal.hide(id)
    },
    notifyAttendees() {
      this.bus.$emit('notifyAttendees')
    },
    updateEventStatus(status) {
      this.eventStatus = status.toString()
    },
    updateRouteAssigned(status) {
      this.routeAssigned = status
    },
    updateAttendeesNotificationLoading(value) {
      this.attendeesNotificationLoading = value
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/_nav-tabs.scss';

.dropdown-item {
  color: #000
}

</style>
