<template>
	<transition name="overlay" @enter="mainModal = true">
		<div class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50" v-if="modalStatus">
			<transition name="normal" @leave="modalStatus = false"> 
				<component :modal_prop="propValue" v-if="mainModal" :is="modalComp" class="z-20"/>
			</transition>
			<div class="overlay_color absolute top-0 left-0 w-full h-full z-10"></div>
		</div>
	</transition>
</template>

<script setup>
import { watch } from 'vue'
import { useModal } from '@/composables/core/modal'
import { useRoute } from 'vue-router/composables'
const { modalStatus, mainModal, modalComp, closeModal, propValue } = useModal()

const route = useRoute()
watch(route, (from, to) => {
	closeModal()
})

// const closing = () => {
// 	if(closeWithOverlay.value) closeModal()
// }
</script>

<style scoped>
.overlay_color{
	background: rgba(30, 30, 30, 0.56);
}
.overlay-enter-from, .overlay-leave-to{
	opacity: 0;
}

.overlay-enter-active, .overlay-leave-active{
	transition: all 1s ease;
}
.normal-enter-from, .normal-leave-to{
	opacity: 0;
	transform: translateY(100px);
}

.normal-enter-active, .normal-leave-active{
	transition: all .7s ease;
}
</style>