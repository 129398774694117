import { loadRouteComponent } from '@/utils/helpers'
import TripService from '@/layouts/modules/TripService/Index.vue'
const TripServiceRoutes = [
  {
    path: 'on-trip-services',
    component: TripService,
    children: [
      {
        path: 'list',
        component: loadRouteComponent('TripServices/List'),
        name: 'TripServicesList',
        props: true
      },
      {
        path: 'add',
        component: loadRouteComponent('TripServices/Create'),
        name: 'CreateService',
        props: true
      },
      {
        path: ':serviceId',
        component: loadRouteComponent('TripServices/Show'),
        name: 'ShowService',
        props: true
      }
    ]
  }
]

export default TripServiceRoutes
