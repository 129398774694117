<template>
	<div
		class="modal fade"
		id="topupWalletModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="topupWalletLabel"
		aria-hidden="true"
		>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="wallet"
			>
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="topupWalletLabel">Topup Wallet</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<div class="row g-0 align-items-center justify-content-center">
						<div class="py-4 text-center">
							<h6 class="text-uppercase text-muted">Wallet Balance</h6>
							<h2 class="mb-0">₦{{ wallet.amount | money }}</h2>
						</div>
					</div>
					<form @submit.prevent="topupWallet">
						<div class="form-group">
							<label class="form-label">Topup Amount</label>
							<input
								v-model.trim="form.amount"
								required
								type="number"
								class="form-control"
								/>
						</div>
						<div class="form-group">
							<label for="topup-description" class="col-form-label"
								>Topup Description</label
								>
							<textarea
								v-model.trim="form.description"
								class="form-control"
								id="topup-description"
								placeholder="Enter topup description"
								required
								></textarea>
						</div>
						<div class="form-group">
							<label for="description" class="col-form-label">Account</label>
							<select
								class="form-select form-control mb-3"
								v-model.trim="form.source"
								required
								>
								<option value="main_balance">Main Balance</option>
							</select>
						</div>
					</form>
				</form>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="topupWallet"
						:disabled="processing || !formValid"
						>
						{{ processing ? 'Processing...' : 'Topup Wallet' }}
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#topupWalletModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'

export default {
  props: {
    show: Boolean,
    wallet: Object
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        amount: '',
        description: '',
        source: 'main_balance'
      },
      processing: false,
      errorMessage: ''
    }
  },
  computed: {
    formValid () {
      return this.form.amount && this.form.source && this.form.description
    }
  },
  methods: {
    close (e) {
      const target = (e && e.target) || this.$refs.closeModalButton

      target.click()

      this.$emit('close')
    },
    topupWallet () {
      if (!this.formValid) {
        return
      }

      this.processing = true

      this.errorMessage = ''

      this.axios
        .patch(`/v1/wallet/${this.wallet.id}/user/${this.wallet.user_id}`, {
          title: this.form.description,
          amount: this.form.amount,
          source: this.form.source,
          type: 'credit'
        })
        .then(() => {
          this.close()

          this.$swal({
            icon: 'success',
            title: 'Wallet Topup',
            text: `Wallet topup of ${this.$options.filters.money(
              this.form.amount
            )} has been completed successfully`,
            showCloseButton: true
          })

          this.$emit('wallet-funded')
        })
        .catch((e) => {
          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
