<template>
	<section>
		<div class="mx-4 p-1">
			<p>
				<router-link to="/partners" class="text-secondary font-weight-light"
					>{{ parentPath }}<img src="@/assets/img/caret-right.svg" alt=""
						/></router-link>
				<router-link :to="urlPath" class="text-dark font-weight-light"
					>{{ partner?.owner?.fname }} {{ partner?.owner?.lname }}</router-link
					>
			</p>
		</div>
	</section>
</template>

<script>
export default {
  name: 'BreadcrumComponent',
  props: {
    parentPath: {
      type: String,
      default: ''
    },
    partner: {
      type: Object
    }
  },
  computed: {
    urlPath () {
      return `/partners/${this.partner?.id}/info`
    }
  }
}
</script>

<style scoped>
</style>
