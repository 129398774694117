<template>
	<div>
		<div class="mt-5">
			<div v-for="(t, idx) in allPricingTypes" v-bind:key="idx" class="d-flex flex-row align-center cursor-pointer" @click="() => handleChoosePriceType(t.value)">
				<div :class="pricingType === t.value ? 'active-radio' : 'inactive-radio'"></div>
				<p>{{t.name}}</p>
			</div>
		</div>
		<div class="card mt-5">
			<div class="card-body">
				<h3 class="card-title">{{pricingHeaderText}}</h3>
				<div v-if="loadingPricing">
					<div class="d-flex py-5 justify-content-center align-items-center w-100">
						<div class="spinner-border" role="status"></div>
					</div>
				</div>
				<div v-else>
					<div v-if="pricingType.key === 'fixed'">
						<table class="mt-3 table striped table-borderless table-route-pricing">
							<thead>
								<tr>
									<th>BASE FARE</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<div class="no-padding col-12 col-lg-4 col-md-4">
											<div class="form-group">
												<div class="input-group">
													<div class="input-group-prepend">
														<span class="input-group-text">{{form.currency}}</span>
													</div>
													<input type="number" v-model.number="form.baseFare" placeholder="Price" class="form-control" />
												</div>
												<div class="invalid-feedback"
													v-if="$v.form.baseFare.$dirty && !$v.form.baseFare.required">
													Amount is required.
												</div>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-if="pricingType.key === 'dynamic'">
						<table class="mt-3 table striped table-borderless table-route-pricing">
							<thead>
								<tr>
									<th>DISTANCE</th>
									<th>MEASUREMENT UNIT</th>
									<th>CHARGE</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<div class="form-group">
											<input type="number" min="1" v-model.number="form.distance" placeholder="Distance" class="form-control"/>
											<div class="invalid-feedback"
												v-if="$v.form.distance.$dirty && !$v.form.distance.required">
												Distance is required.
											</div>
										</div>
									</td>
									<td>
										<div class="form-group">
											<select
												class="custom-select"
												v-model.trim="form.measurementUnit">
												<option
													v-for="(option, i) in measurementUnits"
													:value="option.key"
													:disabled="option.key === null"
													:selected="option.key === form.measurementUnit"
													:key="i">
													{{ option.value }}
												</option>
											</select>
											<div class="invalid-feedback"
												v-if="$v.form.measurementUnit.$dirty && !$v.form.measurementUnit.required">
												Select a distance unit.
											</div>
										</div>
									</td>
									<td>
										<div class="form-group">
											<div class="input-group">
												<div class="input-group-prepend">
													<span class="input-group-text">{{form.currency }}</span>
												</div>
												<input type="number" v-model.number="form.charge" placeholder="Price" class="form-control"/>
											</div>
											<div class="invalid-feedback"
												v-if="$v.form.charge.$dirty && !$v.form.charge.required">
												Amount is required.
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-if="pricingType.key === 'busstop'">
						<table class="mt-3 table striped table-borderless table-route-pricing table-route-pricing--fixed">
							<thead>
								<tr>
									<th>BASE FARE</th>
								</tr>
							</thead>

							<tbody>
								<tr>
									<td>
										<div class="no-padding col-12 col-lg-4 col-md-4">
											<div class="form-group">
												<div class="input-group">
													<div class="input-group-prepend">
														<span class="input-group-text">{{form.currency}}</span>
													</div>
													<input type="number" min="0" v-model.number="form.baseFare" placeholder="Price" class="form-control"/>
												</div>
											</div>
											<div class="invalid-feedback"
												v-if="$v.form.baseFare.$dirty && !$v.form.baseFare.required">
												Amount is required.
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<table class="mt-3 table striped table-borderless table-route-pricing table-route-pricing--fixed w-100">
							<thead>
								<tr>
									<th>From</th>
									<th>To</th>
									<th>Charge</th>
								</tr>
							</thead>

							<tbody>
								<template v-for="(pricing, index) in mainDestinationPoints">
									<tr :key="index">
										<td>
											<select
												class="custom-select"
												v-model="pricing.fromPoint"
												@change="updatePrevPricePoint(pricing.fromPoint)"
												v-if="pricing.fromPoint !== currentRouteItinerary.starting_bus_stop.id"
												>
												<option
													v-for="(b, i) in routeBusStops"
													:value="b.id"
													:key="i"
													:disabled="isStartRouteOptionDisabled(b.id)"
													>
													{{ b.name }}
												</option>
											</select>
											<select class="custom-select" v-else>
												<option
													disabled
													selected
													:value="currentRouteItinerary.starting_bus_stop.id"
													>{{startBusStopName}}</option>
											</select>
										</td>
										<td>
											<select
												class="custom-select"
												v-model="pricing.toPoint"
												@change="redrawPriceTable(index)"
												>
												<option
													v-for="(b, i) in routeBusStopsAfter(
														pricing.fromPoint,
													)"
													:value="b.id"
													:key="i"

													>
													{{ b.name }}
												</option>
											</select>
										</td>
										<td>
											<div class="input-group">
												<div class="input-group-prepend">
													<span class="input-group-text">{{
														pricingData.currency
													}}</span>
												</div>
												<input
													type="text"
													v-model.number="pricing.charge"
													placeholder="Price"
													class="form-control"
													/>
											</div>
										</td>
										<td>
											<div class="removeBg">
												<i
													v-if="mainDestinationPoints.length > 1"
													:class="index < 1 ? 'noDisplay' : 'fe fe-x-circle fa-lg remove_'"
													style="color: red"
													@click="removePricing(index)"
													></i>
											</div>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
					<div class="action_sec">
						<div class="plus_" v-if="this.pricingType.key === 'busstop'">
							<i
								class="fe fe-plus-circle add_ fa-lg"
								@click="addPricing"
								></i>
							<label style="
                color: #95AAC9;
                marginTop: 2px
              ">
								Add Price Bound
							</label>
						</div>
						<div v-else></div>
						<div class="btn-group float-right">
							<button
								:disabled="loadingRouteItinerary"
								class="btn btn-primary"
								@click="savePriceChanges"
								>
								{{
									loadingRoutePriceUpdate ? 'Please wait...' : 'Save Changes'
								}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import drpService from '@/views/Routes/itenerary/drp.service'
import { PRICING_TYPES } from '@/views/Routes/itenerary/pricing-types'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import store from '../../../store'

export default {
  name: 'itinerary-pricing',
  mixins: [validationMixin],
  components: {},
  inject: [
    'activeRoute'
  ],
  props: {
    routeId: {
      type: [String, Number],
      required: true
    },
    itineraryId: {
      type: [String, Number],
      required: true
    },
    currentRouteItinerary: {
      type: Object,
      required: true
    },
    routeBusStops: {
      type: Array,
      required: true
    }
  },
  validations: {
    form: {
      baseFare: {
        required: requiredIf(function () {
          return this.pricingType === 'fixed' || this.pricingType === 'busstop'
        })
      },
      charge: {
        required: requiredIf(function () {
          return this.pricingType === 'dynamic'
        })
      },
      measurementUnit: {
        required: requiredIf(function () {
          return this.pricingType === 'dynamic'
        })
      },
      distance: {
        required: requiredIf(function () {
          return this.pricingType === 'dynamic'
        })
      }
    }
  },
  data () {
    return {
      loadingPricing: false,
      hasDrpPricingData: false,
      isErrored: false,
      loadingRoutePriceUpdate: false,
      pricingData: {},
      currentItinerary: null,
      loadingBusStops: false,
      pricingType: PRICING_TYPES.FIXED,
      shouldUpdatePricingType: true,
      allPricingTypes: [
        { name: 'Fixed pricing', value: PRICING_TYPES.FIXED },
        { name: 'Dynamic pricing', value: PRICING_TYPES.DYNAMIC },
        { name: 'Bus stop based pricing', value: PRICING_TYPES.BUS_STOP_BASED }
      ],
      measurementUnits: [
        { key: 'meters', value: 'Meters' },
        { key: 'kilometers', value: 'Kilometers' },
        { key: null, value: 'Select unit' }
      ],
      savingUpdate: false,
      editData: null,
      isInititializing: false,
      form: {
        userId: store.getters.currentUser.id,
        itineraryId: this.itineraryId,
        routeId: null,
        currency: 'NGN',
        baseFare: 0,
        measurementUnit: null,
        distance: 0,
        charge: 0,
        coordinates: [],
        destinationPoints: []
      },
      mainDestinationPoints: [],
      loadingRouteItinerary: false
    }
  },
  watch: {
    pricingType: function (nv, ov) {
      if (nv !== ov) {
        this.fetchPricingData(this.itineraryId)
      }
    },
    itineraryId: function (nv, ov) {
      if (nv !== ov) {
        this.fetchPricingData(nv)
      }
    },
    currentRouteItinerary: function (nv, ov) {
      if (nv?.updated_at !== ov?.updated_at) {
        this.fetchPricingData(this.itineraryId)
        this.updateCurrentItinerary()
      }
    },
    $route: function () {
      this.pricingType = PRICING_TYPES.FIXED
      this.init()
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters.currentUser
    },
    startBusStopName () {
      return this.routeBusStops.find((b) => b.id === this.currentRouteItinerary.starting_bus_stop.id)?.name || ''
    },
    pricingHeaderText () {
      switch (this.pricingType) {
        case PRICING_TYPES.FIXED:
          return 'Fixed Pricing'
        case PRICING_TYPES.DYNAMIC:
          return 'Dynamic Pricing'
        default:
          return 'Bus stop based pricing'
      }
    },
    updateOrCreatePricing () {
      const method = this.hasDrpPricingData ? 'put' : 'post'
      const urlPath = this.hasDrpPricingData ? `/update/${this.pricingType.key}` : `/create/${this.pricingType.key}`
      const result = {
        method,
        urlPath
      }
      return result
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.initFormData(this.currentRouteItinerary)
      this.fetchPricingData(this.itineraryId)
      this.getRouteCordinate()
    },
    initFormData (currentItinerary) {
      this.form = {
        ...this.form,
        routeId: this.routeId,
        startTime: currentItinerary.trip_time,
        startBusstopId: currentItinerary.starting_bus_stop.id
      }
    },
    isStartRouteOptionDisabled (currId) {
      const idCurr = this.routeBusStops.findIndex((r) => r.id === currId)
      if (idCurr === this.routeBusStops.length - 1) return false
      const lastPricingTableId = this.routeBusStops.findIndex((r) => r.id === this.mainDestinationPoints[this.mainDestinationPoints.length - 2]?.toPoint ?? this.currentRouteItinerary.end_route_bus_stop_id)
      return (idCurr < lastPricingTableId)
    },
    getRouteCordinate () {
      const route = this.activeRoute()
      const coordinates = []
      if (route) {
        coordinates[0] = drpService.convertXYtoLatLng(route.destination_geometry)
        coordinates[1] = drpService.convertXYtoLatLng(route.pickup_geometry)
        this.form.coordinates = coordinates
      }
    },
    updatePricingType (number) {
      const mapType = {
        0: PRICING_TYPES.FIXED,
        1: PRICING_TYPES.DYNAMIC,
        2: PRICING_TYPES.BUS_STOP_BASED
      }
      if (this.shouldUpdatePricingType) {
        this.pricingType = mapType[number]
      }
    },
    updatePrevPricePoint (currPoint) {
      if (currPoint === this.routeBusStops[this.routeBusStops.length - 1].id) {
        this.mainDestinationPoints = [
          {
            charge: this.form.baseFare,
            fromPoint: this.form.startBusstopId,
            toPoint: this.routeBusStops[this.routeBusStops.length - 1].id
          }
        ]
      } else {
        const allDestinationPoints = this.mainDestinationPoints
        // get the index of the currennt point;
        const currIndex = allDestinationPoints.findIndex((p) => p.fromPoint === currPoint)
        // get the actual data of thee prev point;
        const prevPoint = allDestinationPoints[currIndex - 1]
        if (prevPoint.toPoint !== currPoint) {
          // get the index of the prev point;
          const prevPointIndex = allDestinationPoints.findIndex((p) => p.toPoint === prevPoint.toPoint)
          // update the prev point with the current point;
          allDestinationPoints[prevPointIndex] = {
            ...allDestinationPoints[prevPointIndex],
            toPoint: currPoint
          }
          this.mainDestinationPoints = allDestinationPoints
        }
      }
    },
    async fetchPricingData (itinerary_id) {
      try {
        if (!itinerary_id) return
        this.loadingPricing = true
        this.$Progress.start()
        const priceConfigData = await drpService.fetchItineraryPricingConfig(itinerary_id)
        this.loadingPricing = false
        this.$Progress.finish()
        if (priceConfigData?.status === 200) {
          this.hasDrpPricingData = true
          this.pricingData = priceConfigData.data.data
          this.form = {
            ...this.form,
            ...priceConfigData.data.data,
            destinationPoints: priceConfigData.data.data.destinationPoints ?? []
          }
          this.updatePricingType(priceConfigData.data.data.type)
          this.setupPricingBusStop(this.form.destinationPoints, this.routeBusStops)
        } else {
          this.$toastr.e('This itinerary has no pricing data', 'Error')
        }
      } catch (e) {
        this.$Progress.fail()
        this.loadingPricing = false
        if (this.pricingType === PRICING_TYPES.BUS_STOP_BASED) {
          this.setupPricingBusStop(this.form.destinationPoints, this.routeBusStops)
        }
      }
    },
    handleChoosePriceType (value) {
      this.shouldUpdatePricingType = false
      this.pricingType = value
    },
    removePricing (index) {
      this.mainDestinationPoints.splice(index, 1)
      // get the prev point;
      const prevPoint = this.mainDestinationPoints[index - 1]
      // get the current point;
      const nextPoint = this.mainDestinationPoints[index]
      // update the new index fromPoint with the prevIndex toPoint;
      if (prevPoint && nextPoint) {
        this.mainDestinationPoints[index] = {
          ...this.mainDestinationPoints[index],
          fromPoint: prevPoint.toPoint
        }
      }
    },
    setupPricingBusStop (actualPriceBusStops, routeBusStops) {
      // check if the pricing data has no busstop.
      const points = []
      if (!actualPriceBusStops.length) {
        const defaultPoint = {
          charge: this.form.baseFare,
          fromPoint: this.form.startBusstopId,
          toPoint: routeBusStops[routeBusStops.length - 1].id
        }
        points.push(defaultPoint)
        this.mainDestinationPoints = points
      } else {
        this.rebuildPriceTable(actualPriceBusStops)
      }
    },
    rebuildPriceTable (initialBusStops) {
      if (initialBusStops.length) {
        const currentRouteStartPoint = this.currentRouteItinerary.starting_bus_stop.id
        if (initialBusStops[0].fromPoint !== currentRouteStartPoint) {
          const newDefault = {
            charge: this.form.baseFare,
            fromPoint: currentRouteStartPoint,
            toPoint: this.routeBusStops[this.routeBusStops.length - 1].id
          }
          this.mainDestinationPoints = [newDefault]
        } else {
          this.mainDestinationPoints = initialBusStops
        }
      }
    },

    async savePriceChanges () {
      try {
        const { method, urlPath } = this.updateOrCreatePricing
        const payload = this.validatePriceData(this.form)
        if (!payload) return
        if (this.isErrored) return
        this.$Progress.start()
        this.loadingRouteItinerary = true
        const result = await drpService.saveOrUpdatePricing(method, urlPath, payload)
        this.$Progress.finish()
        this.loadingRouteItinerary = false
        if (result.status === 200) {
          await this.fetchPricingData(this.itineraryId)
          this.$toastr.s('Pricing updated successfully', 'Success')
        } else {
          this.$toastr.e('An error occured', 'Error')
        }
      } catch (e) {
        this.$Progress.fail()
        this.$toastr.e('An error occured', 'Error')
        this.loadingRouteItinerary = false
      }
    },
    validatePriceData (data) {
      let payload
      switch (this.pricingType) {
        case PRICING_TYPES.FIXED:
          payload = this.validateFixedPriceData(data)
          break
        case PRICING_TYPES.DYNAMIC:
          payload = this.validateDynamicPriceData(data)
          break
        case PRICING_TYPES.BUS_STOP_BASED:
          payload = this.validateBusStopPriceData(data)
          break
        default:
          payload = false
          break
      }
      if (payload) {
        payload.stopBusstopId = this.routeBusStops[this.routeBusStops.length - 1].id
        return payload
      }

      this.isErrored = true
      return false
    },
    redrawPriceTable (affectedIndex) {
      const val = this.mainDestinationPoints[affectedIndex]
      const destinationId = val.toPoint
      const busStopIndex = this.routeBusStops.findIndex(
        (i) => i.id === destinationId
      )

      if (busStopIndex <= 0) return

      if (
        busStopIndex == this.routeBusStops.length - 1 &&
          this.mainDestinationPoints.length > 1
      ) {
        this.mainDestinationPoints.splice(affectedIndex + 1)
        return
      }

      const newPosition = {
        fromPoint: this.routeBusStops[busStopIndex].id,
        toPoint: this.routeBusStops[this.routeBusStops.length - 1].id,
        charge: 0
      }
      if (this.mainDestinationPoints.length == affectedIndex + 1) {
        this.mainDestinationPoints.push(newPosition)
      } else {
        this.mainDestinationPoints[affectedIndex + 1].fromPoint =
            newPosition.fromPoint

        if (
          this.mainDestinationPoints[affectedIndex + 1]
            .fromPoint ===
            this.mainDestinationPoints[affectedIndex + 1]
              .toPoint
        ) {
          this.mainDestinationPoints.splice(affectedIndex + 1, 1)
        }
      }
    },
    addPricing () {
      // first get the index of the last known point;
      const lastPoint = this.mainDestinationPoints[this.mainDestinationPoints.length - 1].toPoint
      const lastPointIndex = this.routeBusStops.findIndex(
        (i) => i.id === lastPoint
      )
      if (lastPointIndex === -1) return
      // check if the index is the last in main route array;
      if (lastPointIndex === this.routeBusStops.length - 1) return
      this.mainDestinationPoints.push({
        fromPoint: this.routeBusStops[lastPointIndex + 1].id,
        toPoint:
        this.routeBusStops[this.routeBusStops.length - 1].id,
        charge: 0
      })
    },

    validateFixedPriceData (data = {}) {
      if (!Object.prototype.hasOwnProperty.call(data, 'baseFare')) {
        this.isErrored = true
        this.$toastr.e('base fare cannot be empty', 'Error')
      } else if (data.baseFare <= 0) {
        this.isErrored = true
        this.$toastr.e('base fare cannot be less than or zero', 'Error')
      } else {
        const {
          measurementUnit,
          distance,
          charge,
          ...res
        } = data
        return res
      }
      return false
    },
    validateDynamicPriceData (data = {}) {
      if (!data.measurementUnit) {
        this.isErrored = true
        this.$toastr.e('Please select a measurement unit', 'Error')
      }
      if (data.charge) {
        if (data.charge < 50) {
          this.isErrored = true
          this.$toastr.e('Charge cannot be less than 50', 'Error')
        }
      }
      return data
    },
    validateBusStopPriceData (data = {}) {
      if (!Object.prototype.hasOwnProperty.call(data, 'baseFare')) {
        this.isErrored = true
        this.$toastr.e('base fare cannot be empty', 'Error')
      } else if (data.baseFare <= 0) {
        this.isErrored = true
        this.$toastr.e('base fare cannot be less than or zero', 'Error')
      } else {
        data.destinationPoints = this.mainDestinationPoints
        const {
          measurementUnit,
          distance,
          charge,
          ...res
        } = data
        return res
      }
    },
    routeBusStopsAfter (val) {
      if (val == null) return this.routeBusStops
      const busStop = this.routeBusStops.find((i) => i.id === val)
      if (!busStop) return this.routeBusStops
      return this.routeBusStops.filter((v) => {
        return v.position > busStop.position
      })
    },
    updateCurrentItinerary () {
      if (!this.itineraryId) return
      this.loadingPricing = true
      this.$Progress.start()
      this.axios.get(`/v1/route-itineraries/${this.itineraryId}`).then((response) => {
        this.currentItinerary = response.data
        this.form.startTime = response.data.trip_time
        this.form.startBusstopId = response.data.starting_bus_stop.id
        // this.fetchBusStops();
        this.$Progress.finish()
        this.loadingPricing = false
      }).catch(() => {
        this.$Progress.fail()
      }).finally(() => {
        this.loadingPricing = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.form-group {
  margin-bottom: 0;
}
.invalid-feedback {
  display: block;
}
.inactive-radio {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #E5E9F2;
  margin-right: .4rem;
}
.active-radio{
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #101211;
  margin-right: .4rem;
  display: flex;
  align-items: center;
  justify-items: center;
  &::before {
    position: relative;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 100%;
    color: #fff;
    justify-self: center;
    text-align: center;
    left: 30%;
    right: 53%;
    background-color: #fff;
  }
}

.table-route-pricing {

  &--fixed {
    table-layout: fixed;
  }

  tbody {
    tr > td:first-of-type {
      padding-left: 0;
    }
    tr > td:last-of-type {
      padding-right: 0;
    }
  }

  .custom-select {
    &:focus {
      box-shadow: none;
    }
  }
}

.no-padding {
  padding: 0;
}

::v-deep .multiselect__tags-wrap {
  display: flex;
  flex-wrap: wrap;
  & .multiselect {
    &__tag {
      background: black;
      margin-bottom: 2px;
      margin-right: 5px;
      padding: 4px 25px 4px 5px;
      font-size: 0.8125rem;
      font-weight: normal;
    }

    &__tag-icon::after {
      color: #ffffff;
    }
  }
}

</style>
