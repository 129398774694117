import { format, parseISO } from 'date-fns'

const filters = {
  titleCase: function (value) {
    const newValue = Array.isArray(value) ? value.join(' ') : value

    return newValue
      .split(' ')
      .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
      .join(' ')
  },
  uppercase: function (value) {
    return value.toUpperCase()
  },
  date: function (value, dateFormat = 'd MMMM, yyyy HH:mm:ss') {
    if (!value || value === 'Invalid Date') {
      return ''
    }

    return format(parseISO(value), dateFormat)
  },
  utcDate (value, dateFormat) {
    try {
      if (!value) {
        return ''
      }
      if (value.endsWith('Z')) {
        value = value.slice(0, -1)
      }
      return format(parseISO(value), dateFormat)
    } catch (e) {
      return value
    }
  },
  money: function (value, digits = 2) {
    const n = typeof value !== 'number' ? parseFloat(value) : value
    return n.toLocaleString('en-US', { minimumFractionDigits: digits })
  },
  initials: function (value, limit = 2) {
    const words = value.split(' ').filter((v) => !!v)

    return (limit ? words.slice(0, limit) : words)
      .map((word) => word[0].toUpperCase())
      .join('')
  }
}

export default filters
