<template>
  <div>
    <div v-if="loadingRouteData">
      <div class="py-5 d-flex flex-column text-dark justify-content-center text-secondary align-items-center">
        <div class="spinner-border" role="status"></div>
        <p class="mt-2 text-center"><strong>Loading...</strong></p>
      </div>
    </div>
    <div class="overflow-auto d-flex vh-100 position-relative" v-else>
      <template v-if="activeRoute">
        <div class="px-0 col-4">
          <div class="card">
            <div class="card-body">
              <div class="border-0 header-body">
                <div class="row align-items-end">
                  <div class="col">
                    <h1 class="header-title">Edit Route</h1>
                    <p>
                      {{ activeRoute.route_code }} &#x2022;
                      {{ activeRoute.pickup }} - {{ activeRoute.destination }}
                    </p>
                  </div>
                </div>
                <!-- / .row -->
              </div>

              <div class="mb-0 form-group">
                <vue-google-autocomplete :value="activeRoute.pickup" ref="startingPointAddress" id="startingPointAddressInput" classname="form-control" placeholder="Starting Point" v-on:placechanged="originChanged" @no-results-found="clearOrigin" types="" country="ng">
                </vue-google-autocomplete>
              </div>

              <div class="mb-0 form-group">
                <draggable v-model="waypoints" group="waypoints" @start="dragWaypoint = true" @end="dragWaypoint = false">
                  <div class="my-1 input-group" v-for="(w, index) in waypoints" :key="w.id">
                    <vue-google-autocomplete :ref="`wayPointRef${w.id}`" :id="`wayPointInput${w.id}`" classname="form-control" placeholder="Enter Stop Point" v-on:placechanged="w.val = $event" @no-results-found="w.val = null" types="" country="ng">
                    </vue-google-autocomplete>

                    <div class="input-group-append">
                      <button class="btn btn-light" @click.prevent="removeStop(index)" title="Remove Stop">
                        <span class="fe fe-x"></span>
                      </button>
                    </div>
                  </div>
                </draggable>
              </div>

              <div class="mb-0 form-group">
                <button class="btn btn-rounded" @click.prevent="addWayPoint">
                  <span class="fe fe-plus"></span> Add Stop
                </button>
              </div>

              <div class="mb-0 form-group">
                <vue-google-autocomplete :value="activeRoute.destination" ref="destinationPointAddress" id="destinationPointAddressInput" classname="form-control" placeholder="Destination Point" v-on:placechanged="destinationChanged" @no-results-found="clearDestination" types="" country="ng">
                </vue-google-autocomplete>
              </div>

              <div class="form-group"></div>
              <hr />

              <div class="form-group">
                <label for="">Route Code</label>
                <input type="text" class="form-control" v-model="newRoute.code" placeholder="e.g S300" />
              </div>

              <div class="form-group">
                <label for="">Route Description</label>
                <textarea type="text" rows="3" class="resize-none form-control" v-model="newRoute.description" maxlength="500" placeholder="Route description (Max. 500 characters)"></textarea>
              </div>

              <div class="form-group">
                <div><label for="">Route Status</label></div>

                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model.number="newRoute.status" type="radio" class="custom-control-input" name="route_status" value="1" id="status_active" />
                  <label for="status_active" class="custom-control-label">
                    Active
                  </label>
                </div>

                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" v-model.number="newRoute.status" name="route_status" value="0" id="status_inactive" />
                  <label for="status_inactive" class="custom-control-label">
                    Inactive
                  </label>
                </div>
              </div>

              <div class="form-group">
                <div><label for="">Route Visibility</label></div>

                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="newRoute.visibility" type="radio" class="custom-control-input" name="route_visibility" value="public" id="public_route" />
                  <label for="public_route" class="custom-control-label">
                    Public
                  </label>
                </div>

                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" v-model="newRoute.visibility" name="route_visibility" value="private" id="private_route" />
                  <label for="private_route" class="custom-control-label">
                    Private
                  </label>
                </div>
              </div>

              <div class="form-group" v-if="newRoute.visibility == 'private'">
                <label for="">Select Corporate that owns this private route</label>
                <multiselect v-model="newRoute.corporateOwner" id="ajax" :custom-label="corporateName" track-by="id" placeholder="Type to search" open-direction="bottom" :options="corporatesList" :multiple="false" :searchable="true" :loading="isLoading" :internal-search="true" :clear-on-select="false"
                  :close-on-select="true" :options-limit="300" :limit="10" :limit-text="limitText" :show-no-results="true" :hide-selected="false" @search-change="asyncFindCorporates">
                  <template slot="clear">
                    <div class="multiselect__clear" v-if="newRoute.corporateOwner" @mousedown.prevent.stop="newRoute.corporateOwner = null"></div>
                  </template><span slot="noResult">Oops! No corporates found. Consider changing the search
                    query.</span>
                </multiselect>
              </div>

              <div class="form-group">
                <div><label for="">Route Availability</label></div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="routeAvailability" type="radio" class="custom-control-input" value="true" id="routeAvailabilityAll" @change="onRouteAvailabilityChange(true)" />
                  <label for="routeAvailabilityAll" class="custom-control-label">
                    Everyday
                  </label>
                </div>

                <div class="custom-control custom-radio">
                  <input v-model="routeAvailability" type="radio" class="custom-control-input" value="false" id="routeAvailabilitySelected" @change="onRouteAvailabilityChange(false)" />
                  <label for="routeAvailabilitySelected" class="custom-control-label">
                    Selected days
                  </label>
                </div>
                <day-selector v-if="!routeAvailability" @on-change="updateAvailableDays($event)" :activeDays="newRoute.route_availability_days" class="mt-3">
                </day-selector>
              </div>

              <div class="form-group">
                <div><label>Availability Start Date</label></div>
                <div class="d-inline-block">
                  <v-datepicker v-model="newRoute.route_availability_start_date" value-type="YYYY-MM-DD"></v-datepicker>
                </div>
              </div>

              <div class="form-group">
                <div><label>Availability End Date</label></div>
                <div class="d-inline-block">
                  <v-datepicker v-model="newRoute.route_availability_end_date" value-type="YYYY-MM-DD"></v-datepicker>
                </div>
              </div>

              <div class="form-group">
                <div><label>Unavailable Dates</label></div>
                <div class="d-inline-block">
                  <multi-date-selector v-model="newRoute.blacklisted_availability_days"></multi-date-selector>
                </div>
              </div>

              <div class="form-group">
                <div><label for="">Route Type</label></div>

                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model.number="newRoute.is_exclusive" type="radio" class="custom-control-input" name="route_type" value="0" :checked="newRoute.is_exclusive == 0" id="shared_route" />
                  <label for="shared_route" class="custom-control-label">
                    Shared
                  </label>
                </div>

                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" v-model.number="newRoute.is_exclusive" name="route_type" :checked="newRoute.is_exclusive == 1" value="1" id="exclusive_route" />
                  <label for="exclusive_route" class="custom-control-label">
                    Exclusive
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label for="">Route Owner Type</label>
                <select v-model="newRoute.owner_type" class="custom-select">
                  <option value="corporate">Corporate</option>
                  <option value="user">User</option>
                  <option value="system">Shuttlers Owned</option>
                </select>
              </div>

              <div class="form-group" v-if="newRoute.owner_type == 'corporate' || newRoute.owner_type == 'user'">
                <label for="">Select Route Owner</label>
                <div v-if="newRoute.owner_type == 'corporate'">
                  <multiselect v-model="newRoute.owner_id" id="corporate-route-owner" :custom-label="corporateName" track-by="id" placeholder="Type to search" open-direction="bottom" :options="corporatesList" :multiple="false" :searchable="true" :loading="isLoading" :internal-search="true"
                    :clear-on-select="false" :close-on-select="true" :options-limit="300" :limit="10" :limit-text="limitText" :show-no-results="true" :hide-selected="false" @search-change="asyncFindCorporates">
                    <template slot="clear">
                      <div class="multiselect__clear" v-if="newRoute.corporateOwner" @mousedown.prevent.stop="newRoute.corporateOwner = null"></div>
                    </template><span slot="noResult">Oops! No corporates found. Consider changing the search
                      query.</span>
                  </multiselect>
                </div>

                <div v-if="newRoute.owner_type == 'user'">
                  <multiselect v-model="newRoute.owner_id" id="user-route-owner" :custom-label="userName" track-by="id" placeholder="Type to search" open-direction="bottom" :options="usersList" :multiple="false" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false"
                    :close-on-select="false" :options-limit="300" :limit="10" :limit-text="limitText" :show-no-results="false" :hide-selected="true" @search-change="asyncFindUsers">
                    <template slot="clear">
                      <div class="multiselect__clear" v-if="newRoute.owner_id" @mousedown.prevent.stop="newRoute.owner_id = null"></div>
                    </template><span slot="noResult">Oops! No users found. Consider changing the search
                      query.</span>
                  </multiselect>
                </div>
              </div>

              <div class="form-group">
                <label for="">Who is paying for the bookings on this route?</label>
                <select v-model="newRoute.payer" class="custom-select">
                  <option value="passenger">Passenger</option>
                  <option value="owner" v-if="newRoute.owner_type == 'corporate' || newRoute.owner_type == 'user'">Route
                    Owner</option>
                </select>
              </div>

              <div class="form-group">
                <label for="">When is booking payment happening?</label>
                <select v-model="newRoute.payment_mode" class="custom-select">
                  <option value="pre-trip">Before Trip Starts</option>
                  <option value="post-trip">After Trip Ends</option>
                </select>
              </div>


              <!--  -->
              <div class="mt-5 form-group">
                <button class="btn btn-primary btn-block" @click="saveRoute" :disabled="generatingRoutePreview || savingRoute">
                  <span v-if="generatingRoutePreview">Generating Route Preview..</span>
                  <span v-if="savingRoute">Creating Route..</span>
                  <span v-if="!savingRoute && !generatingRoutePreview">Save Route</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="px-0 col-8 sticky-map-wrapper">
          <div class="info-windows" style="height: 100vh; width: 100%">
            <!-- <route-item-map-preview :active-route="activeRoute" v-if="activeRoute && !routeEditMode" /> -->
            <google-map id="map" ref="Map">
              <google-map-marker v-if="startingPointModel" :position="{
                lat: startingPointModel.latitude,
                lng: startingPointModel.longitude,
              }" :key="`starting-point-marker`" :clickable="true" @click="
  startingPointModel.showInfo = !startingPointModel.showInfo
  " />
              <google-map-infowindow v-if="startingPointModel" :position="{
                lat: startingPointModel.latitude,
                lng: startingPointModel.longitude,
              }" :show.sync="startingPointModel.showInfo" :key="`starting-point-windowpickup`" :options="{ maxWidth: 300, pixelOffset: 10 }">
                <div>
                  <h4>Starting Point</h4>
                  <p>{{ startingPointModel.route }}</p>
                </div>
              </google-map-infowindow>

              <google-map-marker v-if="destinationPointModel" :position="{
                lat: destinationPointModel.latitude,
                lng: destinationPointModel.longitude,
              }" :key="`destination-point-marker`" :clickable="true" @click="
  destinationPointModel.showInfo = !destinationPointModel.showInfo
  " />
              <google-map-infowindow v-if="destinationPointModel" :position="{
                lat: destinationPointModel.latitude,
                lng: destinationPointModel.longitude,
              }" :show.sync="destinationPointModel.showInfo" :key="`destination-point-windowpickup`" :options="{ maxWidth: 300, pixelOffset: 10 }">
                <div>
                  <h4>Destination Point</h4>
                  <p>{{ destinationPointModel.route }}</p>
                </div>
              </google-map-infowindow>
            </google-map>
          </div>
        </div>
      </template>
      <p v-else class="text-center">Could not load route data.</p>
    </div>
  </div>
</template>

<script>
import routeResource from '@/api/route'
import { CancelToken } from 'axios'
import draggable from 'vuedraggable'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Multiselect from 'vue-multiselect'
// Main JS (in UMD format)

// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'
import RouteItemMapPreview from './RouteItemMapPreview.vue'
import DaySelector from '@/views/Routes/DaySelector'
import MultiDateSelector from '@/components/core/MultiDateSelector'

let asyncFindUsersAbortController = new AbortController()
let asyncFindCorporateAbortController = new AbortController()

export default {
  beforeRouteEnter(to, from, next)
  {
    next(async (vm) =>
    {
      try {
        await vm.loadActiveRoute()
      } catch (e) {

      }
    })
  },
  beforeRouteUpdate(to, from, next)
  {
    this.loadActiveRoute().finally(() =>
    {
      next()
    })
  },
  components: {
    MultiDateSelector,
    DaySelector,
    VueGoogleAutocomplete,
    draggable,
    Multiselect,
    RouteItemMapPreview
  },
  props: {
    routeId: {
      type: [String, Number],
      required: true
    }
  },
  data()
  {
    return {
      savingRoute: false,
      routeEditMode: false,
      activeRoute: null,
      newRoute: {
        owner_type: 'system',
        owner_id: null,
        payer: 'passenger',
        payment_mode: 'pre-trip',
        is_exclusive: 0,
        status: 1,
        corporateOwner: null,
        code: '',
        description: '',
        visibility: 'public',
        route_availability_days: [],
        route_availability_start_date: '',
        route_availability_end_date: '',
        blacklisted_availability_days: [],
        itineraries: [
          {
            id: 1,
            time: '06:00 AM',
            fare: 0
          }
        ]
      },
      activeRouteOption: null,
      corporatesList: [],
      usersList: [],
      isLoading: false,
      dragWaypoint: false,
      startingPoint: '',
      startingPointModel: null,
      destinationPointModel: null,
      destinationPoint: '',
      waypoints: [],
      cancelFnToRoutePreview: null,
      currentRoutePreviewData: null,
      currentlyShowingFeatures: [],
      generatingRoutePreview: false,
      unwatchers: [],
      routeAvailability: true,
      loadingRouteData: false,
      findUsersLoading: false,
      findCorporateLoading: false
    }
  },
  mounted()
  {
    this.loadActiveRoute()
  },
  created()
  {
    this.loadCorporates()
  },

  watch: {
    $route: {
      deep: true,
      handler()
      {
        if (this.activeRoute && this.$route.routeId != this.activeRoute.id) {
          this.loadActiveRoute()
        }
      }
    },
    startingPointModel() {
      this.adjustMapZoom()
    },
    destinationPointModel() {
      this.adjustMapZoom()
    },
    waypoints: {
      deep: true,
      handler() {
        this.adjustMapZoom()
      }
    }
  },
  methods: {
    async loadActiveRoute()
    {
      try {
        this.loadingRouteData = true
        const result = await this.axios.get(`/v1/routes/${this.routeId}`)
        const route = result.data
        this.activeRoute = route
        await this.initializeFromModel()
      } catch (e) {

      } finally {
        this.loadingRouteData = false
      }
    },
    setupWatchers()
    {
      this.unwatchers.push(
        this.$watch('startingPointModel', () =>
        {
          this.adjustMapZoom()
        })
      )

      this.unwatchers.push(
        this.$watch('destinationPointModel', () =>
        {
          this.adjustMapZoom()
        })
      )

      this.unwatchers.push(
        this.$watch(
          'waypoints',
          () =>
          {
            this.adjustMapZoom()
          },
          {
            deep: true
          }
        )
      )
    },
    async initializeFromModel()
    {
      this.unwatchers.forEach((v) =>
      {
        v()
      })
      this.unwatchers.splice(0, this.unwatchers.length)

      if (this.activeRoute.pickup_geometry) {
        this.startingPointModel = {
          latitude: this.activeRoute.pickup_geometry.y,
          longitude: this.activeRoute.pickup_geometry.x
        }
        this.startingPoint = this.activeRoute.pickup
      }

      if (this.activeRoute.destination_geometry) {
        this.destinationPointModel = {
          latitude: this.activeRoute.destination_geometry.y,
          longitude: this.activeRoute.destination_geometry.x
        }
        this.destinationPoint = this.activeRoute.destination
      }

      this.newRoute = {
        ...this.activeRoute,
        ...{
          status: parseInt(this.activeRoute.status),
          code: this.activeRoute.route_code,
          visibility: this.activeRoute.visibility,
          description: this.activeRoute.info ? this.activeRoute.info.description : '',
          route_availability_end_date: this.activeRoute.route_availability_end_date,
          route_availability_start_date: this.activeRoute.route_availability_start_date,
          blacklisted_availability_days: JSON.parse(this.activeRoute.blacklisted_availability_days) || []
        }
      }
      this.initRouteAvailableDays(JSON.parse(this.activeRoute.route_availability_days))

      if (this.activeRoute.corporate_id) {
        const existingCorporate = this.corporatesList.find((i) =>
        {
          return parseInt(i.id) == parseInt(this.activeRoute.corporate_id)
        })
        if (existingCorporate) {
          this.newRoute.corporateOwner = existingCorporate
        }

        if (!this.newRoute.corporateOwner) {
          await this.axios
            .get(`/v1/corporates/${this.activeRoute.corporate_id}`)
            .then((response) =>
            {
              this.newRoute.corporateOwner = response.data.data
                ? response.data.data
                : response.data
              this.isLoading = false
            })
        }
      }

      if (this.activeRoute.owner_id && this.activeRoute.owner_type == 'corporate') {
        const existingCorporate = this.corporatesList.find((i) =>
        {
          return parseInt(i.id) == parseInt(this.activeRoute.owner_id)
        })
        if (existingCorporate) {
          this.newRoute.owner_id = existingCorporate
        }

        if (!this.newRoute.owner_id) {
          await this.axios
            .get(`/v1/corporates/${this.activeRoute.corporate_id}`)
            .then((response) =>
            {
              this.newRoute.owner_id = response.data.data
                ? response.data.data
                : response.data
              this.isLoading = false
            })
        }
      }

      if (this.activeRoute.owner_id && this.activeRoute.owner_type == 'user') {
        await this.axios
          .get(`/v1/users/${this.activeRoute.owner_id}`)
          .then((response) =>
          {
            this.newRoute.owner_id = response.data.data
            this.isLoading = false
          })
      }

      this.setupWatchers()
    },
    limitText(count)
    {
      return `and ${count} other corporates`
    },
    loadCorporates()
    {
      this.isLoading = true
      this.axios
        .get('/v1/corporates')
        .then((response) =>
        {
          this.corporatesList = response.data.data
          this.isLoading = false
        })
        .catch((e) =>
        {

          this.isLoading = false
          this.corporatesList = []
        })
    },
    asyncFindCorporates(query)
    {
      if (this.findCorporateLoading) {
        asyncFindCorporateAbortController.abort()
      }
      asyncFindCorporateAbortController = new AbortController()
      this.findCorporateLoading = true
      this.isLoading = true
      this.axios
        .post('/v1/corporates/search', {
          name: query
        }, {
          signal: asyncFindCorporateAbortController.signal,
        })
        .then((response) =>
        {
          this.corporatesList = response.data
          this.isLoading = false
        })
        .catch((e) =>
        {

          this.isLoading = false
          this.corporatesList = []
        }).finally(() =>
        {
          this.findCorporateLoading = false
        })
    },
    asyncFindUsers(query)
    {
      if (this.findUsersLoading) {
        asyncFindUsersAbortController.abort()
      }
      asyncFindUsersAbortController = new AbortController()
      this.findUsersLoading = true
      this.isLoading = true
      this.axios
        .post('/v1/users/search', { user: query }, { signal: asyncFindUsersAbortController.signal, })
        .then((response) =>
        {
          this.usersList = response.data
          this.isLoading = false
        })
        .catch((e) =>
        {
          this.isLoading = false
          this.usersList = []
        }).finally(() =>
        {
          this.findUsersLoading = false
        })
    },
    corporateName({ corporate_name })
    {
      return corporate_name
    },
    userName({ fname, lname, email })
    {
      return `${fname} ${lname} (${email})`
    },
    panToMarker(pos)
    {
      this.$refs.Map.panTo(pos)
    },
    adjustMapZoom()
    {
      if (!this.routeEditMode) {
        this.routeEditMode = true
      }
      this.zoomLevel = 13
      const bounds = new window.google.maps.LatLngBounds()
      if (this.startingPointModel) {
        bounds.extend({
          lat: this.startingPointModel?.latitude,
          lng: this.startingPointModel?.longitude
        })
      }

      if (this.destinationPointModel) {
        bounds.extend({
          lat: this.destinationPointModel?.latitude,
          lng: this.destinationPointModel?.longitude
        })
      }
      if (Object.keys(this.$refs).length > 0) {
    this.$refs.Map.fitBounds(bounds)
      this.panToMarker(bounds.getCenter())
      this.generateRoutePreview()
}
    
    },
    originChanged(val)
    {
      this.startingPointModel = val
      this.startingPoint = this.$refs.startingPointAddress.autocompleteText
    },
    clearOrigin()
    {
      this.startingPointModel = null
      this.$refs.startingPointAddress.clear()
      this.startingPoint = ''
    },
    destinationChanged(val)
    {
      this.destinationPointModel = val
      this.destinationPoint =
        this.$refs.destinationPointAddress.autocompleteText
    },
    clearDestination()
    {
      this.destinationPointModel = null
      this.$refs.destinationPointAddress.clear()
      this.destinationPoint = ''
    },
    addWayPoint()
    {
      const newId = this.waypoints.length + 1
      const newPoint = { id: newId, val: null }
      if (!newPoint) return
      this.waypoints.push(newPoint)
    },
    removeStop(index)
    {
      this.waypoints.splice(index, 1)
    },
    async generateRoutePreview()
    {
      if (this.savingRoute) return

      const vm = this
      if (!this.startingPointModel || !this.destinationPointModel) return
      const startPoint = `${this.startingPointModel?.latitude},${this.startingPointModel?.longitude}`
      const endPoint = `${this.destinationPointModel?.latitude},${this.destinationPointModel?.longitude}`
      const waypoints = []

      if (this.waypoints && this.waypoints.length) {
        this.waypoints.forEach((entry) =>
        {
          const v = entry.val
          if (v && v.latitude && v.longitude) {
            waypoints.push(`${v.latitude},${v.longitude}`)
          }
        })
      }

      if (vm.cancelFnToRoutePreview != null) {
        vm.cancelFnToRoutePreview()
        vm.cancelFnToRoutePreview = null
      }

      try {
        this.generatingRoutePreview = true
        this.$Progress.start()
        const { data } = await routeResource.generateRoutePreview(
          startPoint,
          endPoint,
          waypoints,
          {
            cancelToken: new CancelToken(function executor(c)
            {
              vm.cancelFnToRoutePreview = c
            })
          }
        )

        this.$Progress.finish()
        this.generatingRoutePreview = false
        this.currentRoutePreviewData = data.data
        this.renderRoutePreview()
      } catch (e) {
        this.generatingRoutePreview = false
        this.$Progress.finish()
      }
    },
    renderRoutePreview()
    {
      const vm = this
      if (
        this.currentlyShowingFeatures &&
        this.currentlyShowingFeatures.length
      ) {
        this.currentlyShowingFeatures.forEach((r) =>
        {
          this.$refs.Map.$_map.data.remove(r)
        })
        this.currentlyShowingFeatures = []
      }
      if (this.currentRoutePreviewData && this.currentRoutePreviewData.routes) {
        this.currentRoutePreviewData.routes.forEach((route) =>
        {
          this.$refs.Map.$_map.data.setStyle({
            strokeWeight: 8,
            strokeColor: '#4a4a4a',
            clickable: true,
            title: 'preview'
          })

          this.$refs.Map.$_map.data.addListener('click', function (event)
          {
            const index = vm.currentlyShowingFeatures.indexOf(event.feature)
            if (index > -1) {
              vm.selectActiveRoute(index)
            }
          })

          const obj = JSON.parse(route.overview_geojson)
          route.overview_geojson_obj = obj

          this.currentlyShowingFeatures.push(
            this.$refs.Map.$_map.data.add(
              this.getGeoJson(
                obj.coordinates.map((i) =>
                {
                  return { lat: i[1], lng: i[0] }
                })
              )
            )
          )
        })
      }

      if (this.currentlyShowingFeatures.length) {
        this.selectActiveRoute(0)
      }
    },
    selectActiveRoute(index)
    {
      this.$refs.Map.$_map.data.overrideStyle(
        this.currentlyShowingFeatures[index],
        { strokeColor: '#109352' }
      )
      this.activeRouteOption = this.currentRoutePreviewData.routes[index]
    },
    getGeoJson(route)
    {
      return {
        geometry: new window.google.maps.Data.LineString(route)
      }
    },

    async saveRoute()
    {
      if (this.savingRoute) {
        this.$swal('Oops', 'Still saving route, please wait', 'danger')
        return
      }

      if (this.generatingRoutePreview) {
        this.$swal('Oops', 'Still generating route preview', 'danger')
        return
      }

      if (this.routeEditMode && !this.activeRouteOption) {
        this.$swal('Oops', 'active route not set', 'danger')
        return
      }

      if (
        this.newRoute.visibility == 'private' &&
        !this.newRoute.corporateOwner
      ) {
        this.$swal('Oops', 'private route need a corporate', 'danger')
        return
      }

      if (!this.isValidAvailableDates()) {
        this.$swal('Oops!', 'Please select a valid route availability option', 'error')
        return
      }

      let data = {
        payer: this.newRoute.payer,
        payment_mode: this.newRoute.payment_mode,
        owner_type: this.newRoute.owner_type,
        owner_id: this.newRoute.owner_id ? this.newRoute.owner_id.id : null,
        is_exclusive: this.newRoute.is_exclusive,
        visibility: this.newRoute.visibility,
        route_availability_days: this.newRoute.route_availability_days,
        corporate_id: this.newRoute.corporateOwner
          ? this.newRoute.corporateOwner.id
          : null,
        is_future_route: false,
        route_code: this.newRoute.code,
        info: {
          description: this.newRoute.description
        },
        route_availability_end_date: this.newRoute.route_availability_end_date ? this.newRoute.route_availability_end_date : null,
        route_availability_start_date: this.newRoute.route_availability_start_date ? this.newRoute.route_availability_start_date : null,
        status: this.newRoute.status,
        blacklisted_availability_days: this.newRoute.blacklisted_availability_days || []

      }

      if (!data.owner_id) {
        delete data.owner_id
      }

      if (this.$refs.startingPointAddress.autocompleteText != '') {
        data = {
          ...data, pickup: this.$refs.startingPointAddress.autocompleteText
        }
      }

      if (this.$refs.destinationPointAddress.autocompleteText != '') {
        data = {
          ...data, destination: this.$refs.destinationPointAddress.autocompleteText
        }
      }

      if (this.routeEditMode) {
        const activeRoute = this.activeRouteOption
        const legCumulative = {
          duration: { value: 0, text: '' },
          distance: { value: 0, text: '' }
        }

        activeRoute.legs.forEach((v) =>
        {
          if (v.duration.value && v.duration.value > 0) {
            legCumulative.duration.value =
              v.duration.value + legCumulative.duration.value
            legCumulative.duration.text = `${(
              legCumulative.duration.value / 60
            ).toFixed(0)} mins`
          }

          if (v.distance.value && v.distance.value > 0) {
            legCumulative.distance.value =
              v.distance.value + legCumulative.distance.value
            if (legCumulative.distance.value < 1000) {
              legCumulative.distance.text = `${legCumulative.distance.value} m`
            } else {
              legCumulative.distance.text = `${(
                legCumulative.distance.value / 1000
              ).toFixed(2)} km`
            }
          }
        })

        data = {
          ...data,
          route_preview: activeRoute.overview_geojson_obj,
          bounds: activeRoute.bounds,
          duration_value: legCumulative.duration.value,
          distance_value: legCumulative.distance.value,
          duration: legCumulative.duration.text,
          distance: legCumulative.distance.text,
          start_location: {
            lat: this.startingPointModel?.latitude,
            lng: this.startingPointModel?.longitude
          },
          end_location: {
            lat: this.destinationPointModel?.latitude,
            lng: this.destinationPointModel?.longitude
          }
        }
      }

      try {
        this.savingRoute = true
        this.$Progress.start()
        const updatedRoute = await routeResource.saveRoute(
          this.activeRoute.id,
          data
        )
        this.$eventbus.$emit('route:updated', updatedRoute.data)
        this.$Progress.finish()
        this.savingRoute = false
        this.$router.push({
          name: 'ShowRoute',
          params: {
            routeId: this.activeRoute.id
          }
        })
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          this.$swal('Opps', e.response.data.message, 'danger')
        }

        this.savingRoute = false
        this.$Progress.fail()
      }
    },
    setDefaultAvailableDays()
    {
      this.routeAvailability = true
      this.newRoute.route_availability_days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    },
    initRouteAvailableDays(availableDays)
    {
      this.newRoute.route_availability_days = availableDays
      if (availableDays && availableDays.length === 7) {
        this.routeAvailability = true
      } else if (!availableDays) {
        this.setDefaultAvailableDays()
      } else {
        this.routeAvailability = false
      }
    },
    updateAvailableDays(value)
    {
      this.newRoute.route_availability_days = value
      this.routeAvailability = value && value.length === 7
    },
    onRouteAvailabilityChange(value)
    {
      this.routeAvailability = value
      if (value) {
        this.setDefaultAvailableDays()
      } else {
        this.newRoute.route_availability_days = []
      }
    },
    isValidAvailableDates()
    {
      return (this.routeAvailability && this.newRoute.route_availability_days.length === 7) ||
        (!this.routeAvailability && this.newRoute.route_availability_days.length)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.vue__time-picker input.display-time {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px) !important;
  padding: 0.5rem 0.75rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5 !important;
  color: #12263f;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #d2ddec;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>

<style lang="scss" scoped>
.sticky-map-wrapper {
  position: sticky;
  top: 0;
}
</style>
