import {ref} from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import Swal from 'sweetalert2'
import {useFetchChargeTypes} from './fetch'

const chargeObj = {
	name: ref(''),
	short_name: ref(''),
	desc: ref(''),
	id: ref(null)
}
const loading = ref(false)
const showAddTypeModal = ref(false)
const isEditChargeType = ref(false)
const closeAddTypeModal = () => {
	showAddTypeModal.value = false
	isEditChargeType.value = false
	chargeObj.name.value = ''
	chargeObj.short_name.value = ''
	chargeObj.desc.value = ''
	chargeObj.id.value = null
}
const { fetchAllChargeTypes } = useFetchChargeTypes()
export const useCreateChargeType = () => {
	const createCharge = async () => {
		loading.value = true
		const payload = {
			name: chargeObj.name.value,
			short_name: chargeObj.short_name.value,
			description: chargeObj.desc.value,
			is_compulsory: false
		}
		try {
			const res = await axios.post('/v1/additional-charges-types', payload)
			if(res.data?.id) {
				Swal.fire({ icon: 'success', title: 'Success', text: 'Charge created successfully'})
				closeAddTypeModal()
				fetchAllChargeTypes()
			} else {
				Swal.fire({ icon: 'error', title: 'Error', text: res.data?.message ?? 'Could not create charge'})
			}
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		} finally{
			loading.value = false
		}
	}

	const initEditCharge = (data) => {
		isEditChargeType.value = true
		chargeObj.id.value = data.id
		chargeObj.name.value = data.name
		chargeObj.short_name.value = data.short_name
		chargeObj.desc.value = data.description
		showAddTypeModal.value = true
	}

	const updateCharge = async () => {
		loading.value = true
		const payload = {
			name: chargeObj.name.value,
			short_name: chargeObj.short_name.value,
			description: chargeObj.desc.value,
			is_compulsory: false
		}
		try {
			const res = await axios.patch(`/v1/additional-charges-types/${chargeObj.id.value}`, payload)
			if(res.data?.name) {
				Swal.fire({ icon: 'success', title: 'Success', text: 'Charge updated successfully'})
				closeAddTypeModal()
				fetchAllChargeTypes()
			} else {
				Swal.fire({ icon: 'error', title: 'Error', text: res.data?.message ?? 'Could not update charge'})
			}
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		} finally {
			loading.value = false
		}
	}

	return { ...chargeObj, createCharge, loading, showAddTypeModal, closeAddTypeModal, initEditCharge, isEditChargeType, updateCharge }
}