import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardLayout from '@/layouts/core/Dashboard.vue'
import Login from '../views/Auth/Login.vue'
import UnAuthorized from '../views/Auth/UnAuthorized.vue'
import ForgotPassword from '../views/Auth/ForgotPassword.vue'
import ResetPassword from '../views/Auth/ResetPassword.vue'
// import BusStopsIndex from '@/views/BusStops/Index.vue'
// import BusStopsCreate from '@/views/BusStops/Create.vue'
import Dashboard from '@/views/Dashboard.vue'
import Redirect from '@/views/Redirect.vue'

// import FeatureFlagService, {FEATURE_FLAGS} from "@/utils/feature-flags";
import tripRoutes from '@/views/Trips/route/trip.routes'
import WaitlistRoutes from '@/views/Waitlist/waitlist.routes'
import CompanyRoutes from '@/views/Company/company.routes'
import PartnerRoutes from '@/views/Partners/partner.routes'
import RoutesModule from '@/views/Routes/routes'
import BookingRoutes from '@/views/Bookings/bookings.routes'
import ReferralCodeRoutes from '@/views/ReferralCodes/referralCodes.routes'
import TransactionsRoutes from '@/views/Transactions/transactions.routes'
import PromoCodesRoutes from '@/views/PromoCodes/promoCodes.routes'
import FleetsRoutes from '@/views/Fleet/fleets.routes'
import CharterRoutes from '@/views/Charter/charter.routes'
import RouteSearchRoutes from '@/views/RouteSearch/routeSearch.routes'
import UserRoutes from '@/views/Users/users.routes'
import DriversRoutes from '@/views/Drivers/drivers.routes'
import AdminRoutes from '@/views/Admins/admin.routes'
import AdminConfigRoutes from '@/views/AdminConfig/adminConfig.routes'
import EventRoutes from '@/views/Events/event.routes'
import GamesAndCampaignsRoutes from '@/views/Games&Campaigns/games&campaigns.routes'
import TripServiceRoutes from '@/views/TripServices/tripService.routes'
import BusstopRoutes from '@/views/BusStops/busstops.routes'
import RequestRoutes from '@/views/Request/request.routes'
// import RouteItinerary from '@/views/Routes/RouteItinerary'

function loadRouteComponent (path) {
  return () =>
    import(
      /* webpackChunkName: "app-view-[request]" */
      `@/views/${path}.vue`
    )
}

Vue.use(VueRouter)

// TODO: Remove OldRouteItinerary when feature is fully adopted on production and feature flag is removed from release

const routes = [
  {
  path: '/redirect',
  name: 'Redirect',
  component: Redirect,
  },

  {
    path: '/',
    name: 'Home',
    redirect: '/dashboard',
    meta: { requiresAuth: true },
    component: DashboardLayout,
    children: [
      {
        path: '/unauthorized',
        name: 'unauthorized',
        component: UnAuthorized
      },
      {
        path: 'dashboard',
        component: Dashboard,
        name: 'Dashboard',
        props: true,
        meta: {
          requiredPermissions: false
        }
      },
      {
        path: 'routes/suggested-routes',
        component: loadRouteComponent('Trips/pages/SuggestedRoutes'),
        name: 'SuggestedRoutes',
        props: true
      },
      {
        path: 'companies/add/:companyId?',
        component: loadRouteComponent('Company/pages/Manage'),
        name: 'ManageCompany',
        props: true,
        meta: {
          requiredPermissions: ['corporates001']
        }
      },
      {
        path: 'companies/edit/:companyId?',
        component: loadRouteComponent('Company/pages/EditCompany'),
        name: 'EditCompany',
        props: true,
        meta: {
          requiredPermissions: ['corporates004']
        }
      },
      {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      ...tripRoutes,
      ...WaitlistRoutes,
      ...PartnerRoutes,
      ...CompanyRoutes,
      ...RoutesModule,
      ...BookingRoutes,
      ...ReferralCodeRoutes,
      ...TransactionsRoutes,
      ...PromoCodesRoutes,
      ...FleetsRoutes,
      ...CharterRoutes,
      ...RouteSearchRoutes,
      ...UserRoutes,
      ...DriversRoutes,
      ...AdminRoutes,
      ...AdminConfigRoutes,
      ...EventRoutes,
      ...GamesAndCampaignsRoutes,
      ...TripServiceRoutes,
      ...BusstopRoutes,
      ...RequestRoutes,
      {
        path: 'profile',
        component: loadRouteComponent('Profile'),
        name: 'Profile',
        props: true
      },
      {
        path: 'survey',
        component: loadRouteComponent('ListSurvey'),
        name: 'ListSurvey',
        props: true
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: { requiresAuth: false }
  },
  {
    path: '/password/:token',
    name: 'reset-password',
    component: ResetPassword,
    props: true,
    meta: { requiresAuth: false }
  },
  { path: '*', component: loadRouteComponent('NotFound') }
  // { path: '*', redirect: '/page-not-found' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
