import Vue from 'vue'
import 'h3-js'
import 'bootstrap'
import '@/assets/scss/theme.scss'
import '@/assets/styles/main.css'
import 'vue-select/dist/vue-select.css'
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Axios, { axiosInstance, setAuthorization } from './plugins/axios'
import socketController from './services/socketio'
import App from './App.vue'
import router from './router'
import store from './store'
import AdminSession from './services/session'
import VueCurrencyFilter from 'vue-currency-filter'
import { ServerTable } from 'vue-tables-2'
import VueProgressBar from 'vue-progressbar'
import vSelect from 'vue-select'
import VueNotification from '@kugatsu/vuenotification'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueGoogleMap from 'vuejs-google-maps'
import 'vuejs-google-maps/dist/vuejs-google-maps.css'
import DatePicker from 'vue2-datepicker'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import LightGallery from 'vue-light-gallery'
import 'vue2-datepicker/index.css'
import VuePapaParse from 'vue-papa-parse'
import zoom from 'vue-image-zoom'
import moment from 'moment'
import 'vue-image-zoom/dist/vue-image-zoom.css'
import
{
  AlertPlugin,
  ButtonPlugin,
  DropdownPlugin,
  FormCheckboxPlugin,
  FormSelectPlugin,
  FormInputPlugin,
  FormTimepickerPlugin,
  ModalPlugin,
  PaginationPlugin,
  TablePlugin,
  BadgePlugin,
  CardPlugin,
  LayoutPlugin,
  PopoverPlugin,
  AvatarPlugin,
  BreadcrumbPlugin,
  SpinnerPlugin,
  FormGroupPlugin,
  IconsPlugin,
  ToastPlugin,
  FormTextareaPlugin
} from 'bootstrap-vue'
import authMiddleware from '@/router/middleware/auth.js'
import authorizationMiddleware from '@/router/middleware/authorization.js'

import 'vue2-timepicker/dist/VueTimepicker.css'
import VueToastr from 'vue-toastr'
import filters from '@/filters'
import OtpInput from '@bachdgvn/vue-otp-input'

import { isTokenExpired } from '@/utils/helpers'
import VueSimpleContextMenu from 'vue-simple-context-menu'
import FeatureFlagService from '@/utils/feature-flags'
import RemoteConfigService from '@/services/remote-config'
import VCalendar from 'v-calendar'
import { SimpleTimelinePlugin } from 'simple-vue-timeline'
// import VueClipboard from 'vue-clipboard2'
// import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js'

Vue.use(BadgePlugin)
Vue.use(CardPlugin)
// Vue.use(VueClipboard)
Vue.use(LayoutPlugin)
Vue.use(SimpleTimelinePlugin)
Vue.use(VueToastr)
Vue.use(DropdownPlugin)
Vue.use(ModalPlugin)
Vue.use(TablePlugin)
Vue.use(PaginationPlugin)
Vue.use(ButtonPlugin)
Vue.use(PopoverPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormTimepickerPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(AlertPlugin)
Vue.use(AvatarPlugin)
// Vue.use(VueEasyLightbox)
Vue.use(LightGallery)
Vue.use(VuePapaParse)
Vue.use(zoom)
Vue.use(BreadcrumbPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormGroupPlugin)
Vue.use(IconsPlugin)
Vue.use(ToastPlugin)
Vue.use(FormTextareaPlugin)
Vue.component('vue-simple-context-menu', VueSimpleContextMenu)
Vue.component('font-awesome-icon', FontAwesomeIcon)

moment.locale('en')
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'v'
})

Vue.use(VueGoogleMap, {
  load: {
    apiKey: process.env.VUE_APP_GOOGLE_APIKEY,
    libraries: ['places', 'geometry']
  }
})

const vueSweetalert2Options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674'
}

Vue.use(VueSweetalert2, vueSweetalert2Options)
Vue.use(Axios)
Vue.component('v-select', vSelect)
Vue.component('v-datepicker', DatePicker)
Vue.component('v-otp-input', OtpInput)
Vue.use(VueNotification, {
  timer: 20,
  showCloseIcn: true
})

Vue.use(ServerTable, {}, false, 'bootstrap4')

const options = {
  color: '#081570',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  autoFinish: false,
  location: 'top',
  inverse: false
}

Vue.use(VueProgressBar, options)

Vue.use(VueCurrencyFilter, {
  symbol: '₦',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
})

Vue.config.productionTip = false
Vue.prototype.$session = AdminSession
Vue.prototype.$socketController = socketController

const getUser = async (staffId) =>
{
  const result = await axiosInstance.get(
    `/v1/super-admin/staff-module-permissions/staff/${staffId}`
  )
  return result.data
}

AdminSession.fetchCurrentSession()
  .then(async (session) =>
  {
    setAuthorization(session?.token?.token)
    const user = await getUser(session?.id)
    user.staff.permissions = user?.permissions
    user.staff.role = user?.staff?.role ?? 'user'
    user.staff.token = session?.token

    store.dispatch('setCurrentUser', user.staff)
    socketController.setAuthToken(session?.token?.token)
  }) 
  .then(() =>
  {
    return RemoteConfigService.initialize()
  })
  .then(() =>
  {
    FeatureFlagService.setFeatureFlagConfigData()
  })
  .catch((e) =>
  {
    store.dispatch('setCurrentUser', null)
  })
  .finally(() =>
  {
    socketController.boot()
    router.beforeEach(authMiddleware(store, isTokenExpired))
    router.beforeEach(authorizationMiddleware(store))

    Object.keys(filters).forEach((filterKey) =>
    {
      Vue.filter(filterKey, filters[filterKey])
    })

    const eventBus = new Vue()

    Vue.prototype.$eventbus = eventBus

    new Vue({
      router,
      store,
      render: (h) => h(App)
    }).$mount('#app')
  })
