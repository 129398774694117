<template>
  <main>
    <div class="card-body p-0">
      <div
        class="flex flex-col md:flex-row px-4 py-3 rounded-tl-lg rounded-tr-lg gap-4 justify-end md:items-center bg-white">
        <div class="flex items-center gap-2">
          <label class="m-0 text-sm text-[#6E717C]">Route type</label>
          <select v-model="type" class="border-0 font-medium">
            <option value="">All routes</option>
            <option value="exclusive">Exclusive</option>
            <option value="shared">Shared</option>
          </select>
        </div>

        <div class="flex items-center gap-2">
          <label class="m-0 text-sm text-[#6E717C]">Status</label>
          <select v-model="status" class="border-0 font-medium">
            <option value="">All</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      </div>
      <b-table ref="staffTable" :current-page="currentPage" :busy="routeLoading" responsive striped
        :items="suspendedRoutes" :fields="fields">
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <strong>Loading...</strong>
          </div>
        </template>
        <template #empty>
          <h4>There are no staff found</h4>
        </template>
        <template #cell(serial_number)="data">
          {{ (currentPage - 1) * perPage + data.index + 1 }}
        </template>
        <template #cell(suspension_data)="data">
          {{ !data.item.suspension_data?.routes?.length ? 'All' : data.item.suspension_data.routes.map((route) =>
            route.route_code ?? 'N/A').join(', ') }}
        </template>
        <template #cell(suspension_dates)="data">
          <!-- // data.value
            //   .map((date) => moment(date).format('dddd, MMMM Do YYYY'))
            //   .join(' ⚫️ \n ') -->
          <span v-for="n in data.value" :key="n" class="block whitespace-nowrap">
            {{ moment(n).format('dddd, MMMM Do YYYY') }}
          </span>
        </template>
        <template #cell(route_type)="data">
          <p class="m-0 "
            :class="data.item.route_type == 'shared' ? 'text-[#B89E00]' : data.item.route_type == 'exclusive' ? 'text-[#4848ED]' : ''">
            {{ Capitalize(data.item.route_type) ?? 'N/A' }}</p>

        </template>
        <template #cell(route_visibility)="data">
          {{ Capitalize(data.item.route_visibility) ?? 'N/A' }} <br>
          {{ data.item.suspension_data?.corporates? `(${data.item.suspension_data?.corporates[0].corporate_name})` : '' }}
        </template>
        <template #cell(staff_id)="data">
          <p class="whitespace-nowrap">{{ data.item.staff.fname }} {{ data.item.staff.lname }} <br /> {{
            data.item.staff.email }}</p>
        </template>
        <template #cell(created_at)="data">
          <p class="m-0 text-sm whitespace-nowrap w-fit">{{ moment(data.item.created_at).format('ddd, Do MMMM YYYY') }}
          </p>
        </template>
        <template #cell(suspension_reason)="data">
          <p class="m-0 min-w-[130px]">{{ data.item.suspension_reason }}</p>
        </template>
        <template #cell(status)="data">
          <p class=" m-0 py-1 text-white text-[12px] w-fit rounded px-2 font-medium"
            :class="data.item.status == 'active' ? 'bg-[#15CD70]' : data.item.status == 'completed' ? 'bg-[#4848ED]' : data.item.status == 'inactive' ? 'bg-[#6E717C]' : 'bg-[#6E717C]'">
            {{ data.item.status == 'inactive' ? 'Cancelled' : data.item.status }}</p>
        </template>
        <template #cell(updated_at)="data">
          <button v-if="data.item.status == 'active'"
            class='btn bg-transparent text-black text-xs rounded outline-none border-black'
            @click='openRestoreModal(data.item)'>Cancel</button>
          <p v-else class="m-0 text-sm whitespace-nowrap w-fit">{{ moment(data.item.updated_at).format('MMMM Do YYYY') }}
          </p>
        </template>
      </b-table>
      <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
    </div>

    <BS_modal :show='showRestoreModal' title='Restore Routes' @close='closeRestoreModal' modalId='restoreModal'>
      <div class="col-12">
        <div class="form-group relative">
          <label class="mb-1">Type in your Password for Confirmation</label>
          <input required :type="showPassword ? 'text' : 'password'" class="form-control"
            v-model='payloadDate.staff_password.value' />
          <span @click="showPassword = !showPassword" class="fe absolute right-4 top-[34px] cursor-pointer"
            :class="[showPassword ? 'fe-eye' : 'fe-eye-off']"></span>
        </div>
      </div>

      <div class="col-12">
        <button class='btn bg-dark text-white w-full' @click='restoreRoute'
          :disabled="payloadDate.staff_password.value == ''">
          {{ payloadDate.loading.value ? 'loading...' : 'Restore routes' }}
        </button>
      </div>
    </BS_modal>

  </main>
</template>

<script setup >
import Swal from 'sweetalert2'
import { ref, watch } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import BS_modal from '@/components/core/modals/BS_modal.vue'
import moment from 'moment'
import { useSuspendedRoutes } from './composable'
import { Capitalize } from '@/composables/utils'

const showPassword = ref(false)
const showRestoreModal = ref(false)
const payloadDate = {
  loading: ref(false),
  staff_password: ref(''),
}
const selectedRoute = ref(null)
const restoreRoute = () => {
  payloadDate.loading.value = true
  const obj = {
    suspension_ids: [selectedRoute.value.id],
    staff_password: payloadDate.staff_password.value,
    // route_ids: selectedRoute.value.suspension_data.routes.map((route) => route.id),
  }

  if (selectedRoute.value.suspension_data?.routes?.length) {
    obj['route_ids'] = selectedRoute.value.suspension_data.routes.map((route) => route.id)
  }

  axiosInstance
    .patch('/v1/routes/booking-restorations', obj)
    .then((res) => {
      payloadDate.loading.value = false
      payloadDate.staff_password.value = ''
      showRestoreModal.value = false

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Route restored successfully',
      })
      getRoutes()
    })
    .catch((err) => {
      payloadDate.loading.value = false
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: err.response.data.message,
      })
    })
}
const openRestoreModal = (data) => {
  selectedRoute.value = data
  showRestoreModal.value = true
}
const { currentPage, getRoutes, routeLoading, suspendedRoutes, perPage, totalRecords, type, status } = useSuspendedRoutes()
getRoutes()

const fields = [
  // { key: 'id', label: 'ID' },
  { key: 'serial_number', label: 's/n' },
  { key: 'route_type', label: 'Route Type' },
  { key: 'route_visibility', label: 'Route Visibility' },
  { key: 'suspension_data', label: 'Route Codes' },
  { key: 'suspension_dates', label: 'Period' },
  { key: 'staff_id', label: 'Admin' },
  { key: 'created_at', label: 'Date performed' },
  { key: 'suspension_reason', label: 'Reason' },
  { key: 'status', label: 'Status' },
  // { key: 'suspension_dates', label: 'Suspension Dates' },
  { key: 'updated_at', label: 'Action' },
]

watch(currentPage, () => {
  getRoutes()
})
watch([type, status], () => {
  getRoutes()
})

const closeRestoreModal = () => {
  showRestoreModal.value = false
  payloadDate.staff_password.value = ''
}
</script>

<style scoped></style>
