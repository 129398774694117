import {ref} from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import Swal from 'sweetalert2'
import router from '@/router'

const loading = ref(false)
const chargeHistory = ref([])
const chargeHistoryMeta = ref(null)
const currentPage = ref(1)
const perPage = ref(2)
const date = ref([])
const status = ref('All')


export const useDetails = () => {
	const fetchHistory = async () => {
		loading.value = true
		try {
			const res = await axios.get(`/v1/additional-charges-history?limit=${perPage.value}&page=${currentPage.value}`)
			if (res.data?.data?.length) {
				chargeHistory.value = res.data.data
				chargeHistoryMeta.value = res.data.metadata
			} else {
				chargeHistory.value = []
			}
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		} finally{
			loading.value = false
		}
	}

	return { loading, fetchHistory, chargeHistory, chargeHistoryMeta, currentPage, perPage, date, status }
}
