<template>
	<div class="route-controller">
		<div class="bg-white border-bottom" >
			<div class="header mb-0">
				<div class="container-fluid">
					<div class="header-body border-0">
						<div class="row align-items-end">
							<div class="col">
								<h6 class="header-pretitle">
									<router-link :to="{ name: 'RoutesList' }" class=""
										>Routes</router-link
										>
								</h6>
								<h2 class="header-title" v-if="activeRoute">
									{{ activeRoute.route_code }} &#x2022;
									{{ activeRoute.pickup }} - {{ activeRoute.destination }}
								</h2>
							</div>
							<div class="col-auto">
								<route-list-item-menu :route="activeRoute" :show-label="true" />
							</div>
						</div>
						<!-- / .row -->
					</div>
					<!-- / .header-body -->
				</div>
			</div>

			<div class="container-fluid">
				<div class="row">
					<div class="col">
						<div>
							<ul class="nav nav-pills mb-1">
								<li class="nav-item">
									<router-link
										class="nav-link"
										:to="{
											name: 'ShowRouteDetails',
											params: { routeId: routeId },
										}"
										exact-active-class="active"
										>Details</router-link
										>
								</li>
								<li class="nav-item">
									<router-link
										class="nav-link"
										:to="{
											name: 'ShowRouteBusStops',
											params: { routeId: routeId },
										}"
										exact-active-class="active"
										>Bus Stops</router-link
										>
								</li>
								<li class="nav-item">
									<router-link
										class="nav-link"
										:to="{
											name: 'ShowRouteItineraries',
											params: { routeId: routeId },
										}"
										exact-active-class="active"
										active-class="active"
										>Itineraries</router-link
										>
								</li>
								<li class="nav-item">
									<router-link
										class="nav-link"
										:to="{
											name: 'ShowRouteTrips',
											params: { routeId: routeId },
										}"
										exact-active-class="active"
										active-class="active"
										>Trips</router-link
										>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div>
			<router-view />
		</div>
		<BS_modal :show='modalStatus' title='Duplicate Routes' @close='closeDuplicateRouteModal' modalId='duplicateRouteModalOne'>
			<div class="flex flex-col gap-4">
			<div class="flex flex-col">
				<label class="m-0">New route code</label>
				<input type="text" class="p-2 border rounded-md" v-model="new_route_code">
			</div>
			
			<div class="flex items-center gap-4">
				<input type="checkbox" id="iti" v-model="copy_itinary">
				<label class="m-0" for="iti">Copy Itineraries</label>
			</div>

			<button class='btn bg-dark text-white w-full mt-[30px]' @click='proceedToDuplicate' :disabled="new_route_code == ''">
				{{ dup_loading ? 'loading...' : 'Duplicate route' }}
			</button>
			</div>
		</BS_modal>
	</div>
</template>

<script setup>
import { useRouteDuplicate } from '@/views/Routes/composables/route'
import BS_modal from '@/components/core/modals/BS_modal.vue'

const { modalStatus, new_route_code, copy_itinary, dup_loading, proceedToDuplicate, closeDuplicateRouteModal } = useRouteDuplicate()
</script>

<script>
import RouteListItemMenu from '@/components/modules/routes/RouteListItemMenu.vue'
export default {
  components: {
    RouteListItemMenu
  },
  props: {
    routeId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      activeRoute: null
    }
  },
  provide () {
    return {
      activeRoute: () => this.activeRoute
    }
  },
  created () {
    this.loadActiveRoute()
  },
  watch: {
    $route: {
      deep: true,
      handler () {
        if (this.activeRoute && this.$route.routeId != this.activeRoute.id) {
          this.loadActiveRoute()
        }
      }
    }
  },
  methods: {
    async loadActiveRoute () {
      try {
        this.$Progress.start()
        const result = await this.axios.get(`/v1/routes/${this.routeId}`)
        const route = result.data
        route.showInfo = false
        this.showRoute(route)
        this.$Progress.finish()
      } catch (e) {
        this.$Progress.fail()
      }
    },
    showRoute (route) {
      this.activeRoute = route
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show>.nav-link {
  border-radius: 0;
}
</style>
