import moment from 'moment'
import {ref} from 'vue'
import { useResizeObserver } from '@vueuse/core'

export const addToQuery = (route, router, obj) => {
	// obj is an object
	const oldQuery = route.query
	const newQuery = { ...oldQuery, ...obj }
	router.push({ query: newQuery })
}
export const removeQueryParam = (route, router, queryNames) => {
	// queryNames is an array
	const queries = route.query
	const query = { ...queries }
	for (let i = 0; i < queryNames.length; i++) {
		const el = queryNames[i]
		delete query[el]
	}
	router.push({ query })
}

export const formatTime = (time) => {
	return moment(time).format('LT')
}

export const formatDate = (date) => {
	return moment(date).format('DD-MM-YYYY')
}

export const formatApiCallDate = (date) => {
	return moment(date).format('YYYY-MM-DD')
}

export function downloadFile (url, fileName) {
	const link = document.createElement('a')
	link.href = url
	link.download = fileName
	link.style.display = 'none'
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

export const dateStringToDateObject = (date) => {
	return moment(date, 'DD-MM-YYYY').toDate()
}

export const Capitalize = (str) => {
	if(!str) return null
	return str.charAt(0).toUpperCase() + str.slice(1)
}

export const formatNaira = (amount) => {
	if (amount === null) return null
	if (amount === undefined) return undefined
	if (typeof amount !== 'number') return amount
	return amount.toLocaleString('en-NG', { style: 'currency', currency: 'NGN' })
}

export const convertToCurrency = (value) => {
	if (typeof value === 'undefined') return
 
	let parseAmount
	if (typeof value !== 'number') {
		parseAmount = parseFloat((value).replace(',', '').replace(' ', ''))
	} else {
		parseAmount = value
	}
	return new Intl.NumberFormat('en-NG', {
		style: 'currency',
		currency: 'NGN'
	}).format(parseAmount)
}

export const windowWidth = ref(window.innerWidth)

useResizeObserver(window.document.body, (entries) => {
	const entry = entries[0]
	const { width } = entry.contentRect
	windowWidth.value = width
})

export const truncate = (str, count = 50) => {
	if (str.length <= count) {
		return str
	} else {
		return str.slice(0, count) + '...'
	}
}