var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg-white border-bottom"},[_vm._m(0),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row pt-3 pb-1"},[_c('div',{staticClass:"col justify-self-center align-self-center flex-column"},[_c('ul',{staticClass:"nav nav-pills"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
									name: 'ActiveTrips',
								},"active-class":"active"}},[_vm._v("Active Trips")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
									name: 'UpcomingTrips',
								},"active-class":"active"}},[_vm._v("Upcoming Trips")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
									name: 'CompletedTrips',
								},"active-class":"active"}},[_vm._v("Completed Trips")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{name: 'BusCaptains'},"active-class":"active"}},[_vm._v(" Bus Captains ")])],1)])])])])]),_c('div',[_c('div',{staticClass:"container-fluid"},[_c('router-view')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header mb-0"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"header-body py-0 mt-4 border-0"},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"header-pretitle"},[_vm._v("Overview")]),_c('h1',{staticClass:"header-title"},[_vm._v("Trips")])])])])])])
}]

export { render, staticRenderFns }