<template>
	<div class="content card-body d-flex flex-column justify-content-center align-items-center">
		<div>
			<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
				<circle cx="12" cy="12" r="10"></circle>
				<line x1="12" y1="8" x2="12" y2="12"></line>
				<line x1="12" y1="16" x2="12.01" y2="16"></line>
			</svg>
		</div>
		<p class="text-muted mt-3">Permission not allowed!</p>
		<button class="btn-primary btn-sm px-6 py-2" @click='$router.go(-1)'>
			Go Back
		</button>
	</div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import { getUserPermission } from '@/composables/core/permissions/index'

const route = useRoute()
const router = useRouter()

const initialRedirect = () => {
  const redirect = route.query.redirect
  if (!redirect) return
  router.push(redirect)
}

onMounted(async () => {
  await getUserPermission()
  initialRedirect()
})

</script>

<style scoped>
.content {
  height: 100vh;
}
</style>
