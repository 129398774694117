<template>
    <div class="card">
        <table class="table">
            <tbody>
                <tr>
                    <td>Who pays for booking?</td>
                    <td>
                        <span >
                            {{ route.payer }}
                        </span>
                    </td>
                </tr>

                <tr>
                    <td>When is payment happening?</td>
                    <td>
                        <span >
                            {{ route.payment_mode }}
                        </span>
                    </td>
                </tr>

                <tr>
                    <td>Who owns this route?</td>
                    <td>
                        <span>
                            {{ route.owner_type }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
	props: {
		route: {
			type: [Object],
			required: true
		}
	},
}
</script>