import { differenceInDays, endOfMonth, startOfMonth, isLastDayOfMonth, lastDayOfMonth, format, eachDayOfInterval, parseISO, getWeek, getWeekOfMonth } from 'date-fns'
import _ from 'lodash'

export const joinWithRatingsData = (array1, array2) => {
  const keys = _.keys(array2[0])
  const vals = _.map(keys, function () {
    return ''
  })

  return _.map(array1, function (item1) {
    const found = _.find(array2, function (item2) {
      return item1.id == item2.parameter_id
    })

    if (found) { return _.merge({}, item1, found) }

    return _.merge({}, item1, _.zipObject(keys, vals))
  }, [])
}

// TODO: Review helper functions here for memoization
export function getDatesInMonth (date) {
  const firstDayInMonth = format(startOfMonth(date), 'yyyy-MM-dd')
  let lastDayInMonth = format(lastDayOfMonth(date), 'yyyy-MM-dd')
  if (isInLastWeekOfMonth(date)) {
    const eom = endOfMonth(date)
    const nextMonthStart = new Date(eom)
    nextMonthStart.setDate(eom.getDate() + 1)
    lastDayInMonth = format(lastDayOfMonth(nextMonthStart), 'yyyy-MM-dd')
  }
  const results = eachDayOfInterval({ start: parseISO(firstDayInMonth), end: parseISO(lastDayInMonth) })
  const dates = results.map((date) => format(date, 'yyyy-MM-dd'))
  return dates
}

export function isInLastWeekOfMonth (date) {
  const eom = endOfMonth(date)
  const res = differenceInDays(eom, date)
  return (res <= 7)
}

export function getDatesInRange (startDate, endDate) {
  const results = eachDayOfInterval({ start: parseISO(startDate), end: parseISO(endDate) })
  const dates = results.map((date) => format(date, 'yyyy-MM-dd'))

  return dates
}

export function getWeeklyBookings (bookingsData) {
  let currentWeek = getWeek(parseISO(bookingsData[0].date), { weekStartsOn: 1 })

  // contains all the 'week'-arrays - represents your pages in the pagination.
  const clusterOfWeeklyBookings = []

  // contains all bookings data of one week
  let week = []

  bookingsData.forEach((booking) => {
    if (getWeek(parseISO(booking.date), { weekStartsOn: 1 }) === currentWeek) {
      week.push(booking)
    } else {
      clusterOfWeeklyBookings.push(week)

      // clear the array and increment the week
      week = []
      week.push(booking)
      currentWeek++
    }
  })
  clusterOfWeeklyBookings.push(week)

  return clusterOfWeeklyBookings
}

export function getCurrentWeek () {
  const week = getWeekOfMonth(new Date(), { weekStartsOn: 1 })
  return week
}

export function isTokenExpired (expiry) {
  return Date.now() >= expiry * 1000
}

/**
 * --------------- Money format Utils ---------------
 */
export const NAIRA = '₦'
export const NAIRA_UNICODE = '\u20A6'

export function formatNumberToMoney (
  number = 0,
  decimals = 0,
  { countryCode = 'NG', currencyCode } = {}
) {
  const formatter = new Intl.NumberFormat(`en-${countryCode}`, {
    style: currencyCode && 'currency',
    currency: currencyCode,
    minimumFractionDigits: decimals
  })
  return formatter.format(number ? Number(+number).toFixed(decimals || 2) : 0)
}

export function convertCurrencyFormatToNumbers (currencyText) {
  if (currencyText && typeof currencyText === 'string') {
    return parseFloat(currencyText.replace(/[\u20A6₦,]/gi, ''))
  }
}

export function formatNumberAsCurrencyText (number) {
  return number || number === 0 ? `${NAIRA}${formatNumberToMoney(number)}` : 'N/A'
}

export const extractErrorMessage = (error, defaultMessage = null) => {
  return error.response && error.response.data && error.response.data.message
    ? error.response.data.message
    : defaultMessage || error.toString()
}
/**
 * Filter drivers by name or email applicable to v-select component control for drivers
 * @param option {Object} driver object
 * @param label {string} default driver property that drives search/filter
 * @param search {string} search parameter
 * @example:
 * <v-select ... label="anotherProperty" :filterBy="filterDriverBy" ...> </v-select>
 *
 * @return {boolean}
 * @returns a boolean determining if driver has/contains search parameter
 * */
export const filterDriverBy = (option, label, search) => {
  return (label && !!option[label] ? (option[label].toLocaleLowerCase().includes(search.toLocaleLowerCase())) : false) ||
      `${option.fname} ${option.lname} ${option.email}`.toLowerCase().includes(search.toLowerCase())
}

export const filterVehicleBy = (option, label, search) => {
  return (label && !!option[label] ? (option[label].toLocaleLowerCase().includes((search || '').toLocaleLowerCase())) : false) ||
    `${option.brand} ${option.name} ${option.registration_number}`.toLowerCase().includes((search || '').toLowerCase())
}

export function loadRouteComponent (path) {
  return () => import(/* webpackChunkName: "app-view-[request]" */`@/views/${path}.vue`)
}

export const monitizeValue = (amount, currency = 'NGN') => {

  if(typeof amount === 'string') amount = Number(amount)
  const currencySymbol = '₦' // TODO: Get symbol based on currency parameter
  if (amount !== null && typeof amount === 'number') {
    return `${currencySymbol} ${parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
  } else {
    return 'N/A'
  }
}

export function timeSince (date) {
  const seconds = Math.floor((new Date() - date) / 1000)

  let interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + ' years'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + ' months'
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + ' days'
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + ' hours'
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + ' minutes'
  }
  return Math.floor(seconds) + ' seconds'
}
