<template>
	<div>
		<b-modal
			:id="modalId"
			centered
			no-close-on-backdrop
			no-close-on-esc
			@hidden="resetForm"
			>
			<template #modal-title> Admin Feedback </template>
			<div class="py-2">
				<b-form-group
					label="Description"
					label-for="description"
					:state="isValid"
					class="mb-4"
					>
					<b-form-textarea
						id="description"
						v-model="form.description"
						label="Description"
						:state="isValid"
						@input="checkValidity"
						></b-form-textarea>
				</b-form-group>
			</div>
			<div class="description">
				<i class="fe fe-alert-circle alert-circle"></i>
				<span class="text"
					>Customers need to know why their request is being rejected <br />
					so they can modify it.
				</span>
			</div>
			<template #modal-footer>
				<div class="w-100">
					<b-button
						:disabled="isDisabled"
						variant="primary"
						class="float-right mr-2 button"
						@click="rejectEvent"
						>
						{{ loading === true ? 'Processing' : 'Reject Request' }}
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { reactive } from 'vue'

export default {
  name: 'RejectEventModal',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: reactive({
        description: null
      }),
      modalId: 'reject-event-modal',
      isValid: null
    }
  },
  computed: {
    isDisabled () {
      if (!this.form.description || this.$props.loading) {
        return true
      }
      return false
    }
  },
  emits: ['onRejectEvent'],
  methods: {
    checkValidity () {
      if (!this.form.description) {
        this.isValid = false
        return
      }
      this.isValid = null
    },

    resetForm () {
      this.form.description = null
      this.isValid = null
    },

    rejectEvent () {
      this.checkValidity()
      this.$emit('onRejectEvent', this.form)
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  &:disabled {
    opacity: 40%;
    cursor: not-allowed;
  }
}

.description {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  & .alert-circle {
    font-size: 16px;
    color: #0dac5c;
  }

  & .text {
    font-size: 12px;
    color: #737876;
  }
}
</style>
