<template>
	<div>
		<div class="d-flex">
			<div class="col-4 px-0">
				<div class="card">
					<div class="card-body">
						<div class="header-body border-0">
							<div class="row align-items-end">
								<div class="col">
									<h1 class="header-title">New Route</h1>
								</div>
							</div>
							<!-- / .row -->
						</div>

						<div class="form-group mb-0">
							<vue-google-autocomplete ref="startingPointAddress" id="startingPointAddressInput" classname="form-control"
								placeholder="Starting Point" v-on:placechanged="originChanged" @no-results-found="clearOrigin" types=""
								country="ng">
							</vue-google-autocomplete>
						</div>
						<div class="form-group mb-0">
							<draggable v-model="waypoints" group="waypoints" @start="dragWaypoint = true" @end="dragWaypoint = false">
								<div class="input-group my-1" v-for="(w, index) in waypoints" :key="w.id">
									<vue-google-autocomplete :ref="`wayPointRef${w.id}`" :id="`wayPointInput${w.id}`"
										classname="form-control" placeholder="Enter Stop Point" v-on:placechanged="w.val = $event"
										@no-results-found="w.val = null" types="" country="ng">
									</vue-google-autocomplete>

									<div class="input-group-append">
										<button class="btn btn-light" @click.prevent="removeStop(index)" title="Remove Stop">
											<span class="fe fe-x"></span>
										</button>
									</div>
								</div>
							</draggable>
						</div>
						<div class="form-group mb-0">
							<button class="btn btn-rounded" @click.prevent="addWayPoint">
								<span class="fe fe-plus"></span> Add Stop
							</button>
						</div>
						<div class="form-group mb-0">
							<vue-google-autocomplete ref="destinationPointAddress" id="destinationPointAddressInput"
								classname="form-control" placeholder="Destination Point" v-on:placechanged="destinationChanged"
								@no-results-found="clearDestination" types="" country="ng">
							</vue-google-autocomplete>
						</div>

						<div class="form-group"></div>
						<hr />

						<div class="form-group">
							<label for="">Route Code</label>
							<input type="text" class="form-control" v-model="newRoute.code" placeholder="e.g S300" />
						</div>

						<div class="form-group">
							<label for="">Route Description</label>
							<textarea type="text" rows="3" class="form-control resize-none" v-model="newRoute.description"
								maxlength="500" placeholder="Route description (Max. 500 characters)"></textarea>
						</div>

						<div class="form-group">
							<div><label for="">Route Visibility</label></div>

							<div class="custom-control custom-radio custom-control-inline">
								<input v-model="newRoute.visibility" type="radio" class="custom-control-input" name="route_visibility"
									value="public" id="public_route" />
								<label for="public_route" class="custom-control-label">
									Public
								</label>
							</div>

							<div class="custom-control custom-radio">
								<input type="radio" class="custom-control-input" v-model="newRoute.visibility" name="route_visibility"
									value="private" id="private_route" />
								<label for="private_route" class="custom-control-label">
									Private
								</label>
							</div>
						</div>

						<div class="form-group">
							<div><label for="">Route Type</label></div>

							<div class="custom-control custom-radio custom-control-inline">
								<input v-model.number="newRoute.is_exclusive" type="radio" class="custom-control-input" name="route_type"
									value="0" :checked="newRoute.is_exclusive == 0" id="shared_route" />
								<label for="shared_route" class="custom-control-label">
									Shared
								</label>
							</div>

							<div class="custom-control custom-radio">
								<input type="radio" class="custom-control-input" v-model.number="newRoute.is_exclusive" name="route_type"
									:checked="newRoute.is_exclusive == 1" value="1" id="exclusive_route" />
								<label for="exclusive_route" class="custom-control-label">
									Exclusive
								</label>
							</div>
						</div>

						<div class="form-group" v-if="newRoute.visibility == 'private'">
							<label for="">Select Corporate that owns this private route</label>
							<multiselect v-model="newRoute.corporateOwner" id="ajax" :custom-label="corporateName" track-by="id"
								placeholder="Type to search" open-direction="bottom" :options="corporatesList" :multiple="false"
								:searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false"
								:close-on-select="false" :options-limit="300" :limit="10" :limit-text="limitText" :show-no-results="false"
								:hide-selected="true" @search-change="asyncFindCorporates">
								<template slot="clear">
									<div class="multiselect__clear" v-if="newRoute.corporateOwner"
										@mousedown.prevent.stop="newRoute.corporateOwner = null"></div>
								</template><span slot="noResult">Oops! No corporates found. Consider changing the search
									query.</span>
							</multiselect>
						</div>

						<div class="form-group">
							<div><label for="">Route Availability</label></div>
							<div class="custom-control custom-radio custom-control-inline">
								<input v-model="routeAvailability" type="radio" class="custom-control-input" value="true"
									id="routeAvailabilityAll" @change="onRouteAvailabilityChange(true)" />
								<label for="routeAvailabilityAll" class="custom-control-label">
									Everyday
								</label>
							</div>

							<div class="custom-control custom-radio">
								<input v-model="routeAvailability" type="radio" class="custom-control-input" value="false"
									id="routeAvailabilitySelected" @change="onRouteAvailabilityChange(false)" />
								<label for="routeAvailabilitySelected" class="custom-control-label">
									Selected days
								</label>
							</div>
							<day-selector v-if="!routeAvailability" @on-change="updateAvailableDays($event)"
								:activeDays="newRoute.route_availability_days" class="mt-3">
							</day-selector>
						</div>

						<div class="form-group">
							<div><label>Availability Start Date</label></div>
							<div class="d-inline-block">
								<v-datepicker v-model="newRoute.route_availability_start_date" value-type="YYYY-MM-DD"></v-datepicker>
							</div>
						</div>

						<div class="form-group">
							<div><label>Availability End Date</label></div>
							<div class="d-inline-block">
								<v-datepicker v-model="newRoute.route_availability_end_date" value-type="YYYY-MM-DD"></v-datepicker>
							</div>
						</div>

						<div class="form-group">
							<div><label>Unavailable Dates</label></div>
							<div class="d-inline-block">
								<multi-date-selector v-model="newRoute.blacklisted_availability_days"></multi-date-selector>
							</div>
						</div>

						<div>
							<span>Route Itinerary</span>
						</div>
						<div>
							<draggable v-model="waypoints" group="waypoints" @start="dragWaypoint = true" @end="dragWaypoint = false">
								<div class="row my-2" v-for="itinerary in newRoute.itineraries" :key="itinerary.id">
									<div class="col-12 mb-2">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">Time</span>
											</div>
											<vue-timepicker format="hh:mm A" hide-clear-button v-model="itinerary.time"
												input-class="form-control"></vue-timepicker>
										</div>
									</div>
									<div class="col-12 d-flex">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">Fare</span>
											</div>
											<input style="max-width: 150px" v-model.number="itinerary.fare" class="form-control" />
										</div>
									</div>
								</div>
							</draggable>
						</div>


						<div class="form-group mt-5">
							<button class="btn btn-primary btn-block" @click="createRoute"
								:disabled="generatingRoutePreview || creatingRoute">
								<span v-if="generatingRoutePreview">Generating Route Preview..</span>
								<span v-if="creatingRoute">Creating Route..</span>
								<span v-if="!creatingRoute && !generatingRoutePreview">Create Route</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="col-8 px-0">
				<div class="info-windows" style="height: 100vh; width: 100%">
					<google-map id="map" ref="Map">
						<google-map-marker v-if="startingPointModel"
							:position="{
								lat: startingPointModel.latitude,
								lng: startingPointModel.longitude,
							}" :key="`starting-point-marker`" :clickable="true" @click="
								startingPointModel.showInfo = !startingPointModel.showInfo
							" />
						<google-map-infowindow v-if="startingPointModel" :position="{
							lat: startingPointModel.latitude,
							lng: startingPointModel.longitude,
						}" :show.sync="startingPointModel.showInfo" :key="`starting-point-windowpickup`"
							:options="{ maxWidth: 300, pixelOffset: 10 }">
							<div>
								<h4>Starting Point</h4>
								<p>{{ startingPointModel.route }}</p>
							</div>
						</google-map-infowindow>

						<google-map-marker v-if="destinationPointModel"
							
							:position="{
								lat: destinationPointModel.latitude,
								lng: destinationPointModel.longitude,
							}" :key="`destination-point-marker`" :clickable="true" @click="
								destinationPointModel.showInfo = !destinationPointModel.showInfo
							" />
						<google-map-infowindow v-if="destinationPointModel" :position="{
							lat: destinationPointModel.latitude,
							lng: destinationPointModel.longitude,
						}" :show.sync="destinationPointModel.showInfo" :key="`destination-point-windowpickup`"
							:options="{ maxWidth: 300, pixelOffset: 10 }">
							<div>
								<h4>Destination Point</h4>
								<p>{{ destinationPointModel.route }}</p>
							</div>
						</google-map-infowindow>
					</google-map>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import VueTimepicker from 'vue2-timepicker'
import { CancelToken } from 'axios'
import routeResource from '@/api/route'
import draggable from 'vuedraggable'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Multiselect from 'vue-multiselect'
import DaySelector from '@/views/Routes/DaySelector'
import MultiDateSelector from '@/components/core/MultiDateSelector'

export default {
  components: { MultiDateSelector, DaySelector, VueGoogleAutocomplete, draggable, Multiselect, VueTimepicker },
  data() {
    return {
      creatingRoute: false,
      newRouteCreated: null,
      newRoute: {
        is_exclusive: 0,
        corporateOwner: null,
        code: '',
        description: '',
        visibility: 'public',
        route_availability_days: [],
        route_availability_start_date: '',
        route_availability_end_date: '',
        itineraries: [
          {
            id: 1,
            time: '06:00 AM',
            fare: 0
          }
        ],
        blacklisted_availability_days: []
      },
      activeRouteOption: null,
      corporatesList: [],
      isLoading: false,
      dragWaypoint: false,
      startingPoint: '',
      startingPointModel: null,
      destinationPointModel: null,
      destinationPoint: '',
      waypoints: [],
      cancelFnToRoutePreview: null,
      currentRoutePreviewData: null,
      currentlyShowingFeatures: [],
      generatingRoutePreview: false,
      routeAvailability: true
    }
  },
  created() {
    this.loadCorporates()
    this.setDefaultAvailableDays()
  },
  watch: {
    startingPointModel() {
      this.adjustMapZoom()
    },
    destinationPointModel() {
      this.adjustMapZoom()
    },
    waypoints: {
      deep: true,
      handler() {
        this.adjustMapZoom()
      }
    }
  },
  methods: {

    addRouteItinerary() {
      this.newRoute.itineraries.push({
        id: this.newRoute.itineraries.length + 1,
        time: ''
      })
    },
    removeRouteItinerary(index) {
      if (this.newRoute.itineraries.length === 1) return

      this.newRoute.itineraries.splice(index, 1)
    },
    limitText(count) {
      return `and ${count} other corporates`
    },
    loadCorporates() {
      this.isLoading = true
      this.axios
        .get('/v1/corporates')
        .then((response) => {
          this.corporatesList = response.data.data
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
          this.corporatesList = []
        })
    },
    asyncFindCorporates(query) {
      if (!query.length) {
        this.loadCorporates()
        return
      }
      this.isLoading = true
      this.axios
        .post('/v1/corporates/search', { name: query })
        .then((response) => {
          this.corporatesList = response.data
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
          this.corporatesList = []
        })
    },
    corporateName({ corporate_name }) {
      return corporate_name
    },
    panToMarker(pos) {
      this.$refs.Map.panTo(pos)
    },
    adjustMapZoom() {
      this.zoomLevel = 13
      const bounds = new window.google.maps.LatLngBounds()
      if (this.startingPointModel) {
        bounds.extend({
          lat: this.startingPointModel.latitude,
          lng: this.startingPointModel.longitude
        })
      }

      if (this.destinationPointModel) {
        bounds.extend({
          lat: this.destinationPointModel.latitude,
          lng: this.destinationPointModel.longitude
        })
      }
      this.$refs.Map.fitBounds(bounds)
      this.panToMarker(bounds.getCenter())
      this.generateRoutePreview()
    },
    originChanged(val) {
      this.startingPointModel = val
      this.startingPoint = this.$refs.startingPointAddress.autocompleteText
    },
    clearOrigin() {
      this.startingPointModel = null
      this.$refs.startingPointAddress.clear()
      this.startingPoint = ''
    },
    destinationChanged(val) {
      this.destinationPointModel = val
      this.destinationPoint =
        this.$refs.destinationPointAddress.autocompleteText
    },
    clearDestination() {
      this.destinationPointModel = null
      this.$refs.destinationPointAddress.clear()
      this.destinationPoint = ''
    },
    addWayPoint() {
      const newId = this.waypoints.length + 1
      const newPoint = { id: newId, val: null }
      if (!newPoint) return
      this.waypoints.push(newPoint)
    },
    removeStop(index) {
      this.waypoints.splice(index, 1)
    },
    async generateRoutePreview() {
      if (this.creatingRoute) return

      const vm = this
      if (!this.startingPointModel || !this.destinationPointModel) return
      const startPoint = `${this.startingPointModel.latitude},${this.startingPointModel.longitude}`
      const endPoint = `${this.destinationPointModel.latitude},${this.destinationPointModel.longitude}`
      const waypoints = []

      if (this.waypoints && this.waypoints.length) {
        this.waypoints.forEach((entry) => {
          const v = entry.val
          if (v && v.latitude && v.longitude) {
            waypoints.push(`${v.latitude},${v.longitude}`)
          }
        })
      }

      if (vm.cancelFnToRoutePreview != null) {
        vm.cancelFnToRoutePreview()
        vm.cancelFnToRoutePreview = null
      }

      try {
        this.generatingRoutePreview = true
        this.$Progress.start()
        const { data } = await routeResource.generateRoutePreview(
          startPoint,
          endPoint,
          waypoints,
          {
            cancelToken: new CancelToken(function executor(c) {
              vm.cancelFnToRoutePreview = c
            })
          }
        )

        this.$Progress.finish()
        this.generatingRoutePreview = false
        this.currentRoutePreviewData = data.data
        this.renderRoutePreview()
      } catch (e) {
        
        this.generatingRoutePreview = false
        this.$Progress.fail()
      }
    },
    renderRoutePreview() {
      const vm = this
      if (
        this.currentlyShowingFeatures &&
        this.currentlyShowingFeatures.length
      ) {
        this.currentlyShowingFeatures.forEach((r) => {
          this.$refs.Map.$_map.data.remove(r)
        })
        this.currentlyShowingFeatures = []
      }

      if (this.currentRoutePreviewData && this.currentRoutePreviewData.routes) {
        this.currentRoutePreviewData.routes.forEach((route) => {
          this.$refs.Map.$_map.data.setStyle({
            strokeWeight: 8,
            strokeColor: '#4a4a4a',
            clickable: true,
            title: 'preview'
          })

          this.$refs.Map.$_map.data.addListener('click', function (event) {
            const index = vm.currentlyShowingFeatures.indexOf(event.feature)
            if (index > -1) {
              vm.selectActiveRoute(index)
            }
          })

          this.currentlyShowingFeatures.push(
            this.$refs.Map.$_map.data.add(this.getGeoJson(route))
          )
        })
      }

      if (this.currentlyShowingFeatures.length) {
        this.selectActiveRoute(0)
      }
    },
    selectActiveRoute(index) {
      this.$refs.Map.$_map.data.overrideStyle(
        this.currentlyShowingFeatures[index],
        { strokeColor: '#109352' }
      )
      this.activeRouteOption = this.currentRoutePreviewData.routes[index]
    },
    getGeoJson(route) {
      const obj = JSON.parse(route.overview_geojson)
      route.overview_geojson_obj = obj
      return {
        geometry: new window.google.maps.Data.LineString(
          obj.coordinates.map((i) => {
            return { lat: i[1], lng: i[0] }
          })
        )
      }
    },

    async createRoute() {
      if (this.creatingRoute) {
        this.$swal('Oops', 'Already creating route', 'error')
        return
      }

      if (this.generatingRoutePreview) {
        this.$swal('Oops', 'Already generating route preview', 'error')
        return
      }

      if (!this.activeRouteOption) {
        this.$swal('Oops', 'active route not set', 'error')
        return
      }

      if (
        this.newRoute.visibility == 'private' &&
        !this.newRoute.corporateOwner
      ) {
        this.$swal('Oops', 'private route need a corporate', 'error')
        return
      }

      if (!this.isValidAvailableDates()) {
        this.$swal('Oops!', 'Please select a valid route availability option', 'error')
        return
      }

      for (const i of this.newRoute.itineraries) {
        if (i.fare < 0) {
          this.$swal('Oops', 'Fare should be greater or equal to zero', 'error')
          return
        }
      }

      const activeRoute = this.activeRouteOption
      const legCumulative = {
        duration: { value: 0, text: '' },
        distance: { value: 0, text: '' }
      }

      activeRoute.legs.forEach((v) => {
        if (v.duration.value && v.duration.value > 0) {
          legCumulative.duration.value =
            v.duration.value + legCumulative.duration.value
          legCumulative.duration.text = `${(
            legCumulative.duration.value / 60
          ).toFixed(0)} mins`
        }

        if (v.distance.value && v.distance.value > 0) {
          legCumulative.distance.value =
            v.distance.value + legCumulative.distance.value
          if (legCumulative.distance.value < 1000) {
            legCumulative.distance.text = `${legCumulative.distance.value} m`
          } else {
            legCumulative.distance.text = `${(
              legCumulative.distance.value / 1000
            ).toFixed(2)} km`
          }
        }
      })

      const data = {
        pickup: this.$refs.startingPointAddress.autocompleteText,
        destination: this.$refs.destinationPointAddress.autocompleteText,
        day_of_week: 'MON - FRI',
        visibility: this.newRoute.visibility,
        route_availability_days: this.newRoute.route_availability_days,
        corporate_id: this.newRoute.corporateOwner
          ? this.newRoute.corporateOwner.id
          : null,
        is_future_route: false,
        is_exclusive: this.newRoute.is_exclusive,
        route_code: this.newRoute.code,
        info: {
          description: this.newRoute.description
        },
        route_preview: activeRoute.overview_geojson_obj,
        bounds: activeRoute.bounds,
        duration_value: legCumulative.duration.value,
        distance_value: legCumulative.distance.value,
        duration: legCumulative.duration.text,
        distance: legCumulative.distance.text,
        start_location: {
          lat: this.startingPointModel.latitude,
          lng: this.startingPointModel.longitude
        },
        end_location: {
          lat: this.destinationPointModel.latitude,
          lng: this.destinationPointModel.longitude
        },
        itineraries: this.newRoute.itineraries,
        route_availability_end_date: this.newRoute.route_availability_end_date ? this.newRoute.route_availability_end_date : null,
        route_availability_start_date: this.newRoute.route_availability_start_date ? this.newRoute.route_availability_start_date : null,
        blacklisted_availability_days: this.newRoute.blacklisted_availability_days || []
      }

      try {
        this.creatingRoute = true
        this.$Progress.start()
        const newRouteResult = await routeResource.createRoute(data)

        this.$Progress.finish()
        this.creatingRoute = false
        this.newRouteCreated = newRouteResult.data

        this.$router.push({
          name: 'ShowRoute',
          params: {
            routeId: this.newRouteCreated.id
          }
        })
      } catch (e) {
        if (e.response && e.response.data) {
          this.$swal('Opps', e.response.data.message, 'error')
        }

        this.creatingRoute = false
        this.$Progress.fail()
      }
    },
    setDefaultAvailableDays() {
      this.routeAvailability = true
      this.newRoute.route_availability_days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    },
    isValidAvailableDates() {
      return (this.routeAvailability && this.newRoute.route_availability_days.length === 7) ||
        (!this.routeAvailability && this.newRoute.route_availability_days.length)
    },
    updateAvailableDays(value) {
      this.newRoute.route_availability_days = value
      this.routeAvailability = value && value.length === 7
    },
    onRouteAvailabilityChange(value) {
      this.routeAvailability = value
      if (value) {
        this.setDefaultAvailableDays()
      } else {
        this.newRoute.route_availability_days = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vue__time-picker input.display-time {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px) !important;
  padding: 0.5rem 0.75rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5 !important;
  color: #12263f;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #d2ddec;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
