<template>
	<div
		class="modal fade"
		id="reviewPayoutModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="reviewPayoutModalLabel"
		aria-hidden="true"
		>
		<div class="modal-dialog modal-dialog-centered" role="document" v-if="removePayout">
			<div class="modal-content">
				<div class="modal-header" id="review-payout-header">
					<div>
						<h3 class="modal-title" id="reviewPayoutLabel">
							{{ isRetry ? 'Retry Payout' : 'Are you absolutely sure?'}}
						</h3>
					</div>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body" id="review-body">
					<div>
						<div class="row">
							<div class="warn col-12 d-flex rounded align-items-center">
								<img src="@/assets/img/payout-caution.svg" alt="">
								<h3>It’s important you read the message below!</h3>
							</div>
							<div class="col-12 my-2">
                <h4 v-if="isRetry" class="info-text">You are about to to retry payout for this vehicle partner.  Are you sure that the information is correct and accurate?</h4>
								<h4 v-else class="info-text">You are about to complete the review of this vehicle partners earning.  Are you sure that the information is correct and accurate?</h4>
							</div>
							<div class="col-12 d-flex flex-column py-2 position-relative">
								<label class="form-label">Please enter <span class="font-weight-bold">YOUR PASSWORD</span> to confirm this action.</label>
								<input class="input-review"
									:type="showPassword ? 'text' : 'password'" v-model.trim="form.password">
								<span @click="toggleShowPassword" class="password-toggle fe" :class="{'fe-eye': showPassword,'fe-eye-off': !showPassword}"></span>
								<p v-if="errorPassword" class="text-danger font-weight-bold">You entered a wrong Password</p>
							</div>
							<div class="col-12 d-flex justify-content-end py-2 mt-3">
								<button class="btn btn-dark review-btn-cancel col-2 mr-3"
									@click="close"
									aria-label="close"
									data-dismiss="modal"
									>Cancel
								</button>
								<button class="btn btn-dark review-btn-proceed col-2 p-1"
									type="submit"
									@click="isRetry ? retryPayout() : approvePayout()"
									:disabled="$v.form.$invalid"
								> {{ processing ? 'Loading...':'Proceed'}}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-dialog modal-dialog-centered" role="document" v-if="showSucess && !isRetry">
			<div class="modal-content" id="review-success-modal">
				<div class="d-flex flex-column align-items-center justify-content-center p-4">
					<img src="@/assets/img/icons/refund-success.svg" alt="">
					<h3 class="pt-3">
						Payout approved
					</h3>
					<p class="refund-success-p" v-if="approver.length == 2">Payout initiated successfully</p>
					<p class="refund-success-p">
						You have successfully approved payouts for this partner.
					</p>
					<button class="btn btn-lg text-white review-btn-success"
						@click="resetModal($event)"
						aria-label="close"
						data-dismiss="modal"
						>
						Dismiss
					</button>
				</div>
			</div>
		</div>

		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#reviewPayoutModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ModalMixin from '@/mixins/modal'

export default {
  props: {
    show: Boolean,
    partner_sid: String,
    earnings_id: String,
    isRetry: {
      type: Boolean,
      required: false
    },
  },
  mixins: [ModalMixin, validationMixin],
  data: () => {
    return {
      removePayout: true,
      processing: false,
      showSucess: false,
      form: {
        password: null
      },
      showPassword: false,
      errorPassword: false,
      approver: null
    }
  },
  validations: {
    form: {
      password: {
        required
      }
    }
  },
  methods: {
    async approvePayout () {
      this.processing = true
      try {
        const res = await this.axios.patch(`/v1/partners/${this.partner_sid}/earnings/${this.earnings_id}`, {
          password: this.form.password
        })

        if (res.status == 200) {
          this.showSucess = true
          this.removePayout = false
          this.$emit('updated')
        }
      } catch (err) {
        if (err.response.status == 500) {
          this.$toastr.e('Something went wrong, please contact Admin', 'Error')
        } else {
          this.$swal({
            icon: 'error',
            title: `${err.response.data.message}`,
            showConfirmButton: true,
            showCancelButton: false
          })
        }
      }
      this.processing = false
    },
    resetModal (e) {
      this.$emit('updated')
      this.close(e)
      setTimeout(() => {
        this.removePayout = true
        this.showSucess = false
        this.form.password = null
      }, 400)
    },
    toggleShowPassword () {
      this.showPassword = !this.showPassword
    },
    async fetchApproval () {
      try {
        const res = await this.axios.get(`cost-revenue/v1/earnings/${this.earnings_id}/approvers`)
        if (res.status == 200) {
          this.approver = res.data
        }
      } catch (err) {
        
      }
    },
    async retryPayout () {
      this.processing = true
      try {
        const res = await this.axios.patch(`v1/partners/${this.partner_sid}/earnings/${this.earnings_id}/retry`, {
          password: this.form.password
        })
        if (res.status == 200) {
          Swal.fire({ icon: 'success', title: 'Success', text: res?.data?.message ?? 'Payout successful'})
          this.$emit('updated')
        }
      } catch (err) {
        Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
      } finally {
        this.processing = false
      }
    }
  },
  emits: ['updated'],
  created () {
    this.fetchApproval()
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/_sh-colors.scss';

.input-review{
    height: 50px;
    border: 1px solid $sh-gray-9;
    border-radius: 8px;
    outline: none;
    padding: 16px 12px;
}

.review-btn-cancel{
    background-color: $sh-gray-11;
    border: none;
}

.review-btn-proceed{
    background-color: $sh-black-1;
}

button:disabled{
    background:#ACAFAE;
    border: none;
}

.warn{
    background: #EBEBFF;
    border-radius: 10px;
    padding: 14px 16px 14px 20px;

    h3{
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        color: #2323F0;
        margin: 0 0 0 8px;
    }
}

.info-text{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #444854;
}

#review-success-modal{
    width: 400px;
    text-align: center;
}

.review-btn-success{
    background-color: #000005;
    width: 100%;
}

.password-toggle {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 50%;
}

</style>
