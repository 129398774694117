var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":_vm.modalId,"centered":"","no-close-on-backdrop":"","no-close-on-esc":""},on:{"hidden":_vm.resetForm},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Create a reward ")]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right mr-2 button",attrs:{"variant":"primary","disabled":_vm.isDisabled},on:{"click":function($event){return _vm.onCreate({
                name: _vm.form.rewardName.name,
                min_point: _vm.form.rewardValue,
                status: _vm.form.status ? 'active' : 'inactive',
              })}}},[_vm._v(" "+_vm._s(_vm.fetchingComplianceRewardUpdate? 'Processing...' : 'Create')+" ")])],1)]},proxy:true}])},[_c('div',{staticClass:"create-reward py-2"},[_c('div',[_c('label',{attrs:{"for":"range-1"}},[_vm._v("Reward")]),_c('v-select',{staticClass:"input",attrs:{"options":_vm.allComplianceReward,"label":"pickup","filterable":false,"placeholder":"Select reward","get-option-label":(option) => option.name},on:{"search":_vm.search},scopedSlots:_vm._u([{key:"option",fn:function(reward){return [_c('p',{staticClass:"route-options text-sm"},[_vm._v(" "+_vm._s(reward.name)+" ")])]}},(_vm.fetchingComplianceReward)?{key:"no-options",fn:function(){return [_vm._v(" loading... ")]},proxy:true}:null],null,true),model:{value:(_vm.form.rewardName),callback:function ($$v) {_vm.$set(_vm.form, "rewardName", $$v)},expression:"form.rewardName"}})],1),_c('div',[_c('label',{attrs:{"for":"range-1"}},[_vm._v("Reward value (points)")]),_c('b-form-input',{staticClass:"input",attrs:{"placeholder":"Enter points"},model:{value:(_vm.form.rewardValue),callback:function ($$v) {_vm.$set(_vm.form, "rewardValue", $$v)},expression:"form.rewardValue"}})],1),_c('div',{staticClass:"d-flex justify-content-start align-items-center mt-2.5"},[_c('div',{staticClass:"toggle relative w-12 h-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.status),expression:"form.status"}],staticClass:"w-12 h-full absolute z-10 opacity-0 cursor-pointer",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.status)?_vm._i(_vm.form.status,null)>-1:(_vm.form.status)},on:{"change":function($event){var $$a=_vm.form.status,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "status", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "status", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "status", $$c)}}}}),_c('label',{class:{
              active: _vm.form.status,
            }},[_c('span',{class:{ active: _vm.form.status }})])]),_c('p',{staticClass:"text-secondary",staticStyle:{"font-size":"12px","margin-left":"12px"}},[_vm._v("Enable reward")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }