<template>
	<div class="col-12 col-xl-12">
		<div class="card">
			<div class="card-header">
				<div class="d-flex col-md-auto justify-content-between align-items-center">
					<h4 class="card-header-title">Trip Rating Service</h4>
          <div class="max-w-lg">
	<v-datepicker
						v-model="filter.range"
						placeholder="Filter by date"
						range
						></v-datepicker>
          </div>
				
				</div>
			</div>
			<div class="card-body">
				<div class="row mt-2 col-md-auto">

					<div class="col-lg-4">
						<div v-if="overallSummaryData">
							<stats-card
								:title="`${'Customer Satisfaction'}`"
								:rating="`${overallSummaryData.average}`"
								:ratings-count="`${overallSummaryData.count}`"
								:max-value="overallSummaryData.max_value"
								:image="require('@/assets/img/img-customer-satisfaction.svg')"
								></stats-card>
						</div>
					</div>

					<div class="col-lg-6">
						<div v-if="overtimeRatingData">
							<stats-card
								:title="`${`Customer ratings between ${dateRange[0]} & ${dateRange[1]}`}`"
								:rating="`${overtimeRatingData.rating}`"
								:ratings-count="`${overtimeRatingData.count}`"
								:max-value="5"
								:image="require('@/assets/img/img-customer-satisfaction.svg')"
								></stats-card>
						</div>
					</div>

					<div class="col-lg-4">
						<router-link
							to="/drivers"
							class="text-reset"
							v-if="averageDriverRating"
							>
							<stats-card
								:title="`${'Average Driver Rating'}`"
								:rating="`${averageDriverRating.average}`"
								:ratings-count="`${averageDriverRating.count}`"
								:max-value="averageDriverRating.max_value"
								:image="require('@/assets/img/img-average-driver-rating.svg')"
								></stats-card>
						</router-link>
					</div>

					<div class="col-lg-4">
						<router-link
							to="/fleet/list/vehicle"
							class="text-reset"
							v-if="averageVehicleRating"
							>
							<stats-card
								:title="`${'Average Vehicle Rating'}`"
								:rating="`${averageVehicleRating.average}`"
								:ratings-count="`${averageVehicleRating.count}`"
								:max-value="averageVehicleRating.max_value"
								:image="require('@/assets/img/img-average-vehicle-rating.svg')"
								></stats-card>
						</router-link>
					</div>

				</div>
				<div
					v-if="loadingSummaryData"
					class="d-flex justify-content-center align-items-center"
					>
					<div class="spinner-border" role="status"></div>
				</div>

				<div class="row mt-4">
					<div class="col-md-12">
						<div>
							<p class="font-weight-bold">Summary</p>
						</div>
						<bar-chart
							v-if="ratingsChartData"
							class="chart barchart"
							:chart-data="ratingsChartData"
							:options="barChartOptions"
							></bar-chart>
						<div class="text-center" v-if="errorMessage">
							{{ errorMessage }}
							<span class="text-info pointer" @click="fetchRatingsChartData">
								retry
							</span>
						</div>
					</div>
				</div>

				<div
					v-if="loadingRatingsChartData"
					class="d-flex justify-content-center align-items-center"
					>
					<div class="spinner-border" role="status"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import StatsCard from '@/components/modules/trips/RatingDashboardStatsCard.vue'
import BarChart from '@/components/charts/BarChart.vue'
import { getRatingsChartData } from '@/utils/chart'
import { getDatesInRange } from '@/utils/helpers'
import moment from 'moment'

export default {
  components: {
    StatsCard,
    BarChart
  },
  data () {
    return {
      loading: false,
      loadingSummaryData: true,
      loadingRatingsChartData: false,
      fetchingRatings: true,
      filter: {
        range: []
      },
      barChartOptions: {
        scaleShowValues: true,
        legend: {
          display: true,
          position: 'bottom'
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Trip Date'
              },
              ticks: {
                autoSkip: false
              },
              gridLines: {
                display: false
              },
              offset: true
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Passengers'
              },
              gridLines: {
                borderDash: [8, 4],
                color: '#F6F6F7'
              },
              ticks: {
                stepSize: 500
              }
            }
          ]
        },
        plugins: {
          tooltip: {
            callbacks: {
              footer: function (items) {
                return 'Total: ' + items.reduce((a, b) => a + b.parsed.y, 0)
              }
            }
          }
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              if (tooltipItem.datasetIndex === 1) {
                const dataset = data.datasets[0]
                const totalPassengerRatings = dataset.data[tooltipItem.index]
                return `Total Passengers: ${tooltipItem.yLabel + totalPassengerRatings
                  }`
              }

              return (`No.of Passenger Ratings: ${tooltipItem.yLabel}`)
            }
          }
        }
      },
      overtimeRatingData: null,
      overallSummaryData: null,
      averageDriverRating: null,
      averageVehicleRating: null,
      ratingsChartData: null,
      errorLoadingSummaryData: false,
      errorLoadingFilteredRating: false,
      errorLoadingRatingsChartData: false,
      errorMessage: '',
      settingsId: ''
    }
  },
  computed: {
    dateRange () {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },
    dateFilterValid () {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    }
  },
  created () {
    this.init()
  },
  watch: {
    dateRange () {
      this.getRatingInfo()
    }
  },
  methods: {
    async fetchTripRatingSettings (serviceId) {
      const tripRatingSettingsResponse = await this.axios.get(
        `/rating/settings/service-id/${serviceId}`
      )
      return tripRatingSettingsResponse.data.data
    },
    async init () {
      await this.initSettingsId()
      this.getTripSummaryData()
      this.fetchRatingsChartData()
    },
    async initSettingsId () {
      const { reference } = await this.fetchTripRatingSettings(
        process.env.VUE_APP_TRIP_RATING_SERVICE_ID
      )
      this.settingsId = reference
    },

    getRatingInfo () {
      if (this.dateFilterValid) {
        const url = `/v1/stats/rating-stat?from=${this.dateRange[0]}&to=${this.dateRange[1]}`
        this.axios.get(url)
          .then((res) => {
            this.overtimeRatingData = res.data
          }).catch(() => {
            this.$toastr.e('Could not load rating stats')
          })
      } else {
        this.overtimeRatingData = null
      }
    },

    async getTripSummaryData () {
      this.loadingSummaryData = true
      this.errorLoadingSummaryData = false
      this.axios.get(`/rating/reports/all-time/${this.settingsId}`)
        .then((res) => {
          this.overallSummaryData = res.data.data
        }).catch(() => {
          this.errorLoadingSummaryData = true
        }).finally(() => {
          this.loadingSummaryData = false
        })
    },

    async fetchRatingsChartData () {
      this.loadingRatingsChartData = true
      this.errorLoadingRatingsChartData = false
      this.errorMessage = ''

      const startDate = moment().subtract(6, 'd').format('YYYY-MM-DD')
      const endDate = moment().format('YYYY-MM-DD')
      const payload = {
        settings_id: this.settingsId,
        start_date: startDate,
        end_date: endDate
      }

      Promise.all([
        this.axios.get(
          `/v1/routes/passengers/stats?startDate=${startDate}&endDate=${endDate}`
        ),
        this.axios.post('/rating/reports/graph/day', payload)
      ])
        .then((res) => {
          const passengersResponse = res[0].data
          const ratingsResponse = res[1].data.data
          const dates = getDatesInRange(startDate, endDate)

          const ratingsData = dates.map((date) => {
            const datum = {}
            datum.date = date
            datum.total_passengers = passengersResponse[date].total
            datum.average_daily_rating = passengersResponse[date].rating
            datum.total_rating =
              ratingsResponse.find((item) => item.day === date)?.count || 0

            return datum
          })

          this.ratingsChartData = getRatingsChartData(ratingsData)
        })
        .catch(() => {
          this.errorLoadingRatingsChartData = true
          this.errorMessage =
            'Sorry, we couldn\'t load the ratings graph at this time. Please'
        })
        .finally(() => (this.loadingRatingsChartData = false))
    },
    footer (tooltipItems) {
      let sum = 0

      tooltipItems.forEach(function (tooltipItem) {
        sum += tooltipItem.parsed.y
      })
      return 'Sum: ' + sum
    }
  }
}
</script>

<style scoped></style>
