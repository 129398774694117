<template>
	<div class="container">
		<div class="row py-5 back-row" v-show="isPasswordStep">
			<div v-if="isPasswordStep" class="d-flex align-items-center cursor-pointer px-5" @click="goToStart()">
				<span>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6 12L18 12M11 7L6 12L11 7ZM6 12L11 17L6 12Z" stroke="#000005" stroke-width="3"
							stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</span>
				<span class="text-dark font-weight-bold">Back</span>
			</div>
		</div>
		<div class="row justify-content-center align-content-center" :class="isPasswordStep ? 'm-h-screen' : 'm-h-login'">
			<div class="col-md-6 col-sm-8 col-xs-12 col-lg-4">
				<div class="login-wrapper">
					<div class="card-body">
						<div class="title">
							<img src="@/assets/logo.png" />
							<div class="mt-3">
								<h2>{{ titleText }}</h2>
							</div>
							<p class="mt-1 mb-3 text-center highlight-text highlight-text--dark" v-if="step === 2" v-html="actionText">
							</p>
						</div>
						<form class="mt-5">
							<div class="form-group" v-if="error && error.length">
								<div class="alert alert-danger">
									<span>{{ error }}</span>
								</div>
							</div>

							<template v-if="step === 1">
								<div class="row">
									<div class="col-12">
										<ul class="login-tabs">
											<li class="tab-item" :class="isEmailLogin ? 'tab-item--active' : ''" @click="setLoginType('email')">
												Email Address</li>
											<li class="tab-item" :class="isPhoneLogin ? 'tab-item--active' : ''" @click="setLoginType('phone')">
												Phone number</li>
										</ul>
									</div>
								</div>
								<div class="form-group pt-3" v-if="isEmailLogin">
									<label for="email" class="mb-1">Email Address</label>
									<input v-model.trim="$v.form.email.$model"
										:class="{ 'is-invalid': $v.form.email.$dirty && $v.form.email.$error }" id="email" name="email"
										autocomplete type="email" class="form-control" />
									<div class="invalid-feedback" v-if="$v.form.email.$dirty && $v.form.email.$error">
										A valid Email is required.
									</div>
								</div>
								<div class="form-group pt-3" v-if="isPhoneLogin">
									<label for="phone" class="mb-1">Phone number</label>
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<select class="custom-select" v-model="form.country">
												<option :key="country.code" v-for="country in countries" v-bind:value="country">
													{{ countryCodeToEmoji(country.code) }}
													{{ country.phone_code }}
												</option>
											</select>
										</div>
										<input type="tel" class="form-control" v-model.trim="$v.form.phone.$model"
											:class="{ 'is-invalid': $v.form.phone.$dirty && ($v.form.phone.$error && !isPhoneValid) }"
											name="phone" id="phone">
									</div>
									<div class="invalid-feedback" v-if="$v.form.phone.$dirty && ($v.form.phone.$error || !isPhoneValid)">
										Please provide a valid phone number.
									</div>

								</div>
								<div class="form-group pt-3">

									<button @click.prevent.stop="proceedToPassword" :disabled="!validPhone || !validEmail"
										class="btn btn-primary btn-proceed btn-block" type="submit">
										Proceed
									</button>
								</div>
							</template>

							<template v-if="step === 2">
								<template v-if="accessMode === 'password'">
									<div class="form-group">
										<label for="password" class="mb-1 d-flex justify-content-between">
											<span>Password</span>
											<span class="cursor-pointer highlight-text highlight-text--green highlight-text--font-medium"
												@click.stop.prevent="setAccessMode('otp')">
												Login with OTP
											</span>
										</label>

										<div class="w-100 position-relative">
											<input v-model.trim="$v.form.password.$model"
												:class="{ 'is-invalid': $v.form.password.$dirty && $v.form.password.$error }" id="password"
												name="password" :type="showPassword ? 'text' : 'password'" placeholder="Password"
												class="form-control password-input" />
											<span @click="toggleShowPassword" class="password-toggle fe"
												:class="{ 'fe-eye': showPassword, 'fe-eye-off': !showPassword }"></span>
										</div>

										<div class="invalid-feedback" v-if="$v.form.password.$dirty && $v.form.password.$error">
											Password is required.
										</div>
									</div>
									<div class="form-group">
										<button id="login-btn" @click="handleLogin()" :disabled="processingLogin || $v.form.password.$invalid"
											:class="$v.form.password.$invalid ? 'btn-invalid' : ''" class="btn btn-primary btn-block"
											type="submit">
											<span v-if="!processingLogin">Log In</span>
											<span v-else>
												<i class="fa fa-spin fa-spinner"></i>
											</span>
										</button>
									</div>
								</template>
								<template v-if="accessMode === 'otp'">
									<div class="form-group">
										<label class="mb-1 d-flex justify-content-between">
											<span></span>
											<span class="cursor-pointer highlight-text highlight-text--green highlight-text--font-medium"
												@click="setAccessMode('password')">
												Login with Password
											</span>
										</label>
										<div class="input-group">
											<div class="d-flex justify-content-center w-100 mt-3">
												<v-otp-input ref="otpInput" input-classes="otp-input" separator="" :num-inputs="4"
													:should-auto-focus="true" :is-input-num="true" @on-change="handleOnChange"
													@on-complete="handleOnComplete" />
											</div>
										</div>

										<div class="invalid-feedback" v-if="$v.form.otpValue.$dirty && $v.form.otpValue.$error">
											OTP is required.
										</div>

										<div class="text-center mt-4">
											<p class="font-normal mb-2">Didn't receive code?</p>
											<div class="mt-1">
												<countdown-timer value="120" :should-start="false" @on-timer-restart="initiateOtp" />
											</div>
										</div>

									</div>
									<div class="form-group">
										<button :disabled="processingLogin || $v.form.otpValue.$invalid"
											:class="$v.form.otpValue.$invalid ? 'btn-invalid' : ''" class="btn btn-primary btn-block"
											type="button" @click="loginWithOTP">
											<span v-if="!processingLogin">Log In</span>
											<span v-else><i class="fa fa-spin fa-spinner"></i></span>
										</button>
									</div>
								</template>

								<div class="text-center" v-if="!isOTPAccess">

									<router-link to="/forgot-password" class="small text-muted">
										Forgot password?
									</router-link>
								</div>
							</template>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, requiredIf } from 'vuelidate/lib/validators'
import CountdownTimer from '@/components/core/CountdownTimer'
import countryCodeEmoji from 'country-code-emoji'
import { isValidPhoneNumber } from 'libphonenumber-js/mobile'

export default {
  components: { CountdownTimer },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required: requiredIf(function () {
          return this.isEmailLogin
        }),
        email
      },
      password: {
        required: requiredIf(function () {
          return !this.isOTPAccess
        })
      },
      phone: {
        required: requiredIf(function () {
          return this.isPhoneLogin
        })
      },
      otpValue: {
        required: requiredIf(function () {
          return this.isOTPAccess
        })
      }
    }
  },
  data() {
    return {
      showPassword: false,
      error: '',
      processingLogin: false,
      form: {
        email: '',
        password: '',
        phone: '',
        loginType: 'email',
        accessMode: '',
        otp: '',
        otpValue: ''
      },
      step: 1,
      accessMode: 'password',
      loginType: 'email',
      otpReferenceId: null,
      countries: [],
      isPhoneValid: false
    }
  },
  computed: {
    isLoginStep() {
      return this.step === 1
    },
    validEmail() {
      return !this.$v.form.email.$invalid
    },
    validPhone() {
      return (!this.$v.form.phone.$invalid || !(this.isPhoneLogin && !this.isPhoneValid))
    },
    isPasswordStep() {
      return this.step === 2
    },
    isEmailLogin() {
      return this.loginType === 'email'
    },
    isPhoneLogin() {
      return this.loginType === 'phone'
    },
    isOTPAccess() {
      return this.accessMode === 'otp'
    },
    titleText() {
      if (this.isLoginStep) {
        return 'Log in to your account'
      }
      if (this.isOTPAccess) {
        return 'Confirm OTP'
      } else {
        return 'Enter your password'
      }
    },
    actionText() {
      if (!this.isOTPAccess) {
        return `You are about to login with <b>${this.isEmailLogin ? this.form.email : this.form.phone}</b>`
      } else {
        return `Please enter the four digit code sent to <b>${this.isEmailLogin ? this.form.email : this.form.phone}</b>`
      }
    }
  },
  watch: {
    'form.phone'() {
      this.validatePhoneNumber()
    }
  },
  created() {
    this.fetchCountries()
  },
  methods: {
    countryCodeToEmoji(code) {
      return countryCodeEmoji(code)
    },
    async fetchCountries() {
      const response = await this.axios.get('/v1/countries')
      this.countries = response.data
      this.form.country = this.countries[0]
    },
    validatePhoneNumber() {
      this.isPhoneValid = isValidPhoneNumber(
        this.form.phone,
        this.form.country.code
      )
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
    proceedToPassword() {
      if (this.validPhone && this.validEmail) {
        this.step = 2
        this.setAccessMode('password')
      }
    },
    goToStart() {
      this.step = 1
      this.error = ''
    },
    async handleLogin() {
      this.error = ''
      if (this.processingLogin) return false

      this.processingLogin = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.processingLogin = false
        return
      }

      let payload = null
      if (this.isEmailLogin) {
        payload = {
          email: this.form.email,
          password: this.form.password
        }
      } else if (this.isPhoneLogin) {
        payload = {
          phone: this.form.phone,
          password: this.form.password,
          country_code: this.form.country.code
        }
      }

      try {
        const result = await this.$store.dispatch('auth/login', payload)
        this.processingLogin = false
        if (result) {
          await this.$router.push({ path: '/' })
          return
        }
        this.error = 'Invalid Credentials. Try again please.'
      } catch (e) {
        this.error = 'Invalid Credentials. Try again please.'
        this.processingLogin = false
      }
    },
    setLoginType(type) {
      this.loginType = type
    },
    setAccessMode(type) {
      this.accessMode = type
      if (type === 'otp') {
        this.initiateOtp()
      }
    },
    async loginWithOTP() {
      this.processingLogin = true
      this.error = null

      const payload = {
        otpCode: this.form.otpValue,
        type: 'staff',
        referenceId: this.otpReferenceId
      }

      try {
        const result = await this.$store.dispatch('auth/otpLogin', payload)
        this.processingLogin = false
        if (result) {
          await this.$router.push({ path: '/' })
          return
        }
        this.error = 'Invalid Credentials. Try again please.'
      } catch (e) {
        this.error = 'Invalid Credentials. Try again please.'
        this.processingLogin = false
      }
    },
    handleOnChange(data) {
      if (data.length < 4) {
        this.form.otpValue = ''
      }
    },
    handleOnComplete(data) {
      this.form.otpValue = data
    },
    async initiateOtp() {
      let payload = null
      if (this.isEmailLogin) {
        payload = {
          email: this.form.email,
          type: 'staff'
        }
      } else {
        payload = {
          phone: this.form.phone,
          country_code: this.form.country.code,
          type: 'staff'
        }
      }
      const response = await this.axios.post('/v1/otp-login', payload)
      this.otpReferenceId = response.data.reference_id
    }

  }
}
</script>
<style lang="scss" scoped>
$green-700: #0DAC5C;

.resend-text {
  color: $green-700;
  font-weight: 500;
  cursor: pointer;
}

.countdown-text {
  color: $green-700;
  font-weight: 500;
}
</style>
<style lang="scss" scoped>
$green-700: #0DAC5C;
$gray-7: #D3DCE6;
$gray-4: #575A65;
$gray-5: #6E717C;
$gray-10: #EFF2F7;
$neutral-500: #8D918F;
$neutral-900: #101211;

.cursor-pointer {
  cursor: pointer;
}

.title {
  &>img {
    width: 100px;
  }

  text-align: center;
}

.back-row {
  z-index: 2;
  position: absolute;

  @media screen and (max-width: 767.9px) {
    position: relative;
  }
}

.login-wrapper {

  & .btn-proceed {

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      background: $gray-7;
      color: #EFF2F7;
      border-color: $gray-7;
    }
  }

  & .btn-invalid {
    background: $gray-7;
    color: #EFF2F7;
    border-color: $gray-7;
    box-shadow: none;
  }

  .login-tabs {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    justify-content: center;

    .tab-item {
      padding: 0.5rem 0.875rem;
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 1.25rem;
      color: $neutral-500;
      border-radius: 0.375rem;
      cursor: pointer;

      &--active {
        background: #F5FFFA;
        color: $green-700;
      }
    }
  }

  & ::v-deep .otp-input {
    height: 3.9rem;
    width: 3.9rem;
    border-radius: .5rem;
    border: none;
    background: $gray-10;
    margin: auto .5rem;
    font-size: 20px;
    text-align: center;
    padding: 5px;

    &.error {
      border: 1px solid red !important;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {}

  }

  .highlight-text {
    font-size: 0.875rem;

    &--green {
      color: $green-700;
    }

    &--dark {
      color: $gray-4;
    }

    &--font-medium {
      font-weight: 500;
    }
  }

  .form-group {
    label {
      font-size: 0.875rem;
    }
  }

  .password-input {
    padding-right: 2rem;
  }

  .password-toggle {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 22%;
  }

  .input-group-prepend {
    .custom-select {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;

      &:focus {
        border-color: #D2DDEC;
        box-shadow: none;
      }
    }
  }

  .invalid-feedback {
    display: block;
  }
}

.m-h-screen {
  min-height: 100vh;
}

.m-h-login {
  min-height: calc(100vh - 14rem);
}
</style>
