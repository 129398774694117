<template>
	<div class="pt-3">
		<div class="" v-if="!isEditMode">
			<div class="py-1 mb-3">
				<a
					href="#"
					@click="editRouteBusStop"
					class="text-primary font-weight-bolder form-control-text">
					{{ routeBusStop.name }}</a>
			</div>

			<template>
				<div class="form-group mb-0">
					<b-form-checkbox class="v-checkbox" :disabled="true" v-model="tempValue.isPickup">
						Pickup location
					</b-form-checkbox>
				</div>
				<div class="form-group mb-0">
					<b-form-checkbox class="v-checkbox" disabled-field="notEnabled" :disabled="true" v-model="tempValue.isDropOff">
						Drop-off location
					</b-form-checkbox>
				</div>
			</template>

			<div class="d-flex justify-content-end mt-3">
				<button
					class="btn btn-sm btn-text text-danger mr-2"
					@click="deleteRouteBusStop">
					Delete
				</button>

				<button
					class="btn btn-sm btn-primary"
					@click="editRouteBusStop">
					Edit
				</button>
			</div>
		</div>
		<div v-if="isEditMode">
			<div>
				<div class="form-group">
					<input type="text" class="form-control" v-model="tempValue.name" />
				</div>
				<template v-if="!isFirstStop && !isLastStop">
					<div class="form-group mb-0">
						<b-form-checkbox class="v-checkbox" v-model="tempValue.isPickup">
							Enable as pickup location
						</b-form-checkbox>
					</div>
					<div class="form-group mb-0">
						<b-form-checkbox class="v-checkbox" v-model="tempValue.isDropOff">
							Enable as drop-off location
						</b-form-checkbox>
					</div>
				</template>
			</div>

			<div class="d-flex justify-content-end mt-3">
				<button @click="cancelEdit" class="btn btn-sm btn-text text-danger mr-2">
					Cancel
				</button>
				<button
					class="btn btn-sm btn-primary"
					@click="save"
					:disabled="isProcessing">
					<span>{{ isProcessing ? 'Saving...' : 'Save' }}</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import RouteBusStopMixin from '@/mixins/route_bus_stop'
import { extractErrorMessage } from '@/utils/helpers'
export default {
  mixins: [
    RouteBusStopMixin
  ],
  props: {
    routeBusStop: [Object],
    noOfStops: { type: Number, required: true }
  },
  data () {
    return {
      isEditMode: false,
      isProcessing: false,
      tempValue: {
        name: null,
        isPickup: false,
        isDropOff: false
      }
    }
  },
  computed: {
    isFirstStop () {
      return this.routeBusStop.position === 1
    },
    isLastStop () {
      return this.routeBusStop.position === this.noOfStops
    }
  },
  mounted () {
    this.tempValue = { ...this.routeBusStop }
    if (this.tempValue.edit) {
      this.editRouteBusStop()
    }
  },
  watch: {
    routeBusStop: {
      // deep: true,
      handler: function (newv) {
        if (newv) {
          this.initForm()
          if (this.tempValue.edit) {
            this.editRouteBusStop()
          }
        }
      }
    }
  },
  methods: {
    deleteRouteBusStop () {
      this.attemptDeleteRouteBusStop(this.routeBusStop).then(() => {
        this.$emit('deleted', this.routeBusStop)
      })
    },
    async save () {
      this.isProcessing = true
      try {
        const payload = {
          name: this.tempValue.name,
          is_pickup: this.tempValue.isPickup,
          is_dropoff: this.tempValue.isDropOff
        }
        if (this.isFirstStop) {
          payload.is_pickup = true
          payload.is_dropoff = false
        }
        if (this.isLastStop) {
          payload.is_pickup = false
          payload.is_dropoff = true
        }
        await this.axios.patch(`/v1/route-busstops/${this.routeBusStop.id}`, payload)
        this.routeBusStop.name = this.tempValue.name
        this.routeBusStop.is_dropoff = this.tempValue.isDropOff ? 1 : 0
        this.routeBusStop.is_pickup = this.tempValue.isPickup ? 1 : 0
        this.isProcessing = false
        this.isEditMode = false
      } catch (err) {
        this.isProcessing = false
        this.$swal('Could not save route bus stop', extractErrorMessage(err, ''), 'info')
      }
    },
    editRouteBusStop () {
      this.isEditMode = true
      this.initForm()
    },
    initForm () {
      this.tempValue = { ...this.tempValue, ...this.routeBusStop }
      this.tempValue.isPickup = !!this.routeBusStop?.is_pickup
      this.tempValue.isDropOff = !!this.routeBusStop?.is_dropoff
    },
    cancelEdit () {
      if (this.isProcessing) return
      this.isEditMode = false
      this.initForm()
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .v-checkbox {
  .custom-control-input {
    height: 1rem;
  }

  /**
  *Override for the disabled state
  */
  .custom-control-input {
    &:disabled {
      & ~.custom-control-label {
        color: initial;
      }

      &:checked {
        & ~.custom-control-label:before {
          background-color: #00000050;
        }
      }

      &:not(:checked) {
        & ~.custom-control-label:before {
          background-color: #E3EBF6;
          border: #B1C2D9 solid 0;
        }
      }
    }
  }

  .custom-control-label {
    &:before, &:after {
      top: 0;
    }
  }
}

</style>
