<template>
	<b-modal
		:id="modalId"
		@close="close"
		size="md"
		no-close-on-backdrop
		no-close-on-esc
		>
		<template #modal-title>
			{{
				isVerificationStep
					? 'Are you really sure ?'
					: 'Assign Vehicle to Partner'
			}}
		</template>
		<div class="py-2">
			<template v-if="!isVerificationStep">
				<div
					class="alert alert-danger alert-dismissible fade show"
					role="alert"
					v-if="errorMessage"
					>
					{{ errorMessage }}
				</div>

				<div class="form mb-3">
					<div class="form-group mb-3">
						<label class="form-label">Select Vehicle</label>
						<v-select
							v-model="form.vehicle"
							class="form-group"
							:options="localVehicles"
							label="name"
							required
							placeholder="Select a vehicle to assign"
							>
							<template v-slot:option="vehicle">
								{{ vehicle.seats }} Seater - {{ vehicle.brand }}
								{{ vehicle.name }} {{ vehicle.registration_number }}
							</template>
							<template v-slot:selected-option="vehicle">
								{{ vehicle.seats }} Seater - {{ vehicle.brand }}
								{{ vehicle.name }} {{ vehicle.registration_number }}
							</template>
						</v-select>
					</div>

					<template v-if="form.vehicle">
						<div
							class="form-alert form-alert--gray"
							v-if="form.vehicle.partner_id"
							>
							<i class="fe fe-info mr-2"></i>
							<p class="mb-0">
								This vehicle has already been assigned to a partner
							</p>
						</div>
						<div class="form-alert form-alert--gray" v-else>
							<i class="fe fe-info mr-2"></i>
							<p class="mb-0">This vehicle is not assigned to a partner</p>
						</div>

						<div
							class="form-alert form-alert--gray"
							v-if="!form.vehicle.driver"
							>
							<i class="fe fe-info mr-2"></i>
							<p class="mb-0">No driver assigned to vehicle</p>
						</div>
					</template>
				</div>
			</template>

			<template v-else>
				<div class="form-alert form-alert--info-purple mb-4" role="alert">
					<img class="mr-2" src="@/assets/img/caution.svg" />
					<p class="mb-0">{{ alertMessage }}</p>
				</div>

				<p class="mb-4 prompt-message">{{ promptText }}</p>

				<p class="mb-2 prompt-message">
					Please type <b>{{ promptKey }}</b> to confirm this action.
				</p>
				<div class="form mb-3">
					<div class="form-group">
						<input
							v-model="form.password"
							class="form-control mb-3"
							:placeholder="`Type ${promptKey}`"
							/>
					</div>
				</div>
			</template>
		</div>
		<template #modal-footer>
			<div class="w-100">
				<b-button
					:disabled="processing || !buttonEnabled"
					variant="primary"
					class="float-right"
					@click="confirmAction()"
					>
					{{
						processing
							? 'Processing'
							: isVerificationStep
								? 'Assign Vehicle'
								: 'Proceed'
					}}
				</b-button>
				<b-button
					:disabled="processing"
					variant="secondary"
					class="float-right mr-2"
					@click="close()"
					>
					Cancel
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { extractErrorMessage } from '@/utils/helpers'

export default {
  name: 'AssignVehicle',
  props: {
    show: Boolean,
    partnerId: [Number, String],
    vehicles: Array
  },
  data () {
    return {
      form: {
        vehicle: null,
        password: null
      },
      processing: false,
      errorMessage: '',
      pageSize: 3000,
      currentPage: 1,
      localVehicles: [],
      totalRecords: null,
      modalId: 'assign-partner-vehicle-modal',
      steps: 2,
      currentStep: 1,
      promptText:
        'If this vehicle has already been assigned to another vehicle partner, assigning the vehicle to this new partner would automatically un-assign the vehicle from the previous vehicle partner.',
      alertMessage: 'It’s important you read the message below!',
      promptKey: 'YES'
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.showModal()
      } else {
        this.hideModal()
      }
    }
  },
  created () {
    if (this.vehicles) {
      this.localVehicles = this.vehicles
    } else {
      this.fetchVehicles()
    }
  },
  computed: {
    vehicleAssigned () {
      return !!this.form.vehicle?.partner_id
    },
    isValidForm () {
      return this.form.vehicle
    },
    computedVehicles () {
      return this.vehicles || this.localVehicles
    },
    isVerificationStep () {
      return this.currentStep === 2
    },
    validPromptKey () {
      return this.form.password === this.promptKey
    },
    buttonEnabled () {
      return this.isVerificationStep ? this.validPromptKey : this.isValidForm
    }
  },
  methods: {
    fetchVehicles () {
      const baseUrl = '/v1/vehicles'
      const limit = this.pageSize
      const page = this.currentPage
      const url = `${baseUrl}?limit=${limit}&page=${page}&metadata=true`
      this.axios.get(url).then((res) => {
        this.localVehicles = res.data.data
        this.totalPages = res.data.metadata.total_pages
        this.totalRecords = res.data.metadata.total
      })
    },
    assignVehicle () {
      if (!this.isValidForm) {
        return
      }
      this.processing = true
      this.errorMessage = ''
      this.axios
        .patch(`/v1/vehicles/${this.form.vehicle.id}`, {
          partner_id: this.partnerId
        })
        .then(() => {
          this.close()
          this.$emit('vehicle-assigned', this.form.vehicle.id)
          this.$swal({
            icon: 'success',
            title: 'Vehicle Assigned',
            text: 'Vehicle has been assigned successfully',
            showCloseButton: true
          })
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occurred',
            text: 'An error occurred. please review',
            showCloseButton: true
          })
          this.form.password = ''
          const msg = extractErrorMessage(e, 'An error occurred!')
          this.errorMessage = msg
        })
        .finally(() => {
          this.processing = false
          this.form.vehicle = null
        })
    },
    showModal () {
      this.$bvModal.show(this.modalId)
    },
    close () {
      this.$emit('close')
      this.form.vehicle = ''
      this.form.password = ''
      this.currentStep = 1
    },
    hideModal () {
      this.$bvModal.hide(this.modalId)
    },
    confirmAction () {
      if (this.isVerificationStep && this.isValidForm) {
        this.assignVehicle()
      } else {
        if (this.isValidForm) {
          this.currentStep = 2
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$gray-2: #25292d;
$gray-3: #444854;
$gray-5: #6e717c;
$neutral-400: #f4f5f4;

.prompt-message {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: $gray-3;
}

::v-deep .v-select {
  &.vs--single {
    & .vs__selected {
      max-width: 80%;
    }
  }

  & .vs__search::placeholder {
    color: #d3dce6;
    font-weight: 300;
    font-size: 0.875rem;
  }

  & .vs__dropdown-option {
    padding: 1rem 0.75rem;
    font-size: 0.875rem;
    white-space: normal;

    &:hover {
      background: $gray-2;
    }

    &--highlight,
    &--selected {
      background: $gray-2;
      color: white;
    }
  }
}

.form-alert {
  //TODO: Extract to reusable component
  border-radius: 0.375rem;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  &:not(:last-of-type) {
    border-bottom: 1rem;
  }

  &--gray {
    background: $neutral-400;
    color: $gray-5;
  }

  &--info-purple {
    $purple-200: #ebebff;
    $purple-800: #2323f0;
    background: $purple-200;
    color: $purple-800;
  }
}

#assign-partner-vehicle-modal {
  & .modal-title {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.3125rem;
  }

  & .modal-footer {
    border-top: none;
  }
}
</style>
