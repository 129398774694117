<template>
	<div class="card">
		<div class="card-header">
			<div class="row align-items-center">
				<div class="col">
					<h4 class="card-header-title">Reports</h4>
				</div>
				<download-button label="Download" :downloading="downloadingReport" :actions="manifestDownloadOptions">
					<template #option(weekly)="data">
						<span class="text-capitalize" @click.prevent="getWeekBookingReport">{{ data.label }}</span>
					</template>
					<template #option(monthly)="data">
						<span class="text-capitalize" @click.prevent="getMonthBookingReport">{{ data.label }}</span>
					</template>
					<template #option(custom)="data">
						<span class="text-capitalize" @click.prevent="openModal()">{{ data.label }}</span>
					</template>
				</download-button>
			</div>
		</div>
		<div class="card-body">
			<h4>Business Booking Report</h4>
			<form @submit.prevent="downloadBusinessBookingReport" class="mt-2">
				<div class="form-group">
					<label class="form-label">Company</label>
					<v-select v-model="businessReportFilter.corporateId" class="form-group" :options="companies"
						label="corporate_name" :reduce="(company) => company.id" placeholder="Select a company">
					</v-select>
				</div>
				<div class="form-group">
					<label class="form-label">Month</label>
					<select class="form-select form-control mb-3" v-model.trim="businessReportFilter.month" required>
						<option :value="index" v-for="(month, index) in months" :key="month">
							{{ month }}
						</option>
					</select>
				</div>
				<button type="submit" class="btn btn-primary btn-sm">
					Generate
				</button>
			</form>
		</div>
		<b-modal :id="modalId" @close="closeModal" size="sm" no-close-on-backdrop no-close-on-esc>
			<template #modal-title>
				Manifest report (Select date range)
			</template>
			<div class="py-2">
				<div class="form">
					<div class="form-group">
						<label class="form-label mb-0">Start Date</label>
						<div style="width: 100%">
							<v-datepicker v-model="form.startDate" style="width: 100%"></v-datepicker>
						</div>
					</div>

					<div class="form-group">
						<label class="form-label mb-0">End Date</label>
						<div style="width: 100%">
							<v-datepicker v-model="form.endDate" style="width: 100%"></v-datepicker>
						</div>
					</div>
				</div>

			</div>
			<template #modal-footer>
				<div class="w-100">
					<b-button :disabled="downloadingReport" variant="primary" class="float-right"
						@click="generateCustomRangeReport()">
						{{ downloadingReport ? 'Processing' : 'Generate' }}
					</b-button>
					<b-button :disabled="downloadingReport" variant="secondary" class="float-right mr-2" @click="closeModal()">
						Close
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'
import { groupBy, uniq } from 'lodash'
import DownloadButton from '@/components/core/DownloadButton'
import { format } from 'date-fns'

export default {
  name: 'RouteReportWidget',
  components: { DownloadButton },
  props: {
    routeId: [Number, String],
    companies: {
      type: Array,
      required: true
    },
    dateWeek: {
      type: [Array],
      required: true
    },
    activeRoute: { type: Object, default: null, required: true }
  },
  data() {
    return {
      downloadingReport: false,
      businessReportFilter: {
        corporateId: '',
        month: '0'
      },
      months: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'June',
        'July',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec'
      ],
      manifestDownloadOptions: [
        {
          key: 'weekly',
          label: 'This Week'
        },
        {
          key: 'monthly',
          label: 'This Month'
        },
        {
          key: 'custom',
          label: 'Custom'
        }
      ],
      modalId: 'route-report-generation-modal',
      form: {
        startDate: new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`),
        endDate: new Date()
      }
    }
  },
  methods: {
    getWeekBookingReport() {
      const data = {
        start_date: this.dateWeek[0],
        end_date: this.dateWeek[this.dateWeek.length - 1]
      }
      this.fetchAndDownloadReport(data)
    },
    downloadBusinessBookingReport() {
      if (!this.businessReportFilter.corporateId) {
        return
      }

      this.axios
        .get(
          `/v1/corporates/${this.businessReportFilter.corporateId}` +
          `/bookings/route/${this.activeRoute.id}/duration/${this.businessReportFilter.month}`
        )
        .then((res) => {
          if (res.data.length > 0) {
            this.processCorporateBookingData(res.data.data)
          } else {
            this.$swal({
              icon: 'error',
              title: 'Download error',
              text: 'We could not process download for this report',
              showCloseButton: true
            })
          }
        })
    },
    fetchAndDownloadReport(params, customRange = false) {
      this.downloadingReport = true
      return this.axios
        .get(`/v1/routes/${this.activeRoute.id}/bookings/start_date/${params.start_date}/end_date/${params.end_date}`)
        .then((res) => {
          if (res.data.data.length > 0) {
            this.downloadReport(res.data.data, params)
          } else {
            this.$swal({
              icon: 'error',
              title: 'Download error',
              text: 'We could not process download for this report',
              showCloseButton: true
            })
          }
        })
        .finally(() => {
          this.downloadingReport = false
          if (customRange) {
            this.closeModal()
          }
        })
    },
    getMonthBookingReport() {
      const params = this.getFirstAndLastDateOfMonth()
      const data = {
        start_date: params[0].toJSON().split('T')[0],
        end_date: params[1].toJSON().split('T')[0]
      }

      this.fetchAndDownloadReport(data)
    },
    downloadReport(data, params) {
      const csvData = data.map((x) => {
        return {
          amount: x.amount,
          pickup: x.pickup.name,
          destination: x.destination.name,
          email: x.user.email,
          name: x.user.fname + ' ' + x.user.lname,
          start_date: moment(x.start_date).format('YYYY-MM-DD'),
          end_date: moment(x.end_date).format('YYYY-MM-DD')
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: `Bookings for ${this.activeRoute.pickup} between ${params.start_date} and ${params.end_date}`,
        useTextFile: false,
        useBom: true,
        headers: ['Amount', 'Pickup', 'Destination', 'Email', 'Name', 'Start Date', 'End Date'
        ]
      }
      const csvExporter = new ExportToCsv(csvParams)
      csvExporter.generateCsv(csvData)
      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true
      })
    },
    processCorporateBookingData(bookingData) {
      const data = bookingData || null
      if (!data) {
        return window.alert('An error occurred while fetching report, please try again')
      }
      // TODO: review for re-usability and abstraction
      const getDaysInMonth = (month, year) => {
        month = parseInt(month)
        const date = new Date(year, month, 1)
        const days_ = []
        while (date.getMonth() === month) {
          days_.push(moment(new Date(date)).format('YYYY-MM-DD'))
          date.setDate(date.getDate() + 1)
        }
        return days_
      }
      const currentMonthDates = getDaysInMonth(this.businessReportFilter.month, new Date().getFullYear())
      for (let i = 0; i < data.length; i++) {
        const sd = moment(data[i].start_date).format('YYYY-MM-DD')
        const ld = moment(data[i].end_date).format('YYYY-MM-DD')
        const _weeks = this.getDatesForDayIDs(data[i].day_ids, sd, ld)
        data[i].valid_days = currentMonthDates.filter((x) => _weeks.includes(x))
      }
      const corporateFilteredBooking = data
      return this.processAndDownloadBusinessReport(corporateFilteredBooking, currentMonthDates)
    },
    processAndDownloadBusinessReport(
      corporateFilteredBooking,
      currentMonthDates
    ) {
      const _data = groupBy(corporateFilteredBooking, (x) => {
        return x.email
      })

      let arr = []
      const userDates = []
      for (const [key, value] of Object.entries(_data)) {
        arr.push({
          user: key,
          data: value.map((x) => x.valid_days),
          staff_id: value[0].staff_id,
          fname: value[0].fname,
          lname: value[0].lname,
          email: value[0].email
        })
      }

      for (let i = 0; i < arr.length; i++) {
        const merged = [].concat.apply([], arr[i].data)
        userDates.push({ user_email: arr[i].user, dates: uniq(merged) })
      }
      arr = arr.sort((a, b) => a.user.localeCompare(b.user))

      let dateColumns = null

      const csvData = arr.map((x) => {
        const currentUserDates = userDates.filter(
          (user) => user.user_email === x.user
        )
        const dataObj = {}
        const dataSchema = {
          staff_id: x.staff_id || 'N/A',
          email: x.email,
          route: this.activeRoute.pickup,
          name: x.fname + ' ' + x.lname
        }

        for (let i = 0; i < currentMonthDates.length; i++) {
          for (let j = 0; j < currentUserDates[0].dates.length; j++) {
            if (currentMonthDates[i] === currentUserDates[0].dates[j]) {
              dataObj[currentMonthDates[i]] = 1
            }
          }
          dataObj[currentMonthDates[i]] =
            dataObj[currentMonthDates[i]] === 1
              ? dataObj[currentMonthDates[i]]
              : 0
        }

        dateColumns = Object.keys({ ...dataSchema, ...dataObj })

        return { ...dataSchema, ...dataObj }
      })

      const csvParams = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: `Booking report for ${this.activeRoute.pickup} the month of ${this.months[this.businessReportFilter.month]
          }`,
        useTextFile: false,
        useBom: true,
        headers: dateColumns
      }

      const csvExporter = new ExportToCsv(csvParams)
      csvExporter.generateCsv(csvData)
    },
    getFirstAndLastDateOfMonth() {
      const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      return [firstDay, lastDay]
    },
    closeModal() {
      this.$bvModal.hide(this.modalId)
      this.form = {
        startDate: new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`),
        endDate: new Date()
      }
    },
    openModal() {
      this.$bvModal.show(this.modalId)
    },
    generateCustomRangeReport() {
      const data = {
        start_date: format(this.form.startDate, 'yyyy-MM-dd'),
        end_date: format(this.form.endDate, 'yyyy-MM-dd')
      }
      this.fetchAndDownloadReport(data, true)
    }
  },
  beforeDestroy() {
    this.closeModal()
  }
}
</script>

<style scoped></style>
