<template>
	<div
		class="modal fade"
		id="changePasswordModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="changePasswordLabel"
		aria-hidden="true"
		>
		<div class="modal-dialog modal-dialog-centered" role="document" v-if="user">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="changePasswordLabel">
						Change User Password
					</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<form @submit.prevent="changePassword">
						<div class="row">
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label class="form-label" for="new-password"
										>New Password</label
										>
									<input
										id="new-password"
										v-model="form.password"
										required
										type="password"
										class="form-control"
										/>
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="confirm-password">Password Confirmation</label>
									<input
										id="confirm-password"
										v-model="form.passwordConfirmation"
										required
										type="password"
										class="form-control"
										/>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="changePassword"
						:disabled="processing || !formValid"
						>
						{{ processing ? 'Processing...' : 'Change Password' }}
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#changePasswordModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'

export default {
  props: {
    show: Boolean,
    user: Object,
    entityType: {
      type: String,
      default: 'users'
    }
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        password: '',
        passwordConfirmation: ''
      },
      processing: false,
      errorMessage: ''
    }
  },
  computed: {
    formValid () {
      return this.form.password && this.form.passwordConfirmation
    }
  },
  methods: {
    changePassword () {
      if (!this.formValid) {
        return
      }

      if (this.form.password !== this.form.passwordConfirmation) {
        return this.$swal({
          icon: 'error',
          title: 'Passwords Mismatch',
          text: 'The passwords you provided do not match, please try again.',
          showCloseButton: true
        })
      }

      this.processing = true

      this.errorMessage = ''

      this.axios
        .patch(`/v1/${this.entityType}/${this.user.id}`, {
          password: this.form.password
        })
        .then(() => {
          this.close()

          this.$swal({
            icon: 'success',
            title: 'Password Changed',
            text: 'Password has been changed successfully',
            showCloseButton: true
          })
        })
        .catch((e) => {
          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
