import { loadRouteComponent } from '@/utils/helpers'
import Company from '@/layouts/modules/Company/Index.vue'
import CompanyDetails from '@/layouts/modules/Company/CompanyDetails.vue'
import CompanyList from '@/layouts/modules/Company/CompanyList.vue'
const CompanyRoutes = [
  {
    path: 'companies',
    component: Company,
    children: [
      {
        path: '',
        component: CompanyList,
        props: true,
        children: [
          {
            path: 'active',
            component: loadRouteComponent('Company/pages/List/Companies'),
            name: 'Companies',
            props: true,
            meta: {
              requiredPermissions: ['corporates002'],
            },
          },
          {
            path: 'demo-requests',
            component: loadRouteComponent('Company/pages/List/DemoRequests'),
            name: 'DemoRequests',
            props: true,
            meta: {
              requiredPermissions: ['corporates002'],
            },
          },
          {
            path: 'interests',
            component: loadRouteComponent('Company/pages/List/Interests'),
            name: 'CompanyInterests',
            props: true,
            meta: {
              requiredPermissions: ['corporates002'],
            },
          },
        ],
      },
      {
        path: ':companyId/:status',
        component: CompanyDetails,
        props: true,
        children: [
          {
            path: '',
            name: 'ShowCompany',
            redirect: 'credit-line',
            props: true,
            meta: {
              requiredPermissions: [
                'staff001',
                'staff002',
                'staff003',
                'staff004',
                'staff005',
                'staff006',
                'staff007',
                'staff008',
              ],
            },
          },
          {
            path: 'company-info',
            name: 'ShowCompanyInfo',
            component: loadRouteComponent('Company/pages/Show/CompanyInfo'),
            props: true,
            meta: {
              requiredPermissions: ['corporates003'],
            },
          },
          {
            path: 'company-stats',
            name: 'ShowCorporateStats',
            component: loadRouteComponent('Company/pages/Show/CorporateStats'),
            props: true,
            meta: {
              requiredPermissions: ['corporates003'],
            },
          },
          {
            path: 'routes',
            name: 'CorporateRoutes',
            component: loadRouteComponent('Company/pages/Show/Routes/Index'),
            props: true,
            meta: {
              requiredPermissions: ['corporates003'],
            },
          },
          {
            path: 'capacity-configuration',
            name: 'CapacityConfiguration',
            component: loadRouteComponent(
              'Company/pages/VehicleCapacityConfig/List'
            ),
            props: true,
            meta: {
              requiredPermissions: ['corporates003'],
            },
          },
          {
            path: 'trip-report',
            name: 'CompanyTripReport',
            component: loadRouteComponent('Company/pages/Show/TripReport'),
            props: true,
            meta: {
              requiredPermissions: ['corporates003'],
            },
          },
          {
            path: 'company-groups',
            name: 'CompanyGroups',
            component: loadRouteComponent('Company/pages/Show/CompanyGroups'),
            props: true,
            meta: {
              requiredPermissions: ['corporates003'],
            },
          },
          {
            path: 'staff',
            name: 'ShowCompanyStaff',
            component: loadRouteComponent('Company/pages/Show/Staff/Index'),
            props: true,
            meta: {
              requiredPermissions: ['corporates003'],
            },
          },
          {
            path: 'wallet',
            name: 'ShowCompanyWallet',
            component: loadRouteComponent('Company/pages/Show/Wallet'),
            props: true,
            meta: {
              requiredPermissions: ['corporates003'],
            },
          },
          {
            path: 'request',
            name: 'ShowCompanyRequest',
            component: loadRouteComponent('Company/pages/Show/Request'),
            props: true,
            meta: {
              requiredPermissions: ['corporates003'],
            },
          },
          {
            path: 'corporate-pay',
            name: 'ShowCompanyCorporatePay',
            component: loadRouteComponent('Company/pages/Show/CorporatePay'),
            props: true,
            meta: {
              requiredPermissions: ['corporates003'],
            },
          },
          {
            path: 'settings',
            name: 'ShowCompanySettings',
            component: loadRouteComponent('Company/pages/Show/Settings'),
            props: true,
            meta: {
              requiredPermissions: ['corporates003'],
            },
          },
          {
            path: 'credit-line-usages',
            name: 'ShowCompanyCreditLineUsages',
            component: loadRouteComponent(
              'Company/pages/Show/CreditLineUsages/Index'
            ),
            props: true,
            meta: {
              requiredPermissions: ['corporates008'],
            },
          },
          {
            path: 'credit-line',
            name: 'ShowCreditLine',
            component: loadRouteComponent('Company/pages/Show/CreditLine/Show'),
            props: true,
            meta: {
              requiredPermissions: ['corporates008'],
            },
          },
          {
            path: 'credit-line/create',
            name: 'CreateCompanyCreditLine',
            component: loadRouteComponent(
              'Company/pages/Show/CreditLine/Create'
            ),
            props: true,
            meta: {
              requiredPermissions: ['corporates008'],
            },
          },
          {
            path: 'credit-line/edit',
            name: 'UpdateCompanyCreditLine',
            component: loadRouteComponent('Company/pages/Show/CreditLine/Edit'),
            props: true,
            meta: {
              requiredPermissions: ['corporates008'],
            },
          },
          {
            path: 'credit-line/fund',
            name: 'TopUpSelectStaff',
            component: loadRouteComponent(
              'Company/pages/Show/CreditLine/RunForSelectStaff'
            ),
            props: true,
            meta: {
              requiredPermissions: ['staff001', 'corporates008'],
            },
          },
        ],
      },
      {
        path: 'insights',
        component: loadRouteComponent('Company/pages/Insights'),
        name: 'CompanyInsights',
        props: true,
        meta: {
          requiredPermissions: ['corporates003'],
        },
      },
      {
        path: ':companyId/company-groups/:groupId',
        name: 'SingleCompanyGroups',
        component: loadRouteComponent('Company/pages/Show/SingleCompanyGroups'),
        props: true,
      },
    ],
  },
]

export default CompanyRoutes
