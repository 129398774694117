'use strict'

import axios from 'axios'

class UnAuthorizedAccessError {
  constructor (e) {
    this.e = e
  }
}

const config = {
  baseURL: process.env.VUE_APP_API_URL + '/drp',
  timeout: 60 * 1000 // Timeout
}

const _drpAxios = axios.create(config)

_drpAxios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

_drpAxios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    if (error && error.response && error.response.status == 401) {
      return Promise.reject(new UnAuthorizedAccessError(error))
    }

    // Do something with response error
    return Promise.reject(error)
  }
)

export const drpAxiosInstance = _drpAxios
