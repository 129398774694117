<template>
	<div class="container-fluid">
		<template v-if="currentRouteItinerary">
			<div class="row mt-5">
				<div class="col-12">
					<div class="card">
						<div class="card-body">
							<h3 class="card-title">
								{{ currentRouteItinerary.trip_time }} Trip Time <span v-if="!userHasPermission('routes008')">(edit unauthorised)</span>
							</h3>

							<div class="mt-4" >
								<table class="table table-striped">
									<tbody>
										<tr>
											<td>Starting Time</td>
											<td>
												<div class="flex justify-end">
													<div v-if="edits.$trip_time">
														<div class="d-flex flex-row">
															<div>
																<vue-timepicker
																	format="h:mm A"
																	hide-clear-button
																	v-model="tempTripTime"
																	input-class="form-control"
																	></vue-timepicker>
															</div>

															<div>
																<span
																	@click="onUpdateTripTime"
																	class="btn btn-sm btn-primary ml-2"
																	>Save</span
																	>
																<span
																	@click="edits.$trip_time = false"
																	class="btn btn-sm btn-link ml-2"
																	>Cancel</span
																	>
															</div>
														</div>
													</div>

													<div v-else class="d-flex flex-row gap-2">
														<div>{{ currentRouteItinerary.trip_time }}</div>
														<div>
															<span
                              v-if="userHasPermission('routes008')"
																@click="editRouteItineraryTripTime"
																class="text-info"
																>Edit</span
																>
														</div>
													</div>
												</div>
											</td>
										</tr>

										<tr>
											<td>Starting Bus Stop</td>
											<td>
                        <div class="flex justify-end">
                          <div v-if="edits.$pickup_id">
                            <div class="d-flex flex-row">
                              <div>
                                <select
                                  class="custom-select custom-select-sm"
                                  v-model="currentRouteItinerary.pickup_id"
                                  >
                                  <option
                                    v-for="(b, i) in routeBusStops"
                                    :value="b.id"
                                    :key="i"
                                    >
                                    {{ b.name }}
                                  </option>
                                </select>
                              </div>

                              <div>
                                <span
                                  @click="onUpdatePickup"
                                  class="btn btn-sm btn-primary ml-2"
                                  >Save</span
                                  >
                                <span
                                  @click="edits.$pickup_id = false"
                                  class="btn btn-sm btn-link ml-2"
                                  >Cancel</span
                                  >
                              </div>
                            </div>
                          </div>

                          <div class="d-flex flex-row gap-2" v-else>
                            <div class="">
                              {{
                                currentRouteItinerary.starting_bus_stop
                                  ? currentRouteItinerary.starting_bus_stop.name
                                  : ' - '
                              }}&nbsp;
                            </div>
                            <div>
                              <span
                              v-if="userHasPermission('routes008')"
                                @click="editRouteItineraryPickup"
                                class="text-info"
                                >Edit</span
                                >
                            </div>
                          </div>
                        </div>
											</td>
										</tr>

										<tr>
											<td>Default Fare</td>
											<td>
                        <div class="flex justify-end">
                          <vue-inline-text-editor
                            :value.sync="currentRouteItinerary.default_fare"
                            placeholder="Enter amount"
                            :currency-symbol="
                              currentRouteItinerary.default_fare_currency
                            "
                            @update="onUpdate"
                            type="currency"
                            :disabled="!userHasPermission('routes008')"
                            >
                            <!-- <span slot="edit-label" class="text-info" v-if="userHasPermission('routes008')">Edit</span> -->
                          </vue-inline-text-editor>
                        </div>
											</td>
										</tr>

										<tr>
											<td>Total Capacity</td>
											<td>
                        <p class="flex justify-end">
                          {{
                            currentRouteItinerary.total_seats || 'Not Available'
                          }}
                        </p>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<ItineraryPricingSettings
						@updated="$event => loadCurrentRouteItinerary(false)"
						:routeItinerary="currentRouteItinerary">
					</ItineraryPricingSettings>

					<div class="card mt-5" v-if="currentRouteItinerary.pricing_type === 'route_price_table_lookup'">
						<div class="card-body">
							<h3 class="card-title">Pricing</h3>

							<table class="mt-3 table striped">
								<thead>
									<tr>
										<th>From</th>
										<th>To</th>
										<th>Charge</th>
									</tr>
								</thead>

								<tbody>
									<template
										v-for="(pricing, index) in newItinerary.pricingTable"
										>
										<tr :key="index">
											<td>
												<select
													class="custom-select custom-select-sm mt-3"
													v-model="pricing.originRouteBusStop"
													>
													<option
														v-for="(b, i) in routeBusStops"
														:value="b.id"
														:key="i"
														>
														{{ b.name }}
													</option>
												</select>
											</td>
											<td>
												<select
													class="custom-select custom-select-sm mt-3"
													v-model="pricing.destinationRouteBusStop"
													@change="redrawPriceTable(index)"
													>
													<option
														v-for="(b, i) in routeBusStopsAfter(
															pricing.originRouteBusStop
														)"
														:value="b.id"
														:key="i"
														>
														{{ b.name }}
													</option>
												</select>
											</td>
											<td>
												<div class="input-group input-group-sm mt-3">
													<div class="input-group-prepend">
														<span class="input-group-text">{{
															pricing.fare_currency
														}}</span>
													</div>
													<input
														type="text"
														v-model.number="pricing.fare"
														placeholder="Price"
														class="form-control"
														/>
												</div>
											</td>
											<td>
												<div class="removeBg">
													<i
														v-if="newItinerary.pricingTable.length > 1"
														:class="
															index < 1
																? 'noDisplay'
																: 'fe fe-x-circle fa-lg remove_'
														"
														style="color: red"
														@click="removePricing(index)"
														></i>
												</div>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
							<div class="action_sec">
								<div class="plus_">
									<i
										class="fe fe-plus-circle add_ fa-lg"
										@click="addPricing"
										></i>
									<label style="color: #95aac9; margintop: 2px">
										Add Price Bound
									</label>
								</div>
								<div class="btn-group float-right">
									<button
										:disabled="loadingRoutePriceUpdate"
										class="btn btn-primary"
										@click="savePriceChanges"
										>
										{{
											loadingRoutePriceUpdate
												? 'Please wait...'
												: 'Save Changes'
										}}
									</button>
								</div>
							</div>
						</div>
					</div>

					<itinerary-driver-settings :itinerary="currentRouteItinerary">
					</itinerary-driver-settings>

          <configurePayment v-if="DoNotShowOnProd()" />

				</div>
			</div>
		</template>
		<template v-else-if="loadingRouteItinerary">
			<div class="d-flex py-5 justify-content-center align-items-center w-100">
				<div class="spinner-border" role="status"></div>
			</div>
		</template>
	</div>
</template>

<script setup>
import { userHasPermission, DoNotShowOnProd } from '@/composables/core/permissions'
</script>

<script>
import _ from 'lodash'
import VueInlineTextEditor from 'vue-inline-text-editor'
import VueTimepicker from 'vue2-timepicker'
import ItineraryDriverSettings from '@/views/Routes/components/itinerary/ItineraryDriverSettings'
import ItineraryPricingSettings from '@/views/Routes/components/itinerary/ItineraryPricingSettings'
import configurePayment from '@/views/Routes/components/itinerary/configurePayment'

export default {
  components: { ItineraryDriverSettings, ItineraryPricingSettings, VueTimepicker, VueInlineTextEditor, configurePayment },
  props: {
    routeId: {
      type: [String, Number],
      required: true
    },
    itineraryId: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    hasAnyChanges () {
      const newPricingToSave = this.newItinerary.pricingTable.filter(
        (v) => !v.id
      )
      const updatesPricingToSave = this.newItinerary.pricingTable.filter(
        (v) =>
          v.id &&
          v.$model &&
          (v.$model.end_route_bus_stop_id != v.destinationRouteBusStop ||
            v.fare != v.$model.fare)
      )

      const allPrices = this.currentRouteItinerary.prices
        ? this.currentRouteItinerary.prices.map((i) => i.id)
        : []
      const remainingPricing = this.newItinerary.pricingTable
        .filter((v) => v.id)
        .map((i) => i.id)

      const deletedPricing = allPrices.filter(
        (i) => remainingPricing.indexOf(i) < 0
      )

      return (
        !newPricingToSave.length ||
        (!updatesPricingToSave.length && !deletedPricing.length)
      )
    }
  },
  data () {
    return {
      tempTripTime: '',
      newVehicles: {
        vehicles: []
      },
      vehicles: [],
      currentRouteItinerary: null,
      loadingBusStops: false,
      loadingRouteItinerary: false,
      routeBusStops: [],
      edits: {
        $pickup_id: false,
        $trip_time: false
      },
      newItinerary: {
        time: null,
        pricingTable: []
      },
      loadingRoutePriceUpdate: false,
      isErrored: false
    }
  },
  mounted () {
    this.initialize()
  },
  watch: {
    $route: function () {
      this.initialize()
    }
  },
  methods: {
    initialize () {
      this.loadCurrentRouteItinerary(true)
    },
    editRouteItineraryPickup () {
      this.edits.$pickup_id = !this.edits.$pickup_id
    },
    editRouteItineraryTripTime () {
      this.edits.$trip_time = !this.edits.$trip_time
    },
    routeBusStopsAfter (val) {
      if (val == null) return this.routeBusStops
      const busStop = this.routeBusStops.find((i) => i.id === val)
      if (!busStop) return
      return this.routeBusStops.filter((v) => {
        return v.position > busStop.position
      })
    },
    onUpdatePickup () {
      this.edits.$pickup_id = false
      this.updateRouteItinerary()
    },
    onUpdateTripTime () {
      if (this.tempTripTime.trim() == '') return
      this.currentRouteItinerary.trip_time = this.tempTripTime
      const timeParts = this.tempTripTime.split(' ')
      this.currentRouteItinerary.departure_time = timeParts[0]
      this.currentRouteItinerary.time_of_day = timeParts[1]
      this.edits.$trip_time = false
      this.updateRouteItinerary()
    },
    onUpdate () {
      this.updateRouteItinerary()
    },
    async updateRouteItinerary () {
      if (!this.itineraryId) return
      this.$Progress.start()
      this.loadingRouteItinerary = true
      try {
        const result = await this.axios.patch(
          `/v1/route-itineraries/${this.itineraryId}`,
          this.currentRouteItinerary
        )
        this.currentRouteItinerary = {
          ...this.currentRouteItinerary,
          ...result.data
        }
        this.loadingRouteItinerary = false
        this.$Progress.finish()
      } catch (e) {
        this.loadingRouteItinerary = false
        this.$Progress.fail()
      }
    },
    loadCurrentRouteItinerary (initialLoad = false) {
      if (!this.itineraryId) return
      this.loadingRouteItinerary = true
      this.$Progress.start()
      this.axios
        .get(`/v1/route-itineraries/${this.itineraryId}`)
        .then((response) => {
          this.currentRouteItinerary = response.data
          this.tempTripTime = this.currentRouteItinerary.trip_time
          if (initialLoad) {
            this.fetchBusStops()
          }
          this.$Progress.finish()
        })
        .catch((error) => {
          if (error && error.response && error.response.data) {
            this.$toastr.e(error.response.data.message, 'Error')
          }
          this.$Progress.fail()
        })
        .finally(() => {
          this.loadingRouteItinerary = false
        })
    },
    async fetchBusStops () {
      if (!this.routeId) return
      this.$Progress.start()
      this.loadingBusStops = true
      try {
        const result = await this.axios.get(
          `/v1/routes/${this.routeId}/busstops`
        )

        let routeBusStops = _.sortBy(result.data.data, 'position')
        if (
          this.currentRouteItinerary &&
          this.currentRouteItinerary.starting_bus_stop
        ) {
          routeBusStops = routeBusStops.filter((v) => {
            return (
              v.position >=
              this.currentRouteItinerary.starting_bus_stop.position
            )
          })
        }
        this.routeBusStops = routeBusStops
        this.loadingBusStops = false
        this.initializePrices(
          this.currentRouteItinerary.prices,
          this.routeBusStops
        )
        this.$Progress.finish()
      } catch (e) {
        this.loadingBusStops = false
        this.$Progress.fail()
      }
    },
    redrawPriceTable (affectedIndex) {
      const val = this.newItinerary.pricingTable[affectedIndex]
      const destinationId = val.destinationRouteBusStop
      const busStopIndex = this.routeBusStops.findIndex(
        (i) => i.id === destinationId
      )

      if (busStopIndex <= 0) return

      if (
        busStopIndex == this.routeBusStops.length - 1 &&
        this.newItinerary.pricingTable.length > 1
      ) {
        this.newItinerary.pricingTable.splice(affectedIndex + 1)
        return
      }

      const newPosition = {
        originRouteBusStop: this.routeBusStops[busStopIndex].id,
        destinationRouteBusStop:
          this.routeBusStops[this.routeBusStops.length - 1].id,
        fare_currency: this.currentRouteItinerary.default_fare_currency
      }
      if (this.newItinerary.pricingTable.length == affectedIndex + 1) {
        this.newItinerary.pricingTable.push(newPosition)
      } else {
        this.newItinerary.pricingTable[affectedIndex + 1].originRouteBusStop =
          newPosition.originRouteBusStop

        if (
          this.newItinerary.pricingTable[affectedIndex + 1]
            .originRouteBusStop ===
          this.newItinerary.pricingTable[affectedIndex + 1]
            .destinationRouteBusStop
        ) {
          this.newItinerary.pricingTable.splice(affectedIndex + 1, 1)
        }
      }
    },
    addPricing () {
      this.newItinerary.pricingTable.push({
        originRouteBusStop: this.routeBusStops[0].id,
        destinationRouteBusStop:
          this.routeBusStops[this.routeBusStops.length - 1].id,
        fare_currency: this.currentRouteItinerary.default_fare_currency
      })
    },
    removePricing (index) {
      return this.newItinerary.pricingTable.splice(index, 1)
    },
    initializePrices (prices, busStops) {
      if (!busStops) return
      if (!busStops.length) return
      if (busStops.length < 2) return

      if (prices && prices.length) {
        this.newItinerary.pricingTable.splice(0)
        prices.forEach((e) => {
          this.newItinerary.pricingTable.push({
            id: e.id,
            originRouteBusStop: e.start_route_bus_stop_id,
            destinationRouteBusStop: e.end_route_bus_stop_id,
            fare: e.fare,
            fare_currency: e.fare_currency,
            $model: e
          })
        })
      } else {
        this.newItinerary.pricingTable.splice(0)
        this.newItinerary.pricingTable.push({
          id: null,
          originRouteBusStop: busStops[0].id,
          destinationRouteBusStop: busStops[busStops.length - 1].id,
          fare: 0,
          fare_currency: 'NGN'
        })
      }
    },
    async savePriceChanges () {
      this.validatePriceData(this.newItinerary.pricingTable)
      if (this.isErrored) {
        return
      }
      const newPricingToSave = this.newItinerary.pricingTable.filter(
        (v) => typeof v.id === 'undefined' || v.id == null
      )
      const updatesPricingToSave = this.newItinerary.pricingTable.filter(
        (v) => v.id && v.$model
      )

      const allPrices = this.currentRouteItinerary.prices
        ? this.currentRouteItinerary.prices.map((i) => i.id)
        : []
      const remainingPricing = updatesPricingToSave.map((i) => i.id)
      const deletedPricing = allPrices.filter(
        (i) => remainingPricing.indexOf(i) < 0
      )

      if (
        (!newPricingToSave || !newPricingToSave.length) &&
        (!updatesPricingToSave || !updatesPricingToSave.length) &&
        (!deletedPricing || !deletedPricing.length)
      ) {
        return
      }

      try {
        this.loadingRoutePriceUpdate = true
        await this.axios.patch(
          `/v1/route-itineraries/${this.itineraryId}/price`,
          {
            create: newPricingToSave.map((e) => {
              return {
                start_route_bus_stop_id: e.originRouteBusStop,
                end_route_bus_stop_id: e.destinationRouteBusStop,
                fare: e.fare,
                fare_currency: e.fare_currency
              }
            }),
            update: updatesPricingToSave.map((e) => {
              return {
                id: e.id,
                start_route_bus_stop_id: e.originRouteBusStop,
                end_route_bus_stop_id: e.destinationRouteBusStop,
                fare: e.fare,
                fare_currency: e.fare_currency
              }
            }),
            delete: deletedPricing
          }
        )
        this.$toastr.s('Route price updated successfully', 'Success')
      } catch (error) {
        this.$toastr.e(error.message, 'Error')
      } finally {
        this.loadingRoutePriceUpdate = false
      }
    },
    validatePriceData (data) {
      if (!data || !Array.isArray(data)) {
        this.$toastr.e('A validation error occured, please try again', 'Error')
      }

      for (const entry of data) {
        if (!entry.originRouteBusStop) {
          this.isErrored = true
          this.$toastr.e('Origin busstop is required', 'Error')
        }

        if (!entry.destinationRouteBusStop) {
          this.isErrored = true
          this.$toastr.e('Destination busstop is required', 'Error')
        }

        if (!entry.fare) {
          this.isErrored = true
          this.$toastr.e('Fare is required', 'Error')
        }
      }
    },
    extractErrorMessage (error) {
      return error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.toString()
    }
  }
}
</script>
<style lang="scss" scoped>
.driver-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.link {
  cursor: pointer;
  &:hover {
    color: #00d97e;
  }
}

::v-deep .multiselect__content-wrapper {
  .multiselect__option {
    white-space: normal;
  }
}
</style>

<style scoped>
.action_sec {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
}
.add_ {
  cursor: pointer;
  margin-right: 5px;
}
.plus_ {
  margin-left: 2%;
}
.removeBg {
  margin-top: 10px;
}
.remove_ {
  cursor: pointer;
}
.noDisplay {
  display: none;
}
.pointer {
  cursor: pointer;
}
</style>
