import {ref, computed, watch} from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import Swal from 'sweetalert2'
import { useFetchConfiguredCharges } from './fetch'

const configureChargeObj = {
	id: ref(null),
	chargeTypeId: ref(null),
	chargeEntityId: ref(null), // this is the country id
	chargeValue: ref(''),
	chargeType: ref('percentage'),
	desc: ref(''),
	cityIds: ref([]),
	allCity: ref(false),
	isCompulsory: ref(false)
}

const loading = ref(false)
const showConfigureChargeModal = ref(false)
const isEditConfigureCharge = ref(false)
const { fetchConfiguredCharges } = useFetchConfiguredCharges()

const closeConfigureChargeModal = () => {
	showConfigureChargeModal.value = false
	isEditConfigureCharge.value = false
	configureChargeObj.chargeTypeId.value = null
	configureChargeObj.chargeEntityId.value = null
	configureChargeObj.chargeValue.value = ''
	configureChargeObj.chargeType.value = 'percentage'
	configureChargeObj.desc.value = ''
	configureChargeObj.cityIds.value = []
	configureChargeObj.allCity.value = false
	configureChargeObj.isCompulsory.value = false
}

const enableButton = computed(() => {
	return configureChargeObj.chargeTypeId.value && configureChargeObj.chargeEntityId.value && configureChargeObj.chargeValue.value &&
	configureChargeObj.chargeType.value && configureChargeObj.desc.value && 
	(configureChargeObj.allCity.value || configureChargeObj.cityIds.value.length) ? true : false
})

const getIdsFromCityObj = computed(() => {
	if(!configureChargeObj.cityIds.value.length) return []
	return configureChargeObj.cityIds.value.map((item) => item.id)
})

watch(configureChargeObj.cityIds, () => {
	if(configureChargeObj.cityIds.value.length) configureChargeObj.allCity.value = false
})

export const useCreateConfigureCharge = () => {
	const configureCharge = async () => {
		loading.value = true
		const payload = {
			additional_charge_type_id: configureChargeObj.chargeTypeId.value,
			addition_charge_entity: 'country',
			additional_charge_entity_id: configureChargeObj.chargeEntityId.value,
			charge_value: configureChargeObj.chargeValue.value,
			charge_type: configureChargeObj.chargeType.value,
			description: configureChargeObj.desc.value,
			city_ids: getIdsFromCityObj.value,
			all_cities: configureChargeObj.allCity.value,
			is_compulsory: configureChargeObj.isCompulsory.value
		}
		try {
			const res = await axios.post('/v1/additional-charges', payload)
			if(res.data?.id) {
				Swal.fire({ icon: 'success', title: 'Success', text: 'Configured successfully'})
				closeConfigureChargeModal()
				fetchConfiguredCharges()
			} else {
				Swal.fire({ icon: 'error', title: 'Error', text: res.data?.message ?? 'Could not create configuration'})
			}
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		} finally{
			loading.value = false
		}
	}

	const initEditConfigure = (data) => {
		isEditConfigureCharge.value = true
		configureChargeObj.id.value = data.id
		configureChargeObj.chargeTypeId.value = data.additional_charge_type_id
		configureChargeObj.chargeEntityId.value = data.additional_charge_entity_id
		configureChargeObj.chargeValue.value = data.charge_value
		configureChargeObj.chargeType.value = data.charge_type
		configureChargeObj.desc.value = data.description
		configureChargeObj.cityIds.value = data?.cities_currently_active_in.map((el) => ({ id: el.city_id, name: el.city_name})) || []
		configureChargeObj.isCompulsory.value = data.is_compulsory === 1 ? true : false
		showConfigureChargeModal.value = true
	}

	const updateConfigureCharge = async () => {
		loading.value = true
		const payload = {
			additional_charge_type_id: configureChargeObj.chargeTypeId.value,
			addition_charge_entity: 'country',
			additional_charge_entity_id: configureChargeObj.chargeEntityId.value,
			charge_value: configureChargeObj.chargeValue.value,
			charge_type: configureChargeObj.chargeType.value,
			description: configureChargeObj.desc.value,
			city_ids: getIdsFromCityObj.value,
			all_cities: configureChargeObj.allCity.value,
			is_compulsory: configureChargeObj.isCompulsory.value
		}
		try {
			const res = await axios.patch(`/v1/additional-charges/${configureChargeObj.id.value}`, payload)
			if(res.data?.id || res.data?.charge_type) {
				Swal.fire({ icon: 'success', title: 'Success', text: 'Configuration updated successfully'})
				closeConfigureChargeModal()
				fetchConfiguredCharges()
			} else {
				Swal.fire({ icon: 'error', title: 'Error', text: res.data?.message ?? 'Could not update configuration'})
			}
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		} finally{
			loading.value = false
		}
	}

	return { ...configureChargeObj, loading, showConfigureChargeModal, 
		isEditConfigureCharge, closeConfigureChargeModal, configureCharge, 
		initEditConfigure, enableButton, updateConfigureCharge }
}