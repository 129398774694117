import { axiosInstance } from '@/plugins/axios'

export class RewardPointService {
  /**
   * @param {AxiosInstance} axios
   * */

  constructor (axios) {
    this.axios = axios
  } 

  configureRewardPointRate (payload) {
    const url = '/v1/reward-point-rate'
    return this.axios.post(url, payload)
  }

  getRewardPointRate () {
    const url = '/v1/reward-point-rate'
    return this.axios.get(url)
  }

  createComplianceReward (payload) {
    const url = '/v1/compliance-events'
    return this.axios.post(url, payload)
  }

  updateSingleComplianceRewardEvent (id, payload) {
    const url = `/v1/compliance-events/${id}`
    return this.axios.patch(url, payload)
  }

  getAllComplianceRewardEventList () {
    const url = '/v1/compliance-events'
    return this.axios.get(url)
  }

  getSingleDriverComplianceRewardHistory (userId, userType) {
    const url = `/v1/compliance-reward-histories/${userType}/${userId}`
    return this.axios.get(url)
  }

  getAllComplianceRewards () {
    const url = '/v1/compliance-rewards'
    return this.axios.get(url)
  }

  updateSingleComplianceReward (payload) {
    const url = '/v1/compliance-rewards'
    return this.axios.patch(url, payload)
  }

  getAllDriversComplianceAccumulatedPoints () {
    const url = '/v1/compliance-accumulated-points'
    return this.axios.get(url)
  }

  getHighestLowestDriversComplianceReward () {
    const url = '/v1/compliance-accumulated-points/range'
    return this.axios.get(url)
  }
}

const rewardPointServiceInstance = new RewardPointService(axiosInstance)
export default rewardPointServiceInstance