export default {
  computed: {
    tablePageText () {
      const cpSize = ((this.pageSize || this.perPage) * this.currentPage)
      if (this.currentPage === 1 && this.totalRecords <= (this.pageSize || this.perPage)) {
        return `Showing ${this.totalRecords} record${this.totalRecords > 1 ? 's' : ''}`
      } else {
        return this.totalRecords ? `Showing ${(cpSize - (this.pageSize || this.perPage)) + 1} - ${Math.min(cpSize, this.totalRecords)} of ${this.totalRecords}` : ''
      }
    }
  }
}
