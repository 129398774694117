// TODO: Extract to new route service class for reusability
import Trips from '@/layouts/modules/Trips/Index.vue'
import TripsList from '@/layouts/modules/Trips/TripsList.vue'
import TripsDetails from '@/layouts/modules/Trips/TripsDetails.vue'
function loadRouteComponent(path)
{
  return () => import(/* webpackChunkName: "app-view-[request]" */`@/views/${path}.vue`)
}
const tripRoutes = [

  {
    path: 'trips',
    component: Trips,
    children: [
      {
        path: '',
        name: 'Trips',
        redirect: 'list'
      },
      {
        path: 'list',
        component: TripsList,
        children: [
          {
            path: '',
            name: 'TripsList',
            redirect: 'active-trips'
          },
          {
            path: 'active-trips',
            component: loadRouteComponent('Trips/pages/List/ActiveTrips'),
            name: 'ActiveTrips',
            props: true,
            meta: {
              requiredPermissions: ['trips001']
            }
          },
          {
            path: 'upcoming-trips',
            component: loadRouteComponent('Trips/pages/List/UpcomingTrips'),
            name: 'UpcomingTrips',
            props: true,
            meta: {
              requiredPermissions: ['trips002']
            }
          },
          {
            path: 'completed-trips',
            component: loadRouteComponent('Trips/pages/List/CompletedTrips'),
            name: 'CompletedTrips',
            props: true,
            meta: {
              requiredPermissions: ['trips003']
            }
          },
          {
            path: 'bus-captains',
            component: loadRouteComponent('Trips/pages/List/BusCaptains'),
            name: 'BusCaptains',
            props: true
          }
        ]
      },
      {
        path: 'detail/:tripId/:tripType',
        component: TripsDetails,
        props: true,
        children: [
          {
            path: 'trip-information',
            component: loadRouteComponent('Trips/pages/Detail/TripDetails'),
            name: 'TripDetails',
            props: true,
            meta: {
              requiredPermissions: ['trips012']
            }
          },
          {
            path: 'passengers',
            component: loadRouteComponent('Trips/pages/Detail/TripPassengers'),
            name: 'TripPassengers',
            props: true,
            meta: {
              requiredPermissions: ['trips012']
            }
          },
          {
            path: 'ratings',
            component: loadRouteComponent('Trips/pages/Detail/TripRatings'),
            name: 'TripRatings',
            props: true,
            meta: {
              requiredPermissions: ['trips007']
            }
          },
          {
            path: 'financials',
            component: loadRouteComponent('Trips/pages/Detail/TripFinancials'),
            name: 'TripFinancials',
            props: true,
            meta: {
              requiredPermissions: ['trips007']
            }
          }
        ]
      }
    ]
  },
  {
    path: 'trips/track-vehicle',
    component: loadRouteComponent('Trips/pages/NewTrackVehicle'),
    name: 'NewTrackVehicle',
    props: true
  }
]

export default tripRoutes
