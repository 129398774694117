<template>
	<div>
		<p class="m-0 font-sm">Select days</p>
		<div class="dow-wrapper">
			<div v-for="day in daysOfWeek"
				:key="day.id" class="dow" :class="day.selected ? 'dow__selected' : ''" @click="updateSelection(day)">
				<span>{{day.short}}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'DaySelector',
  emits: ['on-change', 'selected', 'deselected'],
  props: {
    activeDays: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      daysOfWeek: [
        { id: 1, value: 'sunday', short: 'Sun', selected: false },
        { id: 2, value: 'monday', short: 'Mon', selected: false },
        { id: 3, value: 'tuesday', short: 'Tue', selected: false },
        { id: 4, value: 'wednesday', short: 'Wed', selected: false },
        { id: 5, value: 'thursday', short: 'Thu', selected: false },
        { id: 6, value: 'friday', short: 'Fri', selected: false },
        { id: 7, value: 'saturday', short: 'Sat', selected: false }
      ]
    }
  },
  mounted () {
    if (this.activeDays && this.activeDays.length) {
      this.updateSelectionState(this.activeDays)
    }
  },
  computed: {
    selectedDays () {
      return this.daysOfWeek.reduce((a, b) => {
        if (b.selected) {
          a.push(b.value)
        }
        return a
      }, [])
    }
  },
  methods: {
    updateSelection (day) {
      day.selected = !day.selected
      this.$emit(day.selected ? 'selected' : 'deselected', day)
      this.$emit('on-change', this.selectedDays)
    },
    updateSelectionState (selectedDayKeys) {
      this.daysOfWeek.forEach((day) => {
        if (selectedDayKeys.includes(day.value)) {
          day.selected = true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069;
$bg-color: #ffffff;
$border-active: #20E682;
$bg-active: #EDFDF5;
$text-color: #060606;

.dow-wrapper {
  display: flex;
  flex-wrap: wrap;
  .dow {
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0.75rem 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
    font-size: 0.75rem;
    border: 1px solid #E5E9F2;
    background: $bg-color;
    color: $text-color;
    cursor: pointer;
    margin: 0.25rem 0.25rem 0.25rem 0;
    min-width: 3rem;
    justify-content: center;
    display: flex;

    &:last-of-type{
      margin-right: 0;
    }
    &__selected {
      background: $bg-active;
      border: 1px solid $border-active;
    }
  }
}

.font-sm {
  font-size: 0.75rem;
}
</style>
