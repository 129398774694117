import { loadRouteComponent } from '@/utils/helpers'
import AdminConfiguration from '@/layouts/modules/AdminConfiguration/Index.vue'
import CustomerApp from '@/layouts/modules/AdminConfiguration/CustomerApp.vue'
import PriceControl from '@/layouts/modules/AdminConfiguration/PriceControl.vue'
import VehicleAmenities from '@/layouts/modules/AdminConfiguration/VehicleAmenities.vue'
import ChargesIndex from '@/layouts/modules/AdminConfiguration/Charges.vue'
const AdminConfigRoutes = [
  {
    path: 'configuration',
    component: AdminConfiguration,
    children: [
      {
        path: 'vehicle',
        name: 'VehicleCategoryManagement',
        component: loadRouteComponent(
          'AdminConfig/CategoryManagement/VehicleCategoryList'
        ),
        props: true,
      
          children: [
          {
            path: 'category-management',
            name: 'CategoryManagement',
            component: loadRouteComponent(
            'AdminConfig/CategoryManagement/CategoryList'
              ),
              meta: {
          requiredPermissions: ['adminConfig002'],
        },
            props: true,
          },
          {
            path: 'inspection-sites',
            name: 'InspectionSites',
            component: loadRouteComponent(
            'AdminConfig/CategoryManagement/InspectionSiteList'
            ),
            props: true, 
            // meta: {
            //   requiredPermissions: ['inspection003'],
            // },
          },
        ],
      },
      {
        path: 'customer-app',
        name: 'CustomerApp',
        component: CustomerApp,
        props: true,
        children: [
          {
            path: 'trip-rating-settings',
            name: 'TripRatingSettings',
            component: loadRouteComponent(
              'AdminConfig/CustomerApp/TripRatingSettings'
            ),
            props: true,
          },
        ],
      },
      {
        path: 'vehicle-amenities',
        name: 'VehicleAmenities',
        component: VehicleAmenities,
        props: true,
        children: [
          {
            path: 'vehicle-amenities-settings',
            name: 'VehicleAmenitiesSettings',
            component: loadRouteComponent(
              'AdminConfig/VehicleAmenities/VehicleAmenitiesSettings'
            ),
            props: true,
          },
        ],
      },
      {
        path: 'trip-rating-settings/:categoryId',
        name: 'ShowCategory',
        component: loadRouteComponent('AdminConfig/CustomerApp/ShowCategory'),
        props: true,
      },
      {
        path: 'story',
        name: 'Story',
        component: loadRouteComponent('AdminConfig/Story'),
        props: true,
      },
      {
        path: 'pricing-controls',
        component: PriceControl,
        children: [
          {
            path: '',
            name: 'PricingControls',
            component: loadRouteComponent('PricingControl/pages/List'),
            props: true,
            meta: {
              requiredPermissions: ['adminConfig001'],
            },
          },
          {
            path: '/payment',
            name: 'PaymentOptions',
            component: loadRouteComponent(
              'PricingControl/pages/PaymentOptions'
            ),
            props: true,
            meta: {
              requiredPermissions: ['adminConfig001'],
            },
          },
        ],
      },
      {
        path: 'charges',
        component: ChargesIndex,
        name: 'ChargesParentPage',
        children: [
          {
            path: 'configure',
            name: 'ConfigureCharges',
            component: loadRouteComponent('PricingControl/pages/charges/configureCharges'),
            props: true,
            meta: { requiredPermissions: [] },
          },
          {
            path: 'configure/:configureId',
            name: 'ConfiguredChargeDetail',
            component: loadRouteComponent('PricingControl/pages/charges/configureChargesDetail'),
            props: true,
            meta: { requiredPermissions: [] },
          },
          {
            path: 'types',
            name: 'ChargesTypes',
            component: loadRouteComponent('PricingControl/pages/charges/types'),
            props: true,
            meta: { requiredPermissions: [] },
          },
          {
            path: 'report',
            name: 'ChargesReport',
            component: loadRouteComponent('PricingControl/pages/charges/report'),
            props: true,
            meta: { requiredPermissions: [] },
          },
          
        ],
      },
    ],
  },
]

export default AdminConfigRoutes
