<template>
	<div>
		<b-modal :id="modalId" hide-footer @hidden='onClose' 	:size="size">
			<template #modal-title>
				<h3 class="text-2xl mb-0 font-medium">{{ title }}</h3>
			</template>
			<div class="block">
				<slot/>
			</div>

		</b-modal>
	</div>
</template>

<script>

export default {
  name: 'BS-modal',
  props: {
    show: {
      type: Boolean
    },
    modalId: {
      type: String,
      default: 'bv-modal-example'
    },
    size: {
      type: String,
      default: 'md'
    },
    title: {
      type: String,
      default: 'Modal title'
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.$bvModal.show(this.modalId)
      } else {
        this.$bvModal.hide(this.modalId)
      }
    }
  },

  methods: {
    onClose (value) {
      this.$emit('close')
    }
  }
}

</script>

<style scoped>
.modal-backdrop {
     opacity: 1 !important;
}
</style>
