<template>
	<div class="main-content">
		<page-header
			title="Pricing control"
			pre-title="Overview"
			:loading="false">
			<template slot="actions">
			</template>
			<template slot="tabs">
				<div class="container">
					<div class="row pt-3 pb-1">
						<div class="col justify-self-center align-self-center flex-column">
							<ul class="nav nav-pills">
								<li class="nav-item">
									<router-link
										:to="{name: 'PricingControls'}"
										class="nav-link"
										active-class="active"
										>Pricing control</router-link
										>
								</li>
								<li class="nav-item">
									<router-link
										:to="{name: 'PaymentOptions'}"
										class="nav-link"
										active-class="active"
										>Payment option display</router-link
										>
								</li>
								<li class="nav-item" v-if="DoNotShowOnProd()">
									<router-link :to="{name: 'ConfigureCharges'}" class="nav-link" active-class="active" >
										Additional Charges
									</router-link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</template>
		</page-header>

		<page-route-view
			:loading="false">
		</page-route-view>
	</div>
</template>

<script setup>
import { DoNotShowOnProd } from '@/composables/core/permissions'
</script>

<script>
import PageHeader from '@/components/layout/PageHeader'
import PageRouteView from '@/components/layout/PageRouteView'
export default {
  components: { PageRouteView, PageHeader },
  data () {
    return {
    }
  }
}
</script>

  <style lang="scss" scoped>
  @import "src/assets/scss/nav-tabs";
  </style>
