import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth'
import notifications from './notification-count'
import trips from './trips'
import session from './session'
import routeStore from './route.store'
import AdminSession from '@/services/session'
import FeatureFlagService from '@/utils/feature-flags'
import events from './events'
import rewardPoints from './reward-points'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['auth', 'session', 'trips']
    })
  ],
  getters: {
    currentUserPermissionsList (state) {
      const perms = state.session.currentUser?.permissions || []
      return perms.map((i) => i.code)
    },
    currentUserPermissionsModuleList (state) {
      if (state.session?.currentUser?.permissions) {
        const permissionModules = [
          ...new Set(state.session?.currentUser?.permissions)
        ]
        return permissionModules.map((permission) => permission.module_code)
      }
    },
    isAdminOrSuperAdminLoggedIn (state) {
      if (state.session.currentUser == null) {
        return false
      }

      if (state.session.currentUser.role == null) {
        return false
      }

      return (
        state.session.currentUser.role == 'super_admin' ||
        state.session.currentUser.role == 'admin'
      )
    },
    isLoggedIn: (state) => state.session.currentUser != null,
    currentUser: (state) => state.session.currentUser,
    selectedTripData: (state) => state.session.selectedTripData
  },
  actions: {
    async setCurrentUser ({ commit }, parameter) {
      FeatureFlagService.setCurrentUserData({
        email: parameter?.email,
        userId: parameter?.id
      })
      commit('session/setCurrentUser', parameter, { root: true })
    },
    async logout ({ commit }) {
      await AdminSession.clearSession()
      commit('session/setCurrentUser', null, { root: true })
    },
    async setSelectedTripData ({ commit }, parameter) {
      commit('session/setSelectedTripData', parameter, { root: true })
    }
  },
  modules: {
    auth,
    notifications,
    trips,
    session,
    routeStore,
    events,
    rewardPoints
  }
})

export default store
export const useStore = () => store
