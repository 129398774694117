export const PRICING_TYPES = {
  FIXED: {
    id: 1,
    key: 'fixed'
  },
  BUS_STOP_BASED: {
    id: 2,
    key: 'busstop'
  },
  DYNAMIC: {
    id: 3,
    key: 'dynamic'
  }
}
