
<template>
	<div>
		<b-modal
			:id="modalId"
			@close="closeModal"
			:hide-header-close="processing"
			no-close-on-backdrop
			no-close-on-esc>
			<template #modal-title>
				{{ modalTitle }}
			</template>

			<div class="py-2">
				<div v-if="verificationStep">
					<div class="alert sh-alert-info d-flex align-items-center" role="alert">
						<img class="mr-2" src="@/assets/img/caution.svg">
						<p class="mb-0">{{verificationMessage}}</p>
					</div>
					<p class="mb-3">{{promptText}}</p>
					<div class="form mb-3">
						<div class="form-group">
							<input
								v-model="form.password"
								class="form-control mb-3"
								:placeholder="`Type ${promptKey}`"
								:type="isPasswordType ? 'password': 'text'" autocomplete="new-password">
						</div>
					</div>
				</div>
				<template v-else>
					<slot v-bind="{verificationStep, passKey: form.password}"></slot>
				</template>
			</div>
			<template #modal-footer>
				<div class="w-100">
					<b-button
						:disabled="processing || !buttonEnabled"
						variant="primary"
						class="float-right"
						@click="proceed()">
						{{ processing ? 'Processing' : processButtonText }}
					</b-button>
					<b-button
						:disabled="processing"
						variant="secondary"
						class="float-right mr-2"
						@click="closeModal()">
						Close
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
export default {
  name: 'UpdateConfirmationModal',
  props: {
    show: Boolean,
    indexKey: [String, Number], // for case where multiple instances may be on the same page to avoid id conflicts in DOM
    modalTitle: String,
    verificationMessage: String,
    updateFunction: Function,
    processing: Boolean,
    isPasswordType: {
      type: Boolean,
      default: false
    },
    promptText: { type: String, default: 'Please type YES to proceed', required: false },
    canProceed: { type: Boolean, default: true, required: false },
    promptKey: {
      type: String,
      required: false,
      default: 'YES'
    },
    processButtonText: { type: String, default: 'Proceed', required: false }
  },
  data () {
    return {
      form: {
        password: null
      },
      modalKey: 'update-confirmation-modal',
      verificationStep: false
    }
  },
  watch: {
    show: {
      handler: function (value) {
        if (value) {
          this.openModal()
        } else {
          this.closeModal()
        }
      }
    }
  },
  computed: {
    isValidForm () {
      if (this.isPasswordType) {
        return !!this.form.password
      }
      return !!this.form.password && this.form.password === this.promptKey
    },
    buttonEnabled () {
      return this.verificationStep ? this.isValidForm : this.canProceed
    },
    modalId () {
      return this.indexKey ? `prompt-modal-${this.indexKey}-${this.modalKey}-${this.indexKey}` : this.modalKey
    }
  },
  methods: {
    resetForm () {
      this.form.password = null
      this.verificationStep = false
    },
    openModal () {
      this.$bvModal.show(this.modalId)
    },
    closeModal (args = null) {
      this.$bvModal.hide(this.modalId)
      this.$emit('close', args)
      this.resetForm()
    },
    processUpdate () {
      if (!this.isValidForm) {
        return
      }
      this.updateFunction.call(this,
        {
          passKey: this.form.password,
          gotoVerification: () => this.verificationStep = true,
          isVerificationStep: () => this.verificationStep
        })
    },

    proceed () {
      if (this.verificationStep) {
        this.processUpdate()
      } else if (this.canProceed) {
        this.verificationStep = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//@use "src/assets/scss/partials/sh-colors" as colors;
.sh-alert-info {
  $purple-200: #EBEBFF;
  $purple-800: #2323F0;
  background: $purple-200;
  color: $purple-800;
  border: none;
}

</style>
