import { loadRouteComponent } from '@/utils/helpers'
import Admins from '@/layouts/modules/Admins/Index.vue'
import AdminDetails from '@/layouts/modules/Admins/AdminDetails.vue'
import UserDetails from '@/layouts/modules/Admins/UserDetails.vue'
import AdminList from '@/layouts/modules/Admins/AdminList.vue'
import {getAdminType} from '@/composables/core/permissions/admin'
import Swal from 'sweetalert2'


const AdminRoutes = [
  {
    path: 'admin',
    component: Admins,
    children: [
      {
        path: '',
        component: AdminList,
        children: [
          // {
          //   path: '',
          //   name: 'StaffList',
          //   redirect: 'list'
          // },
          {
            path: 'list',
            component: loadRouteComponent('Admins/pages/List/StaffList'),
            name: 'StaffList',
            props: true,
            meta: {
              requiredPermissions: ['staff007']
            }
          },
          {
            path: 'audit-trail',
            component: loadRouteComponent('Admins/pages/List/AuditTrailList'),
            name: 'AuditTrailList',
            props: true,
            beforeEnter: (to, from, next) => {
              if (getAdminType() == 'super_admin') {
                next()
              } else {
                next('/admin/list')
                Swal.fire({
                  icon: 'warning',
                  title: 'No permission',
                  text: 'You do not have permission to access the page',
                  showCloseButton: true
                })
              }
            }
          },
        ]
      },

      {
        path: 'staff/add',
        component: loadRouteComponent('Admins/pages/Create'),
        name: 'AddStaff',
        props: true,
        meta: {
          requiredPermissions: ['staff001']
        }
      },
      {
        path: 'me',
        component: UserDetails,
        props: true,
           children: [
          {
            path: 'info',
            component: loadRouteComponent('Admins/pages/Profile/StaffInformation'),
            name: 'ShowCurrentStaff',
            props: true,
            meta: {
              requiredPermissions: ['staff001']
            }
             },
           ]
      },
      {
        path: ':staffId',
        component: AdminDetails,
        props: true,
        children: [
          {
            path: 'info',
            component: loadRouteComponent('Admins/pages/Profile/StaffInformation'),
            name: 'ShowStaff',
            props: true,
            meta: {
              requiredPermissions: ['staff001']
            }
          },
          {
            path: 'permissions',
            component: loadRouteComponent('Admins/pages/Profile/StaffPermissions'),
            name: 'StaffPermissions',
            props: true,
            meta: {
              requiredPermissions: ['staff004']
            }
          }
        ]
      }
    ]
  }
]

export default AdminRoutes
