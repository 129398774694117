<template>
	<div>
		<page-header :loading="loading" :title="staff ? `${staff?.fname} ${staff?.lname}` : ''" pre-title="Overview">
			<template slot="tabs" v-if="!errorLoading">
				<page-tabs :menu-tabs="is_super_admin ? superAdminTabs : regularTabs">
					<template v-slot="props">
						<router-link :to="{ name: props.menu.routeName }" class="nav-link" active-class="active">
							<span>{{ props.menu.label }}</span>
						</router-link>
					</template>
				</page-tabs>
			</template>
		</page-header>

    <breadcrum-component v-if="!loading" :items="items"></breadcrum-component>

		<page-route-view :loading="loading" :error-loading="errorLoading" error-text="Failed to load partner information" backLink="/admin/list"></page-route-view>
	</div>
</template>

<script>
import PageHeader from '@/components/layout/PageHeader'
import PageTabs from '@/components/layout/PageTabs'
import PageRouteView from '@/components/layout/PageRouteView'
import BreadcrumComponent from '@/views/Admins/Components/BreadcrumComponent'

// TODO: Review implementation with use of vuex
export default {
  name: 'PartnerProfileIndex',
  components: {
    PageRouteView,
    PageTabs,
    PageHeader,
    BreadcrumComponent
  },
  props: {
    staffId: {
      required: true,
      type: [Number, String]
    }
  },
  data()
  {
    return {
      loading: false,
      errorLoading: false,
      staff: null,
      processing: false,
      items: [
        {
          text: 'Admin',
          href: '#/admin/list'
        },
        // {
        //   text: 'Access control',
        //   href: '/list'
        // }
      ],
      regularTabs: [
        {
          routeName: 'ShowStaff',
          label: 'Staff information'
        }
      ],
      superAdminTabs: [
        {
          routeName: 'ShowStaff',
          label: 'Staff information'
        },
        {
          routeName: 'StaffPermissions',
          label: 'Permissions'
        }
      ]
    }
  },
  watch: {
    $route(to)
    {
      this.currentRouteState = to
    }
  },
  created()
  {
    this.fetchStaffs()
  },
  methods: {
    fetchStaffs()
    {
      return this.axios.get('/v1/staff/?limit=10000').then((res) =>
      {
        this.staff = res.data.data.find((staff) => staff.id == this.staffId)
        this.setupBreadCrum(this.staff)
        if (!this.staff) {
          return this.$router.push('/admin/list')
        }

        this.updateStaffModel = { ...this.staff }
      })
    },
    setupBreadCrum(staff)
    {
      const result =
        this.items &&
        this.items.push({
          text: `${staff?.fname} ${staff?.lname}`,
          href: '#',
          active: true
        })
      return result
    }
  },
  computed: {
    currentUser()
    {
      return this.$store.getters.currentUser
    },
    is_super_admin()
    {
      return this.currentUser.role === 'super_admin'
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/nav-tabs';

.alertDisplay {
  background-color: #ebebff;
}

.parentClass {
  display: relative;
}

.childClass {
  display: absolute;
  top: 0px;
  left: 0px;
  border: none;
  outline: none;
}
</style>
