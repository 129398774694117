export default {
  namespaced: true,
  state: {
    busStops: []
  },
  getters: {
    busStops: (state) => state.busStops
  },
  mutations: {
    updateBusStopsList (state, data) {
      state.busStops = data
    }
  },
  actions: {
    setBusStops ({ commit }, data) {
      commit('updateBusStopsList', data)
    }
  }
}
