<template>
	<div>

		<div class="d-flex">
			<div class="px-0 col-3">
				<RouteBusStops
					@deleted="deleteRouteBusStop"
					@selected="busTopSelected"
					ref="routeBusStopsList"
					:bus-stops="activeBusStops"
					:activeRoute="activeRoute"
					:activeRouteId="routeId"
					/>
			</div>
			 <div class="px-0 col-9">
				<div
					class="info-windows"
					style="height: calc(100vh - 132px); width: 100%; position: relative"
					:class="{ 'adding-bus-stop': addingBusStopMode }"
					>
					<div class="map-controls" style="position: absolute;z-index: 100;top: 5px;left: 5px;">
						<button
							@click.passive="enterNewBusStopMode"
							class="mr-2 btn btn-sm"
							:class="addingBusStopMode ? 'bg-shuttlers' : 'bg-white'"
							>
							{{ addingBusStopMode ? 'Adding' : 'Add'}} Bus Stop
						</button>

					</div>
					<google-map
						id="map"
						ref="Map"
						@click="onMapClicked"
						:zoom="zoomLevel"
						@rightclick="showContextMenu"
						>
						<google-map-marker
							v-if="newBusStop"
							:position="newBusStop.position"
							:key="`new-bus-stop-${newBusStop.position.lat}-${newBusStop.position.lng}`"
							:clickable="true"
							:draggable="true"
							@dragstart="dragStartedForNewBusStop(newBusStop.position, $event)"
							@dragend="dragEndedForNewBusStop(newBusStop.position, $event)"
							@click="toggleInfoWindow(newBusStop)"
							/>

						<google-map-infowindow
							v-if="newBusStop"
							:position="newBusStop.position"
							:show.sync="newBusStop.showInfo"
							:key="`new-bus-stop-info-${newBusStop.position.lat}-${newBusStop.position.lng}`"
							:options="{ maxWidth: 300, pixelOffset: 10 }"
							:draggable="false"
							@info-window-clicked="infoClicked"
							>
							<div>
								<h4>New Bus Stop</h4>
								<NewRouteBusStop
									:lat="newBusStop.position.lat"
									:lng="newBusStop.position.lng"
									:name="newBusStop.name"
									:routeId="activeRoute.id"
									@busStopCreated="newBusStopCreated"
									@cancelEdit="cancelNewBusStopEdit"
									/>
							</div>
              
						</google-map-infowindow>

						<google-map-marker
							v-if="activeRoute"
							icon="https://firebasestorage.googleapis.com/v0/b/android-driver-production.appspot.com/o/public%2Fbus-stop-sm.png?alt=media"
							:position="pickUpCordinatesForRoute(activeRoute)"
							:key="`pickup-${activeRoute.id}`"
							:clickable="true"
							@click="toggleInfoWindow(activeRoute,'showInfoForPickup')"
							/>

						<google-map-infowindow
							v-if="activeRoute"
							:position="pickUpCordinatesForRoute(activeRoute)"
							:show.sync="activeRoute.showInfoForPickup"
							:key="`info-windowpickup-${activeRoute.id}`"
							:options="{ maxWidth: 300, pixelOffset: 10 }"
							@info-window-clicked="infoClicked"
							>
							<div>
								<h4>Starting Point</h4>
								<p>{{ activeRoute.pickup }}</p>
							</div>
						</google-map-infowindow>

						<google-map-marker
							v-if="activeRoute"
							icon="https://firebasestorage.googleapis.com/v0/b/android-driver-production.appspot.com/o/public%2Fbus-stop-end-sm.png?alt=media"
							:position="destinationCordinatesForRoute(activeRoute)"
							:key="`destination-${activeRoute.id}`"
							:clickable="true"
							@click="toggleInfoWindow(activeRoute,'showInfoForDestination')"
							/>
						<google-map-infowindow
							v-if="activeRoute"
							:position="destinationCordinatesForRoute(activeRoute)"
							:show.sync="activeRoute.showInfoForDestination"
							:key="`info-windowdestination-${activeRoute.id}`"
							:options="{ maxWidth: 300, pixelOffset: 10 }"
							@info-window-clicked="infoClicked"
							>
							<div>
								<h4>Ending Point</h4>
								<p>{{ activeRoute.destination }}</p>
							</div>
						</google-map-infowindow>
						<google-map-marker
							v-for="b in activeBusStops"
							:position="{
								lat: b.geometry.y,
								lng: b.geometry.x,
							}"
							:key="b.id"
							:clickable="true"
							@click="toggleInfoWindow(b)"
							/>
						<google-map-infowindow
							v-for="infoWindow in activeBusStops"
							:position="{
								lat: infoWindow.geometry.y,
								lng: infoWindow.geometry.x,
							}"
							:show.sync="infoWindow.showInfo"
							:key="`bus-stop-info-window-${infoWindow.id}`"
							:ref="`infoWindow_${infoWindow.id}`"
							:options="{ maxWidth: 300, pixelOffset: 10 }"
							@info-window-clicked="infoClicked"
							>
							<div>
								<edit-route-bus-stop
									@deleted="deleteRouteBusStop"
									:routeBusStop="infoWindow"
									:no-of-stops="activeBusStops.length"
									/>
							</div>
						</google-map-infowindow>
					</google-map>
				</div>
			</div> 
		</div>

		<vue-simple-context-menu
			:elementId="'RouteCreatorContext'"
			:options="contextMenu"
			:ref="'routeCreatorContextMenu'"
			@option-clicked="onContextMenuSelected"
			/>
	</div>
</template>

<script>
import EditRouteBusStop from './EditRouteBusStop.vue'
import NewRouteBusStop from './NewRouteBusStop.vue'
import RouteBusStops from './RouteBusStops.vue'
import { mapGetters } from 'vuex'

export default {
  components: { RouteBusStops, NewRouteBusStop, EditRouteBusStop },
  props: {
    routeId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      currentlyShowingFeature: null,
      centerPosition: null,
      activeRoute: null,
      zoomLevel: 15,
      showInfo: true,
      infoWindowContext: {
        position: {
          lat: 44.2899,
          lng: 11.8774
        }
      },
      activeBusStops: [],
      infoWindowsList: [],
      addingBusStopMode: false,
      newBusStop: null,
      contextMenu: [
        {
          name: 'Add Bus Stop',
          id: 'addBusStop'
        }
      ],
      mapInstance: null,
      onMapDragStartedListenerID: null,
      onMapDragEndedListenerID: null,
      isMultiBusStopSelectActive: false
    }
  },
  mounted () {
    this.initialize()
    this.loadActiveRoute()
  },
  watch: {
    $route: {
      deep: true,
      handler () {
        if (this.activeRoute && this.$route.routeId != this.activeRoute.id) {
          this.loadActiveRoute()
        }
      }
    },
    busStops: {
      handler (value) {
        this.busStopsLoaded(value)
      }
    }
  },
  computed: {
    ...mapGetters('routeStore', ['busStops'])
  },
  methods: {
    initialize () {
      this.$refs.Map.$_getMap().then((v) => {
        this.mapInstance = v
        this.configureGoogleMap()
      })
    },
    configureGoogleMap () {
      if (!this.mapInstance) return

      this.mapInstance.setOptions({
        fullscreenControl: false,
        mapTypeControl: false
      })
    },
    toogleMultiBusStopSelectActive () {
      if (this.isMultiBusStopSelectActive) {
        this.isMultiBusStopSelectActive = false
        this.resetMultiselectBusStops()
      } else {
        this.isMultiBusStopSelectActive = true
        this.multiSelectBusStops()
      }
    },
    resetMultiselectBusStops () {
      this.mapInstance.setOptions({
        gestureHandling: 'auto'
      })
      if (this.onMapDragStartedListenerID) {
        this.onMapDragStartedListenerID.remove()
      }
      if (this.onMapDragEndedListenerID) {
        this.onMapDragEndedListenerID.remove()
      }
    },
    
    multiSelectBusStops () {
    // this.mapInstance.setOptions({
    //   gestureHandling: 'none',
    // })
      this.onMapDragStartedListenerID = this.mapInstance.addListener(
        'dragstart',
        this.onMapDragStarted
      )
      this.onMapDragEndedListenerID = this.mapInstance.addListener(
        'dragend',
        this.onMapDragEnded
      )
    },
    onMapDragStarted () {
      return false
    },

    onMapDragEnded () {

      return false
    },
    onContextMenuSelected ({ item, option }) {
      if (option && option.id && option.id === 'addBusStop') {
        this.addingBusStopMode = true
        this.addBusStopAtLocation({
          lat: item.lat(),
          lng: item.lng()
        })
      }
    },
    showContextMenu (e) {
      this.$refs.routeCreatorContextMenu.showMenu(e.domEvent, e.latLng)
    },
    deleteRouteBusStop (v) {
      const index = this.activeBusStops.findIndex((y) => y.id == v.id)
      if (index < 0) return
      this.activeBusStops.splice(index, 1)
    },
    cancelNewBusStopEdit () {
      this.newBusStop = null
    },
    newBusStopCreated (value) {
      if (value.geometry) {
        this.activeBusStops.push(value)
      }
      this.newBusStop = null
    },
    onMapClicked ($evt) {
      if (!this.addingBusStopMode) {
        return
      }

      this.addBusStopAtLocation({
        lat: $evt.latLng.lat(),
        lng: $evt.latLng.lng()
      })
    },
    addBusStopAtLocation (point) {
      this.newBusStop = {
        name: 'New Bus Stop',
        position: point,
        showInfo: true
      }
    },
    enterNewBusStopMode () {
      this.addingBusStopMode = !this.addingBusStopMode
    },
    busStopsLoaded (busStops) {
      this.clearBusStopMarkers()
      this.activeBusStops = busStops
    },
    busTopSelected (busStop) {
      if (this.activeBusStops && busStop) {
        const i = this.activeBusStops.find((i) => i.id === busStop.id)
        if (i) {
          this.activeBusStops.forEach((e) => this.$set(e, 'showInfo', false))
          this.toggleInfoWindow(i)
          this.panToMarker({
            lat: busStop.geometry.y,
            lng: busStop.geometry.x
          })
        }
      }
    },
    clearBusStopMarkers () {
      this.activeBusStops = []
    },
    panToMarker (pos) {
      this.$refs.Map.panTo(pos)
    },
    async loadActiveRoute () {
      try {
    
        if (this.currentlyShowingFeature) {
          this.$refs.Map.$_map.data.remove(this.currentlyShowingFeature)
          this.currentlyShowingFeature = null
        }
        this.$Progress.start()
        const result = await this.axios.get(`/v1/routes/${this.routeId}`)
        const route = result.data
        route.showInfo = false
        this.infoWindowsList = [route]
        this.showRoute(route)
        this.$Progress.finish()
      } catch (e) {
        this.$Progress.fail()
      }
    },
    toggleInfoWindow (context, key) {
      const keyName = key || 'showInfo'
      const showInfo = context[keyName] || false
      this.$set(context, keyName, !showInfo)

      const contextInfo = {
        position: {
          lat: context.latitude,
          lng: context.longitude
        },
        ...context
      }
      this.infoWindowContext = contextInfo
      this.showInfo = true
    },
    infoClicked (context) {

    },
    showRoute (route) {
      this.activeRoute = route
      this.infoWindowsList = [route]
      this.zoomLevel = 13
      const bounds = new window.google.maps.LatLngBounds()
      route.geometry.forEach((i) => {
        bounds.extend({ lat: i.y, lng: i.x })
      })

      this.panToMarker(bounds.getCenter())
      this.$refs.Map.fitBounds(bounds)
      if (this.currentlyShowingFeature) {
        this.$refs.Map.$_map.data.remove(this.currentlyShowingFeature)
      }
      this.currentlyShowingFeature = this.$refs.Map.$_map.data.setStyle({
        strokeWeight: 8,
        clickable: true,
        title: `${route.pickup} - ${route.destination}`
      })

      this.currentlyShowingFeature = this.$refs.Map.$_map.data.add(
        this.getGeoJson(route)
      )
    },
    getGeoJson (route) {
      return {
        geometry: new window.google.maps.Data.LineString(
          route.geometry.map((i) => {
            return { lat: i.y, lng: i.x }
          })
        )
      }
    },

    pickUpCordinatesForRoute (route) {
      if (!route.pickup_coordinate) return null

      const parts = route.pickup_coordinate
        .split(',')
        .map((i) => parseFloat(i.trim()))

      if (!parts) return null

      return {
        lat: parts[0],
        lng: parts[1]
      }
    },

    destinationCordinatesForRoute (route) {
      if (!route.destination_coordinate) return null

      const parts = route.destination_coordinate
        .split(',')
        .map((i) => parseFloat(i.trim()))

      if (!parts) return null

      return {
        lat: parts[0],
        lng: parts[1]
      }
    },

    dragStartedForNewBusStop (busStop, evt) {

    },
    dragEndedForNewBusStop (busStop, evt) {
      const lat = evt.latLng.lat()
      const lng = evt.latLng.lng()
      busStop.lat = lat
      busStop.lng = lng
    // this.handleBusStopLocationUpdate(busStop, lat, lng)
    },

    async handleBusStopLocationUpdate (originalVal, latitude, longitude) {
      const payload = {
        location: originalVal.location,
        location_description: originalVal.location_description,
        latitude,
        longitude
      }
      const id = originalVal.id
      try {
        await this.axios.patch(`/v1/bus-stops/${id}`, payload)
        let _busStopItem = this.busStops.find((i) => i.id == originalVal.id)
        if (_busStopItem) {
          _busStopItem.latitude = latitude
          _busStopItem.longitude = longitude
        }

        _busStopItem = this.infoWindowsList.find((i) => i.id == originalVal.id)
        if (_busStopItem) {
          _busStopItem.latitude = latitude
          _busStopItem.longitude = longitude
        }

      // return window.alert('Busstop updated successfully')
      } catch (e) {
        return window.alert(
          'An error occured while updating busstop, please try again later'
        )
      }
    }
  }
}
</script>

<style scoped>
.route-controller .adding-bus-stop {
/* cursor: url('https://firebasestorage.googleapis.com/v0/b/android-driver-production.appspot.com/o/public%2Fbus-stop-end-sm.png?alt=media'), */
}
</style>

<style lang="scss" scoped>
::v-deep  {
// TODO: Extract to reusable css partial
.gm-style .gm-style-iw-c {
  border-radius: 1rem;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  & .gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
  }
}
}
</style>
