<template>
	<div>
		<b-modal
			:id="modalId"
			@close="closeModal"
			no-close-on-backdrop
			no-close-on-esc
			style="width: 657px"
			>
			<template #modal-title>
				<span class="text-black font-weight-bold"> {{ modalTitle }}</span>
			</template>
			<div class="py-2">
				<div
					class="alert alert-danger alert-dismissible fade show"
					role="alert"
					v-if="errorMessage"
					>
					{{ errorMessage }}
				</div>
			</div>

			<form @submit.prevent="updateData">
				<div class="row">
					<div class="col-12" v-if="isDriverUpdate">
						<div class="form-group">
							<label class="form-label">Select Driver</label>
							<div>
								<v-select
									v-model="form.driver"
									class="form-group"
									:options="drivers"
									label="fname"
									:filterBy="driverFilter"
									placeholder="Select a driver"
									@input="updateSelectedVehicle($event)"
									required
									:components="{ Deselect }"
									>
									<template v-slot:option="driver">
										{{ `${driver.fname} ${driver.lname}` }}
									</template>
									<template v-slot:selected-option="driver">
										{{ `${driver.fname} ${driver.lname}` }}
									</template>
								</v-select>
							</div>
						</div>

						<div class="form-group d-inline-flex">
							<label>Update vehicle</label>
							<b-form-checkbox
								class="ml-2"
								v-model="extraControlEnabled"
								:indeterminate="false"
								>
							</b-form-checkbox>
						</div>

						<div class="form-group" v-if="extraControlEnabled">
							<label class="form-label">Select Vehicle</label>
							<v-select
								:disabled="!extraControlActive"
								v-model="form.vehicle"
								class="form-group"
								:options="availableVehicles"
								label="name"
								:filterBy="vehicleFilter"
								required
								placeholder="Select a vehicle to assign"
								:components="{ Deselect }"
								@input="setSelected"
								@search="fetchOptions"
								>
								<template v-slot:option="vehicle">
									{{ vehicle.brand }} • {{ vehicle.name }}
									{{ vehicle.registration_number }} • {{ vehicle.seats }} Seater
								</template>
								<template v-slot:selected-option="vehicle">
									{{ vehicle.brand }} • {{ vehicle.name }}
									{{ vehicle.registration_number }} • {{ vehicle.seats }}Seater
								</template>
							</v-select>
						</div>

						<div
							class="form-group d-flex flex-column"
							v-if="extraControlEnabled"
							>
							<label class="form-label">Supply cost</label>
							<input
								class="p-2 rounded cost-input"
								type="text"
								placeholder="₦"
								v-model.number="form.cost_of_supply"
								/>
						</div>
						<div
							class="form-group d-inline-flex"
							v-if="fullUpdate && extraControlEnabled"
							>
							<b-form-checkbox
								class="ml-2"
								v-model="extraControlActive"
								:indeterminate="false"
								>
								<label>Update Vehicle</label>
							</b-form-checkbox>
						</div>
					</div>

					<div class="col-12" v-if="isVehicleUpdate">
						<div class="form-group">
							<label class="form-label">Select Vehicle</label>
							<v-select
								v-model="form.vehicle"
								class="form-group"
								:options="availableVehicles"
								label="name"
								:filterBy="vehicleFilter"
								required
								placeholder="Select a vehicle to assign"
								:components="{ Deselect }"
								@input="setSelected"
								@search="fetchOptions"
								>
								<template v-slot:option="vehicle">
									{{ `${vehicle.brand}` }} • {{ `${vehicle.name}` }}
									{{ `${vehicle.registration_number}` }} •
									{{ `${vehicle.seats}` }} Seater
								</template>
								<template v-slot:selected-option="vehicle">
									{{ `${vehicle.brand}` }} • {{ `${vehicle.name}` }}
									{{ `${vehicle.registration_number}` }} •
									{{ `${vehicle.seats}` }} Seater
								</template>
							</v-select>
						</div>
						<div class="form-group d-flex flex-column">
							<label class="form-label">Supply cost</label>
							<input
								class="p-2 rounded cost-input"
								type="text"
								placeholder="₦"
								v-model.number="form.cost_of_supply"
								/>
						</div>
						<div class="form-group d-inline-flex" v-if="!fullUpdate">
							<b-form-checkbox
								class="ml-2"
								v-model="extraControlEnabled"
								:indeterminate="false"
								>
								<label>Update Driver sss</label>
							</b-form-checkbox>
						</div>
						<div class="form-group" v-if="extraControlEnabled">
							<label class="form-label">Select Driver</label>
							<v-select
								:disabled="!extraControlActive"
								v-model="form.driver"
								class="form-group"
								:options="drivers"
								label="fname"
								:filterBy="driverFilter"
								placeholder="Select a driver"
								required
								:components="{ Deselect }"
								>
								<template v-slot:option="driver">
									{{ `${driver.fname} ${driver.lname}` }}
								</template>
								<template v-slot:selected-option="driver">
									{{ `${driver.fname} ${driver.lname}` }}
								</template>
							</v-select>
						</div>
						<div
							class="form-group d-inline-flex"
							v-if="fullUpdate && extraControlEnabled"
							>
							<label>Update Driver sss</label>
							<b-form-checkbox
								class="ml-2"
								v-model="extraControlActive"
								:indeterminate="false"
								>
							</b-form-checkbox>
						</div>
					</div>
				</div>
			</form>

			<template #modal-footer>
				<button
					type="button"
					class="btn btn-secondary btn-close mr-3 rounded"
					data-dismiss="modal"
					ref="closeModalButton"
					@click="closeModal()"
					id="btn-size"
					>
					Close
				</button>
				<button
					type="button"
					class="btn btn-primary rounded p-2"
					id="btn-size"
					@click.prevent="updateData"
					:disabled="processing || !formValid"
					>
					{{ processing ? 'Processing...' : 'Proceed' }}
				</button>
			</template>
		</b-modal>
	</div>
</template>

<script>
// import ModalMixin from '@/mixins/modal'
import {
  extractErrorMessage,
  filterDriverBy,
  filterVehicleBy
} from '@/utils/helpers'

export default {
  props: {
    show: Boolean,
    fullUpdate: { type: Boolean, default: false },
    model: Object,
    updateType: String,
    url: { type: String, default: '/v1/upcoming-trips' },
    modalTitle: {
      type: String,
      default: 'Update Information'
    },
    drivers: {
      type: Array,
      default: () => []
    },
    vehicles: {
      type: Array
      // default: () => [],
    },
    selectedTrip: {
      type: String
    }
  },
  data () {
    return {
      form: {
        driver: null,
        vehicle: null,
        cost_of_supply: null
      },
      processing: false,
      debounce: null,
      loading: false,
      errorMessage: '',
      extraControlEnabled: false,
      vehicleList: [],
      extraControlActive: true,
      Deselect: {
        render: (createElement) => createElement('span', '❌')
      }
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.openModal()
      } else {
        this.closeModal()
      }
    },
    'form.vehicle' () {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.fetchVehicles()
      }, 600)
    }
  },
  created () {
    if (this.fullUpdate) {
      this.extraControlEnabled = true
      this.extraControlActive = false
    }
    if (!this.vehicles.length) {
      this.fetchVehicles()
    }
  },
  computed: {
    modalId () {
      return `changeTripDriverModal_${this.updateType}`
    },
    isDriverUpdate () {
      return this.updateType === 'driver'
    },
    isVehicleUpdate () {
      return this.updateType === 'vehicle'
    },
    availableVehicles () {
      return this.vehicles.length ? this.vehicles : this.vehicleList
    },
    formValid () {
      if (this.isDriverUpdate) {
        return this.extraControlEnabled
          ? this.form.driver && this.form.vehicle && this.form.cost_of_supply
          : this.form.driver
      } else {
        return this.extraControlEnabled
          ? this.form.vehicle && this.form.driver && this.form.cost_of_supply
          : this.form.vehicle
      }
    }
  },
  methods: {
    fetchOptions (search) {
      if (search.length) {
        this.loading = true
        this.axios
          .post('/v1/vehicles/search', {
            search_term: search,
            status: 'active'
          })
          .then((res) => {
            this.loading = false
            this.vehicleList = res.data
          })
          .catch(() => {
            this.loading = false
          })
      } else {

      }
    },
    setSelected (value) {
      if (value.vehicle_type.cost_of_supply === null) {
        this.form.cost_of_supply = null
      } else {
        this.form.cost_of_supply = value.vehicle_type.cost_of_supply
      }
    },
    openModal () {
      this.$bvModal.show(this.modalId)
      if (this.model) {
        this.patchForm(this.model)
      }
      if (this.fullUpdate) {
        this.extraControlEnabled = true
        this.extraControlActive = false
      }
    },
    closeModal () {
      this.$bvModal.hide(this.modalId)
      this.$emit('close')
      this.extraControlEnabled = false
      this.extraControlActive = true
    },
    updateData () {
      if (!this.formValid) {
        return
      }
      this.processing = true
      this.errorMessage = ''
      const payload = {}
      if (this.isVehicleUpdate) {
        payload.vehicle_id = this.form?.vehicle?.id
        payload.driver_id = this.form?.driver?.id
        payload.cost_of_supply = Number(this.form.cost_of_supply)
        if (this.extraControlEnabled) {
          payload.driver_id = this.form?.driver?.id
          payload.cost_of_supply = Number(this.form?.cost_of_supply)
          payload.vehicle_id = this.form?.vehicle?.id
        }
      } else if (this.isDriverUpdate) {
        payload.driver_id = this.form?.driver?.id
        payload.cost_of_supply = Number(this.form?.cost_of_supply)
        payload.vehicle_id = this.form?.vehicle?.id
        if (this.extraControlEnabled) {
          payload.vehicle_id = this.form?.vehicle.id
          payload.cost_of_supply = Number(this.form?.cost_of_supply)
          payload.driver_id = this.form?.driver?.id
        }
      }

      this.update(this.model.id, payload)
        .then(() => {
          this.closeModal()
          this.$swal({
            icon: 'success',
            title: 'Updated',
            text: 'Update was successful',
            showCloseButton: true
          }).finally(() => {
            this.$emit('data-changed', {
              driver: this.form.driver,
              vehicle: this.form.vehicle,
              cost_of_supply: this.form.cost_of_supply
            })
            this.resetForm()
          })
        })
        .catch((e) => {
          const msg = extractErrorMessage(e, 'Update failed')
          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    },
    // TODO: review and refactor to use function input prop instead for more control and extensibility for different payload types
    update (tripId, data) {
      const updateUrl =
        this.selectedTrip === 'active'
          ? `/v1/active-trips/${tripId}`
          : `${this.url}/${tripId}`
      return this.axios.patch(updateUrl, {
        ...data
      })
    },
    driverFilter (option, label, search) {
      return filterDriverBy(option, label, search)
    },
    vehicleFilter (option, label, search) {
      return filterVehicleBy(option, label, search)
    },
    fetchVehicles () {
      this.axios.get('/v1/vehicles', {status: 'active'}).then((res) => {
        this.vehicleList = res.data.data
      })
    },
    patchForm (model) {
      let newDriver
      let newVehicle = null
      // Find done for cases where model is different, observed a strange behaviour with v-select using only the id
      if (this.drivers.length) {
        newDriver = this.drivers.find((driver) => driver.id === model.driver.id)
      }
      if (this.vehicles.length) {
        newVehicle = this.availableVehicles.find(
          (vehicle) => vehicle.id === model.vehicle.id
        )
      }

      if (model && model.driver && model.driver.id) {
        this.form.driver = { ...model.driver, ...(newDriver || {}) }
      }
      if (model && model.vehicle && model.vehicle.id) {
        this.form.vehicle = {
          ...model.vehicle,
          ...(newVehicle || {})
        }
      }
    },
    resetForm () {
      this.errorMessage = ''
      this.form.driver = null
      this.form.vehicle = null
      this.form.cost_of_supply = null
    },
    updateSelectedVehicle (value) {
      if (value) {
        const vehicle = this.availableVehicles.find(
          (o) => o.id == value.vehicle_id
        )
        if (vehicle) {
          this.form.vehicle = vehicle
        } else {
          this.form.vehicle = null
          if (this.fullUpdate) {
            this.extraControlActive = true
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.form-label {
  margin-bottom: 0;
}
.cost-input {
  border: 0.6px solid #c6c8c7;
}

#btn-size {
  width: 77px;
  height: 37px;
}
.btn-close {
  background: #98a2b3;
  border: none;
  width: 77px;
  height: 37px;
}
</style>
