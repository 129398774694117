<template>
	<div class="container-fluid px-0 root-layout">
		<Sidebar></Sidebar>
		<div class="main-content">
			<div class="dashboard-view">
				<div class="">
					<router-view />
				</div>
			</div>
		</div>

		<router-view name="modals" />
	</div>
</template>

<script>
import Sidebar from '@/components/layout/sidebar/Sidebar.vue'

export default {
  components: { Sidebar }
}
</script>

<style lang="scss" scoped>
@use "src/assets/scss/partials/sh-colors" as colors;
@use "src/assets/scss/partials/layout";
.main-content {
  background-color: colors.$sh-background-color;
  min-height: 100vh;
}

.dashboard-view {
  position: relative;
}
.dashboard-padded {
  padding-top: 60px !important;
}

.root-layout {
  & > .main-content {
    width: 100%;
    margin-left: 0;
    background-color: colors.$sh-background-color;

    @media screen and (min-width: 768px) {
      width: layout.$content-area-width;
      margin-left: layout.$sidebar-width;
    }
  }
}
</style>
