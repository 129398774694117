<template>
	<div class="day-selector-wrapper">
		<v-date-picker
			:value="selected.date"
			@input="updateSelectedDate($event)">
			<template #default="{ inputValue, togglePopover, hidePopover }">
				<div class="d-flex flex-wrap">
					<span
						v-for="(date, i) in dates"
						:key="`${date.date}_${i}`"
						class="day-option"
						@click.stop="dateSelected($event, date, togglePopover)"
						:ref="`button_${i}`"
						>
						{{ parseDate(date.date) }}
						<svg
							class="day-option__remove"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
							@click.stop="removeDate(date, hidePopover)"
							>
							<path d="M6 18L18 6M6 6l12 12"></path>
						</svg>
					</span>
				</div>
			</template>
		</v-date-picker>
		<button
			class="btn btn-sm btn-primary mt-2"
			@click.stop="addDate">
			+ Add Date
		</button>
	</div>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'MultiDateSelector',
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {
      dates: [],
      selected: {}
    }
  },
  watch: {
    value (newValue) {
      if (newValue) {
        const dates = (newValue || []).map((val) => ({ date: new Date(val) }))
        if (JSON.stringify(dates) !== JSON.stringify(this.dates)) {
          this.dates = dates
        }
      }
    }
  },
  created () {
    if (this.value && this.value.length) {
      const dates = (this.value || []).map((val) => ({ date: new Date(val) }))
      if (JSON.stringify(dates) !== JSON.stringify(this.dates)) {
        this.dates = dates
      }
    }
  },
  methods: {
    addDate () {
      const newDate = {
        date: new Date()
      }
      this.selected = newDate
      this.dates.push(newDate)
      this.$nextTick(() => {
        const btn = this.$refs[`button_${[this.dates.length - 1]}`][0]
        if (btn) {
          btn.click()
        }
      })
    },
    removeDate (date, hide) {
      this.dates = this.dates.filter((d) => d !== date) || []
      hide()
      this.updateValue()
    },
    dateSelected (e, date, toggle) {
      this.selected = date
      toggle({ ref: e.target })
    },
    updateValue () {
      this.$emit('input', (this.dates || []).map((obj) => obj.date))
    },
    parseDate (value) {
      if (!value || value === 'Invalid Date') {
        return ''
      }
      return format(value, 'yyyy-MM-dd')
    },
    updateSelectedDate ($event) {
      if ($event) {
        this.selected.date = $event
        this.updateValue()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069;
$bg-color: #ffffff;
$border-active: #20E682;
$bg-active: #EDFDF5;
$text-color: #060606;

.day-selector-wrapper {
  width: 100%;
  border-radius: 8px;

  .day-option {
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    font-weight: 500;
    line-height: 1rem;  background: $bg-active;
    border: 1px solid $border-active;
    color: $text-color;
    cursor: pointer;
    margin: 0.25rem 0.25rem 0.25rem 0;
    min-width: 3rem;
    justify-content: center;
    display: flex;
    &__remove {
      height: 1rem;
      width: 1rem;
      margin-left: .25rem;
    }
  }

  .add-date {

  }
}

:deep .vc-container {
  .vc-highlights .vc-day-layer {
    //overrides highlight color in date picker in blue theme mode
    --blue-600: #060606;
  }
}
</style>
