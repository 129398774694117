import {ref} from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import Swal from 'sweetalert2'

const loading = ref(false)
const date = ref([])
const password = ref('')
const showPassword = ref(false)
const showMarkChargesModal = ref(false)
const showRemitChargeModal = ref(false)

const closeMarkChargesModal = () => {
	date.value = []
	password.value = ''
	showMarkChargesModal.value = false
}

const closeRemitChargesModal = () => {
	password.value = ''
	showRemitChargeModal.value = false
}

export const useRemitted = () => {
	const markAsRemitted = async () => {
		try {
			const res = await axios.get(`/v1/additional-charges-history?limit=${perPage.value}&page=${currentPage.value}`)
			if (res.data?.data?.length) {
				chargeHistory.value = res.data.data
				chargeHistoryMeta.value = res.data.metadata
			} else {
				chargeHistory.value = []
			}
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		} finally{
			loading.value = false
		}
	}

	return { loading, showMarkChargesModal, showRemitChargeModal, closeMarkChargesModal, closeRemitChargesModal, markAsRemitted, date, password, showPassword }
}