const LSName = 'vuex'

const getDataFromLS = () => {
	const LS = localStorage.getItem(LSName)
	if(LS) {
		return JSON.parse(LS)
	} else {
		return LS
	}
} 

export const getAdminType = () => {
	return getDataFromLS() ? getDataFromLS()?.session?.currentUser?.role : null
}

