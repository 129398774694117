<template>
	<div>
		<page-header
			:loading="loading"
			:title="staff ? `${staff?.fname} ${staff?.lname}` : ''"
			pre-title="Overview"
			>
			<template slot="tabs" v-if="!errorLoading">
				<page-tabs :menu-tabs="[{ routeName: 'ShowStaff', label: 'Staff information'}]">
					<template v-slot="props">
						<router-link
							:to="{ name: props.menu.routeName }"
							class="nav-link"
							active-class="active"
							>
							<span>{{ props.menu.label }}</span>
						</router-link>
					</template>
				</page-tabs>
			</template>
		</page-header>

		<page-route-view
			:loading="loading"
			:error-loading="errorLoading"
			error-text="Failed to load partner information"
			backLink="/admin/list"
			></page-route-view>
	</div>
</template>


<script setup>
import PageHeader from '@/components/layout/PageHeader'
import PageTabs from '@/components/layout/PageTabs'
import PageRouteView from '@/components/layout/PageRouteView'
import { ref } from 'vue'
import { currentUser } from '@/composables/core/permissions'
import { getAdminType } from '@/composables/core/permissions/admin'

const loading = ref(false)
const errorLoading = ref(false)
const processing = ref(false)
const staff = ref()

staff.value = currentUser.value

</script>



<style lang="scss" scoped>
@use 'src/assets/scss/nav-tabs';
.alertDisplay {
  background-color: #ebebff;
}

.parentClass {
  display: relative;
}

.childClass {
  display: absolute;
  top: 0px;
  left: 0px;
  border: none;
  outline: none;
}
</style>
