import RemoteConfigService from '@/services/remote-config'

const featureFlagConfig = new Map()
let currentUserEmail = null
let currentUserId = null

// TODO: Review converting to es5 syntax with Injected instance of RemoteConfigService

/**
 * Feature flags to be utilized for access control for new feature releases and updates
 * Values for object keys are the keys defined on the remote server holding the feature configuration data
 * Newer flags can be added as the project grows with newer features Implemented
 * Flags may also be removed at points when the feature is fully established and completed
 *
 * @example of feature configuration data object
 *  {
 *   isEnabled: boolean,
 *   targetType: 'all' | 'user' | 'email'
 *   targetAccount: [] // Array of  values of type is user or email,
 *   isBeta: boolean
 *  }
 *
 * Feature configuration could also be of boolean type for simplicity and lower latency for
 * reduced payload during app bootstrap or initialization
 * */
// Currently contains keys for new and beta features, could also contain existing
// features/pages to drive additional logic in codebase
export const FEATURE_FLAGS = {
  ROUTE_SEARCH: 'ROUTE_SEARCH',
  VEHICLE_PARTNERS: 'VEHICLE_PARTNER_MANAGEMENT',
  ADMIN_CONFIGURATION: 'ADMIN_CONFIGURATION',
  REVENUE_AND_COST_AUTOMATION: 'REVENUE_AND_COST_AUTOMATION',
  DRP: 'DRP',
  DRP_CLIENT: 'DRP_CLIENT'
}

function setFeatureFlagConfigData () {
  try {
    const envAlias = process.env.VUE_APP_ENV_ALIAS
    Object.keys(FEATURE_FLAGS).forEach((featureFlag) => {
      featureFlagConfig.set(FEATURE_FLAGS[featureFlag], RemoteConfigService.getConfigurationValue(`${envAlias}_${FEATURE_FLAGS[featureFlag]}`))
    })
  } catch (e) {

  }
}

function setCurrentUserData (data) {
  currentUserEmail = data?.email
  currentUserId = data?.userId
}

/**
 * Checks if feature is enabled for current user by email
 *
 * @param {(Object|boolean)} featureConfig
 * @param {boolean} featureConfig.isEnabled
 * @param {String} featureConfig.targetType
 * @param {Array} featureConfig.targetAccount
 * @param {boolean} featureConfig.isBeta
 *
 * @returns {boolean}
 */
function featureEnabledForEmail (featureConfig) {
  return featureConfig.targetAccount.includes(currentUserEmail)
}

/**
 * Checks if feature is enabled for current user by userId
 *
 * @param {(Object|boolean)} featureConfig
 * @param {boolean} featureConfig.isEnabled
 * @param {String} featureConfig.targetType
 * @param {Array} featureConfig.targetAccount
 * @param {boolean} featureConfig.isBeta
 *
 * @returns {boolean}
 */
function featureEnabledForUserId (featureConfig) {
  return featureConfig.targetAccount.includes(currentUserId)
}

/**
 * Utility function that determines if a feature is enabled, can be used to set guard logic on routes/paths
 * and also ui elements and driver feature specific logic
 *
 * @returns {boolean}
 */
const isFlagEnabled = (flagKey) => {
  let featureFlagEnabled = process.env.VUE_APP_FEATURE_FLAG_ENABLED
  featureFlagEnabled = typeof featureFlagEnabled === 'string'
    ? featureFlagEnabled === 'true' ?? false
    : !!featureFlagEnabled
  if (featureFlagEnabled) {
    if (featureFlagConfig.has(flagKey)) {
      /**
       * @type {(Object|boolean)}
       * @property featureConfig.isEnabled
       * @property featureConfig.targetType
       * @property featureConfig.targetAccount
       * @property featureConfig.isBeta
       */
      const featureConfig = featureFlagConfig.get(flagKey)
      if (featureConfig && typeof featureConfig === 'object') {
        if (featureConfig.isEnabled) {
          switch (featureConfig.targetType) {
            case 'all':
              return true
            case 'user':
              return featureEnabledForUserId(featureConfig)
            case 'email':
              return featureEnabledForEmail(featureConfig)
            default:
              return true
          }
        }
      } else if (typeof featureConfig === 'boolean') {
        return featureConfig
      }
    }
    return false
  } else {
    return true
  }
}
const FeatureFlagService = {
  isFlagEnabled,
  setFeatureFlagConfigData,
  setCurrentUserData
}
export default FeatureFlagService
