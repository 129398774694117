import { loadRouteComponent } from '@/utils/helpers'
import Bookings from '@/layouts/modules/Bookings/Index.vue'
import BookingsList from '@/layouts/modules/Bookings/BookingsList.vue'

const BookingRoutes = [
  {
    path: 'bookings',
    component: Bookings,
    props: true,
    children: [
      {
        path: '',
        redirect: 'batch'
      },
      {
        path: 'batch',
        name: 'BatchBookings',
        component: BookingsList,
        props: true,
        children: [
          {
            path: '',
            name: 'ListBatchBookings',
            component: loadRouteComponent('BatchBookingRequests/List'),
            props: true,
            requiredPermissions: false
          },
          {
            path: 'new',
            name: 'CreateBatchBooking',
            component: loadRouteComponent('BatchBookingRequests/Create'),
            props: true,
            meta: {
              requiredPermissions: ['users011']
            }
          },
          {
            path: ':batchRequestID',
            name: 'ShowBatchBooking',
            component: loadRouteComponent('BatchBookingRequests/Show'),
            props: true,
            requiredPermissions: false
          }
        ]
      }
    ]
  }
]

export default BookingRoutes
