import { loadRouteComponent } from '@/utils/helpers'
import PromoCodes from '@/layouts/modules/PromoCodes/Index.vue'
const PromoCodesRoutes = [
  {
    path: 'promo-codes',
    component: PromoCodes,
    props: true,
    children: [
      {
        path: '',
        name: 'ListPromoCode',
        component: loadRouteComponent('PromoCodes/pages/ListPromoCodes'),
        props: true,
        meta: {
          requiredPermissions: ['promotions002']
        }
      },
      {
        path: 'new',
        name: 'NewPromoCode',
        component: loadRouteComponent('PromoCodes/pages/NewPromoCode'),
        props: true,
        meta: {
          requiredPermissions: ['promotions001']
        }
      },
      {
        path: ':promoCodeId',
        name: 'ShowPromoCode',
        component: loadRouteComponent('PromoCodes/pages/ShowPromoCode'),
        props: true,
        meta: {
          requiredPermissions: ['promotions003']
        }
      }
    ]
  }
]
 
export default PromoCodesRoutes
