import {ref} from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import Swal from 'sweetalert2'
import router from '@/router'

const loading = ref(false)
const configuredCharges = ref([])
const configuredChargesMetadata = ref(null)
const currentPage = ref(1)
const perPage = ref(10)
const search = ref('')
const singleConfiguredCharge = ref(null)

export const useFetchConfiguredCharges = () => {
	const fetchConfiguredCharges = async () => {
		loading.value = true
		try {
			const res = await axios.get(`/v1/additional-charges?limit=${perPage.value}&page=${currentPage.value}&search=${search.value}`)
			if(res.data?.data?.length){
				configuredCharges.value = res.data.data
				configuredChargesMetadata.value = res.data.metadata
			} else {
				configuredCharges.value = []
			}
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		} finally{
			loading.value = false
		}
	}

	const fetchSingleConfiguredCharges = async (id) => {
		loading.value = true
		try {
			const res = await axios.get(`/v1/additional-charges/${id}`)
			if(res.data?.id) {
				singleConfiguredCharge.value = res.data
			} else {
				Swal.fire({ icon: 'error', title: 'Error', text: 'Could not get configuration details'})
				router.push('/configuration/charges/configure')
				singleConfiguredCharge.value = null
			}
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		} finally{
			loading.value = false
		}
	}

	return {loading, configuredCharges, fetchConfiguredCharges, configuredChargesMetadata, currentPage, perPage, search, fetchSingleConfiguredCharges, singleConfiguredCharge}
}