<template>
	<div style="height: 100vh; width: 100%">
		<google-map
			id="map-preview"
			ref="PreviewMap"
			:zoom="zoomLevel"
			v-if="activeRoute"
			>
			<google-map-marker
				v-if="activeRoute"
				:position="pickUpCordinatesForRoute(activeRoute)"
				:key="`preview-pickup-${activeRoute.id}`"
				:clickable="true"
				@click="toggleInfoWindow(activeRoute, 'showInfoForPickup')"
				/>

			<google-map-infowindow
				v-if="activeRoute"
				:position="pickUpCordinatesForRoute(activeRoute)"
				:show.sync="activeRoute.showInfoForPickup"
				:key="`preview-info-windowpickup-${activeRoute.id}`"
				:options="{ maxWidth: 300, pixelOffset: 10 }"
				>
				<div>
					<h4>Starting Point</h4>
					<p>{{ activeRoute.pickup }}</p>
				</div>
			</google-map-infowindow>

			<google-map-marker
				v-if="activeRoute"
				:position="destinationCordinatesForRoute(activeRoute)"
				:key="`preview-destination-${activeRoute.id}`"
				:clickable="true"
				@click="toggleInfoWindow(activeRoute, 'showInfoForDestination')"
				/>
			<google-map-infowindow
				v-if="activeRoute"
				:position="destinationCordinatesForRoute(activeRoute)"
				:show.sync="activeRoute.showInfoForDestination"
				:key="`preview-info-windowdestination-${activeRoute.id}`"
				:options="{ maxWidth: 300, pixelOffset: 10 }"
				>
				<div>
					<h4>Ending Point</h4>
					<p>{{ activeRoute.destination }}</p>
				</div>
			</google-map-infowindow>
		</google-map>
	</div>
</template>

<script>
export default {
  props: {
    activeRoute: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      zoomLevel: 15,
      mapInstance: null,
      currentlyShowingFeature: null
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    initialize () {
      this.$refs.PreviewMap.$_getMap().then((v) => {
        this.mapInstance = v
        this.configureGoogleMap()
      })
    },
    configureGoogleMap () {
      if (!this.mapInstance) return

      this.mapInstance.setOptions({
        fullscreenControl: false,
        mapTypeControl: false
      })

      if (this.currentlyShowingFeature) {
        this.$refs.PreviewMap.$_map.data.remove(this.currentlyShowingFeature)
        this.currentlyShowingFeature = null
      }

      this.showRoute()
    },
    pickUpCordinatesForRoute (route) {
      if (!route.pickup_coordinate) return null

      const parts = route.pickup_coordinate
        .split(',')
        .map((i) => parseFloat(i.trim()))

      if (!parts) return null

      return {
        lat: parts[0],
        lng: parts[1]
      }
    },

    destinationCordinatesForRoute (route) {
      if (!route.destination_coordinate) return null

      const parts = route.destination_coordinate
        .split(',')
        .map((i) => parseFloat(i.trim()))

      if (!parts) return null

      return {
        lat: parts[0],
        lng: parts[1]
      }
    },
    toggleInfoWindow (context, key) {
      const keyName = key || 'showInfo'
      const showInfo = context[keyName] || false
      this.$set(context, keyName, !showInfo)

      const contextInfo = {
        position: {
          lat: context.latitude,
          lng: context.longitude
        },
        ...context
      }
      this.infoWindowContext = contextInfo
      this.showInfo = true
    },
    panToMarker (pos) {
      this.$refs.PreviewMap.panTo(pos)
    },
    showRoute () {
      this.zoomLevel = 13

      const bounds = new window.google.maps.LatLngBounds()
      this.activeRoute.geometry.forEach((i) => {
        bounds.extend({ lat: i.y, lng: i.x })
      })

      this.panToMarker(bounds.getCenter())
      this.$refs.PreviewMap.fitBounds(bounds)
      if (this.currentlyShowingFeature) {
        this.$refs.PreviewMap.$_map.data.remove(this.currentlyShowingFeature)
      }
      this.currentlyShowingFeature = this.$refs.PreviewMap.$_map.data.setStyle({
        strokeWeight: 8,
        clickable: true,
        title: `${this.activeRoute.pickup} - ${this.activeRoute.destination}`
      })

      this.currentlyShowingFeature = this.$refs.PreviewMap.$_map.data.add(
        this.getGeoJson(this.activeRoute)
      )
    },
    getGeoJson (route) {
      return {
        geometry: new window.google.maps.Data.LineString(
          route.geometry.map((i) => {
            return { lat: i.y, lng: i.x }
          })
        )
      }
    }
  }
}
</script>
