var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('page-header',{attrs:{"title":"Event request","pre-title":"Overview","loading":false}},[_c('template',{slot:"tabs"},[_c('page-tabs-vue',{attrs:{"menu-tabs":_vm.menuTabs},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: props.menu.routeName},"active-class":"active"}},[_vm._v(" "+_vm._s(props.menu.label)+" ")])]}}])})],1),_c('template',{slot:"actions"},[_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-primary dropdown-toggle",attrs:{"type":"button","id":"dropdownMenuButton","data-toggle":"dropdown","aria-expanded":"false","disabled":_vm.isActionButtonDisabled}},[_vm._v(" Actions ")]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right mt-3",attrs:{"aria-labelledby":"dropdownMenuButton"}},[_c('a',{staticClass:"dropdown-item",class:{
                'btn btn-primary' : _vm.routeAssigned,
                'btn disabled' : !_vm.routeAssigned
              },on:{"click":() => (
                // if (routeAssigned) {
                //   showModal('notify-attendees-modal')
                // }
                _vm.routeAssigned ? _vm.showModal('notify-attendees-modal') : null
              )}},[_vm._v(" Notify Attendees ")])])])])],2),_c('page-route-view',{attrs:{"loading":_vm.loading,"bus":_vm.bus},on:{"update:attendeesNotificationLoading":_vm.updateAttendeesNotificationLoading,"update:eventStatus":_vm.updateEventStatus,"update:routeAssigned":_vm.updateRouteAssigned}}),_c('notify-attendee-modal',{attrs:{"loading":_vm.attendeesNotificationLoading,"modal-type":"attendees"},on:{"onNotifyAttendees":_vm.notifyAttendees}}),_vm._l((_vm.toastData),function(toast,index){return _c('custom-toast',{key:index,attrs:{"toastId":toast.toastId,"variant":toast.variant,"icon":toast.icon,"iconBgClass":toast.iconBgClass,"message":toast.message,"toastBodyClass":toast.toastBodyClass}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }