<template>
	<div class="flex flex-col h-screen gap-4 p-5 justify-center items-center w-screen">
		<img src="@/assets/logo.png" alt="logo" class="w-96">
		<!-- <Spinner class="!border-t-dark" /> -->
	</div>
</template>

<script setup>
import { useSignInUserExternally } from '@/composables/core/external'
import { useRoute, useRouter } from 'vue-router/composables'
import { onMounted } from 'vue'
import { useStore } from '@/store/index.js'
import sessionServiceInstance from '@/services/session'
import { setAuthorization } from '@/plugins/axios'
import { getUserPermission } from '@/composables/core/permissions/index'


const { query } = useRoute()
const router = useRouter()
const store = useStore()

const { sign_user_in } = useSignInUserExternally()

onMounted(async () =>
{
	const res = await sign_user_in((query.token).replace(/"/g, '').trim(), query.path)

	try {
		if (res) {
			await setAuthorization(res.token.token)
			await store.dispatch('setCurrentUser', res)
			await getUserPermission()
			router.push(query.path || '/')
		}
	} catch (err) {

	}
	// router.push(query.path || '/'

})

</script>

<style scoped></style>
