<template>
	<div>
		<div class="bg-white border-bottom">
			<div class="mb-0 header">
				<div class="container-fluid">
					<div class="py-0 mt-4 border-0 header-body">
						<div class="row align-items-end">
							<div class="col">
								<h6 class="header-pretitle">Company Overview</h6>
								<h1 class="header-title">
									{{ request ? request.name : '' }}
								</h1>
							</div>
							<div class="col-auto">
								<div class="dropdown">
									<button
										class="btn btn-primary dropdown-toggle"
										type="button"
										id="dropdownMenuButton"
										data-toggle="dropdown"
										aria-expanded="false"
										>
										Actions
									</button>
									<div
										class="dropdown-menu dropdown-menu-right"
										aria-labelledby="dropdownMenuButton"
									> 
                    <button class="dropdown-item" @click="modifyRequest('active', 'accept')">Accept</button>
                    <button class="dropdown-item">Shuttle cost</button>
                    <button class="dropdown-item text-[red]" @click="modifyRequest('deactivated', 'reject')">Reject</button>
									</div>
								</div>
							</div>
						</div>
						<!-- / .row -->
					</div>
					<!-- / .header-body -->
				</div>
			</div>
			<div class="container-fluid">
				<div class="pt-3 pb-1 row">
					<div class="col justify-self-center align-self-center flex-column">
						<ul class="nav nav-pills">
							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShuttleInformation',
									}"
									class="nav-link"
									active-class="active"
									>Shuttle Information</router-link
									>
							</li>

							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShuttleStaffList',
										// props: { companyId: $route.params.companyId },
									}"
									class="nav-link"
									active-class="active"
								>Staff list</router-link
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="pt-4">
			<div class="container-fluid" v-if="!loading">
				<router-link
					:to="{ name: 'Shuttle Requests' }"
					class="mb-4 btn btn-light btn-sm"
					
					>Go back</router-link>
				<router-view></router-view>
			</div>
			<p v-else class="font-medium text-center text-md">Loading...</p>
		</div>

		<BS_modal :show='comment_modal' title='Duplicate Routes' @close='closeCommentModal' modalId='duplicateRouteModalTwo'>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col">
            <label class="m-0">Rejection comment</label>
			<textarea class="p-2 border rounded-md h-[100px]" v-model="reject_comment" cols="30" rows="10"></textarea>
          </div>
          
          <button class='btn bg-dark text-white w-full mt-[30px]' @click='continueRejectionProcess' :disabled="reject_comment == ''">
			Continue
          </button>
        </div>
    </BS_modal>

	</div>
</template>


<script setup>
import { userHasPermission } from '@/composables/core/permissions'
import Swal from 'sweetalert2'
import { extractErrorMessage } from '@/utils/helpers'
import { defineProps, ref, provide } from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import BS_modal from '@/components/core/modals/BS_modal.vue'

const props = defineProps({
  requestId: String
})
const loading = ref(false)
const request = ref()
const comment_modal = ref(false)
const reject_comment = ref('')
provide('requestData', request)


const fetchRequest = () => {
  loading.value = true
  axios.get(`/v1/shuttle-requests/${props.requestId}`)
  .then((res) => {
    if(res?.data?.data) {
      request.value = res.data.data
    } else {
      request.value = null
    }
    loading.value = false
  })
  .catch((err) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: err?.message || 'An error occured'
    })
    loading.value = false
  })
}

const continueRejectionProcess = () => {
	modifyRequest('deactivated', 'reject', reject_comment.value)
	comment_modal.value = false
}

const modifyRequest = (data, actionName, reason='') => {
	if(actionName == 'reject' && reject_comment.value == '') {
		comment_modal.value = true
		return
	}
	Swal.fire({
		title: 'Modify request?',
		text: `Are you sure you want to ${actionName} this shuttle request?`,
		icon: 'warning',
		showCancelButton: true,
	}).then((result) => {
		if (result.isConfirmed) {
		proceedToModifyRequest(data, actionName, reason)
		}
	})
}

const proceedToModifyRequest = (data, actionName, reason) => {
	const payload = {
		status: data
	}
	if(actionName == 'reject') payload['rejection_reason'] = reason

	axios.patch(`/v1/shuttle-requests/${props.requestId}`, payload)
	.then((res) => {
		if(res?.data?.id) {
		Swal.fire({
			icon: 'success',
			title: 'Successful',
			text: res?.data?.message || getSuccessMessage(actionName)
		})
		fetchRequest()
		} else {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: res?.data?.message || 'An error occured'
		})
		}
	})
	.catch((err) => {
		Swal.fire({
		icon: 'error',
		title: 'Oops...',
		text: extractErrorMessage(err, 'An error occured')
		})
	})
}

const getSuccessMessage = (actionName) => {
	if(actionName == 'accept') {
		return 'Request accepted successfully'
	} else if(actionName == 'reject') {
		return 'Request rejected successfully'
	}
}

const closeCommentModal = () => {
	comment_modal.value = false
	reject_comment.value = ''
}

provide('modifyRequest', modifyRequest)
fetchRequest()

</script>


<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}

.VueTables__search-field > label {
  display: none;
}

.VueTables__search-field {
  margin-top: 20px;
}

.VueTables__limit {
  margin-right: 10px !important;
  padding-top: 10px;
}
</style>
