<template>
	<div>
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div class="bg-white border-bottom" v-else>
			<div class="header mb-0">
				<div class="container-fluid">
					<div class="header-body py-0 mt-4 border-0">
						<div class="row align-items-end">
							<div class="col">
								<h6 class="header-pretitle">Overview</h6>
								<h1 class="header-title">Amenities</h1>
							</div>
						</div>
						<!-- / .row -->
					</div>
					<!-- / .header-body -->
				</div>
			</div>
			<div class="container">
				<div class="row pt-3 pb-1">
					<div class="col justify-self-center align-self-center flex-column">
						<ul class="nav nav-pills">
							<li class="nav-item">
								<router-link
									:to="{
										name: 'VehicleAmenitiesSettings',
									}"
									class="nav-link"
									active-class="active"
									>Add amenities</router-link
									>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="pt-4">
			<div class="container">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {},
  data () {
    return {
      loading: false
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}
</style>
