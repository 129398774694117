// import { axiosInstance } from '@/plugins/axios';
import { drpAxiosInstance } from '@/plugins/drpAxios'
import { PRICING_TYPES } from '@/views/Routes/itenerary/pricing-types'

class DrpService {
  /**
   * @param {AxiosInstance} axios
   * */
  constructor (axios) {
    this.axios = axios
    this.baseUrl = '/v1'
  }

  // Utilities

  convertXYtoLatLng (data) {
    return data ? { longitude: data.x, latitude: data.y } : null
  }

  buildFeeCreationPayload (pricingType, form, userId) {
    const payload = {
      amount: form.amount,
      baseAmount: form.baseAmount || form.amount,
      currency: form.currency,
      userId: `${userId}`
    }
    switch (pricingType) {
      case PRICING_TYPES.FIXED.id:
        return payload
      case PRICING_TYPES.DYNAMIC.id:
        return {
          ...payload,
          distance: form.distance,
          distanceUnit: `${form.distanceUnit}`
        }
      case PRICING_TYPES.BUS_STOP_BASED.id:
        return {
          ...payload,
          amount: form.baseAmount
        }
    }
  }

  buildBusStopsPayload (drpRouteUid, stopPricingConfigurations, baseAmount, userId) {
    const data = stopPricingConfigurations.map((config) => {
      const payload = {
        sourceBusstopId: `${config.sourceBusStop.id}`,
        destinationBusstopId: `${config.destinationBusStop.id}`,
        sourceBusstopPoint: this.convertXYtoLatLng(config.sourceBusStop.geometry),
        destinationBusstopPoint: this.convertXYtoLatLng(config.destinationBusStop.geometry),
        fee: {
          amount: config.fee.amount,
          baseAmount,
          currency: 'NGN'
        }
      }
      if (userId) {
        payload.userId = userId
      }
      if (drpRouteUid) {
        payload.routeId = drpRouteUid
      }
      if (config.id) {
        payload.id = config.id
        payload.uuId = config.uuId
      }
      if (config.fee) {
        payload.fee = { ...config.fee, ...payload.fee }
      }
      return payload
    })
    return data
  }

  /**
   * @description Re-arranges source and destination configurations based on selection,
   * returns an array of removed values for deletion
   * @returns {Array}
   * */
  redrawBusStopsConfigTable (affectedIndex, busStopConfigsForm, busStopMap) {
    const config = busStopConfigsForm[affectedIndex]
    const destinationId = config.destinationBusStop.id
    const removedValues = []
    if (!busStopMap.has(destinationId)) {
      return []
    }
    const busStopIndex = busStopMap.get(destinationId).index
    const totalStopsLength = busStopMap.entries().length
    const nextStopIndex = affectedIndex + 1
    const lastBusStopIndex = totalStopsLength - 1
    /**
     * Removes every other entry on form after destination is set as last bus-stop
     * */
    if (busStopIndex === lastBusStopIndex && busStopConfigsForm.length > 1) {
      removedValues.push(...busStopConfigsForm.splice(nextStopIndex))
      return removedValues
    }

    const lastBusStop = Array.from(busStopMap.values()).pop()
    const newPosition = {
      id: null,
      sourceBusStop: busStopMap.get(destinationId),
      destinationBusStop: lastBusStop,
      uuid: null,
      fee: {
        currency: 'NGN',
        amount: 0
      },
      routeId: null
    }
    if (busStopConfigsForm.length === nextStopIndex) {
      busStopConfigsForm.push(newPosition)
    } else {
      /**
       * Sets next bus-stop source to that of previous destination
       * */
      busStopConfigsForm[nextStopIndex].sourceBusStop = newPosition.originRouteBusStop

      /**
       * Removes next bus-stop if it's origin is the destination
       * */
      if (busStopConfigsForm[nextStopIndex].originRouteBusStop === busStopConfigsForm[nextStopIndex].destinationRouteBusStop) {
        removedValues.push(...busStopConfigsForm.splice(nextStopIndex, 1))
      }
    }
    return removedValues
  }

  // CRUD Functions

  /**
   * @description returns saved pricing configuration
   * @returns {Promise}
   * */
  fetchItineraryPricingConfig (itineraryId) {
    return this.axios.get(this.baseUrl + `/pricing/${itineraryId}`)
  }

  /**
   * @description saves or updates pricing information
   * @returns {Promise}
   * */
  async saveOrUpdatePricing (method, urlPath, formData) {
    return this.axios[method](this.baseUrl + `/pricing${urlPath}`, formData)
  }

  /**
   * @description update itinerary startime or startbusstopid
   * @returns {Promise}
   * */
  async updateItinerary (id, data) {
    return this.axios.patch(this.baseUrl + `/itinerary/${id}`, data)
  }

  /**
   * @description returns saved pricing configuration for bus-stop based pricing type
   * @returns {Promise}
   * */
  fetchPricingBusStopsData (routeOrItineraryId) {
    return this.axios.get(this.baseUrl + `/routes-busstops/?routeId=${routeOrItineraryId}`)
  }

  fetchItineraryPricingData (itineraryId) {
    const data = {}
    return new Promise((resolve, reject) => {
      this.fetchItineraryPricingConfig(itineraryId)
        .then(async (res) => {
          data.itineraryData = res.data.data?.route
          if (data?.itineraryData && data.itineraryData.type.id === PRICING_TYPES.BUS_STOP_BASED.id) {
            const stopsRes = await this.fetchPricingBusStopsData(data.itineraryData.uuId)
            data.busStopPricingData = stopsRes.data.data.routesBusstops
          }
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  updateFeeData (fee) {
    return this.axios.put(this.baseUrl + `/fees/${fee.uuId}`, fee)
  }

  /**
   * @description Performs a create/update for subsequent updates for bus-stop pricing
   * */
  updateBusStopsData (busstops) {
    const requests = busstops.map((busStop) => {
      if (busStop.uuId) {
        return this.axios.put(this.baseUrl + `/routes-busstops/${busStop.uuId}`, busStop)
      } else {
        return this.axios.post(this.baseUrl + '/routes-busstops/one', busStop)
      }
    })
    return Promise.all(requests)
  }

  deleteBusStopPricing (id) {
    return this.axios.delete(this.baseUrl + `/routes-busstops/${id}`)
  }

  /**
   * @description Deletes all discarded stop setting from
   * */
  async deleteDiscardedStops (stopsToDelete) {
    const requests = stopsToDelete.reduce((res, busStop) => {
      if (busStop.uuId) {
        res.push(this.deleteBusStopPricing(busStop.uuId))
      }
      return res
    }, [])
    return Promise.all(requests)
  }
}

const drpService = new DrpService(drpAxiosInstance)

export default drpService
