<template>
	<div class="bg-white border-bottom header" :class="headerCss">
		<div class="header mb-0" :class="{ 'mb-3': loading || !hasTabs }">
			<div class="container-fluid">
				<div class="header-body py-0 pt-4 border-0">
					<div class="row align-items-center">
						<div class="col">
							<h6 class="header-pretitle">{{ preTitle }}</h6>
							<h1 class="header-title">{{ title }}</h1>
						</div>
						<div class="col d-flex justify-content-end">
							<slot name="actions"></slot>
						</div>
					</div>
					<!-- / .row -->
				</div>
				<!-- / .header-body -->
			</div>
		</div>
		<div class="container-fluid" v-if="!loading && hasTabs">
			<slot name="tabs"></slot>
		</div>
		<div>
			<slot name="desc"></slot>
		</div>
	</div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    headerCss: [Array, Object],
    title: { type: String, required: true },
    preTitle: { type: String, required: false, default: 'Overview' },
    loading: { type: Boolean, required: false, default: false }
  },
  computed: {
    hasTabs () {
      return !!this.$slots.tabs
    }
  }
}
</script>

<style lang="scss" scoped>
.header-title {
  font-weight: 500;
  line-height: 29px;
  font-size: 20px !important;
}
</style>
