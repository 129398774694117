var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_vm._m(0)]):_c('div',{staticClass:"bg-white border-bottom"},[_c('div',{staticClass:"header mb-0"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"header-body py-0 mt-4 border-0"},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"header-pretitle"},[_vm._v("Vehicle Overview")]),_c('h1',{staticClass:"header-title"},[_vm._v(" "+_vm._s(_vm.currentVehicle ? `${_vm.currentVehicle.brand} ${_vm.currentVehicle.name}` : '')+" ")])]),_c('div',{staticClass:"col-auto"})])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row pt-3 pb-1"},[_c('div',{staticClass:"col justify-self-center align-self-center flex-column"},[(_vm.partner_id)?_c('ul',{staticClass:"nav nav-pills"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowVehicle',
										props: {
											vehicleId: _vm.$route.params.vehicleId,
											partnerId: _vm.partner_id,
										},
									},"active-class":"active"}},[_vm._v("Vehicle overview")])],1),(_vm.partner_id)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowVehicleDocument',
										props: {
											vehicleId: _vm.$route.params.vehicleId,
											partnerId: _vm.partner_id,
										},
									},"active-class":"active"}},[_vm._v("Vehicle document")])],1):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowVehicleTripHistory',
										props: {
											vehicleId: _vm.$route.params.vehicleId,
											partnerId: _vm.partner_id,
										},
									},"active-class":"active"}},[_vm._v("Trip History")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowVehicleCustomerRatings',
										props: {
											vehicleId: _vm.$route.params.vehicleId,
											partnerId: _vm.partner_id,
										},
									},"active-class":"active"}},[_vm._v("Customer Ratings")])],1)]):_vm._e(),(!_vm.partner_id)?_c('ul',{staticClass:"nav nav-pills"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowVehicle',
										props: {
											vehicleId: _vm.$route.params.vehicleId,
										},
									},"active-class":"active"}},[_vm._v("Vehicle overview")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowVehicleTripHistory',
										props: {
											vehicleId: _vm.$route.params.vehicleId,
										},
									},"active-class":"active"}},[_vm._v("Trip History")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowVehicleCustomerRatings',
										props: {
											vehicleId: _vm.$route.params.vehicleId,
										},
									},"active-class":"active"}},[_vm._v("Customer Ratings")])],1)]):_vm._e()])])])]),(!_vm.loading)?_c('div',{staticClass:"container-fluid my-4 p-1"},[_c('p',[_c('router-link',{staticClass:"text-secondary font-weight-light",attrs:{"to":"/fleet/list/vehicle"}},[_vm._v("Vehicles"),_c('img',{attrs:{"src":require("@/assets/img/caret-right.svg"),"alt":""}})]),_c('router-link',{staticClass:"text-secondary font-weight-light",attrs:{"to":_vm.url}},[_vm._v(" "+_vm._s(_vm.currentVehicle ? `${_vm.currentVehicle.brand} ${_vm.currentVehicle.name}` : ''))]),_c('img',{attrs:{"src":require("@/assets/img/caret-right.svg"),"alt":""}}),_c('router-link',{staticClass:"text-dark font-weight-light",attrs:{"to":_vm.url}},[_vm._v(" "+_vm._s(_vm.currentVehicle ? _vm.currentVehicle.registration_number : ''))])],1)]):_vm._e(),_c('div',{},[_c('div',{staticClass:"container-fluid"},[(!_vm.loading)?_c('router-link',{staticClass:"btn btn-light mb-4 btn-sm",attrs:{"to":"/fleet/list/vehicle"}},[_vm._v("Go back")]):_vm._e(),_c('router-view')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center text-center p-4"},[_c('span',{staticClass:"fe fe-spinner"},[_vm._v("Loading..")])])
}]

export { render, staticRenderFns }