<template>
	<div class="container">
		<div class="row mt-5">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<form @submit.prevent="createAvailableTime">
							<h1 class="card-title">New Available Time</h1>
							<div class="mt-4">
								<div class="form-group">
									<label for="">Select Trip Start Time</label>
									<div>
										<vue-timepicker
											format="hh:mm A"
											hide-clear-button
											v-model="dto.departure_time"
											input-class="form-control"
											></vue-timepicker>
									</div>
								</div>
							</div>

							<div class="form-group">
								<span>Starting Bus Stop</span>
								<div>
									<VueSelect v-model="dto.pickup" label="name" :options="routeBusStops"></VueSelect>
								</div>
							</div>

							<div class="form-group" v-if="!isNewDRPEnabled">
								<div><span>Default Fare</span></div>
								<span class="text-muted"
									>This is the fare that will be used when no other specific bus
									stop fare is found for this itinerary</span
									>
								<input
									type="text"
									class="form-control"
									v-model.number="dto.default_fare"
									/>
							</div>

							<div class="form-group">
								<button
									:disabled="creatingAvailability"
									type="submit"
									class="btn btn-primary"
									>
									{{
										creatingAvailability
											? 'Creating..'
											: 'Create Available Time'
									}}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import VueSelect from 'vue-multiselect'
import FeatureFlagService, { FEATURE_FLAGS } from '@/utils/feature-flags'
export default {
  components: { VueTimepicker, VueSelect },
  props: {
    routeId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      loadingBusStops: false,
      creatingAvailability: false,
      routeBusStops: [],
      dto: {
        departure_time: null,
        time_of_day: null,
        default_fare: 0,
        pickup: null
      }
    }
  },
  mounted () {
    this.fetchBusStops()
  },
  methods: {
    async fetchBusStops () {
      if (!this.routeId) return
      this.$Progress.start()
      this.loadingBusStops = true
      try {
        const result = await this.axios.get(
          `/v1/routes/${this.routeId}/busstops`
        )
        this.routeBusStops = result.data.data
        this.loadingBusStops = false
        this.$Progress.finish()
      } catch (e) {
        
        let msg = 'Could not load route bus stops'

        if (e.response && e.response.message) {
          msg = e.response.message
        }
        this.$swal('Opps', msg, 'error')

        this.routeBusStops = []
        this.loadingBusStops = false
        this.$Progress.fail()
      }
    },
    async createAvailableTime () {
      if (this.creatingAvailability) return

      if (!this.dto.pickup || !this.dto.departure_time) return

      this.$Progress.start()
      this.creatingAvailability = true
      try {
        const result = await this.axios.post(
          `/v1/routes/${this.routeId}/itineraries`,
          {
            departure_time: `${this.dto.departure_time.hh}:${this.dto.departure_time.mm}`,
            time_of_day: this.dto.departure_time.A,
            default_fare: this.dto.default_fare,
            pickup_id: this.dto.pickup.id
          }
        )
        const newItinerary = result.data
        this.creatingAvailability = false
        this.$Progress.finish()

        this.$router.push({
          name: 'ShowRouteItinerary',
          params: {
            routeId: this.routeId,
            itineraryId: newItinerary.id
          }
        })
      } catch (e) {
        
        this.creatingAvailability = false

        let msg = 'Could not create itinerary at this time'

        if (e.response && e.response.message) {
          msg = e.response.message
        }
        this.$swal('Oops', msg, 'error')
        this.$Progress.fail()
      }
    }
  },
  computed: {
    isNewDRPEnabled () {
      return FeatureFlagService.isFlagEnabled(FEATURE_FLAGS.DRP)
    }
  }
}
</script>
