<template>
	<div class="py-5">
		<div class="text-center" v-if="containsData">Select an itinerary to view more details</div>
		<div class="text-center" v-else>No itinerary data available</div>
	</div>
</template>
<script>
export default {
  inject: ['hasItineraries'],
  computed: {
    containsData () {
      return this.hasItineraries()
    }
  }
}
</script>
