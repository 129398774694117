import axios from 'axios'
'use strict'

const localforage = require('localforage')

class UnAuthorizedAccessError {
  constructor (e) {
    this.e = e
  }
}

const config = {
  baseURL: process.env.VUE_APP_API_URL || '/',
  timeout: 60 * 1000 // Timeout
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    if (error && error.response && error.response.status == 401) {
      localforage.removeItem('session')
      removeAuthorization()
      return Promise.reject(new UnAuthorizedAccessError(error))
    }

    // Do something with response error
    return Promise.reject(error)
  }
)

Plugin.install = function (Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      }
    },
    $axios: {
      get () {
        return _axios
      }
    }
  })
}

export const setAuthorization = (token) => {
  _axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const removeAuthorization = () => {
  delete _axios.defaults.headers.common.Authorization
}

export default Plugin

export const axiosInstance = _axios
