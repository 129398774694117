var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"p-4 w-full"},[(!_vm.loadingRouteItinerary)?_c('div',{staticClass:"flex flex-col lg:flex-row gap-4"},[_c('div',{staticClass:"w-full md:max-w-[150px] lg:max-w-[200px] xl:max-w-[250px] px-0 shrink-0"},[(_setup.userHasPermission('routes003'))?_c('router-link',{staticClass:"btn mb-3 bg-shuttlers text-white",attrs:{"to":{ name: 'NewRouteItinerary', params: { routeId: _vm.routeId } }}},[_vm._v(" Add Availability Time ")]):_vm._e(),_c('div',{staticClass:"list-group"},_vm._l((_vm.itineraries),function(item,index){return (_setup.userHasPermission('routes007'))?_c('router-link',{key:item.id,staticClass:"list-group-item",attrs:{"to":{
						name: 'ShowRouteItinerary',
						params: {
							routeId: _vm.routeId,
							itineraryId: item.id,
						},
					},"exact-active-class":"active"}},[_vm._v(" "+_vm._s(item.trip_time)+" "),(_setup.userHasPermission('routes009'))?_c('button',{staticClass:"btn btn-sm font-medium float-right",class:_vm.$route.params.itineraryId === item.id ? 'text-white' : 'text-black',on:{"click":function($event){$event.preventDefault();return _vm.deleteRouteItinerary(item, index)}}},[_vm._v(" Delete ")]):_vm._e()]):_vm._e()}),1)],1),_c('div',{staticClass:"px-0 w-[100%] lg:w-[calc(100%-200px)]"},[_c('router-view')],1)]):[_vm._m(0)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}}),_c('p',{staticClass:"text-center mt-4"},[_c('strong',[_vm._v("Loading Itineraries...")])])])
}]

export { render, staticRenderFns }