import _ from 'lodash'

export default (store) =>
{
  return (to, from, next) =>
  {
    const whiteListPaths = ['/unauthorized', '/login']
    const whiteListNames = ['forgot-password', 'reset-password']

    if (store.getters.isAdminOrSuperAdminLoggedIn) {
      next()
      return
    }

    if (whiteListPaths.indexOf(to.path) > -1 || whiteListNames.indexOf(to.name) > -1) {
      next()
      return
    }

    const currentUserPermissions = store.getters.currentUserPermissionsList || []

    let hasRequiredPermission = true
    to.matched.forEach((record) =>
    {
      if (!Object.hasOwn(record.meta, 'requiredPermissions')) {
        hasRequiredPermission = hasRequiredPermission || true
        return true
      }

      if (!record.meta.requiredPermissions) {
        hasRequiredPermission = hasRequiredPermission || true
        return true
      }

      let requiredPermissions
      if (process.env.VUE_APP_USER_BY_PASS_ACL === 'true') {
        requiredPermissions = []
      } else {
        requiredPermissions = record.meta.requiredPermissions || []
      }
      for (const list of requiredPermissions) {
     
        const found = currentUserPermissions.includes(list)
        if (!found) {
          hasRequiredPermission = false
          break
        }
      }
    })

    if (hasRequiredPermission) {
      next()
    } else {
      next({
        name: 'unauthorized',
        query: { redirect: to.fullPath },
        replace: true
      })
    }
  }
}
