<template>
	<div class="main-content">
		<page-header title="Vehicle Partners" pre-title="Overview" :loading="false">
			<template slot="actions">
				<router-link v-if="!intrestedPartner && userHasPermission('partners001')" :to="{ name: 'NewVehiclePartner' }" class="btn btn-primary"
					>Add Partner </router-link
					>
			</template>
			<!-- <feature-flag-enabled feature-flag="VEHICLE_PARTNERS"> -->
			<template slot="tabs">
				<page-tabs :menu-tabs="menuTabs">
					<template v-slot="props">
						<router-link
							:to="{ name: props.menu.routeName }"
							class="nav-link"
							active-class="active"
							>
							{{ props.menu.label }}
						</router-link>
					</template>
				</page-tabs>
			</template>
			<!-- </feature-flag-enabled> -->
		</page-header>

		<page-route-view :loading="false"> </page-route-view>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
// import FeatureFlagEnabled from "@/components/layout/FeatureFlagEnabled";
import PageHeader from '@/components/layout/PageHeader'
import PageTabs from '@/components/layout/PageTabs'
import PageRouteView from '@/components/layout/PageRouteView'
export default {
  // components: {PageRouteView, PageTabs, PageHeader, FeatureFlagEnabled},
  components: { PageRouteView, PageTabs, PageHeader },
  data () {
    return {
      menuTabs: [
        {
          routeName: 'PartnersList',
          label: 'All partners'
        },
        {
          routeName: 'NewPartnerRequest',
          label: 'New partners'
        },
        {
          routeName: 'IntrestedPartnersList',
          label: 'Interested partners'
        }
      ]
    }
  },
  computed: {
    intrestedPartner () {
      return this.$route.name === 'IntrestedPartnersList'
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/nav-tabs';
</style>
