<template>
	<div class="main-content">
		<page-header title="Admin controls" pre-title="Overview" :loading="false">
			<template slot="tabs">
				<page-tabs :menu-tabs="menuTabs">
					<template v-slot="props">
						<router-link :to="{ name: props.menu.routeName }" class="nav-link" active-class="active">
							{{ props.menu.label }}
						</router-link>
					</template>
				</page-tabs>
			</template>
			<template slot="actions">
				<button v-if="userHasPermission('staff001') && getAdminType() !== 'user'" @click="$router.push({ name: 'AddStaff' })" class="btn btn-primary">
					Add Staff
				</button>
			</template>
		</page-header>

		<page-route-view :loading="false"> </page-route-view>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
import { getAdminType } from '@/composables/core/permissions/admin'
import PageHeader from '@/components/layout/PageHeader'
import PageTabs from '@/components/layout/PageTabs'
import PageRouteView from '@/components/layout/PageRouteView'

const menuTabsList = [
				{
					routeName: 'StaffList',
					label: 'Access',
					requiredSuperAdmin: false
				},
				{
					routeName: 'AuditTrailList',
					label: 'Audit Trail',
					requiredSuperAdmin: true
				}
]
const menuTabs = menuTabsList.filter((tab) => {
				if (tab.requiredSuperAdmin) {
					return getAdminType() === 'super_admin'
				}
				return true
})
</script>



<style lang="scss" scoped>
@import 'src/assets/scss/nav-tabs';
</style>
