<template>
  <div>
    <Sidebar :SidebarData='SidebarData' :signOutFunction='logout' :currentUser='currentUser' :route='route' />
  </div>
</template>

<script setup lang="ts">
// import { ShSidebar } from '@shuttlershq/shuttlers-ui'
import Sidebar from '@/components/layout/sidebar/SidebarComp.vue'
import { ADMIN_SIDEBAR_MENUS, getSidebarMenus, OPS_AND_MANAGEMENT_SIDEBAR_MENUS, TOP_LEVEL_SIDEBAR_MENUS, } from '@/utils/sidebar'
import { useStore } from '@/store'
import { currentUser } from '@/composables/core/permissions'
import { useRoute, useRouter } from 'vue-router/composables'
import { ref } from 'vue'

const router = useRouter()
const route = useRoute()
const store = useStore()
const permissionModules = store.getters.currentUserPermissionsModuleList
const topLayerMenus = ref(getSidebarMenus(TOP_LEVEL_SIDEBAR_MENUS, permissionModules, (currentUser.value.role || 'user')))
const featureMenus = ref(getSidebarMenus(OPS_AND_MANAGEMENT_SIDEBAR_MENUS, permissionModules, (currentUser.value.role || 'user')))
const adminMenus = ref(getSidebarMenus(ADMIN_SIDEBAR_MENUS.value, permissionModules, (currentUser.value.role || 'user')))

const SidebarData = {
  logoUrl: require('@/assets/img/icons/sidebar/shuttlers-logo.svg'),
  menuUrl: require('@/assets/img/icons/sidebar/toggle.svg'),
  menuList: [
    {
      title: '',
      menus: topLayerMenus
    },
    {
      title: 'Manage',
      menus: featureMenus
    },
    {
      title: 'Admin',
      menus: adminMenus
    }
  ]
}

const logout = async () =>
{
    store
        .dispatch('logout')
        .then(async () =>
        {
            await router.push('/login')
        })
        .catch(async () =>
        {
            await router.push('/login')
        })
}
</script>

<style scoped></style>