import { axiosInstance } from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    pendingCorporateInterest: null
  },
  getters: {
    pendingCorporateInterest: (state) => state.pendingCorporateInterest
  },
  mutations: {
    updateMapData (state, data) {
      state[data.key] = data.value
    }
  },
  actions: {
    async fetchPendingCorporateInterest ({ commit }) {
      try {
        const result = await axiosInstance.get('/v1/corporates/interest/list', {
          params: { metadata: true, status: 'pending', page: 1, limit: 1 }
        })
        if (result.data && result.data?.metadata) {
          commit('updateMapData', { key: 'pendingCorporateInterest', value: result.data.metadata.total })
        }
      } catch (e) {

      }
    }
  }
}
