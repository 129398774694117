<template>
	<div class="container-fluid py-4 flex flex-col gap-6">
		<div class="flex flex-col lg:flex-row items-start lg:justify-between gap-y-5 gap-x-7">
			<div class="flex flex-col gap-4 lg:flex-grow">
				<!-- <router-link to="/" class="btn bg-transparent text-black border !border-[black] shrink-0 w-fit">
					Go back
				</router-link> -->
				<button @click="$router.go(-1)" class="btn bg-transparent text-black border !border-[black] shrink-0 w-fit">
					Go back
				</button>
				<div class="flex flex-col gap-2 pb-2 border-bottom">
					<p class="head">Route Itinerary auction</p>
					<p class="sub">This shows information about the ongoing auction as vehicle partners bid for the route itinerary. </p>
				</div>
				<div class="flex flex-col gap-4">
					<p class="text-xs font-medium text-[#09090F]">Current winning bid</p>
					<div class="flex flex-col md:flex-row md:items-center gap-5 justify-between">
						<p class="text-sm text-[#6E717C]">The winning bid will be automatically allocated to the route once the auction duration has elapsed.</p>
						<button @click="openAssignModal" class="bg-black whitespace-nowrap rounded px-3 py-2 text-sm text-white border-none w-fit disabled:bg-[#ACAFAE]">
							Assign to route
						</button>
					</div>
					<div v-if="false" class="rounded bg-white border p-4 md:p-6 flex gap-4 flex-wrap items-center justify-between">
						<p class="text-sm text-[#101211] font-medium">Samuel Olubi</p>
						<div class="flex flex-col gap-[4px]">
							<p class="text-sm font-medium text-[#6E717C]">Daniel Yusuf</p>
							<p class="text-sm font-medium text-[#6E717C]">Daniel Yusuf</p>
						</div>
						<p class="text-sm font-medium text-[#101211]">₦20,500</p>
						<div class="flex flex-col gap-[4px] text-right">
							<p class="text-xs font-medium text-[#6E717C]">11:00 am</p>
							<p class="text-xs font-medium text-[#6E717C]">11-11-2023</p>
						</div>
					</div>
					<div v-else class="rounded bg-white px-4 flex flex-col">
						<div v-for="n in 2" :key="n" class="w-full flex items-center gap-3 py-4 border-bottom">
							<input type="checkbox" class="shrink-0">
							<div class="flex flex-grow flex-wrap items-center justify-between gap-4">
								<p class="text-sm text-[#101211] font-medium">Samuel Olubi</p>
								<div class="flex flex-col gap-[4px]">
									<p class="text-sm font-medium text-[#6E717C]">Daniel Yusuf</p>
									<p class="text-sm font-medium text-[#6E717C]">Daniel Yusuf</p>
								</div>
								<p class="text-sm font-medium text-[#101211]">₦20,500</p>
								<div class="flex flex-col gap-[4px] text-right">
									<p class="text-xs font-medium text-[#6E717C]">11:00 am</p>
									<p class="text-xs font-medium text-[#6E717C]">11-11-2023</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			<div class="bg-white border rounded p-4 flex flex-col gap-6 w-full max-w-[400px] lg:w-[400px] lg:shrink-0">
				<h3 class="text-lg font-bold text-[#444854]">Auction Information</h3>
				<div class="border"></div>
				<div class="flex items-center gap-4 justify-between">
					<p class="text-sm font-semibold text-[#101211]">Preferred Vehicle</p>
					<p class="text-[13px] font-medium text-[#313533]">Toyota Coaster 2022 (24 Seaters)</p>
				</div>
				<div class="flex items-center gap-4 justify-between">
					<p class="text-sm font-semibold text-[#101211]">Trip Start Date</p>
					<p class="text-[13px] font-medium text-[#313533]">Wed Oct 29 2023 - 11: 00AM</p>
				</div>
				<div class="flex items-center gap-4 justify-between">
					<p class="text-sm font-semibold text-[#101211]">Started at</p>
					<p class="text-[13px] font-medium text-[#313533]">Wed Oct 29 2023 - 11: 00AM</p>
				</div>
				<div class="flex items-center gap-4 justify-between">
					<p class="text-sm font-semibold text-[#101211]">Auction Duration</p>
					<div class="flex items-center justify-end gap-2">
						<p class="bg-[#EFF2F7] text-[#313533] font-medium rounded text-xs px-2 py-1">4 hours</p>
						<p class="text-base font-bold text-[#4848ED]">03h:23m left</p>
					</div>
				</div>
			</div>
		</div>

		<div class="flex flex-col bg-white">
			<div class="flex flex-col md:flex-row md:jusify-between gap-y-3 gap-x-4 py-2">
				<div class="w-full flex items-center gap-3 md:flex-grow p-2">
					<img class="shrink-0" src="@/assets/img/icons/search.svg" alt="">
					<input type="text" class="flex-grow border-none outline-none">
				</div>
				<div class="flex items-center gap-4">
					<div class="flex items-center gap-2">
						<label class="whitespace-nowrap">Sort by:</label>
						<select class="border rounded p-3">
							<option value="low_to_high">Bidding time</option>
							<option value="low_to_high">Lowest to highest</option>
							<option value="low_to_high">Highest to lowest</option>
						</select>
					</div>
					<button class="bg-black whitespace-nowrap rounded px-3 py-2 text-sm text-white border-none w-fit flex items-center gap-2">
						<img src="@/assets/img/icons/refresh.svg" alt="">
						Refresh
					</button>
				</div>
			</div>
			<div>
				<b-table striped hover :items="data" :fields="field" :current-page="currentPage" :busy="false" class='cursor-pointer'>
					<template #table-busy>
					<div class="my-2 text-center text-secondary">
						<strong>Loading...</strong>
					</div>
					</template>

					<template #cell(index)="data">
					{{ data.index }}
					</template>

					<!-- <template #cell(route_code)="data">
					<div class="d-flex">
						<div><span class="mr-2">{{ data.value }}</span></div>
					</div>
					</template> -->

					<template #cell(options)="data">
						<b-dropdown size="sm" variant="link" right toggle-class="text-decoration-none" :no-caret="true">
							<template #button-content>
								<i class="fe fe-more-horizontal"></i>
							</template>
							<b-dropdown-item class="text-sm font-medium text-[#000005]" @click.prevent="openAssignModal()">Assign to route</b-dropdown-item>
							<b-dropdown-item class="text-sm font-medium text-[#000005]" @click.prevent="doSomething(data.index)">Set as winning bid</b-dropdown-item>
						</b-dropdown>
					</template>
				</b-table>
				<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" aria-controls="my-table"></b-pagination>
			</div>
		</div>
		<b-modal ref="assignToRoute" id="assign-driver-from-auction" title="Assign to route itenerary" 
			no-close-on-esc no-close-on-backdrop @close="closeAssignModal()"
		>
			<div class="bg-[#F9FBFD] p-4 rounded-lg flex flex-col gap-4">
				<div class="flex items-center gap-4 justify-between">
					<p class="text-sm font-medium text-[#101211]">Samuel Olubi</p>
					<div class="flex flex-col gap-1 text-right">
						<p class="text-sm font-medium text-[#6E717C]">Daniel Yusuf</p>
						<p class="text-sm font-medium text-[#6E717C]">Sienna (AKD 748 HB) - 24 seats</p>
					</div>
				</div>
				<div class="w-full border"></div>
				<div class="flex items-center gap-4 justify-between">
					<p class="text-xs font-medium text-[#6E717C]">Cost of Supply</p>
					<p class="text-base font-medium text-[#101211]">₦20,500</p>
				</div>
			</div>
			<form class="flex flex-col gap-4 mt-4" @submit.prevent="doSomething('hello')">

				<div class="grid grid-cols-2 gap-4 ">
					<div class="flex flex-col gap-2">
						<label class="text-xs font-medium text-[#6E717C]">Seat Utilization</label>
						<input required type="number" class="p-3 text-sm outline-none border rounded text-[#313533]">
					</div>
					<div class="flex flex-col gap-2">
						<label class="text-xs font-medium text-[#6E717C]">Pricing Margin</label>
						<input required type="number" class="p-3 text-sm outline-none border rounded text-[#313533]">
					</div>
				</div>

				<div class="flex flex-col gap-2">
					<label class="text-xs font-medium text-[#6E717C]">Princing Margin Unit</label>
					<select required class="p-3 text-sm outline-none border rounded text-[#313533]">
						<option value="flat">Flat Amount</option>
					</select>
				</div>

				<p class="text-xs font-medium text-[#6E717C] mt-2">Assigning a Vehicle and Driver to this route itinerary will automatically end the auction.</p>
				<button type="submit" class="bg-black text-center text-white rounded-lg w-full p-3 disabled:cursor-not-allowed disabled:opacity-50">Assign</button>
			</form>
			<template #modal-footer>
				<button type="button" class="btn btn-secondary mr-3" data-dismiss="modal" ref="closeModalButton" @click="closeAssignModal()">
				Cancel
				</button>
			</template>
		</b-modal>
	</div>
</template>

<script setup>
import {ref} from 'vue'

const currentPage = ref(1)
const perPage = ref(10)
const totalRecords = ref(1)
const assignToRoute = ref(null)

const assign_route = {
	util: ref(0.7),
	margin: ref(20),
	margin_unit: ref('flat')
}

const field= [
	{ key: 'id', label: 'ID' },
	{ key: 'name', label: 'VEHICLE PARTNERS' },
	{ key: 'type', label: 'VEHICLE TYPE' },
	{ key: 'seat', label: 'NO OF SEATS' },
	{ key: 'driver', label: 'ASSIGNED DRIVER' },
	{ key: 'cost', label: 'COST OF SUPPLY' },
	{ key: 'time', label: 'TIME' },
	{ key: 'options', label: 'ACTION' }
]

const data = ref([
	{
		id: 1,
		name: 'Abubakri Sherif',
		type: 'Ferrari',
		seat: 4,
		driver: 'Driver name',
		cost: 500,
		time: '11:00 AM',
	},
	{
		id: 1,
		name: 'Abubakri Sherif',
		type: 'Ferrari',
		seat: 4,
		driver: 'Driver name',
		cost: 500,
		time: '11:00 AM',
	}
])

const doSomething = (val) => {
	alert(val)
}

const openAssignModal = () => {
	assignToRoute.value.show()
}

const closeAssignModal = () => {
	assignToRoute.value.hide()
}
</script>


<style scoped>
span,p,h1,h2,h3,h4,h5,h6,label {
	margin: 0 !important;
}

.head{
	font-size: 14px;
	color: #444854;
	font-weight: 500;
}

.sub{
	font-size: 12px;
	color: #6E717C;
	font-weight: 500;
}

.head_box{
	display: flex;
	flex-direction: column;
	gap: 4px;
}
</style>