// Import the functions you need from the SDKs you need
import { initializeApp } from '@firebase/app'
import { fetchAndActivate, getRemoteConfig, getValue } from '@firebase/remote-config'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

class FirebaseRemoteConfig {
  firebaseConfig = {
    apiKey: 'AIzaSyBqjcn9zyD_xRbYIIe9dGJN_qSEiKjcbNU',
    authDomain: 'shuttlers-admin-remote-config.firebaseapp.com',
    projectId: 'shuttlers-admin-remote-config',
    storageBucket: 'shuttlers-admin-remote-config.appspot.com',
    messagingSenderId: '350152049738',
    appId: '1:350152049738:web:f90dcb1b17886b68ded2b3'
  };

  app = null;

  constructor () {
  }

  async initialize () {
    const HOUR = 3600
    try {
      this.app = initializeApp(this.firebaseConfig)
      this.remoteConfig = getRemoteConfig()
      this.remoteConfig.settings.minimumFetchIntervalMillis = 1000 * (0.083 * HOUR) // 5 minutes
      await fetchAndActivate(this.remoteConfig)
    } catch (e) {

    }
  }

  getConfigurationValue (key) {
    const value = getValue(this.remoteConfig, key).asString()
    return value && value?.length ? JSON.parse(value) : null
  }
}

const RemoteConfigService = new FirebaseRemoteConfig()

export default RemoteConfigService
