<template>
	<b-toast :id="toastId" :variant="variant" header-class="hide" :body-class="toastBodyClass"  toaster="b-toaster-top-right" >
		<div class="content">
			<div :class="iconBgClass">
				<i :class="icon"></i>
			</div>
			<p class="m-0">{{ message }}</p>
		</div>
	</b-toast>
</template>

<script>
export default {
  name: 'CustomToast',
  props: {
    toastId: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    iconBgClass: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    toastBodyClass: {
        type: String,
        required: true
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" >
.content {
  display: flex;
  align-items: center;
  gap: 16px;

  & .icon-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;

    &.success {
      background-color: #0ea255;
    }

    & .check-icon {
      padding: 4.25px 8px;
    }
  }
}

.body-success {
    color: #0A0B0A !important;
    background-color: #F5FFFA !important;
    border-radius: 0.375rem;
    border: 0.6px solid rgba(14, 162, 85, 0.62) !important;
}

.hide {
  display: none;
}

.wrap {
    position: absolute;
    top: 0px
}

.toast {
    position: fixed; 
    right: -1rem;
    top: 3.25rem;
    bottom: unset !important;
    z-index: 1030;
    transform: scale(.8);
}

.toast-body {
    padding:  .425rem 1rem;
}

@media(min-width: 992px) {
    .toast {
        right: 1rem;
        transform: scale(1);
        top: 4.75rem;
    }
}


</style>
