
<template>
	<div class="container">
		<div
			class="row justify-content-center align-content-center"
			style="height: 100vh">
			<div class="col-md-6 col-sm-8 col-xs-12 col-lg-4">
				<div class="card">
					<div class="card-body">
						<div class="title mb-4">
							<img src="@/assets/logo.png" />
						</div>
						<template v-if="!linkSent">
							<form class="mt-4">
								<div class="mt-3 text-center">
									<h2 class="mb-1">Forgot your password?</h2>
									<small>Don't worry, it happens to the best of us.</small>
								</div>
								<div class="form-group" v-if="errorMessage">
									<div class="alert alert-danger">
										<span>{{ errorMessage }}</span>
									</div>
								</div>
								<div class="form-group mt-4">
									<label for="email" class="sr-only">Email Address</label>
									<input
										v-model.trim="$v.form.email.$model"
										:class="{
											'is-invalid':
												$v.form.email.$dirty && $v.form.email.$error,
										}"
										id="email"
										name="email"
										type="email"
										placeholder="Email Address"
										class="form-control"
										/>
									<div
										class="invalid-feedback"
										v-if="$v.form.email.$dirty && !$v.form.email.$required"
										>
										Email is required.
									</div>
									<div class="invalid-feedback" v-if="!$v.form.email.$email">
										A Valid Email is required.
									</div>
								</div>

								<div class="form-group">
									<button
										id="login-btn"
										:disabled="processing"
										class="btn btn-primary btn-block"
										type="submit"
										@click="sendPasswordResetEmail">
										<span v-if="!processing">Recover Password</span>
										<span v-else>
											<i class="fa fa-spin fa-spinner"></i>
										</span>
									</button>
								</div>

								<div class="text-center">
									<router-link to="/login" class="small text-muted">
										Back to Login
									</router-link>
								</div>
							</form>
						</template>

						<template v-else>
							<div class="text-center">
								<h2 class="mt-2">Check your email</h2>
								<p class="mt-1">
									Check your email for password reset instructions
								</p>
								<router-link to="/" class=""
									><button class="btn btn-primary btn-block">
										Back to login
									</button></router-link
									>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      linkSent: false,
      errorMessage: '',
      form: {
        email: '',
        type: 'staff'
      },
      processing: false
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    sendPasswordResetEmail () {
      this.$v.form.$touch()

      if (this.processing || this.$v.form.$error) {
        return
      }

      this.processing = true
      this.errorMessage = null

      this.axios
        .post('/v1/password', { ...this.form })
        .then(async () => {
          this.linkSent = true
        })
        .catch((err) => {
          this.linkSent = false

          if (err.response && err.response.data.message) {
            this.errorMessage = err.response.data.message
          } else {
            this.errorMessage = 'Oops! An error occurred. Please try again.'
          }
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
