<template>
	<div>
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div class="bg-white border-bottom" v-else>
			<div class="header mb-0">
				<div class="container-fluid">
					<div class="header-body py-0 mt-4 border-0">
						<div class="row align-items-end">
							<div class="col">
								<h6 class="header-pretitle">Driver Overview</h6>
								<h1 class="header-title">
									{{
										currentDriver
											? `${currentDriver.fname} ${currentDriver.lname}`
											: ''
									}}
								</h1>
							</div>
							<div class="col-auto">
								<!-- <a href="#!" class="btn btn-primary">Create Report</a> -->
								<!-- <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Action
                  </button>
                  <div class="dropdown-menu dropdown-menu-right mt-3">
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="handleDriverSuspension(currentDriver)"
                    >
                      {{currentDriver?.active == "0" ? 'Unsuspend' : 'Suspend'}} driver
                    </button>
                  </div>
                </div> -->
							</div>
						</div>
						<!-- / .row -->
					</div>
					<!-- / .header-body -->
				</div>
			</div>
			<div class="container-fluid">
				<div class="row pt-3 pb-1">
					<div class="col justify-self-center align-self-center flex-column">
						<ul class="nav nav-pills">
							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShowDriver',
										props: { driverId: $route.params.driverId },
									}"
									class="nav-link"
									active-class="active"
									>Driver Info</router-link
									>
							</li>
							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShowDriverCustomerRatings',
										props: { driverId: $route.params.driverId },
									}"
									class="nav-link"
									active-class="active"
									>Customer Ratings</router-link
									>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="pt-4">
			<div class="container-fluid">
				<router-link
					to="/drivers"
					class="btn btn-light mb-4 btn-sm"
					v-if="!loading"
					>Go back</router-link
					>
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import { extractErrorMessage } from '@/utils/helpers'
import Swal from 'sweetalert2'
export default {
  props: {
    driverId: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    return {
      loading: false,
      currentDriver: null
    }
  },
  mounted () {
    this.fetchDriver(this.driverId)
  },
  methods: {
    async fetchDriver (driverId) {
      if (this.loading) return

      try {
        this.loading = true
        const result = await this.axios.get(`/v1/drivers/${driverId}`)
        this.currentDriver = result.data
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    handleDriverSuspension (currentDriver) {
      this.$swal({
        icon: 'question',
        title: `${currentDriver.active == '0' ? 'Unsuspend' : 'Suspend'} driver ?`,
        text: 'Are you sure you want to continue?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .post(`/driver/suspend${this.driverId}`)
            .then(() => {
              this.fetchDriver(this.driverId)
            })
            .catch((error) => {
              const msg = extractErrorMessage(
                error,
                `An error occurred. Failed to ${currentDriver.active == '0' ? 'unsuspend' : 'suspend'} driver`
              )
              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: `Driver ${currentDriver.active == '0' ? 'unsuspend' : 'suspended'}`,
            text: `You have successfully ${currentDriver.active == '0' ? 'deacctivated' : 'activated'} this driver.`,
            showCloseButton: true
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}

.VueTables__search-field > label {
  display: none;
}

.VueTables__search-field {
  margin-top: 20px;
}
</style>
