<template>
	<b-modal
		centered
		hide-footer
		hide-header
		class="modal fade"
		id="event-route-modal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="eventRouteLabel"
		aria-hidden="true"
		@hidden="resetForm"
		>
		<div class="modal-header">
			<h4 class="modal-title">Assign routes</h4>
			<button
				type="button"
				class="close"
				@click="close"
				aria-label="Close"
				data-dismiss="modal"
				>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<!-- <div
				class="alert alert-danger alert-dismissible fade show"
				role="alert"
				v-if="errorMessage"
			>
				{{ errorMessage }}
			</div> -->
			<form>
				<div class="row">
					<div class="col-12">
						<div class="mb-4 selected">
							<p class="text-sm" v-if="!form.route.length">No routes assigned to this event yet</p>

							<div class="routes" v-for="route in form.route" :key="route.id">
								<div class="route-box">
									<p class="route-options text-sm">
										<span v-if="route.route_code">{{
											`${route.route_code} - `
										}}</span>
										{{ `${route.pickup} to ${route.destination}` }}
									</p>
									<i
										class="fe fe-x"
										style="font-size: 24px; color: #E13D45"
										@click="removeRouteFromSelectedList(route.id)"
										></i>
								</div>
							</div>
						</div>

						<label class="form-label">Select Route</label>
						<v-select
							v-model="form.route"
							class="form-group routes-select"
							:options="allRoutes"
							label="pickup"
              :filterable="false"
							placeholder="Select a route to assign"
							@search="searchRoutes"
							multiple
							>
							<!-- attach an event to the select... to append the selected route to the selectedRoutes Array -->

							<template v-slot:option="route">
								<p class="route-options text-sm">
									<span v-if="route.route_code">{{
										`${route.route_code} - `
									}}</span>
									{{ `${route.pickup} to ${route.destination}` }}
								</p>
							</template>
							<template v-if="isLoadingGetRoutes"  v-slot:no-options>
								loading...
							</template>
							<!-- <template v-slot:selected-option="route">
                <span v-if="route.route_code">{{
                  `${route.route_code} - `
                }}</span>
                {{ `${route.pickup} to ${route.destination}` }}
              </template> -->
						</v-select>
					</div>
				</div>
			</form>
		</div>
		<div class="modal-footer">
			<button
				type="button"
				class="btn btn-primary"
				@click="assignRoutes"
				:disabled="loading || !formValid"
				>
				{{ loading ?  'Processing...' : 'Proceed' }}
			</button>
		</div>
	</b-modal>
</template>

<script>
import ModalMixin from '@/mixins/modal'

export default {
  props: {
    allRoutes: {type: Array, default: () => []},
    loading: {type: Boolean, default: false},
    isLoadingGetRoutes: {type: Boolean, default: false},
    eventRoutes: {type: Array, default: () => []}
  },
  mixins: [ModalMixin],
  data() {
    return {
      form: {
        route: [],
      }
    }
  },
  emits: ['onAssignRoutes', 'searchRoutes'],
  computed: {
    formValid() {
      return this.form.route.length
    }
  },
  methods: {
    searchRoutes(data) {
      this.$emit('searchRoutes', data)
    },

    close() {
      this.$bvModal.hide('event-route-modal')
    },

    resetForm() {
      this.form.route = []
    },

    assignRoutes() {
      if (!this.form.route) return

      let routes = this.form.route.map((route) => {
        const routeExists = this.$props.eventRoutes.some((existingRoute) => existingRoute.routeId === route.id)

        if (routeExists) {
          return
        } else {
          return {
            id: route.id,
            routeCode: route.route_code,
            pickup: route.pickup,
            dropoff: route.destination,
          }
        }
    }).filter((val) => val !== undefined)

      routes = [...this.$props.eventRoutes.map((route) => (
        {
          id: route.routeId,
          routeCode: route.routeCode,
          pickup: route.pickup,
          dropoff: route.dropoff,
        }
      )), ...routes]

      const payload = {
        routes,
      }

      this.$emit('onAssignRoutes', payload)
    },

    removeRouteFromSelectedList(id) {
      const routes = this.form.route.filter((r) => r.id !== id)
      this.form.route = [...routes]
    }
  },
}
</script>

<style lang="scss">
.icon:hover {
  opacity: 50%;
}

.routes {
  width: 100%;
}
.route-box {
  width: 100%;
  background: #f4f5f4;
  border-radius: 12px;
  display: flex;
  padding: 8px;
  /* padding-bottom: 0px; */
  // margin: 5px 3px;

  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  font-size: 14px;

  p {
    margin: 0px;
    padding: 0px;
  }
}
.selected {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.text-sm {
  font-size: 14px;
}

.route-options {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #444854
}

.routes-select {
  & .vs__dropdown-toggle  {
    border-radius: 6px !important;
    border: 0.6px solid #98A2B3 !important;
  }

  & .vs__search {
    margin: 0px !important;
    height: 50px !important;
  }

  & .vs__selected {
    display: none !important;
  }
}
</style>
