import {ref, computed, watch} from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import Swal from 'sweetalert2'
import { useFetchConfiguredCharges } from './fetch'

const loading = ref(false)
const password = ref('')
const id = ref(null)
const { fetchConfiguredCharges } = useFetchConfiguredCharges()
const showActivateChargeModal = ref(false)
const showPassword = ref(false)

const openActivateChargeModal = (_id) => {
	id.value = _id
	showActivateChargeModal.value = true
}
const closeActivateChargeModal = () => {
	password.value = ''
	showActivateChargeModal.value = false
}

export const useActivateConfiguration = () => {
	const activateConfiguredCharge = async () => {
		loading.value = true
		const payload = {
			activate: true,
			password: password.value
		}
		try {
			const res = await axios.post(`/v1/additional-charges/${id.value}/routes`, payload)
			if(res.data?.is_active) {
				Swal.fire({ icon: 'success', title: 'Success', text: 'Activated successfully'})
				closeActivateChargeModal()
				fetchConfiguredCharges()
			} else {
				Swal.fire({ icon: 'error', title: 'Error', text: res.data?.message ?? 'Could not activate configuration'})
			}
		} catch (err) {
			Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message ?? 'An error occured'})
		} finally{
			loading.value = false
		}
	}


	return {  loading, activateConfiguredCharge, password, showPassword, showActivateChargeModal, openActivateChargeModal, closeActivateChargeModal }
}