module.exports = {
  methods: {
    async fetchBusStops (routeId) {
      const result = await this.axios.get(
        `/v1/routes/${routeId}/busstops`
      )
      return result.data
    },
    saveBusStopOrder (routeId, changes) {
      return this.axios.patch(`/v1/routes/${routeId}/busstops`, {
        bus_stops: changes
      })
    },
    attemptDeleteRouteBusStop (routeBusStop) {
      return this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          return this.handleDeleteRouteBusStop(routeBusStop).catch((err) => {
            this.$swal(
              'Could not delete route bus stop',
              err.toString(),
              'info'
            )
            return Promise.reject(false)
          })
        }
        return Promise.reject(false)
      })
    },
    async handleDeleteRouteBusStop (r) {
      await this.axios.delete(`/v1/route-busstops/${r.id}`)
    },
    pickUpCordinatesForRoute (route) {
      if (!route.pickup_coordinate) return null

      const parts = route.pickup_coordinate
        .split(',')
        .map((i) => parseFloat(i.trim()))

      if (!parts) return null

      return {
        lat: parts[0],
        lng: parts[1]
      }
    },
    destinationCordinatesForRoute (route) {
      if (!route.destination_coordinate) return null

      const parts = route.destination_coordinate
        .split(',')
        .map((i) => parseFloat(i.trim()))

      if (!parts) return null

      return {
        lat: parts[0],
        lng: parts[1]
      }
    }
  }
}
