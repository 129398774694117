<template>
	<div>
		<b-modal
			:id="modalId"
			centered
			no-close-on-backdrop
			no-close-on-esc
			hide-header
			footer-class="border-top-0"
			>
			<section class="d-flex flex-column align-items-center">
				<div class="circle-ripple">
					<i class="fe fe-alert-circle text-warn" style="font-size: 24px"></i>
				</div>
				<p class="mt-4 mb-0 title-text">Clear point</p>
				<p class="mt-2 mb-0 description-text">
					Are you sure you want to clear this events point?
				</p>
			</section>
			<template #modal-footer>
				<div class="btns">
					<button
						class="btn cancel"
						@click="$bvModal.hide('clear-point-modal')"
						>
						Cancel
					</button>
					<button
						:disabled="loading"
						class="btn proceed btn-primary"
						@click="openClearedModal"
						>
						{{ loading ? 'Processing...' : 'Proceed' }}
					</button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
export default {
  name: 'ClearPointModal',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modalId: 'clear-point-modal'
    }
  },
  methods: {
    openClearedModal () {
      this.$bvModal.hide('clear-point-modal')
      this.$bvModal.show('clear-point-success-modal')
    }
  },
  emits: ['onAcceptEvent']
}
</script>

<style lang="scss" scoped>
$caution-icon-bg: #fef0c7;

.hidden {
  display: none !important;
}
.title-text {
  color: #101828;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.description-text {
  color: #667085;
  font-size: 14px;
  line-height: 20px;
}

.text-warn {
  display: block;
  text-align: center;
  color: #dc6803;
}

.btns {
  width: 100%;
  display: flex;
  gap: 20px;

  .btn {
    height: 44px;

    &.cancel {
      flex: 1;
      border: 1px solid#D0D5DD;
    }
    &.proceed {
      flex: 1;
    }
  }
}

.circle-ripple {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $caution-icon-bg;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  animation: ripple 0.7s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($caution-icon-bg, 0.3),
      0 0 0 1em rgba($caution-icon-bg, 0);
  }
  100% {
    box-shadow: 0 0 0 1em rgba($caution-icon-bg, 0.3),
      0 0 0 3em rgba($caution-icon-bg, 0);
  }
}
</style>
