<template>
	<div class="main-content">
		<page-header
			title="Reward History"
			pre-title="Overview"
			:loading="false">

			<!-- <template slot="tabs">
				<page-tabs-vue :menu-tabs="menuTabs">
					<template v-slot="props">
						<router-link
							:to="{ name: props.menu.routeName}"
							class="nav-link"
							active-class="active">
							{{ props.menu.label }}
						</router-link>
					</template>
				</page-tabs-vue>
			</template> -->
		</page-header>
		<page-route-view
			:loading="false">
		</page-route-view>
	</div>
</template>

<script>
import PageHeader from '@/components/layout/PageHeader.vue'
import PageRouteView from '@/components/layout/PageRouteView.vue'
import PageTabsVue from '@/components/layout/PageTabs.vue'
export default {
  name: 'EventsListIndex',
  components: { PageTabsVue, PageRouteView, PageHeader },
  data () {
    return {
      menuTabs: [
        {
          routeName: 'EventList',
          label: 'Event Requests'
        },
        {
          routeName: 'UpcomingEvents', // list of approved events
          label: 'Upcoming Events'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/_nav-tabs.scss';

</style>
