import { io } from 'socket.io-client'

export class SocketIOController {
  connectionErrorCallbacks = []
  connectedCallbacks = []
  pendingSubscriptions = {};
  constructor (
    uri = process.env.VUE_APP_WS_BASE_URL,
    path = process.env.VUE_APP_WS_SOCKET_PATH || '/socket.io',
    options = {}
  ) {
    this.uri = uri
    this.path = path
    this.options = options
    this.init()
  }

  init () {
    this.io = io(this.uri, {
      ...this.options,
      transports: ['websocket', 'polling'],
      path: this.path,
      autoConnect: false
    })
  }

  setAuthToken (token) {
    this.token = token
    if (this.io.connected) {
      this.io.disconnect()
    }
    this.options = {
      ...this.options,
      auth: { token: token },
      query: { token: token }
    }
    this.init()
    this.boot()
  }

  onConnectionError (callback) {
    this.onConnectionErrorCallbacks.push(callback)
  }

  onConnected (callback) {
    this.connectedCallbacks.push(callback)
    if (this.io.connected) {
      callback()
    }
  }

  off (eventName) {
    if (this.pendingSubscriptions[eventName]) {
      delete this.pendingSubscriptions[eventName]
    }
    this.io.off(eventName)
  }

  emit (eventName, data) {
    this.io.emit(eventName, data)
  }

  async joinRoom (roomName) {
    return new Promise((resolve, reject) => {
      this.io.emit('join-rooms', [roomName], (result) => {

        resolve(result)
      })
    })
  }

  on (eventName, callback) {
    this.pendingSubscriptions[eventName] = callback
    if (!this.io.connected) {
      return
    }
    this.io.on(eventName, callback)
  }

  close () {
    if (!this.io.connected) {
      return
    }
    this.io.disconnect()
  }

  reset () {
    this.close()
    this.onConnectionErrorCallbacks = []
    this.pendingSubscriptions = {}
    this.connectedCallbacks = []
    this.token = ''
    this.options = {}
  }

  boot () {
    if (this.io.connected) {
      return
    }

    this.io.connect()

    this.io.on('connect_error', (err) => {
      this.connectionErrorCallbacks.forEach((item) => {
        item(err)
      })
    })

    this.io.on('connect', () => {

      this.connectedCallbacks.forEach((item) => {
        item()
      })

      Object.keys(this.pendingSubscriptions).forEach((evt) => {
        this.io.on(evt, this.pendingSubscriptions[evt])
      })
    })

    if (!this.io.connected) {
      this.io.connect()
    }
  }
}

export default new SocketIOController()
