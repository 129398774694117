import { loadRouteComponent } from '@/utils/helpers'
import Users from '@/layouts/modules/Users/Index.vue'
import UserDetails from '@/layouts/modules/Users/UserDetails.vue'
const UserRoutes = [
  {
    path: 'users',
    component: Users,
    children: [
      {
        path: '',
        name: 'Users',
        redirect: {
          name: 'UsersList'
        }
      },
      {
        path: 'insights',
        component: loadRouteComponent('Users/pages/Insights'),
        name: 'UsersInsight',
        props: true,
        meta: {
          requiredPermissions: ['users002']
        }
      },
      {
        path: 'list',
        component: loadRouteComponent('Users/pages/List'),
        name: 'UsersList',
        props: true,
        meta: {
          requiredPermissions: ['users002']
        }
      },
      {
        path: 'notify',
        component: loadRouteComponent('Users/pages/Notify'),
        name: 'NotifyUser',
        props: true,
        meta: {
          requiredPermissions: ['users013']
        }
      },
      {
        path: 'add/:userId?',
        component: loadRouteComponent('Users/pages/Manage'),
        name: 'ManageUser',
        props: true,
        meta: {
          requiredPermissions: ['users001']
        }
      },
      {
        path: ':userId',
        component: UserDetails,
        props: true,
        children: [
          {
            path: 'user-info',
            name: 'ShowUser',
            component: loadRouteComponent('Users/pages/Show/UserInfo'),
            props: true,
            meta: {
              requiredPermissions: ['users003']
            }
          },
          {
            path: 'credit-line-usages',
            name: 'ShowACompanyStaffCreditLineUsages',
            component: loadRouteComponent('Users/pages/Show/CreditLineUsages'),
            props: true,
            meta: {
              requiredPermissions: [ 'users003', ]
            }
          },
          {
            path: 'rides',
            name: 'UserRides',
            component: loadRouteComponent('Users/pages/Show/UserRideHistory'),
            props: true,
            meta: {
              requiredPermissions: [ 'users003', ]
            }
          },
          {
            path: 'transactions',
            name: 'UserTransactions',
            component: loadRouteComponent('Users/pages/Show/UserTransactions'),
            props: true,
            meta: {
              requiredPermissions: [ 'users003', ]
            }
          },
          {
            path: 'active-bookings',
            name: 'UserActiveBookings',
            component: loadRouteComponent('Users/pages/Show/ActiveBookings'),
            props: true,
            meta: {
              requiredPermissions: [ 'users003', ]
            }
          },
          {
            path: 'past-bookings',
            name: 'UserPastBookings',
            component: loadRouteComponent('Users/pages/Show/PastBookings'),
            props: true,
            meta: {
              requiredPermissions: [ 'users003', ]
            }
          },
          {
            path: 'cancelled-bookings',
            name: 'UserCancelledBookings',
            component: loadRouteComponent('Users/pages/Show/CancelledBookings'),
            props: true,
            meta: {
              requiredPermissions: [ 'users003', ]
            }
          }
        ]
      },
      {
        path: ':bookingId/booking-ticket/:scheduleId/active',
        name: 'ActiveBookingTicket',
        component: loadRouteComponent('Users/pages/Show/ActiveBookingTicket'),
        props: true,
        meta: {
          requiredPermissions: ['users003']
        }
      },
      {
        path: ':bookingId/booking-ticket/:scheduleId/past',
        name: 'PastBookingTicket',
        component: loadRouteComponent('Users/pages/Show/PastBookingTicket'),
        props: true,
        meta: {
          requiredPermissions: ['users003']
        }
      },
      {
        path: ':bookingId/booking-ticket/:scheduleId/cancelled',
        name: 'CancelledBookingTicket',
        component: loadRouteComponent('Users/pages/Show/CancelledBookingTicket'),
        props: true,
        meta: {
          requiredPermissions: ['users003']
        }
      }
    ]
  }
]

export default UserRoutes
