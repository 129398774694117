<template>
	<div class="main-content cont">
		<!-- {{ eventStatus }} -->
		<page-header
			:loading="loading"
			:title="loading ? '' : eventDescription"
			pre-title="Overview"
			v-if="renderHeader"
			>
			<template slot="tabs" v-if="!loading && !eventStatus">
				<div></div>
			</template>
			<template slot="tabs" v-else-if="!loading && isAccepted">
				<page-tabs-vue :menu-tabs="menuTabs">
					<template v-slot="props">
						<router-link
							:to="{ name: props.menu.routeName }"
							class="nav-link"
							active-class="active"
							>
							{{ props.menu.label }}
						</router-link>
					</template>
				</page-tabs-vue>
			</template>
			<template slot="tabs" v-else-if="!loading && !isAccepted">
				<div>
					<b-breadcrumb>
						<b-breadcrumb-item href="#/events/list">Events</b-breadcrumb-item>
						<i class="px-2 inactive fe fe-chevron-right"></i>
						<b-breadcrumb-item active-class="active" active
							>Event information</b-breadcrumb-item
							>
					</b-breadcrumb>
				</div>
			</template>
      
			<template slot="actions">
				<div class="dropdown">
					<button
						class="btn btn-primary dropdown-toggle"
						type="button"
						id="dropdownMenuButton"
						data-toggle="dropdown"
						aria-expanded="false"
						:disabled="isActionButtonDisabled"
						>
						Actions
					</button>
					<div
						v-if="eventStatus === 'accepted'"
						class="dropdown-menu dropdown-menu-right mt-3"
						aria-labelledby="dropdownMenuButton"
						>
						<a
							class="dropdown-item btn btn-primary"
							@click="showModal('notify-attendees-modal')"
							>Notify Attendee
            </a>
					</div>
				</div>
			</template>
		</page-header>
		<page-route-view
			:loading="loading"
			:error-loading="errorLoadingData"
			:error-text="'Failed to load event details'"
			:bus="bus"
			@update:eventStatus="updateEventStatus"
			@update:attendeesNotificationLoading="updateAttendeesNotificationLoading"
			>
		</page-route-view>
		<notify-attendee-modal 
			:loading="attendeesNotificationLoading"
      modal-type="attendee"
			@onNotifyAttendees="notifyAttendees"
			>
		</notify-attendee-modal>
		<custom-toast
			v-for="(toast, index) in toastData" :key="index"
			:toastId="toast.toastId"
			:variant="toast.variant"
			:icon="toast.icon"
			:iconBgClass="toast.iconBgClass"
			:message="toast.message"
			:toastBodyClass="toast.toastBodyClass"
			></custom-toast>
	</div>
</template>
<script>
import Vue from 'vue'
import PageHeader from '@/components/layout/PageHeader.vue'
import PageTabsVue from '@/components/layout/PageTabs.vue'
import PageRouteView from '@/components/layout/PageRouteView.vue'
import NotifyAttendeeModal from '@/views/Events/components/modals/NotifyAttendeeModal.vue'
import CustomToast from '@/components/core/CustomToast.vue'
export default {
  components: {
    PageRouteView,
    PageHeader,
    PageTabsVue,
    NotifyAttendeeModal,
    CustomToast
  },
  data () {
    return {
      warning: false,
      event: {},
      loading: false,
      errorLoadingData: false, // error state if api request fails to fetch event information
      showEventRoute: false,
      rejectionLoading: false,
      acceptanceLoading: false,
      attendeesNotificationLoading: false,
      queryLoading: false,
      routeAssignmentLoader: false,
      eventStatus: '',
      allRoutes: [],
      bus: new Vue(),
      toastData: [
        {
          toastId: 'attendee-route-notification',
          variant: 'success',
          icon: 'fe fe-check check-icon',
          iconBgClass: 'icon-bg success',
          message: 'Successfully sent notfication.',
          toastBodyClass: 'body-success'
        }
      ],
      menuTabs: [
        {
          routeName:'ShowEventInfo',
          label: 'Event information'
        },
        {
          routeName:'ShowAttendeeList',
          label: 'Attendees list'
        }
      ],
    }
  },
  computed: {
    eventDescription () {
      // this will return a dynamic value based one api model property and can be tweaked as preferred
      return 'Event Request'
    },

    renderHeader () {
      const notAllowedRoutes = ['ShowAcceptedEvent']

      return !notAllowedRoutes.includes(this.$route?.name)
    },

    isActionButtonDisabled() {
      return ['', 'rejected', 'queried'].includes(this.eventStatus)
    },

    isAccepted() {
      if (['accepted'].includes(this.eventStatus)) {
        return true
      } 
      return false
    }
  },
  emits: ['notifyAttendees'],
  methods: {
    showModal (id) {
      this.$bvModal.show(id)
    },
    hideModal (id) {
      this.$bvModal.hide(id)
    },
    updateEventStatus(status) {
      this.eventStatus = status.toString()
    },
    updateAttendeesNotificationLoading(value) {
      this.attendeesNotificationLoading = value
    },
    notifyAttendees() {
      this.bus.$emit('notifyAttendees')
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/_nav-tabs.scss';

.cont {
  position: relative;
}

.cancel {
  flex: 1;

  outline: none;
  background: #fff;
  border: 1px solid #d0d5dd;
  color: #344054;
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
}

.proceed {
  flex: 1;

  outline: none;
  background: #101211;
  border: none;
  color: #fff;
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.content {
  font-size: 14px;
  color: #667085;
  text-align: center;
}

.inactive {
  color: #6e84a3;
}

.breadcrumb-item {
  & a {
    color: #6e84a3;

    &:hover {
      color: #09090f;
    }
  }

  &.active {
    font-weight: 500;
    color: #09090f;
  }
}
</style>
