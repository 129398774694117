<template>
	<div v-if="options && options.length">
		<b-dropdown
			size="sm"
			variant="link"
			class="position-relative text-center w-100"
			:toggle-class="'text-decoration-none'"
			:disabled="loading"
			right
			:no-caret="true">
			<template #button-content>
				<slot name="selector"></slot>
				<div class="sh-dropdown-btn" v-if="!isSelectorTemplateAvailable">
					<template v-if="!loading">
						<label class="sh-dropdown-btn__label">
							<slot name="label"></slot>
						</label>
						<i v-if="options.length && showCaret" class="fe fe-chevron-down"></i>
					</template>
					<div class="d-flex align-items-center" v-else>
						<div class="spinner-border spinner" role="status"></div>
						<p class="text-center sh-dropdown-btn__label"><span>{{ loadingText }}</span></p>
					</div>
				</div>
			</template>
			<template v-if="options">
				<b-dropdown-item v-for="(option, index) in options" :key="index" @click="$emit('item-selected', option)">
					<slot name="option" v-bind="getOptionValue(option)"></slot>
				</b-dropdown-item>
			</template>
		</b-dropdown>
	</div>
</template>

<script>
export default {
  name: 'FilterButton',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array,
      required: false
    },
    showCaret: {
      type: Boolean,
      required: false,
      default: true
    },
    loadingText: { type: String, default: 'Loading', required: false }
  },
  computed: {
    isSelectorTemplateAvailable () {
      return !!this.$slots.selector
    }
  },
  methods: {
    getOptionValue (option) {
      return typeof option === 'object' ? option : { value: option }
    }
  }
}
</script>

<style lang="scss" scoped>
.sh-dropdown-btn {
  padding: 0.5rem .25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &__icon {
    height: 0.75rem;
    width: 0.875rem;
  }

  &__label {
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    margin: 0;
    padding: 0 .25rem;
  }
}

::v-deep .b-dropdown {
  outline: none;
  & .dropdown-toggle {
    box-shadow: none;
  }
}

.spinner {
  width: 1rem;
  margin-right: 5px;
  height: 1rem;
  border-width: 0.15rem;
}
</style>
