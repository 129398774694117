import { axiosInstance } from '@/plugins/axios'

export class RouteBusstopService {
  constructor (axios) {
    this.axios = axios
  }

  /**
   * Syncs model with UI change based on positional changes persisted
   *
   * @param stops {Array}
   * @param updatedBusStops {{id: number, currentPosition: number, position: number}[]}
   * @return {Array}
   * @returns Array of updated bus stops
   * */
  updateAllChangedBusStopsData (stops, updatedBusStops) {
    const deepCopy = (data) => {
      return data ? JSON.parse(JSON.stringify(data)) : data
    }
    const busStopsCopy = deepCopy(stops)
    const newStops = deepCopy(stops)
    updatedBusStops.forEach((item) => {
      const index = busStopsCopy.findIndex(
        (o) => o.position === item.currentPosition
      )
      if (index > -1) {
        newStops[index] = this._updateStopPosition(
          stops,
          busStopsCopy[index],
          item.position
        )
      }
    })
    return newStops
  }

  /**
   * Updates bus stop position after change
   * @param busStop {Object}
   * @param stops {Array}
   * @param newPosition {number}
   * @returns updated bus stop with new position data
   * */
  _updateStopPosition (stops, busStop, newPosition) {
    const object = { position: newPosition }
    const isFirstStop = newPosition === 1
    const isLastStop = newPosition === stops.length
    if (isLastStop) {
      object.is_pickup = false
      object.is_dropoff = true
    } else if (isFirstStop) {
      object.is_dropoff = false
      object.is_pickup = true
    }
    if (isFirstStop || isLastStop) {
      this.updateBusStop({ ...busStop, ...object })
    }
    return { ...busStop, ...object }
  }

  /**
   * Silently update bus-stop position
   * @param busStop {Object}
   * @return {void}
   * */
  updateBusStop (busStop) {
    this.axios
      .patch(`/v1/route-busstops/${busStop.id}`, {
        is_pickup: busStop.is_pickup,
        is_dropoff: busStop.is_dropoff
      })
      .catch((e) => {})
  }
}

const routeBusStopService = new RouteBusstopService(axiosInstance)
export default routeBusStopService
