import { loadRouteComponent } from '@/utils/helpers'

const BusstopRoutes = [
  {
    path: 'bus-stops',
    component: loadRouteComponent('BusStops/Index'),
    name: 'BusStopsIndex',
    props: true
  },
  {
    path: 'bus-stops/new',
    component: loadRouteComponent('BusStops/Create'),
    name: 'BusStopsCreate',
    props: true
  }
]

export default BusstopRoutes
