import { loadRouteComponent } from '@/utils/helpers'
import NewRoute from '@/views/Routes/NewRoute.vue'
import ListRoutes from '@/views/Routes/ListRoutes.vue'
import MainRoutes from '@/views/Routes/pages/mainRoute.vue'
import SuspendedRoutes from '@/views/Routes/pages/suspendedRoute.vue'
import ShowRouteBusStops from '@/views/Routes/ShowRouteBusStops.vue'
import ShowRouteDetails from '@/views/Routes/ShowRouteDetails.vue'
import ShowRouteTripsList from '@/views/Routes/ShowRouteTripsList.vue'
import NoRouteItinerary from '@/views/Routes/NoRouteItinerary.vue'
import NewRouteItinerary from '@/views/Routes/NewRouteItinerary.vue'
import EditRoute from '@/views/Routes/EditRoute.vue'
import AuctionRoutes from '@/views/Routes/pages/auctionRoutes.vue'

import RouteItinerary from '@/views/Routes/RouteItinerary'
import Auction from '@/views/Routes/Auction.vue'
import AuctionDetails from '@/views/Routes/AuctionDetails'

import Routes from '@/layouts/modules/Routes/Index.vue'
import RouteDetails from '@/layouts/modules/Routes/RouteDetails.vue'
import RouteItineraries from '@/layouts/modules/Routes/RouteItineraries.vue'
import RouteTrips from '@/layouts/modules/Routes/RouteTrips.vue'


function getRouteItineraryRoute()
{
  return {
    path: ':itineraryId',
    name: 'ShowRouteItinerary',
    component: RouteItinerary,
    props: true,
    meta: {
      requiredPermissions: [
        'routes006' 
      ]
    }
  }
}


const RoutesModule = [
  {
    path: 'routes',
    component: Routes,
    props: true,
    children: [
      {
        path: '',
        component: ListRoutes,
        name: 'RoutesList',
        props: true,
        meta: {
          requiredPermissions: ['routes002']
        },
        children: [
          { path: '', component: MainRoutes, name: 'MainRoutes' },
          { path: 'suspended', component: SuspendedRoutes, name: 'SuspendedRoutes' },
          { path: 'auctions', component: AuctionRoutes, name: 'AuctionRoutes' },
        ]
      },
      {
        path: 'new',
        name: 'NewRoute',
        component: NewRoute,
        props: true,
        meta: {
          requiredPermissions: ['routes001']
        }
      },
      {
        path: ':routeId',
        component: RouteDetails,
        props: true,
        children: [
          {
            path: '',
            name: 'ShowRoute',
            redirect: 'details'
          },
          {
            path: 'details',
            name: 'ShowRouteDetails',
            component: ShowRouteDetails,
            props: true,
            meta: {
              requiredPermissions: ['routes017']
            }
          },
          {
            path: ':bookingInfo/bookings',
            name: 'ShowRouteBookings',
            component: loadRouteComponent('Routes/ShowRouteBookings'),
            props: true,
            meta: {
              requiredPermissions: ['routes017']
            }
          },
          {
            path: 'itineraries/new',
            name: 'NewRouteItinerary',
            component: NewRouteItinerary,
            props: true,
            meta: {
              requiredPermissions: [
                'routes005'
              ]
            }
          },
          {
            path: 'itineraries/auction',
            name: 'CreateItinerariesDriverAuction',
            component: Auction,
            props: true,
            meta: {
              requiredPermissions: []
            }
          },
          {
            path: 'itineraries/auction-details',
            name: 'ItinerariesDriverAuctionDetails',
            component: AuctionDetails,
            props: true,
            meta: {
              requiredPermissions: []
            }
          },
          {
            path: 'itineraries',
            component: RouteItineraries,
            props: true,
            children: [
              {
                path: '',
                name: 'ShowRouteItineraries',
                component: NoRouteItinerary,
                props: true,
                meta: {
                  requiredPermissions: [
                    'routes007'
                  ]
                }
              },
              {
                path: 'auction',
                name: 'CreateItinerariesDriverAuction',
                component: Auction,
                props: true,
                meta: {
                  requiredPermissions: [
                    'routes007'
                  ]
                }
              },
              getRouteItineraryRoute()
            ]
          },
          {
            path: 'trips',
            component: RouteTrips,
            props: true,
            children: [
              {
                path: '',
                name: 'ShowRouteTrips',
                component: ShowRouteTripsList,
                props: true,
                meta: {
                  requiredPermissions: ['trips001', 'trips002', 'trips003']
                }
              }
            ]
          },
          {
            path: 'bus-stops',
            name: 'ShowRouteBusStops',
            component: ShowRouteBusStops,
            props: true,
            meta: {
              requiredPermissions: ['routes017']
            }
          }
        ]
      },
      {
        path: ':routeId/edit',
        name: 'EditRoute',
        component: EditRoute,
        props: true,
        meta: {
          requiredPermissions: [ 'routes003']
        }
      }
    ]
  }
]

export default RoutesModule
