<template>
	<div>
		<div>
			<div class="form-group">
				<input type="text" class="form-control" v-model="form.name" />
			</div>
			<div class="form-group mb-0">
				<b-form-checkbox class="v-checkbox" v-model="form.isPickup">
					Enable as pickup location
				</b-form-checkbox>
			</div>
			<div class="form-group mb-0">
				<b-form-checkbox class="v-checkbox" v-model="form.isDropOff">
					Enable as drop-off location
				</b-form-checkbox>
			</div>
			<div class="d-flex justify-content-end mt-3">
				<button @click="cancelEdit" class="btn btn-sm btn-text text-danger mr-2">
					Cancel
				</button>
				<button
					class="btn btn-sm btn-primary"
					@click="save"
					:disabled="isProcessing">
					<span>{{ isProcessing ? 'Saving...' : 'Save' }}</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { extractErrorMessage } from '@/utils/helpers'

export default {
  props: {
    name: String,
    routeId: [Number, String],
    lat: [Number],
    lng: [Number]
  },
  data () {
    return {
      isProcessing: false,
      form: {
        name: '',
        isPickup: true,
        isDropOff: true
      }
    }
  },
  watch: {
    name (value) {
      this.form.name = value
    }
  },
  mounted () {
    this.form.name = this.name
  },
  methods: {
    cancelEdit () {
      this.$emit('cancelEdit')
      this.clearForm()
    },
    clearForm () {
      this.form = {
        name: '',
        isDropOff: true,
        isPickup: true
      }
    },
    async handleCreateRouteBusStop () {
      try {
        this.isProcessing = true
        this.$Progress.start()
        const { data } = await this.axios.post(
          `/v1/routes/${this.routeId}/busstops`,
          {
            name: this.form.name,
            is_pickup: this.form.isPickup,
            is_dropoff: this.form.isDropOff,
            geometry: `${this.lat},${this.lng}`
          }
        )
        this.$emit('busStopCreated', data.data)
        this.isProcessing = false
        this.$Progress.finish()
      } catch (e) {
        this.isProcessing = false
        this.$Progress.fail()
        this.$swal('Could not create route bus stop', extractErrorMessage(e, ''), 'danger')
      }
    },
    save () {
      this.handleCreateRouteBusStop()
    }
  }
}
</script>
<style lang="scss" scoped>

::v-deep .v-checkbox {
  .custom-control-input {
    height: 1rem;
  }

  .custom-control-label {
    &:before, &:after {
      top: 0;
    }
  }
}

</style>
