import { loadRouteComponent } from '@/utils/helpers'
import Request from '@/layouts/modules/Request/Index.vue'
import RequestDetails from '@/layouts/modules/Request/RequestDetails.vue'
import RequestList from '@/layouts/modules/Request/RequestList.vue'

const CompanyRoutes = [
  {
    path: 'request',
    component: Request,
    children: [
      {
        path: '',
        component: RequestList,
        props: true,
        
        children: [
          { 
            path: '',
            component: loadRouteComponent('Request/pages/List/Index'),
            name: 'Shuttle Requests',
            props: true,
            meta: {
              requiredPermissions: []
            }
          }
        ]
      },

      {
        path: ':requestId',
        component: RequestDetails,
        props: true,
        children: [
          {
            path: '',
            name: 'Shuttle',
            redirect: 'request-info',
            // props: true,
            meta: {
              requiredPermissions: ['staff001', 'staff002', 'staff003', 'staff004', 'staff005', 'staff006', 'staff007', 'staff008']
            }
          },
          {
            path: 'request-info',
            name: 'ShuttleInformation',
            component: loadRouteComponent('Request/pages/show/requestInfo'),
            props: true,
            meta: {
              requiredPermissions: ['staff001', 'staff002', 'staff003', 'staff004', 'staff005', 'staff006', 'staff007', 'staff008']
            }
          },
          {
            path: 'request-staff',
            name: 'ShuttleStaffList',
            component: loadRouteComponent('Request/pages/show/staffList'),
            props: true,
            meta: {
              requiredPermissions: ['corporates003']
            }
          },
        ]
      },
    ]
  }
]

export default CompanyRoutes
