var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"route-controller"},[_c('div',{staticClass:"bg-white border-bottom"},[_c('div',{staticClass:"header mb-0"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"header-body border-0"},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"header-pretitle"},[_c('router-link',{attrs:{"to":{ name: 'RoutesList' }}},[_vm._v("Routes")])],1),(_vm.activeRoute)?_c('h2',{staticClass:"header-title"},[_vm._v(" "+_vm._s(_vm.activeRoute.route_code)+" • "+_vm._s(_vm.activeRoute.pickup)+" - "+_vm._s(_vm.activeRoute.destination)+" ")]):_vm._e()]),_c('div',{staticClass:"col-auto"},[_c(_setup.RouteListItemMenu,{attrs:{"route":_vm.activeRoute,"show-label":true}})],1)])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',[_c('ul',{staticClass:"nav nav-pills mb-1"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowRouteDetails',
										params: { routeId: _vm.routeId },
									},"exact-active-class":"active"}},[_vm._v("Details")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowRouteBusStops',
										params: { routeId: _vm.routeId },
									},"exact-active-class":"active"}},[_vm._v("Bus Stops")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowRouteItineraries',
										params: { routeId: _vm.routeId },
									},"exact-active-class":"active","active-class":"active"}},[_vm._v("Itineraries")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
										name: 'ShowRouteTrips',
										params: { routeId: _vm.routeId },
									},"exact-active-class":"active","active-class":"active"}},[_vm._v("Trips")])],1)])])])])])]),_c('div',[_c('router-view')],1),_c(_setup.BS_modal,{attrs:{"show":_setup.modalStatus,"title":"Duplicate Routes","modalId":"duplicateRouteModalOne"},on:{"close":_setup.closeDuplicateRouteModal}},[_c('div',{staticClass:"flex flex-col gap-4"},[_c('div',{staticClass:"flex flex-col"},[_c('label',{staticClass:"m-0"},[_vm._v("New route code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.new_route_code),expression:"new_route_code"}],staticClass:"p-2 border rounded-md",attrs:{"type":"text"},domProps:{"value":(_setup.new_route_code)},on:{"input":function($event){if($event.target.composing)return;_setup.new_route_code=$event.target.value}}})]),_c('div',{staticClass:"flex items-center gap-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.copy_itinary),expression:"copy_itinary"}],attrs:{"type":"checkbox","id":"iti"},domProps:{"checked":Array.isArray(_setup.copy_itinary)?_vm._i(_setup.copy_itinary,null)>-1:(_setup.copy_itinary)},on:{"change":function($event){var $$a=_setup.copy_itinary,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_setup.copy_itinary=$$a.concat([$$v]))}else{$$i>-1&&(_setup.copy_itinary=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_setup.copy_itinary=$$c}}}}),_c('label',{staticClass:"m-0",attrs:{"for":"iti"}},[_vm._v("Copy Itineraries")])]),_c('button',{staticClass:"btn bg-dark text-white w-full mt-[30px]",attrs:{"disabled":_setup.new_route_code == ''},on:{"click":_setup.proceedToDuplicate}},[_vm._v(" "+_vm._s(_setup.dup_loading ? 'loading...' : 'Duplicate route')+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }